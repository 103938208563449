import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button, Table, Stack,Modal } from 'react-bootstrap';
import { NavLink,useParams } from "react-router-dom";
import './MerReceipts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';

const MerRefundReceipts = () => {
	const { id } = useParams();
	const [loading,setLoading] = useState(false);
	const [orderdetail,setOrderdetail] = useState([]);
	const [inputs,setInputs] = useState({store_name:'',store_address:'',store_mobile:'',order_number:'',date:'',store_image:'',store_zip:'',saving_amount:0,sales_tax_rate_per:0,sales_tax_rate:0,subtotal:'',total:'',tips:'',amount:'',total_due:'',user_address_title:'',user_address_street_name:'',user_address_state:'',user_address_city:'',user_address_zip_code:'',user_mobile:'',user_name:'',payment_txn_id:'',payment_type:'',payment_card_name:'',payment_card_number:'',payment_card_month:'',payment_card_year:'',due_date:'',refund_price:'',refund_discount:'',refund_tax:'',refund_total:'',refund_date:'',employee_name:'',total_return:0,return_coupon_name:''});
	
	const [coupon,setCoupon] = useState([]);
	
	const [showhelp, setShowhelp] = useState(false);
	const handleCloseHelp = () => { setShowhelp(false); }
	
useEffect(() => {
      document.title = `Merchant Receipts | Blue Faucet`;
      document.getElementById('bodywrap').className='usrrcptcpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get receipt data */
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	API.post('get_product_or_service_order_with_order_id', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setOrderdetail(response.data.data.order_detail);
			
			var sales_tax_rate_per=response.data.data.sales_tax_rate_per;
			var sales_tax_rate=response.data.data.sales_tax_rate;
			
			var saving_amount=0;
			if(response.data.data.saving_amount!=='')
			{
				saving_amount=parseFloat(response.data.data.saving_amount);
			}
			var subtotal=parseFloat(response.data.data.subtotal);
			var total=subtotal-saving_amount;
			
			var tips=parseFloat(response.data.data.tips);
			var total_due=total+tips+sales_tax_rate;
			
			saving_amount=saving_amount.toFixed(2);
			
			sales_tax_rate=sales_tax_rate.toFixed(2);
			
			subtotal=subtotal.toFixed(2);
			
			tips=tips.toFixed(2);
			
			total_due=total_due.toFixed(2);
			
			
			var refund_price=response.data.data.refund_price;
				var refund_discount=response.data.data.refund_discount;
				var refund_tax=response.data.data.refund_tax;
				var refund_total=response.data.data.refund_total;
				refund_price=refund_price.toFixed(2);
				refund_discount=refund_discount.toFixed(2);
				refund_tax=refund_tax.toFixed(2);
				refund_total=refund_total.toFixed(2);
			
			setInputs({
				store_name:response.data.data.store_name,
				store_address:response.data.data.store_address,
				store_mobile:response.data.data.store_mobile,
				order_number:response.data.data.order_number,
				date:response.data.data.order_date,
				store_image:response.data.data.store_image,
				store_zip:response.data.data.store_zip,
				saving_amount:saving_amount,
				sales_tax_rate_per:sales_tax_rate_per,
				sales_tax_rate:sales_tax_rate,
				subtotal:subtotal,
				total:total,
				tips:tips,
				amount:response.data.data.amount,
				total_due:total_due,
				user_address_title:response.data.data.user_address_title,
				user_address_street_name:response.data.data.user_address_street_name,
				user_address_state:response.data.data.user_address_state,
				user_address_city:response.data.data.user_address_city,
				user_address_zip_code:response.data.data.user_address_zip_code,
				user_mobile:response.data.data.user_mobile,
				user_name:response.data.data.user_name,
				payment_txn_id:response.data.data.payment_txn_id,
				payment_type:response.data.data.payment_type,
				payment_card_name:response.data.data.payment_card_name,
				payment_card_number:response.data.data.payment_card_number,
				payment_card_month:response.data.data.payment_card_month,
				payment_card_year:response.data.data.payment_card_year,
				due_date:response.data.data.due_date,
				refund_price:refund_price,
				refund_discount:refund_discount,
				refund_tax:refund_tax,
				refund_total:refund_total,
				refund_date:response.data.data.refund_date,
				employee_name:response.data.data.employee_name,
				total_return:response.data.data.total_return,
				return_coupon_name:response.data.data.return_coupon_name,
			});
			
			setCoupon(response.data.data.coupon);
		}                  
	}).catch(error => {
		 
	});  
	 /* End get receipt data */
	
},[id])  

const addressview = (user_address_street_name,user_address_state,user_address_city,user_address_zip_code) => {
	var html='';
	if(user_address_street_name!=='')
	{
		html+=user_address_street_name+', ';
	}
	if(user_address_state!=='')
	{
		html+=user_address_state+', ';
	}
	if(user_address_city!=='')
	{
		html+=user_address_city+', ';
	}
	if(user_address_zip_code!=='')
	{
		html+=user_address_zip_code;
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}

const handleShowHelp = () => {
	setShowhelp(true);
}

    return (
        <>
      {/* ========== Merchant Receipt Page Start ========== */}
         <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Refund Receipt'}/>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-2">
      {/* ========== Receipt Content Start ========== */}
               <Row className="mx-0 maincnt-recepts">
                  <Col sm={10} xs={12} className="mrrtop">
                  <h3>{inputs.store_name}</h3>
                  <p>{inputs.store_address} , {inputs.store_zip}</p>
                  <p>Phone: {inputs.store_mobile} </p>
				  {/*<h2 className="mt-4 cus-add-lab">Customer Address</h2>
				  <h3>{inputs.user_name}</h3>
				  <h3>{inputs.user_address_title}</h3>
                  <p>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</p>
                  <p>Phone: {inputs.user_mobile}</p>*/}
				  <div className="left-cnt-rcptr">
					 <h4>Customer Name: <span>{inputs.user_name}</span></h4>
				     <h4>Contact No: <span>{inputs.user_mobile}</span></h4>
					 {inputs.user_address_street_name!=='' ?
					<h4>Customer address: <span>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</span></h4>
						: null }
                    
				  </div>
				  
                  <div className="dwncmt">
                     {/*<h4>Serviced by: <span>xxxxxxxxxxxx</span></h4>*/}
                     <h5>Information:</h5>
					 <h4>Schedule date & time:<span>{inputs.due_date}</span></h4>
                     <h4>Invoice No.<span>{inputs.order_number}</span></h4>
                     <h4>Invoice Date: <span>{inputs.date}</span></h4>
					 {inputs.employee_name!=='' ?
					<h4>Employee Name: <span>{inputs.employee_name}</span></h4>
						: null }
                  </div>
                  </Col>
                  <Col sm={2} xs={12} className="text-end left-cnt-rcpt mrrmid">
                  <img src={inputs.store_image} className="img-fluid ps-2" alt="Hm Img"/>
                  {/*<h4>Due Date:<span>20May 2023</span></h4>*/}
                  </Col>
                  <Col md={12} xs={12} className="tblinvcpg mrrbot">
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
                           <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
					 {orderdetail && orderdetail.map((val,index) => {
					   return(
                        <tr className={val.status ? "textdecoration-line-through" : ""}>
                           <td>{index+1}</td>
                           <td>{val.product_name}</td>
                           <td>${val.product_price}</td>
                           <td>{val.qty===0 ? 1 :val.qty}</td>
                           <td>${val.total}</td>
                        </tr>
						 )
					  })}
                     </tbody>
                  </Table>
				  <Row>
				  <Col sm={6} xs={12} className="py-2 mrrrileft">
					<div className="text-left tbldwndv">
						<h3>Refund information <NavLink to="#" onClick={() =>handleShowHelp()}><img src={process.env.REACT_APP_BASE_URL + '/assets/images/green.png'} className="img-fluid img-help" alt="Logo"/></NavLink></h3>
						<p><b>The Price after refund and discount: <span className="text-danger">${inputs.refund_price}</span></b></p>
							{/*<p>Coupon or Discount: <span className="text-danger">-${inputs.refund_discount}</span></p>*/}
						<p><b>The tax for the price: 	 <span className="text-danger">${inputs.refund_tax}</span></b></p>
						<p><b>Refund Total Amount: <span className="text-danger">${inputs.refund_total}</span></b></p>
						<p><b>Refund Date: <span className="text-danger">{inputs.refund_date}</span></b></p>
						{inputs.total_return >0 && (
						<>
						<p><b><span className="text-danger">{inputs.return_coupon_name}</span> cannot be applied to the order after a refund</b></p>
						
						{/*<p>The total refund amount is: <span className="text-danger">${inputs.total_return}</span></p>*/}
						</>
						)}
					</div>
				  </Col>
				  <Col sm={6} xs={12} className="py-2 mrrriright">
                  <div className="text-end tbldwndv">
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Subtotal</p>
                        </div>
                        <div className="pb-1"><span>${inputs.subtotal}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon or discount</p>
                        </div>
                        <div className="pb-1">-<span>${inputs.saving_amount}</span></div>
                     </Stack>
					 <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Tax ({inputs.sales_tax_rate_per}%)</p>
                        </div>
                        <div className="pb-1"><span>${inputs.sales_tax_rate}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Gratuity</p>
                        </div>
                        <div className="pb-1 ps-3"><span> {inputs.tips} </span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Due</h6>
                        </div>
                        <div className="">
                           <h6>${inputs.total_due}</h6>
                        </div>
                     </Stack>
                  </div>
				  </Col>
				  </Row>
				  {coupon.length >0 &&(
						<div className="mb-3 mt-3">
						<label className="form-label mb-4">Coupon</label>
					  <Row>
					  
						<Col md={12} xs={12}>
							<ul className="sldr-add">
								{coupon.length > 0 && coupon.map((item,index) => {
							return(<li className="sldr-in-add">
								<div className="row mincrd">
								<Col md={12} xs={12} className="align-self-center mb-2">
									<h4>{item.coupon_type}</h4>
								   </Col>
									
									<Col md={12}>
										{(() => {
										if (item.coupon_type==='Buy & Get') {
											return (<p>Buy {item.buy_quantity} get {item.get_quantity} free,for {item.product_item_name}</p>)
										}else if(item.coupon_type==='Discount Offer'){
											return (  <p>{item.discount_percentage} % Discount When Purchase Over ${item.minimum_purchase_amount}</p>)
										}else if(item.coupon_type==='Loyalty Setup'){
											return (<p>Buy {item.buy_quantity_needed} {item.product_applicable_item_if_any} get {item.product_get_item_name} free</p>)
										}else if(item.coupon_type==='Invitation'){
											return (<p>{item.product_item_name}</p>)
										}else if(item.coupon_type==='Welcome coupon'){
													return (<p>{item.discount_percentage}% for all new members joining your membership has been set up</p>)
												}								
										})()}
									   {(() => {
										if (item.coupon_type!=='Welcome coupon') {
											return (<p>{item.description}</p>)
										}									
										})()}
									</Col>
									</div>
								</li>
								)})}
							</ul>
						</Col>
						
						</Row>
						</div>
					  )}
                  </Col>
               </Row>
      {/* ========== Receipt Content End ========== */}
               <Col className="rcptbtn mt-3 d-none"><NavLink to="#"><Button variant="" type="submit"> Apply For Refund</Button></NavLink></Col>
               </Col>
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
         </section>
      {/* ========== Merchant Receipt Page End ========== */}
	  {/* ========== Help Popup Start ========== */}
		<Modal show={showhelp} onHide={handleCloseHelp} className="popupwrngcnt" centered>
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Refund Calculation How Works</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col md={12} xs={12} className="tblinvcpg help-text-justify ">
				  <p>Your refund request involves coupons that were applied for a discount, which may be adjusted due to coupon requirements. Here is the equation to recalculate your refund based on the new order:
Product Price Difference after Discount + Tax Difference = Total Refund Amount</p>
				</Col>
							 
                             
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
	  {/* ========== Help Popup End ========== */}
        </>
    )
}
export default MerRefundReceipts;