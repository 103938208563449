import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import './UserOrdpymt.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api'; 
import loader from '../../../assests/loader.gif';


const UserOrdpymt = () => {
	const [orderinvoice, setOrderinvoice] = useState([]);
	const [orderrev, setOrderrev] = useState([]);
	const [loading,setLoading] = useState(true);
	
useEffect(() => {
    document.title = `User Order Payments | Blue Faucet`;
    document.getElementById('bodywrap').className='usrnw usordrpymtpg uf1';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

	/* Start get order reciept */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_order', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setOrderrev(response.data.data);
				
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order reciept */
	 /* Start get order invoice */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('service_invoice', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setOrderinvoice(response.data.data);
				
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order invoice */

},[])  

const linkset = (id,status) => {
	var html='';
	if(status===1 || status===8){
		html='/receipts/'+id;
	}else if(status===5){
		html='/refund-receipt/'+id;
	}else{
		html='/order-summary/'+id;
	}
	return html;
}
const linksetser = (id,status) => {
	var html='';
	if(status===1 || status===8){
		html='/receipts-service/'+id;
	}else if(status===5){
		html='/refund-receipt-service/'+id;
	}else{
		html='/order-summary-service/'+id;
	}
	return html;
}

    return (
        <>
        {/* ========== User Order Payment Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={'Orders & Payments'}/>
         {/* ========== Navbar End ========== */}
           <Container>
              <Row className="mx-0 mianhmdwncnt">
                 <Col className="mnhmcnt">
                 <Col md={12} xs={12} className="py-2">
                 {/* ========== Content Box Start ========== */}
                 <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                       <Col md={12} sm={12} className="tpuspymt-tbpill px-0">
                       <Nav variant="pills" className="d-flex">
                          <Nav.Item>
                             <Nav.Link eventKey="first">Invoices</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                             <Nav.Link eventKey="second">Receipts/Refund</Nav.Link>
                          </Nav.Item>
                       </Nav>
                       </Col>
                       <Col md={12} sm={12}>
                       <Tab.Content>
                          <Tab.Pane eventKey="first">
						  {orderinvoice && orderinvoice.map((val,index) => {
							return(
                             <Col className="fsttb-bxcnt mb-1">
							 {val.type==='service' ? (  
								 <NavLink to={`/invoice/${val.id}`}>
									<Row className="mx-0 fsttbcnt">
									   <Col md={2} xs={2} className="align-self-center">
									   <img src= {val.image} className="img-fluid main-img" alt="Sld Img"/></Col>
									   <Col md={5} xs={5} className="align-self-center">
									   <h3>{val.store_name}</h3>
									   <p>{val.date}</p>
									   </Col>
									   <Col md={5} xs={5} className="align-self-center text-end fstrgtcnt">
									   <h5>${val.price}</h5>
									   
									   </Col>
									</Row>
								 </NavLink>
								) : (
									<NavLink to={`/invoice-product/${val.id}`}>
										<Row className="mx-0 fsttbcnt">
										   <Col md={2} xs={2} className="align-self-center">
										   <img src= {val.image} className="img-fluid main-img" alt="Sld Img"/></Col>
										   <Col md={5} xs={5} className="align-self-center">
										   <h3>{val.store_name}</h3>
										   <p>{val.date}</p>
										   </Col>
										   <Col md={5} xs={5} className="align-self-center text-end fstrgtcnt">
										   <h5>${val.price}</h5>
										   
										   </Col>
										</Row>
									</NavLink>
								)}
                             </Col>
                             )
							})} 
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
						  {orderrev && orderrev.map((val,index) => {
							return(
                             <Col className="ordrtb-bxcnt mb-1">
                              {val.type==='service' ? (                        
                             <NavLink to={linksetser(val.id,val.status)} >
                                <Row className="mx-0 ordrtbcnt main-ord-box">
                                   	<Col md={2} xs={2} className="align-self-center">
                                  		<img src={val.image} className="img-fluid main-img" alt="Sld Img"/>
                                  	</Col>
                                   	<Col md={5} xs={5} className="align-self-center">
                                   		<h3>{val.store_name}</h3>
                                   		<p>Ordered on {val.date}</p>
								   				<p>{val.order_number}</p>
                                   	</Col>
                                   	<Col md={5} xs={5} className="align-self-center text-end">
								   				<p className="arwtxt">{val.status_name} </p>
                                   		<img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/>
                                   	</Col>
                                </Row>
                             </NavLink>
							 ) : (
								<NavLink to={linkset(val.id,val.status)} >
                                <Row className="mx-0 ordrtbcnt main-ord-box">
                                   <Col md={2} xs={2} className="align-self-center">
                                   <img src={val.image} className="img-fluid main-img" alt="Sld Img"/></Col>
                                   <Col md={5} xs={5} className="align-self-center">
                                   <h3>{val.store_name}</h3>
                                   <p>Ordered on {val.date}</p>
								   <p>{val.order_number}</p>
                                    </Col>
                                    <Col md={5} xs={5} className="align-self-center text-end">
								   				<p className="arwtxt">{val.status_name} </p> 
                                   		<img src= {'assets/images/svg/arw-rgt.svg'} className="img-fluid" alt="Sld Img"/>
                                    </Col>
                                </Row>
                             </NavLink>
								)}
                             </Col>
                             )
							})} 
                          </Tab.Pane>
                       </Tab.Content>
                       </Col>
                    </Row>
                 </Tab.Container>
                 {/* ========== Content Box End ========== */}
                 </Col>
                 </Col>
              </Row>
			  {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
           </Container>
        </section>
        {/* ========== User Order Payment Page End ========== */}
        {/*<Footer />*/}
        </>
    )
}
export default UserOrdpymt;