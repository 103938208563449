import React, { useEffect,useState } from 'react';
import { Row, Col, Container,Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerCal.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
// import $ from 'jquery';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import moment from 'moment';


const MerCal = () => {
const [loading,setLoading] = useState(true);

//const events = [{ title: "Appointment", date: new Date(),className: 'event-green'  },{ title: "Appointment 1", date: '2023-08-13T01:00:00',className: 'event-yellow'  },{ title: "Appointment 2", date: '2023-08-14T02:00:00',className: 'event-yellow1'  },{ title: "Appointment 3", date: '2023-08-15T01:00:00',className: 'event-yellow2'  },{ title: "Appointment 4", date: '2023-08-16T02:00:00',className: 'event-light-gray'},{ title: "Appointment 5", date: '2023-08-17T05:00:00',className: 'event-green'},{ title: "Appointment 6", date: '2023-08-18T01:00:00',className: 'event-yellow'  },{ title: "Appointment 7", date: '2023-08-19T02:00:00',className: 'event-yellow1'  },{ title: "Appointment 8", date: '2023-08-20T01:00:00',className: 'event-yellow2'  },{ title: "Appointment 9", date: '2023-08-21T02:00:00',className: 'event-light-gray'},{ title: "Appointment 10", date: '2023-08-17T02:00:00',className: 'event-green'}];
const [events,setEvents] = useState([]);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);

const [ord, setord] = useState([]);

useEffect(() => {
      document.title = `Merchant Calender | Blue Faucet`;
      document.getElementById('bodywrap').className='clndrpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
    
	/* Start get time slot */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_marchant_calendar_data', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setEvents(response.data.data);
				
			}                  
		}).catch(error => {
			 
		});  
	 /* End get time slot */
    
},[])  
const renderDayHeader = ({ date }) => {
    const formattedDate = date.toLocaleString('default', {
      weekday: 'short',
      day: 'numeric',
    });
    let array = formattedDate.split(" ");
    
    return <span>{array[1]} <p>{array[0]}</p></span>;
  };
  const handleEventClick = (info) => {
	  const startDate = info.event.start;
	  const start_date = moment(startDate).format('YYYY-MM-DD');
	  let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('date', start_date);
		API.post('get_order_by_date_and_marchant_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setord(response.data.data);
				setShow1(true);
			}			  
		}).catch(error => {
			 
		});  
  };
  
 const handlelink = (id,status) => {
	var html='#';
	if(status===1 || status===8){
		html='/mer-receipts/'+id;
	}else if(status===5){
		html='/mer-refund-receipts/'+id;
	}else if(status===7){
		html='/mer-refund-request/'+id;
	}else{
		html='/mer-order-detail/'+id;
	}
	return html;
}
  
    return (
        <>
        
        {/* ========== Merchant Calender Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Calender'}/>
            {/* ========== Navbar End ========== */}
               <Container className="px-0">
                  <Row className="mx-0 mianhmdwncnt">
                     <Col md={12} xs={12} className="mnhmcnt my-3 px-0">
                     <Col md={12} xs={12} className="clndrmaindv px-0">
                {/* ========== Calender Content Start ========== */}
                     <FullCalendar
                     editable
                     selectable
                     events={events}
                     contentHeight='auto'
                     slotLabelFormat= {
                     {  
						hour: 'numeric'
                     }}
                     headerToolbar={{
						 start: "prev, next",
						 center: 'title',
						 right: "dayGridMonth,timeGridWeek,timeGridDay"
                     }}
                     plugins={[timeGridPlugin]}
                     initialView="timeGridWeek"
                     allDaySlot={false}
                     views={{
                     week: {
						titleFormat: { month: 'long',year:'numeric' },
                     },
                     }}
                     dayHeaderContent={renderDayHeader}
                     eventClick={handleEventClick}
                     />
                {/* ========== Calender Content End ========== */}
                     </Col>
                     </Col>
                  </Row>
				  
				  
				  {/* ========== Order Popup Box Start ========== */}
               <Modal size="lg" show={show1} onHide={handleClose1} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                  <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Order</h3>
                       </Modal.Header>
				  <Modal.Body>
                     <Row className="mx-0 topppdtlmcstmr">
					 {ord.length >0 ? (
					 <div>
                        {ord && ord.map((val,index) => {
						return(
                        <Col md={12} xs={12} className="mcusbx">
							<NavLink to={handlelink(val.id,val.status)}>
							   <Row className="mx-0 main-ord-box mer-cal">
								  <Col md={2} xs={2} className="align-self-center px-1">
								  <img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></Col>
								  <Col md={7} xs={7} className="align-self-center mcusbx-txtcnt ps-md-2 ">
								  <h3>{val.name}</h3>
								  <p>{val.date}</p>
								  </Col>
								  <Col md={3} xs={3} className="align-self-center text-end">
								  <span className="arwtxt">{val.status_name}</span>
								  
								  </Col>
							   </Row>
							</NavLink> 
                        </Col>
                        )
					  })} 
					  </div>
					  ) : (
						<Row className="mx-0">
							<p className="text-center mt-4">No data found</p>
						</Row>
				 )} 
                     </Row>
                  </Modal.Body>
               </Modal>
               {/* ========== Order Popup Start ========== */}
				  
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )} 
               </Container>
            </section>
            {/* ========== Footer End ========== */}
              <Footer/>
            {/* ========== Footer End ========== */}
        {/* ========== Merchant Calender Page End ========== */}
        </>
    )
}

export default MerCal;