import React, { useEffect, useState } from 'react';
import {  Row, Col, Container, Button,Table,Tab,Nav } from 'react-bootstrap';
import { useNavigate,useParams } from "react-router-dom";
import './UserOrdrsmry.css';
import './slder.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
//import OwlCarousel from 'react-owl-carousel'; 
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

//import { useNavigate } from 'react-router-dom';
// import $ from 'jquery';
import { Stepper } from "@progress/kendo-react-layout";
import { Form, FormElement } from "@progress/kendo-react-form";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import { PaymentForm } from "./PaymentForm";
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastwarning } from '../../../Utils/Common';

const UserOrdrsmrynewstp3 = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [loading,setLoading] = useState(true);
    const [stripeset,setStripeset] = useState('');

	const [stripechk,setStripechk] = useState(true);
	
	const [inputs,setInputs] = useState({order_id:'',order_number:'',user_name:'',mobile:'',store_id:'',order_total:0,savings_total:0,garnd_total:0,payable_total:0,sales_tax_rate_per:0,sales_tax_rate:0,tips:0,date:'',credit_card_name:'',credit_card_number:'',credit_card_month:'',credit_card_year:'',credit_card_cvv:'',debit_card_name:'',debit_card_number:'',debit_card_month:'',debit_card_year:'',debit_card_cvv:''});
	const [orderdata,setOrderdata] = useState([]);
	
	//const [coupon,setCoupon] = useState([]);
	
	//const [couponid,setCouponid] = useState([]);
	
	const [key, setKey] = useState('first');
	

	
  const [title, setTitle] = useState('Contact Setup');
   const [step, setStep] = React.useState(1);
    
   const [formState, setFormState] = React.useState({});
   const [steps, setSteps] = React.useState([
    {
      label: "Contact Setup",
      isValid: undefined,
    },
    {
      label: "Order Summary",
      isValid: undefined,
    },
    {
      label: "Pay The Bill",
      isValid: undefined,
    },
  ]);
   const firstStepIndex = steps.length - 2;
  const isFirstStep = firstStepIndex === step;  
     const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;
  const onStepSubmit = React.useCallback(
    (event) => {
      const { isValid, values } = event;
	
	  
      const currentSteps = steps.map((currentStep, index) => ({

        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));
  
      setSteps(currentSteps);
      setStep(() => Math.min(step + 1, lastStepIndex));
      setFormState(values);
      //setTitle(currentSteps[step+1]['label']);
      setTitle('Contact Setup');
      if (isLastStep && isPreviousStepsValid && isValid) {
        //window.location.href = '/product-order-confirmation';
      }
	  
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  );
  const onPrevClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );

useEffect(() => {
      document.title = `User Order Summary | Blue Faucet`;
      document.getElementById('bodywrap').className='uospgmain';

	  /* Start get order */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('id', id);
		API.post('get_order_by_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				var amount=parseFloat(response.data.data.subtotal);
				var tips=parseFloat(localStorage.getItem("book_tips"));
				var sales_tax_rate_per=parseFloat(response.data.data.sales_tax_rate_per);
				
				var saving_amount=0;
				if(response.data.data.saving_amount!=='')
				{
					saving_amount=parseFloat(response.data.data.saving_amount);
				}
				
				var amount1=amount-saving_amount;
				//var sales_tax_rate=amount1*sales_tax_rate_per/100;
				var sales_tax_rate=response.data.data.sales_tax_rate;
				var sales_tax_rate1=sales_tax_rate.toFixed(2);
				var payable_total=amount1+tips+parseFloat(sales_tax_rate1);
				
				saving_amount=saving_amount.toFixed(2);
				
				payable_total=payable_total.toFixed(2);
		
				
				
				setInputs({
					order_id:response.data.data.order_id,
					order_number:response.data.data.order_number,
					user_name:'',
					mobile:'',
					store_id:response.data.data.store_id,
					order_total:response.data.data.subtotal,
					savings_total:saving_amount,
					garnd_total:amount1,
					payable_total:payable_total,
					sales_tax_rate_per:sales_tax_rate_per,
					sales_tax_rate:sales_tax_rate1,
					tips:localStorage.getItem("book_tips"),	
					date:response.data.data.date,
					credit_card_name:response.data.data.user_credit_card_name,
					credit_card_number:response.data.data.user_credit_card_number,
					credit_card_month:response.data.data.user_credit_card_month,
					credit_card_year:response.data.data.user_credit_card_year,
					credit_card_cvv:'',
					debit_card_name:response.data.data.user_debit_card_name,
					debit_card_number:response.data.data.user_debit_card_number,
					debit_card_month:response.data.data.user_debit_card_month,
					debit_card_year:response.data.data.user_debit_card_year,
					debit_card_cvv:'',					
				});
				
				setOrderdata(response.data.data.order_array);
				//console.log(response.data.data.coupon);
				
				//const  tempArr = response.data.data.coupon.map(item => ({...item, status_chk: 1}))
				//setCoupon(tempArr);
				
				if(response.data.data.store_stripe_public_key==='')
				{
					setStripechk(false);
				}
				const stripePromise = loadStripe(response.data.data.store_stripe_public_key);
				setStripeset(stripePromise);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order */
	
},[id]) 

/* Start Value set */
const handleInputChangeTips = (event) => {
      event.persist();
	  if(event.target.value >0 || event.target.value==='')
	  {
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
	   var tips=event.target.value;
	   tips = (typeof tips !== 'undefined' && tips !== '' && !isNaN(tips)) ? parseFloat(tips) : 0;
	   var garnd_total=inputs.garnd_total;
	   
	   garnd_total = (typeof garnd_total !== 'undefined' && garnd_total !== '' && !isNaN(garnd_total)) ? parseFloat(garnd_total) : 0;
	   
	   var sales_tax_rate=parseFloat(inputs.sales_tax_rate);
	   
	   var total=parseFloat(tips+garnd_total+sales_tax_rate);
	   total=total.toFixed(2);
	   var payable_total_lbl='payable_total';
	   setInputs(inputs => ({...inputs, [payable_total_lbl]: total}));
	  }
}
/* End Value set */
const handleSubmit = () => {
			setLoading(true);
			let store_id=inputs.store_id;
			let tips=inputs.tips;
			var payment_type='';
			if(key==='first')
			{
				payment_type='cash';
			}else if(key==='second')
			{
				payment_type='credit';
			}else if(key==='third')
			{
				payment_type='debit';
			}
			var card_name='';
			var card_number='';
			var card_month='';
			var card_year='';
			var card_cvv='';
			if(payment_type==='credit')
			{
				card_name=inputs.credit_card_name;
				card_number=inputs.credit_card_number;
				card_month=inputs.credit_card_month;
				card_year=inputs.credit_card_year;
				card_cvv=inputs.credit_card_cvv;
			}else if(payment_type==='debit')
			{
				card_name=inputs.debit_card_name;
				card_number=inputs.debit_card_number;
				card_month=inputs.debit_card_month;
				card_year=inputs.debit_card_year;
				card_cvv=inputs.debit_card_cvv;
			}
			
			let formData = new FormData();
			formData.append('token', localStorage.getItem("token"));
			formData.append('amount', inputs.payable_total);
			formData.append('payment_type', payment_type);
			formData.append('card_name', card_name);
			formData.append('card_number', card_number);
			formData.append('card_month', card_month);
			formData.append('card_year',card_year);
			formData.append('card_cvv',card_cvv);
			formData.append('tips', tips);
			formData.append('store_id', store_id);
			formData.append('order_id', inputs.order_id);
			formData.append('coupon_id', JSON.stringify([]));
			formData.append('saving_amount', inputs.savings_total);
			formData.append('sales_tax_rate', inputs.sales_tax_rate);
			API.post('booked_order', formData)
                .then(response => {
                if(response.data.status === 0){
                    //alert(response.data.message);
					gettoastwarning(response.data.message,'');
                    setLoading(false); 
                }else{
					localStorage.removeItem("book_tips");
					setLoading(false);
				   navigate('/product-order-confirmation',{ state: { message:response.data.message } });
                }                  
            }).catch(error => {
                 setLoading(false);
				  //alert("Something went wrong. Please try again later.");
				  gettoastwarning("Something went wrong. Please try again later.",'');
			});  
}


    return (
        <>
        {/* ========== User Order Summary Page Start ========== */}
            <section className="gen-wrap">
			<ToastContainer />
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={title}/>
                <Col className="thstbox">
					{step === 0 ? (
					  <h3>Contact Setup</h3>
						) : undefined}
					{step === 1 ? (
					  <h3>Order Summary</h3>
						) : undefined} 
					{step === 2 ? (
					  <h3>Pay the Bill</h3>
						) : undefined}                  
                </Col>  		
            {/* ========== Navbar End ========== */}
             <Container>
                <Row className="">
                  <Col md={12} lg={12} xl={12} xxl={12} className="usrsmrypg px-0">
                  {/* ========== Order Summary content Start ========== */}
                   <ul className="hmtp-scldv">
                      <Row className="mx-0 ucl-sfbtn">
                         <div className="px-0" style={{display: "flex",flexDirection: "column",justifyContent: "center",}}>
                     <Stepper value={step} items={steps} />
                     <Form initialValues={formState} onSubmitClick={onStepSubmit} render={(formRenderProps) => (
                         <div md={11} xs={11} style={{margin: "0px 15px",}}>
                           <FormElement>
                             {/*stepPages[step]*/}
							{step === 0 ? ( 
							<div className="cntstp-smrypg">
								<h3>Contact Setup</h3>
								<div className="mb-3" controlId="exampleForm.ControlInput1">
									<label className="form-label">User Name</label>
									<input type="text" className="form-control" placeholder="ABCDEF" value={inputs.user_name} disabled/>
								</div>
								<div className="mb-3" controlId="exampleForm.ControlInput1">
									<label className="form-label">Phone Number</label>
									<input type="tel" className="form-control" placeholder="123456789" value={inputs.mobile} disabled/>
								</div>
								<div className="mb-3 ckbox" controlId="">
									<div key="default-1" className="mb-3">
										<input type="checkbox" id="default-1" label="I agree to share with Merchants when placing orders." defaultChecked={true}/> I agree to share with Merchants when placing orders.
									</div>
								</div>
								
								
							</div>
						   ) : ''}
						   {step === 1 ? ( 
						   <Row className="ordr-smrypg mx-0">
						   <Col md={3} xs={3} className="colrchng">Date:</Col> <Col md={9} xs={9}><p>{inputs.date}</p></Col>
						   {orderdata.length > 0 && orderdata.map((item,index) => {
							return(
							  <Row className="mx-0 tpord-sum">
								<Col md={8} xs={8} className=" mb-3"><h3>{item.product_name}</h3></Col>
								<Col md={4} xs={4} className="text-end mb-3">
								  <h3>{item.qty} X ${item.product_price}</h3>
								  <p className="colrchng">${item.total}</p>
								</Col>
								
								
							  </Row>
							 )
							})} 
							  
							   <Col className="dwnordsmry">
								 <h3 className="">Estimate price</h3>
								 <div className="line"></div>
								 <Table responsive>
								  <thead>
									<tr>
									  
									</tr>
								  </thead>
								  <tbody>
									<tr className="tp-lst">
									  <td>Order Total</td>
									  <td><h6>${inputs.order_total}</h6></td>
									</tr>
									<tr className="tp-lst line">
									  <td>Coupon or discount</td>
									  <td><h6 className="grnclr">${inputs.savings_total}</h6></td>
									</tr>
									<tr className="cnt-lst">
									  <td>SubTotal</td>
									  <td><h6>${inputs.garnd_total}</h6></td>
									</tr>
									<tr className="cnt-lst line">
									  <td>Tax ({inputs.sales_tax_rate_per}%)</td>
									  <td><h6>${inputs.sales_tax_rate}</h6></td>
									</tr>
									<tr className="cnt-lst">
									  <td>Gratuity</td>
									  <td><h6>${inputs.tips}</h6></td>
									</tr>
									<tr className="dwn-lst line">
									  <td>Total payable</td>
									  <td><h6 className="bluclr">${inputs.payable_total}</h6></td>
									</tr>
								  </tbody>
								</Table>
							   </Col>
							  </Row>
						      ) : ''}
							  
							 {step === 2 ? (  
							 <Row className="mx-0 pybl-smrypg">
								<Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
								  <Row>
									<Col md={6} xs={6} className="align-self-center low"><p>Conf. No:</p></Col>
									<Col md={6} xs={6} className="align-self-center text-end"><p>{inputs.order_number}</p></Col>
								  </Row>
								</Col>
								<Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
								<h3>Invoice</h3>
								<div className="line specer"></div>
								  <Row className="mx-0">
								  {/*<Col md={6} xs={6} className="align-self-center low"><p>Tax</p></Col>
								  <Col md={6} xs={6} className="align-self-center text-end"><p>$5.99</p></Col>*/}
									<Col md={6} xs={6} className="align-self-center low"><p>Tips</p></Col>
									<Col md={6} xs={6} className="align-self-center text-end"><input type="Number" className="form-control" placeholder="-" name="tips" min="1" value={inputs.tips} onChange={handleInputChangeTips} /></Col>
									<div className="line specer col-12"></div>
									<Col md={6} xs={6} className="align-self-center low"><p>Total Payable</p></Col>
									<Col md={6} xs={6} className="align-self-center text-end"><p className="bluclr">${inputs.payable_total}</p></Col>
								  </Row>
								</Col>
								<Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
								  <Row>
									<Col md={12} xs={12} className="">
									  <Tab.Container id="left-tabs-example" defaultActiveKey={key} onSelect={(k) => setKey(k)}>
											 <Nav variant="pills" className="flex-column">
											  <Row>
											  <Col md={12} xs={12} className="">
												<h3>Select Mode of Payment</h3>
											  </Col>
												<Col sm={4} xs={12}>
												  <Nav.Item>
												   <Nav.Link eventKey="first">Cash</Nav.Link>
												  </Nav.Item>
												</Col>
												{stripechk && (
												 <>
													<Col sm={4} xs={12}>
													  <Nav.Item>
													   <Nav.Link eventKey="second">Credit Card </Nav.Link>
													  </Nav.Item>
													</Col>
													<Col sm={4} xs={12}>
													  <Nav.Item>
													   <Nav.Link eventKey="third">Debit Card</Nav.Link>
													  </Nav.Item>
													</Col>
													</>
                                                )}												
											  </Row>
											  </Nav>
											  <Tab.Content>
												<Tab.Pane eventKey="first">
												 <Row xs={{ gutterX: 1 }} className="mx-0 mt-5">
													<button type="button" className="btn btn-primary" onClick={handleSubmit}>Pay The Bill</button>
												 </Row>
												</Tab.Pane>
												<Tab.Pane eventKey="second">
												
												
												
												<Elements stripe={stripeset}>
													<PaymentForm data={inputs} keydata={key}/>
												</Elements>
													
												</Tab.Pane>
												<Tab.Pane eventKey="third">
												<Elements stripe={stripeset}>
													<PaymentForm data={inputs} keydata={key}/>
												</Elements>
												  
												</Tab.Pane>
											  </Tab.Content>
											</Tab.Container>
									</Col>
								  </Row>
								</Col>
								</Row>
							   ) : ''}
						   
						  
                             <span style={{marginTop: "40px",}} className={"k-form-separator"}/>
                             <div style={{justifyContent: "space-between",alignContent: "center",}}
                               className={"k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"}>
                               <span className="d-none" style={{alignSelf: "center",}}>
                                 Step {step + 1} of 3
                               </span>
                               <div className="btnnxt-smrypg">
                                                                 
                                 {step !== 0 ? (
                                   <Button className="d-none" style={{marginRight: "16px",}} onClick={onPrevClick}> Previous </Button>
                                 ) : undefined}
                                 {isLastStep ?'':<Button className={isFirstStep ? "two" : "fir"} themecolor={"primary"} disabled={isLastStep ? !isPreviousStepsValid : false} onClick={formRenderProps.onSubmit}> {isLastStep ? "Pay The Bill" : "Next"}</Button>}
                               </div>
                             </div>
                           </FormElement>
                         </div>
                       )}
                     />
                   </div>
                      </Row>
                   </ul>
                  {/* ========== Order Summary content End ========== */}
                   </Col>
                </Row>
				{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
             </Container>
          </section>
        {/* ========== User Order Summary Page End ========== */}
        </>
    )
}
export default UserOrdrsmrynewstp3;