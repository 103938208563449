import React, { useEffect, useState,useRef } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import { NavLink  } from "react-router-dom";
import './UserOtp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import loader from '../../../assests/loader.gif';
import {validateforotp} from '../../../Utils/validate';
import API from '../../../Utils/Api';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess } from '../../../Utils/Common';

const UserOtp = () => {
	const otp1Ref = useRef();
	const otp2Ref = useRef();
	const otp3Ref = useRef();
	const otp4Ref = useRef();
	const otp5Ref = useRef();
	const otp6Ref = useRef();
const navigate = useNavigate();
const [error,setError] = useState();
const [email,setEmail] = useState();

const [inputs,setInputs] = useState({no1:'',no2:'',no3:'',no4:'',no5:'',no6:''});
const [loading,setLoading] = useState(false);

useEffect(() => {
      document.title = `User Otp | Blue Faucet`;
      document.getElementById('bodywrap').className='otppgmain';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	if (localStorage.getItem('email') !== null) {
		setEmail(localStorage.getItem('email'));
	}else
	{		
		window.location.href = "/forgot-password";
	}
	localStorage.removeItem("code");
},[])  
//Start OTP check
const handleSubmit = (event) => {
	event.preventDefault();
	const validationErrors = validateforotp(inputs);
	const noErrors = Object.keys(validationErrors).length === 0;
	setError('');
	
	//setErrors({});
	//setErrors(validationErrors);
	if(noErrors){
		setLoading(true);
		let no1=inputs.no1;
		let no2=inputs.no2;
		let no3=inputs.no3;
		let no4=inputs.no4;
		let no5=inputs.no5;
		let no6=inputs.no6;
		let code=no1+''+no2+''+no3+''+no4+''+no5+''+no6;
		let formData = new FormData();
		formData.append('code', code);
		API.post('code_check', formData)
			.then(response => {
				setLoading(false); 
			if(response.data.status === 0){
				setError(response.data.message);
				setLoading(false); 
			}else{
				//alert(response.data.message);
				localStorage.setItem("code", code);
				//navigate('/create-password');
				gettoastsuccess(response.data.message,'/create-password');
			   //setError('');
			   //setLoading(false);
			   
			}                  
		}).catch(error => {
			 setLoading(false);
			  setError("Something went wrong. Please try again later.");
		});  
	}
}
//End OTP check
/* Start Value set */
const handleInputChange = (event) => {
  event.persist();
  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  if(event.target.name==='no1')
  {
	if(event.target.value!=='')
	{
		otp2Ref.current.focus();
	}
  }
  if(event.target.name==='no2')
  {
	if(event.target.value!=='')
	{
		otp3Ref.current.focus();
	}
  }
  if(event.target.name==='no3')
  {
	if(event.target.value!=='')
	{
		otp4Ref.current.focus();
	}
  }	
  if(event.target.name==='no4')
  {
	if(event.target.value!=='')
	{
		otp5Ref.current.focus();
	}
  }
  if(event.target.name==='no5')
  {
	if(event.target.value!=='')
	{
		otp6Ref.current.focus();
	}
  }	
  if(event.target.name==='no6')
  {
	if(event.target.value!=='')
	{
		
	}
  }  
}
/* End Value set */

//Start Forgot password email otp send
const sendotp = () => {
		setLoading(true);
		let formData = new FormData(); 
			formData.append('email', email);
			formData.append('type', 'customer');
			API.post('forgotPassword', formData)
				.then(response => {
					
				if(response.data.status === 0){
					setError(response.data.message);
					setLoading(false); 
				}else{
					setLoading(false); 
					//alert('OTP send successfully.');
				   gettoastsuccess('OTP send successfully.','');
				}                  
			}).catch(error => {
				 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			}); 			
}
//End Forgot password email otp send

    return (
        <>
            {/* ========== Otp Page Start ========== */}
            <section className="gen-wrap gen-main">
			<ToastContainer />
               <Row className="maincnttpnw mx-0">
                  <Col xs={2} md={2} className="align-self-center px-0">
                  <Button onClick={() =>
                     navigate(-1)} className="back-btn">
                     <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                     </svg>
                  </Button>
                  </Col>
                  <Col xs={8} md={8} className="align-self-center text-center">
                  <h3>Enter OTP</h3>
                  </Col>
                  <Col xs={2} md={2} className="align-self-center">
                  <p></p>
                  </Col>
               </Row>
               <Container>
                  <Row className="genscrn">
                     <Col md={12} lg={12} xl={12} xxl={12} className="toptxtcnt px-0">
                     <div className="maincntsrcn">
                        <h5 className="cnttxt text-center">OTP is sent to <span className="fw-bold">{email}</span></h5>
						{error && (
						<div className="alert alert-danger">
						  {error}
						</div>)}
                        <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                        {/* ========== Code Box Page Start ========== */}
                           <Form.Group className="mb-3">
                              <div className="pin-wrapper">
                                 <input type="text" name="no1" maxLength="1" onChange={handleInputChange} ref={otp1Ref}/>
                                 <input type="text" name="no2" maxLength="1" onChange={handleInputChange} ref={otp2Ref}/>
                                 <input type="text" name="no3" maxLength="1" onChange={handleInputChange} ref={otp3Ref}/>
                                 <input type="text" name="no4" maxLength="1" onChange={handleInputChange} ref={otp4Ref}/>
                                 <input type="text" name="no5" maxLength="1" onChange={handleInputChange} ref={otp5Ref}/>
                                 <input type="text" name="no6" maxLength="1" onChange={handleInputChange} ref={otp6Ref}/>
                              </div>
                              <div className="pin d-none"></div>
                           </Form.Group>
                           {/* ========== Code Box Page End ========== */}
                           <div className="mb-3 btn-dv">
                              <Button type="Submit" className="btn">Submit</Button>
                           </div>
                           <div className="otplsttxt">
                              <p>
                                 Didn’t receive code? 
                                 <NavLink to="#" onClick={sendotp}> Request again</NavLink>
                              </p>
                           </div>
                        </Form>
                     </div>
                     </Col>
                  </Row>
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )}  
               </Container>
            </section>
            {/* ========== Otp Page End ========== */}
        </>
    )
}

export default UserOtp;