import React, { useEffect, useState,createRef,useRef } from 'react';
import { Row, Col, Container, Button, Form, Modal} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerCust.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
import ContentEditable from 'react-contenteditable';
import {validatecustomer} from '../../../Utils/validate';
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';
import copy from 'clipboard-copy';
import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS
import intlTelInput from 'intl-tel-input';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const MerCust = () => {
const inputRef = useRef(null);
const contentEditable = createRef();
const navigate = useNavigate();
const [loading,setLoading] = useState(true);

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
//const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => {
	setShow1(true);
}
const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
//const handleShow2 = () => setShow2(true);


const [inputs,setInputs] = useState({name:'',email:'',mobile:'',content:'',country_code:'1',country_short_code:'us','filter':'all'});

const [inputstype,setInputstype] = useState({type:'members',filter:'all',filter_status:'all',total_customer:0});

const [errors,setErrors] = useState({});

const [members, setMembers] = useState([]);
const [inputslnk,setInputslnk] = useState({url:'',twitterLink:'',instagramLink:'',facebooklink:'',whatsappLink:'',emailLink:'',phonelink:''});

const [inputspro,setInputspro] = useState({name:'',email:'',image:'',name_chg:''});

const [content,setContent] = useState({content:"",content_text:"",name:''});

const [showtext, setShowtext] = useState(false);

const [filterdrop, setFilterDrop] = useState(false);

useEffect(() => {
      document.title = ` Merchant Customers | Blue Faucet`;
      document.getElementById('bodywrap').className='cstmrpgmain grnbg mcus';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
  
	var url=process.env.REACT_APP_BASE_URL+'signup?key='+localStorage.getItem("token")+'&type=direct';

	/* Start get customer */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'members');
		formData.append('filter', 'all');
		formData.append('filter_status', 'all');
		API.post('get_customer_invitation', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setMembers(response.data.data.customer_invitation);
				 var total_customer_lbl='total_customer';
				setInputstype(inputstype => ({...inputstype, [total_customer_lbl]: response.data.data.total_customer}));
			}                  
		}).catch(error => {
			 
		});  
	 /* End get customer */
	 
	 /* Start get profile */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				var name_chg=response.data.data.name.replace(' ', '%20');
				name_chg=name_chg.replace(' ', '%20');
			 const twitterLink = `https://twitter.com/intent/tweet?text=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
			 const instagramLink = `instagram://library?AssetPath=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
			 const facebooklink=`https://www.facebook.com/sharer/sharer.php?u=Heres%20the%20link%20I%20wanted%20to%20share:%20`+url;
			 const whatsappLink = `https://api.whatsapp.com/send?text=Check%20out%20this%20link:%20`+url;
			 
			 const phonelink=`sms:?body=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
			 const emailLink = `mailto:?subject=You%20Got%20an%20Invitation%20from%20${name_chg}&body=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
	//const phone_link=`tel:`+url;	 
		setInputslnk({
			  url:url,
			  twitterLink:twitterLink,
			  instagramLink:instagramLink,
			  facebooklink:facebooklink,
			  whatsappLink:whatsappLink,
			  emailLink:emailLink,
			  phonelink:phonelink
		  }); 	
			  setInputspro({
				  name: response.data.data.name,
				  email: response.data.data.email,
				  image: response.data.data.image,
				  name_chg:name_chg
			  }); 
			  var name=response.data.data.user_name;
			  var text_tag="Dear Customer,<br>Thanks for being a loyal customer for many years. We've got some exciting news to share: "+name+" is now part of BlueFaucet, an app that helps you save time and money. We think you're going to love it! We invite you to join our membership so we can offer you an even better experience at "+name+".<br>We understand that you may experience difficulty reaching us when making appointments or orders, requiring multiple attempts to connect. Additionally, when we have promotions or send out coupons, we cannot contact you firsthand. We don't want you to miss any more chances to save. BlueFaucet is the answer to our call.<br>With BlueFaucet, you can book appointments or place orders right from the app. No more waiting on hold or struggling to get through! And when your order is ready, we'll send you a message so you can pick it up and go. It's that easy!<br>Plus, we'll send exclusive coupons and promotions directly to your in-app Coupon Box, so you'll never miss a good deal. And the best part? BlueFaucet is totally free for you to join and use!<br>Are you ready to join our BlueFaucet Membership and start enjoying all these amazing benefits? Just click  here to get started.<br>Thanks again for being a part of the "+name+" family. We look forward to serving you soon.<br>Best regards,<br>"+name+" team";
			  
			  //const regex = /(<([^>]+)>)/ig;
			  //const result = text_tag.replace(regex, '');
			  
			  var result = text_tag.replaceAll('<br>', '%0D%0A');
			  
			  //const regex = /(<([^>]+)>)/ig;
			  //const result = text_tag.replace(regex, '%0D%0A');
				var lbl='content_text';
				var result1=result+'%0D%0A'+url;
				setContent(content => ({...content, [lbl]: result1}));
				var deslbl='content';
				setContent(content => ({...content, [deslbl]: text_tag})); 
			}			  
		}).catch(error => { 
		});  
	 /* End get profile */
},[])  

useEffect(() => {
	/* Start get customer */
	let formData2 = new FormData(); 
		formData2.append('token', localStorage.getItem("token"));
		API.post('get_email_format_for_customer', formData2)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				var deslbl='content';
				//var tt="Dear "+inputs.name+",<br><br>We extend a warm invitation to you! Kindly click the link below to become a valued member of our community.<br><br> At "+response.data.data.store_name+", we genuinely appreciate your business and unwavering support. In our continuous effort to enhance our services, we invite you to join our membership.<br><br> Thank you for choosing "+response.data.data.store_name+". We look forward to welcoming you as a cherished member of our community.<br><br>Best regards,<br>"+response.data.data.store_name+" ";
				var tt="Dear "+inputs.name+",<br><br>Thank you for being a valued customer at "+response.data.data.store_name+", we would like to invite you to join our community to improve your customer experience.<br><br>Joining will allow you to easily make appointments and requests, as well as receive coupons and promotions. We promise no ads!<br><br>We appreciate your business at "+response.data.data.store_name+" so far and look forward to serving you better through your membership with us.<br><br>Best regards,<br>"+response.data.data.store_name+" ";
					setInputs(inputs => ({...inputs, [deslbl]: tt}));
				/*if(response.data.data===''){
					var tt="Dear "+inputs.name+",<br><br>We extend a warm invitation to you! Kindly click the link below to become a valued member of our community.<br><br> At "+response.data.data.store_name+", we genuinely appreciate your business and unwavering support. In our continuous effort to enhance our services, we invite you to join our membership.<br><br> Thank you for choosing "+response.data.data.store_name+". We look forward to welcoming you as a cherished member of our community.<br><br>Best regards,<br>"+response.data.data.store_name+" ";
					setInputs(inputs => ({...inputs, [deslbl]: tt}));
				}else{
					setInputs(inputs => ({...inputs, [deslbl]: response.data.data.content}));
				}*/
			}                  
		}).catch(error => {
			 
		});  
	 /* End get customer */
},[inputs.name]) 	

useEffect(() => {
    if (show1) {
      const input = inputRef.current;
    const iti = intlTelInput(input, {
	  initialCountry:"us",
      separateDialCode:true,
    });

    // Listen for changes
    input.addEventListener('countrychange', () => {
      const selectedCountryData = iti.getSelectedCountryData();
	  var country_code_lbl='country_code';
	  setInputs(inputs => ({...inputs, [country_code_lbl]: selectedCountryData.dialCode}));
	  var country_short_code_lbl='country_short_code';
	  setInputs(inputs => ({...inputs, [country_short_code_lbl]: selectedCountryData.iso2}));
    });

    // Clean up when the component unmounts
    return () => {
      iti.destroy();
    };
    }
  }, [show1]);

/* Start Value set */
const handleInputChange = (event) => {
  event.persist();
  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
}
const handleInputChangeDes = (event) => {
  var deslbl='content';
  setInputs(inputs => ({...inputs, [deslbl]: event.target.value}));  
}
const handleInputChangeDesShare = (event) => {
	var text_tag=event.target.value
	//const regex = /(<([^>]+)>)/ig;
    //const result = text_tag.replace(regex, '');
	
	const regex = /(<([^>]+)>)/ig;	  
    const result = text_tag.replace(regex, '%0D%0A');
	
	
	
	var result1=result+'%0D%0A'+inputslnk.url;
	//console.log(result1);
	var deslbl='content';
    setContent(content => ({...content, [deslbl]: event.target.value})); 
	
  var lbl='content_text';
  setContent(content => ({...content, [lbl]: result1}));  
}
const handleInputChangeType = (event) => {
      event.persist();
      setInputstype(inputstype => ({...inputstype, [event.target.name]: event.target.value}));
	
		if(event.target.value==='customer'){
			setFilterDrop(true);	
		}else{
			setFilterDrop(false);
		}
	  var filter_lbl='filter';
		setInputstype(inputstype => ({...inputstype, [filter_lbl]: 'all'}));
		var filter_status_lbl='filter_status';
		setInputstype(inputstype => ({...inputstype, [filter_status_lbl]: 'all'}));
	  
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', event.target.value);
		formData.append('filter', 'all');
		formData.append('filter_status', 'all');
		API.post('get_customer_invitation', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setMembers(response.data.data.customer_invitation);
				 var total_customer_lbl='total_customer';
				setInputstype(inputstype => ({...inputstype, [total_customer_lbl]: response.data.data.total_customer}));
			}                  
		}).catch(error => {
			 
		});  
}
const handleInputChangeFilter = (event) => {
      event.persist();
      setInputstype(inputstype => ({...inputstype, [event.target.name]: event.target.value}));  
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', inputstype.type);
		formData.append('filter', event.target.value);
		formData.append('filter_status', inputstype.filter_status);
		API.post('get_customer_invitation', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setMembers(response.data.data.customer_invitation);
				 var total_customer_lbl='total_customer';
				setInputstype(inputstype => ({...inputstype, [total_customer_lbl]: response.data.data.total_customer}));
			}                  
		}).catch(error => {
			 
		});  
}
const handleInputChangeFilterStatus = (event) => {
      event.persist();
      setInputstype(inputstype => ({...inputstype, [event.target.name]: event.target.value}));  
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', inputstype.type);
		formData.append('filter', inputstype.filter);
		formData.append('filter_status', event.target.value);
		API.post('get_customer_invitation', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setMembers(response.data.data.customer_invitation);
				 var total_customer_lbl='total_customer';
				setInputstype(inputstype => ({...inputstype, [total_customer_lbl]: response.data.data.total_customer}));
			}                  
		}).catch(error => {
			 
		});  
}
/* End Value set */

/* Start Submit Customer */
const handleSubmit = (event) => {
	event.preventDefault();
		const validationErrors = validatecustomer(inputs);
        const noErrors = Object.keys(validationErrors).length === 0;
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let name=inputs.name;
			let email=inputs.email;
			let mobile=inputs.mobile;
			let content=inputs.content;
			let country_code=inputs.country_code;
			let country_short_code=inputs.country_short_code;
			
			let formData = new FormData();
            formData.append('token', localStorage.getItem("token"));			
			formData.append('name', name);
			formData.append('email', email);
			formData.append('mobile', mobile);
			formData.append('content', content);
			formData.append('country_code', country_code);
			formData.append('country_short_code', country_short_code);
			API.post('invitation_link_to_customer', formData)
                .then(response => {
                if(response.data.status === 0){
                    //alert(response.data.message);
					gettoastwarning(response.data.message,'');
                    setLoading(false); 
                }else{
					setShow1(false);
					setLoading(false);
					//alert(response.data.message);
				    //window.location.href = "/mer-customer";
					gettoastsuccess(response.data.message,'/mer-customer');
                }                  
            }).catch(error => {
                 setLoading(false);
				  //alert("Something went wrong. Please try again later.");
				  gettoastwarning("Something went wrong. Please try again later.",'');
			}); 
		}
}
/* End Submit Customer */
const handleSelectChange =(id, value) =>{	
    setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	formData.append('status', value);
	formData.append('type', inputstype.type);
	API.post('change_customer_invitation_status', formData)
		.then(response => {
			setLoading(false);
		if(response.data.status === 0){
			
		}else{
			let updateData = members.map((item, i) => {
				return id === item.id ? { ...item, other_status: value } : item;
			});
			setMembers(updateData);
		}                  
	}).catch(error => {
		 
	});  
}

const copyToClipboard = () => {
    const linkToCopy = inputslnk.url

    copy(linkToCopy)
      .then(() => {
        
      })
      .catch((error) => {
        console.error('Copy failed: ', error);
      });
};

const copyToClipboard1 = () => {
    const linkToCopy = inputslnk.url

    copy(linkToCopy)
      .then(() => {
        let copyText = document.querySelector(".copy-text");
		copyText.classList.add("active");
		setTimeout(function () {
			copyText.classList.remove("active");
		  }, 2500);
      })
      .catch((error) => {
        console.error('Copy failed: ', error);
      });
};

const handleSubmitcopy = () => {
	/* Start get profile */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setShowtext(true);
			}			  
		}).catch(error => {
			 
		});  
	 /* End get profile */
}

const formatAsPhoneNumber = (event) => {
	event.persist();
	var value=event.target.value;
	value = value.replace(/ /g, '');
	value = value.replace(/\D/g, '');
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	if(value.length > 3 && value.length < 7)
	{
	    value = value.slice(0, 3) + ' ' + value.slice(3, 6);
	}else if(value.length > 6)
	{
		
		value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
	}
	setInputs(inputs => ({...inputs, [event.target.name]: value})); 
}
const shareAll = () => {
	var title='You Got an Invitation from '+inputspro.name;
  var url1=inputslnk.url;
	try {
      navigator.share({
        title: title,
        text: "Here's the link I wanted to share:",
        url: url1,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
}

    return (
        <>
      {/* ========== Merchant Customer Page Start ========== */}
         <section className="gen-wrap">
		 <ToastContainer />
         {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt">
            <Col xs={2} md={2} className="align-self-center px-0">
            <button onClick={() =>
               navigate(-1)} className="back-btn d-none">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </button>
            </Col>
            <Col xs={8} md={8} className="align-self-center text-center">
            <h3>Customers</h3>
            </Col>
         </Row>
         {/* ========== Navbar End ========== */}
         <Container>
            {/* ========== Customer Top Box Start ========== */}
			
            <Row className="tpfixgrn">
			{/*<Col md={12} xs={12}>
				<p>Bluefaucet existing customer</p>
			</Col> 
               <Col md={12} xs={12} className="align-self-center text-end btntpfx btnpfxbexiting px-0 mb-2">
                <NavLink to="/mer-existing-customer" ><Button variant="gren" type="submit">Bluefaucet existing customer membership</Button></NavLink>
			</Col>*/}
			<Col md={12} xs={12}>
				<p>Invite customer for a membership</p>
			</Col>
			<Col md={12} xs={12} className="align-self-center text-end btntpfx btnpfxbexiting px-0 mb-2">
                <NavLink to="#" onClick={handleShow1}><Button variant="gren" type="submit">Invite Customer</Button></NavLink>
               </Col>
               
            </Row>
            {/* ========== Customer Top Box End ========== */}
            {/* ========== Customer Content Start ========== */}
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={6} xs={12} className="cusslctbtn btnspcer mx-auto">
               <div className="cusradio">
			   {/*<input className="radio-label" type="radio" name="type" id="one" value="customer" onChange={handleInputChangeType} checked={inputstype.type === 'customer'}  />
                  <label className="button-label" htmlFor="one">Total Customers: {(() => {
							if (inputstype.type === 'customer') {
							  return ( <span>{inputstype.total_customer}</span>)
							  }else{
								return ( <span>0</span>)
							  }								  
						  })()}
			   </label>*/}
						<input className="radio-label" type="radio" name="type" id="two" value="members" onChange={handleInputChangeType} checked={inputstype.type === 'members'} />
                  <label className="button-label" htmlFor="two">Membership Status: 
				  {(() => {
							if (inputstype.type === 'members') {
								return ( <span className="ms-2">{inputstype.total_customer}</span>)
							  }else{
								return ( <span className="ms-2"> 0 </span>)
							  }								  
						})()}</label> 
               </div>
               </Col>
			   <Col md={12} xs={12} className="cusslctbtn btnspcer">
			   <Row>
			   <Col md={6} xs={6}>
			    <Form.Select aria-label="Default select example" name="filter" onChange={handleInputChangeFilter} value={inputstype.filter}>
                  <option value="all">All</option>
                  <option value="normal">Normal</option>
                  <option value="vip">VIP</option>
                  <option value="block">Block</option>
               </Form.Select>
			   </Col>
			   <Col md={6} xs={6}>
			    <Form.Select aria-label="Default select example" name="filter_status" onChange={handleInputChangeFilterStatus} value={inputstype.filter_status}>
                  <option value="all">All</option>
				  {filterdrop ? ( <><option value="pending">Pending</option><option value="registered">Registered</option></>
				  ) : (<><option value="accepted">Accepted</option><option value="rejected">Rejected</option><option value="Pending">Pending</option></>)}
               </Form.Select>
			   </Col>
			   </Row>
			   </Col>
			   {members.length >0 ? (
			   <div>
			   {members && members.map((val,index) => {
				return(
               <Col md={12} xs={12} className="mcusbx cus-box">
			   
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
				  <NavLink to={`/mer-customer-order/${val.user_id}`}>
				  {val.image ? (
                          <> 
							<img src={val.image} className="img-fluid" alt="Logo"/>
						  </>
							) : (
							  <>
							<img src= {'assets/images/merchant/mcust-img1.png'} className="img-fluid roundedCircle" alt="Mcust Img"/>
						</>
                          )}
					</NavLink>
                  </Col>
                  <Col md={6} xs={6} className="align-self-center mcusbx-txtcnt ps-md-0 ">
				  <NavLink to={`/mer-customer-order/${val.user_id}`}>
                  <h3>{val.name}</h3>
                  <p>{val.email}</p>
				  {(() => {
					if (inputstype.type==='customer'){
					  return (<div>{(() => {
							if (val.status===1){ return (<p className="text-success">Status: Registered</p>) 
							}else{
									return (<p className="text-danger">Status: Pending</p>)
								}						  
						})()}
						</div>)
					  }else{
						return (<div>{(() => {
							if (val.status===1){ return (<p className="text-success">Status: Accepted</p>) 
							}else if(val.status===0){
								return (<p className="text-danger">Status: Pending</p>)
							}else if(val.status===2){
								return (<p className="text-primary">Status: Rejected</p>)
							}						  
						})()}
						</div>)
					  }						  
				  })()}
				  </NavLink>
                  </Col>
                  <Col md={4} xs={4} className="align-self-center text-end">
                  <div className="demo">
                     <select onChange={(e) => handleSelectChange(val.id, e.target.value)} value={val.other_status}>
                        <option value="0">Normal</option>
                        <option value="1">Vip</option>
                        <option value="2">Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
			   )
				})}
               </div>
			   ) : (
						<Col className="mnhmcnt">
							<p className="text-center mt-4">No data found</p>
						</Col>
					  )} 
               <Col>
               <div className="mrcustmrmodal">
                  <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                     <Modal.Body>
                        <Row className="mx-0 topppdtlmcstmr">
                           <Col md={6} xs={6} className="align-self-center">
                           <h3 className="mdlttltxt">Invite Customers</h3>
                           </Col>
                           <Col md={6} xs={6} className="grntxt">
                          
						   <div className="copy-text mer-copy-text">
                       
                       <button onClick={copyToClipboard1}>Copy Link</button>
                    </div>
                           </Col>
                        </Row>
                        <Row className="mx-0 shrelist">
                           <Col md={12} xs={12} className="my-2">
                           <h3 className="mdlttltxt">Click the following icons to send the Invitation Message to the contacts.</h3>
                           </Col>
						   
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={inputslnk.phonelink}>
                              <img src= {'assets/images/svg/shr-icn1.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>SMS</p>
                              </div>
                           </NavLink>
                           </Col>
						   {showtext ? (
						   <>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
							   <NavLink to={`mailto:?subject=You%20Got%20an%20Invitation&body=${content.content_text}`} >
							   
                              <img src= {'assets/images/svg/shr-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Email</p>
                              </div>
							  
							  </NavLink>
                           </Col>
						   </>
						):(
						<>
						  <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={`mailto:?subject=You%20Got%20an%20Invitation&body=${content.content_text}`} target="_blank">
                              <img src= {'assets/images/svg/shr-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Email</p>
                              </div>
                           </NavLink>
                           </Col>
						</>
						   )}
						   
						{showtext ? (
						   <>
							   <Col md={3} xs={3} className="shrelist-mincnt text-center">
							   <NavLink to={`mailto:?subject=You%20Got%20an%20Invitation&body=${content.content_text}`}>
								  <img src= {'assets/images/svg/shr-icn3.svg'} className="img-fluid" alt="Sld Img"/>
								  <div className="nm-shr">
									 <p>Email </p>
								  </div>
							   </NavLink>
							   </Col>
						   </>
						):(
						<>
							<Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={`mailto:?subject=You%20Got%20an%20Invitation&body=${content.content_text}`}>
                              <img src= {'assets/images/svg/shr-icn3.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Email </p>
                              </div>
                           </NavLink>
                           </Col>
						</>
						   )}
						  {showtext ? (
						  <> 
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={`https://api.whatsapp.com/send?text=${content.content_text}`}>
                              <img src= {'assets/images/svg/shr-icn4.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Whatsapp</p>
                              </div>
                           </NavLink>
                           </Col>
                           </>
						):(
						<>
							<Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={`https://api.whatsapp.com/send?text=${content.content_text}`}>
                              <img src= {'assets/images/svg/shr-icn4.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Whatsapp</p>
                              </div>
                           </NavLink>
                           </Col>
						</>
						  )}
                           <Col md={12} xs={12} className="oppbxcntmcustmr mb-3">
                           <h4 className="mdlttltxt">Invitation Message </h4>
						   <ContentEditable
							  innerRef={contentEditable}
							  html={content.content} // innerHTML of the editable div
							  disabled={false}       // use true to disable editing
							  onChange={handleInputChangeDesShare} // handle innerHTML change
							  tagName='article' // Use a custom HTML tag (uses a div by default)
							/>
                            {/*<div>                           
							   <p className="mb-3">Hello,</p>
							   <p className="mb-3">I'm Saloon Shop, a loyal customer who cares about businesses thriving. I know it's challenging to track customer preferences. Many potential customers, like me, are drawn by your store's promotions but end up shopping elsewhere due to a lack of personalization. To bridge this gap, I'd like to introduce you to BlueFaucet.</p>
							   <p className="mb-3">BlueFaucet is a customer engagement platform that helps merchants build meaningful relationships with customers. It allows you to effortlessly manage memberships, keep customers informed about promotions, and more.</p>
							   <p className="mb-3">The best part? You can start with a 30-day free trial. If your membership remains under 50, it's entirely free. This is a risk-free opportunity to unlock personalized customer engagement.</p>
							   <p className="mb-3">Here's the best part: you can get started with a 30-day free trial. Plus, if your membership count remains under 50, your account remains free of charge. It's a risk-free opportunity to unlock the potential of personalized customer engagement and drive more business.</p>
							   <p className="mb-3">To begin, click the link below to sign up for BlueFaucet:</p>
							   <p className="mb-3">Once you have your BlueFaucet account, I'd love to become a part of your store's membership. Imagine the possibilities of creating a tailored shopping experience that benefits both me and your business growth.</p>
							   
							   <p className="mb-3">If you have questions or need assistance during sign-up, feel free to reach out to the BlueFaucet support team.</p>
							   <p className="mb-3">Thank you for considering this innovative approach to customer engagement. I look forward to seeing your business flourish even more.</p>
							   <p className="mb-3">Best regards,</p>
							                                      
							</div>*/}
                           </Col>
                           <Col md={12} xs={12} className="btnpopmcstmr my-2">
                           <NavLink to="#"><Button variant="black" type="submit" onClick={handleSubmitcopy}>Save</Button></NavLink>
                           </Col>
                        </Row>
                     </Modal.Body>
                     <Modal.Footer className="d-none"></Modal.Footer>
                  </Modal>
               </div>
               <div className="madcustmrpop">
                  <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" centered>
                     <Modal.Header closeButton>
                        <h3 className="mdlttltxt">Invite Customer</h3>
                     </Modal.Header>
                     <Modal.Body>
					 <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                        <Col md={12} xs={12} className="ppinpt">
                        
                           <Form.Group className="mb-3">
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" placeholder="Enter customer name" name="name"  onChange={handleInputChange} value={inputs.name}/>
							  {errors.name && (<p className="text-danger">{errors.name}</p>)}
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" placeholder="Enter customer email" name="email" onChange={handleInputChange} value={inputs.email}/>
							  {errors.email && (<p className="text-danger">{errors.email}</p>)}
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Phone Number <span>*</span></Form.Label>
                              <Form.Control type="tel" placeholder="000 000 0000" id="mobile" name="mobile" ref={inputRef} onChange={formatAsPhoneNumber}  value={inputs.mobile} />
                              {errors.mobile && (<p className="text-danger">{errors.mobile}</p>)}
						   </Form.Group>
                           <Col md={12} xs={12} className="oppbxcntmcustmr mb-3">
                           <h4>Your Message</h4>
                          
							<ContentEditable
							  innerRef={contentEditable}
							  html={inputs.content} // innerHTML of the editable div
							  disabled={false}       // use true to disable editing
							  onChange={handleInputChangeDes} // handle innerHTML change
							  tagName='article' // Use a custom HTML tag (uses a div by default)
							
							/>
							{errors.content && (<p className="text-danger">{errors.content}</p>)}
						  </Col>
                        
                        </Col>
                        <Col md={12} xs={12} className="btnpopmcstmr my-2">
                        <Button type="submit">Save and Send Invitation</Button>
                        </Col>
						</Form>
                     </Modal.Body>
                  </Modal>
               </div>
               <div className="modal3">
                  <Modal size="lg" show={show2} onHide={handleClose2} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                     <Modal.Body>
                        <Row className="mx-0 tpcrd-pop3">
                           <Col md={2} xs={2} className="align-self-center">
                           <img src={inputspro.image} className="img-fluid rounded-circle" alt="Hm Img"/></Col>
                           <Col md={10} xs={10} className="align-self-center mb-3">
                           <h3>{inputspro.name}</h3>
                           <p>{inputspro.email}</p>
                           </Col>
                           <Col md={12} xs={12} className="cpytxtdv mt-3">
                           <h4 className="mdlttltxt">Copy your store link and paste to the social media</h4>
                           <div className="copy-text">
                              <input type="text" className="text" value={inputslnk.url} disabled />
                              <button onClick={copyToClipboard}>Copy</button>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="dwncnt3">
                           <h4>Share via</h4>
                           <ul>
						   {/*<NavLink to="#">
                                 <li>
                                    <img src="assets/images/merchant/icn-1.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Yelp</p>
                                 </li>
						   </NavLink>*/}
                              <NavLink to={inputslnk.twitterLink}>
                                 <li>
                                    <img src="assets/images/merchant/icn-2.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Twitter</p>
                                 </li>
                              </NavLink>
                              <NavLink to={inputslnk.instagramLink}>
                                 <li>
                                    <img src="assets/images/merchant/icn-3.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Instagram</p>
                                 </li>
                              </NavLink>
                              <NavLink to={inputslnk.facebooklink}>
                                 <li>
                                    <img src="assets/images/merchant/icn-4.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Meta</p>
                                 </li>
                              </NavLink>
                              <NavLink to={inputslnk.whatsappLink}>
                                 <li>
                                    <img src="assets/images/merchant/icn-5.png" className="img-fluid" alt="Hm Img"/>
                                    <p>WhatsApp</p>
                                 </li>
                              </NavLink>
							  <NavLink to="#" onClick={shareAll}>
                                 <li>
                                    <img src="assets/images/merchant/share.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Share</p>
                                 </li>
                              </NavLink>
                           </ul>
                           </Col>
                        </Row>
                     </Modal.Body>
                  </Modal>
               </div>
               </Col>
               </Col>
            </Row>
            {/* ========== Customer Content End ========== */}
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
        <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Customer Page End ========== */}
        </>
    )
}
export default MerCust;