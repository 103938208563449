import React from "react";
import { Outlet, Navigate,useLocation } from "react-router-dom";

export default function PrivateRoute() {
    let userid = localStorage.getItem("token") == null ? false : true;
	let profile_setup = parseInt(localStorage.getItem("profile_setup"));
	let type = parseInt(localStorage.getItem("type"));
	const location = useLocation();
	var usertype = 1;
    if (userid === true) {
        usertype = type;
    }
	
	if(usertype===1)
	{
		var user_type = 0;
		if (userid === true) {
			
			user_type = 2;
		}
		
		if(user_type===2){
			const pagename=location.pathname;
			if(pagename==='/profile-setup')
			{
				if(profile_setup===2)
				{
					return <>{user_type === 2 ? <Navigate to="/payment" /> : <Navigate to="/login" />}</>;
				}else if(profile_setup===3){
					return <>{user_type === 2 ? <Navigate to="/home" /> : <Navigate to="/login" />}</>;
				}
				return <>{user_type === 2 ? <Outlet /> : <Navigate to="/login" />}</>;
			}else if(pagename==='/payment')
			{
				if(profile_setup===1)
				{
					return <>{user_type === 2 ? <Navigate to="/profile-setup" /> : <Navigate to="/login" />}</>;
				}else if(profile_setup===3){
					return <>{user_type === 2 ? <Navigate to="/home" /> : <Navigate to="/login" />}</>;
				}
				return <>{user_type === 2 ? <Outlet /> : <Navigate to="/login" />}</>;
			}else{
				
				if(profile_setup===1)
				{
					return <>{user_type === 2 ? <Navigate to="/profile-setup" /> : <Navigate to="/login" />}</>;
				}else if(profile_setup===2)
				{
					return <>{user_type === 2 ? <Navigate to="/payment" /> : <Navigate to="/login" />}</>;
				}else{
					
					return <>{user_type === 2 ? <Outlet /> : <Navigate to="/login" />}</>;
				}
				//return <>{user_type === 2 ? <Outlet /> : <Navigate to="/login" />}</>;
			}
		}else{
			return <>{user_type === 2 ? <Outlet /> : <Navigate to="/login" />}</>;
		}
	}else if(usertype===2){
		return <>{usertype === 2 ? <Navigate to="/mer-login" /> : <Navigate to="/mer-login" />}</>;
	}else if(usertype===3){
		return <>{usertype === 3 ? <Navigate to="/emp-login" /> : <Navigate to="/emp-login" />}</>;
	}
}
