import React, { useEffect } from 'react';
import { Row, Col} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { removeUserSession } from '../../Utils/Common';

function Footer(props) {   
  // var navItems = document.querySelectorAll(".bottom-nav-item");

useEffect(() => {

const showBtn = document.querySelector(".btn-bars"),
  closeBtn = document.querySelector(".btn-close"),
  navMenu = document.querySelector(".navbar-collapse");
showBtn.addEventListener("click", () => {
  navMenu.classList.add("showMenu");
});
closeBtn.addEventListener("click", () => {
  navMenu.classList.remove("showMenu");
});      
    
},[]) 

/* Start logout */
const logout = () => {
    removeUserSession();
		window.location.href = "/";
	}
/* End logout */
 
  return (
   //{/* ========== Footer Start ========== */}
      <Row className="mx-0 px-0 footer">
       <header className="headftr">
          <div className="">
             <nav className="bottom-nav">
                <div className="bottom-nav-item">
                   <NavLink to="/mer-customer">
                      <div className="bottom-nav-link">
                         <i className="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                               <path d="M11.5156 2.05749C10.2113 2.29128 9.16543 2.82858 8.23848 3.73503C7.37305 4.58405 6.79473 5.62994 6.53223 6.8399C6.42969 7.30748 6.40508 8.48462 6.48301 8.99731C6.69219 10.3098 7.2623 11.4295 8.19746 12.3688C8.38613 12.5575 8.64453 12.7912 8.77578 12.8897L9.01367 13.066L8.71426 13.1768C7.79141 13.5213 6.56504 14.2432 5.77344 14.9117C4.68652 15.8223 3.70625 17.0569 3.09102 18.275C2.43066 19.5875 2.0041 21.2773 2 22.598V23H2.83672H3.66934L3.69395 22.4586C3.78828 20.3627 4.71113 18.3242 6.26973 16.7697C7.59453 15.4449 9.15312 14.6328 11.0645 14.276C11.6223 14.1735 13.3777 14.1735 13.9355 14.276C15.8469 14.6328 17.4055 15.4449 18.7303 16.7697C20.051 18.0863 20.8918 19.7024 21.2158 21.544C21.2527 21.7449 21.2937 22.1592 21.3061 22.4586L21.3307 23H22.1633H23V22.598C22.9959 21.2773 22.5693 19.5875 21.909 18.275C21.2937 17.0569 20.3135 15.8223 19.2266 14.9117C18.435 14.2432 17.2086 13.5213 16.2857 13.1768L15.9863 13.066L16.2283 12.8856C16.3596 12.7871 16.6139 12.5575 16.7984 12.377C17.7295 11.4418 18.3078 10.3098 18.517 8.99731C18.5949 8.48462 18.5703 7.30748 18.4678 6.8399C18.0576 4.96549 16.8682 3.44382 15.1455 2.58249C14.8379 2.43073 14.1365 2.19284 13.7305 2.10261C13.3121 2.00828 11.9422 1.98367 11.5156 2.05749ZM13.2875 3.73913C14.2145 3.91549 15.0389 4.36667 15.6828 5.04752C16.4867 5.88834 16.8887 6.90142 16.8887 8.07036C16.8887 9.23931 16.4867 10.2524 15.6828 11.0932C14.8338 11.9914 13.7387 12.459 12.5 12.459C11.2613 12.459 10.1662 11.9914 9.31719 11.0932C8.51328 10.2524 8.11133 9.23931 8.11133 8.07036C8.11133 7.53306 8.17285 7.13521 8.33281 6.65943C8.8291 5.17877 10.1211 4.05495 11.6797 3.74323C12.0447 3.6694 12.9143 3.6694 13.2875 3.73913Z" fill=""/>
                            </svg>
                         </i>
                         <span>Customers</span>
                      </div>
                   </NavLink>
                </div>
                <div className="bottom-nav-item">
                   <NavLink to="/mer-sending-coupons">
                      <div className="bottom-nav-link">
                         <i className="">
                            <svg width="24" height="24" x="0" y="0" viewBox="0 0 475.293 475.293">
                               <g opacity="0.6">
                                  <path d="M430.734 415.882H44.559C19.988 415.882 0 395.894 0 371.323v-74.265c0-8.21 6.643-14.853 14.853-14.853 24.571 0 44.559-19.988 44.559-44.559s-19.988-44.559-44.559-44.559C6.643 193.088 0 186.445 0 178.235V103.97c0-24.571 19.988-44.559 44.559-44.559h386.176c24.571 0 44.559 19.988 44.559 44.559v74.265c0 8.21-6.643 14.853-14.853 14.853-24.571 0-44.559 19.988-44.559 44.559s19.988 44.559 44.559 44.559c8.21 0 14.853 6.643 14.853 14.853v74.265c-.001 24.57-19.988 44.558-44.56 44.558zM29.706 310.417v60.906c0 8.195 6.658 14.853 14.853 14.853h386.176c8.195 0 14.853-6.658 14.853-14.853v-60.906c-33.854-6.904-59.412-36.9-59.412-72.771s25.557-65.866 59.412-72.771V103.97c0-8.195-6.658-14.853-14.853-14.853H44.559c-8.195 0-14.853 6.658-14.853 14.853v60.906c33.854 6.904 59.412 36.9 59.412 72.771s-25.558 65.866-59.412 72.77z" fill="" data-original="#000000" className=""></path>
                                  <path d="m179.19 351.051 89.085-237.562 27.814 10.43-89.085 237.562zM167.382 242.647c-24.571 0-44.559-19.988-44.559-44.559s19.988-44.559 44.559-44.559 44.559 19.988 44.559 44.559-19.988 44.559-44.559 44.559zm0-59.412c-8.195 0-14.853 6.658-14.853 14.853s6.658 14.853 14.853 14.853 14.853-6.658 14.853-14.853-6.658-14.853-14.853-14.853zM320.911 334.764c-24.571 0-44.559-19.988-44.559-44.559s19.988-44.559 44.559-44.559 44.559 19.988 44.559 44.559-19.988 44.559-44.559 44.559zm0-59.412c-8.195 0-14.853 6.658-14.853 14.853s6.658 14.853 14.853 14.853 14.853-6.658 14.853-14.853-6.658-14.853-14.853-14.853z" fill="" data-original="#000000" className=""></path>
                               </g>
                            </svg>
                         </i>
                         <span>Marketing</span>
                      </div>
                   </NavLink>
                </div>
                <div className="bottom-nav-item">
                   <NavLink to="/mer-dashboard">
                      <div className="bottom-nav-link cnt-logo">
                         <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" fill="none">
                            <circle cx="42.5" cy="42.5" r="40" fill="url(#paint0_linear_1115_1763)" stroke="#F4F7FE" strokeWidth="5"/>
                            <path d="M29.4188 28.1C28.8498 28.2994 28.3277 28.8157 28.1048 29.4023C27.9933 29.6956 27.9933 29.854 28.005 33.0864L28.0226 36.4713L28.2221 36.8409C28.4626 37.2809 28.8263 37.6329 29.2722 37.8382L29.6066 37.9966H34.8863H40.1661L40.5181 37.8265C40.9639 37.6035 41.357 37.2105 41.5799 36.7646L41.75 36.4127V33.0101V29.6076L41.5917 29.2732C41.3863 28.8274 41.0343 28.4637 40.5944 28.2232L40.2248 28.0237L34.9743 28.012C30.0524 28.0002 29.7063 28.0061 29.4188 28.1ZM39.6675 29.9479L39.8141 30.0241L39.8317 32.9632C39.8435 35.5796 39.8317 35.914 39.7496 35.9961C39.6675 36.0783 39.1571 36.09 34.8629 36.09C30.4689 36.09 30.0524 36.0841 29.982 35.9903C29.9116 35.914 29.8999 35.3684 29.8999 32.9984V30.0945L30.0407 29.9831C30.1697 29.8775 30.334 29.8716 34.8511 29.8716C38.5001 29.8716 39.5501 29.8892 39.6675 29.9479Z" fill="white"/>
                            <path d="M45.7567 28.0763C45.1583 28.2933 44.689 28.7157 44.425 29.2789L44.2725 29.6074V36.7644V43.9214L44.4426 44.2734C44.6655 44.7193 45.0586 45.1123 45.5044 45.3352L45.8564 45.5054H51.1362H56.4159L56.7503 45.347C57.1962 45.1417 57.5599 44.7897 57.8004 44.3497L57.9998 43.9801V36.7644V29.5487L57.8415 29.2261C57.6361 28.8096 57.2138 28.3872 56.7972 28.1819L56.4746 28.0235L51.2242 28.0118C47.0062 28 45.9327 28.0118 45.7567 28.0763ZM55.9818 29.9829L56.1226 30.0943V36.7527C56.1226 42.3023 56.1109 43.4228 56.0405 43.4991C55.9701 43.5929 55.5536 43.5988 51.1596 43.5988C46.8713 43.5988 46.355 43.5871 46.2729 43.5049C46.1849 43.4228 46.179 42.7482 46.1908 36.7175L46.2084 30.0239L46.355 29.9477C46.4724 29.889 47.5224 29.8714 51.1714 29.8714C55.6885 29.8714 55.8527 29.8773 55.9818 29.9829Z" fill="white"/>
                            <path d="M29.536 40.5719C28.9552 40.7596 28.4918 41.1527 28.1867 41.7334L28.0225 42.0444V49.26V56.4757L28.1809 56.7984C28.3862 57.2149 28.8086 57.6373 29.2251 57.8426L29.5477 58.001H34.8862H40.2246L40.5942 57.8015C41.0341 57.561 41.3861 57.1973 41.5915 56.7514L41.7498 56.417V49.26V42.103L41.5797 41.751C41.3568 41.3052 40.9638 40.9122 40.5179 40.6892L40.1659 40.5191L34.9742 40.5074C30.7973 40.5015 29.7355 40.5132 29.536 40.5719ZM39.7494 42.5195C39.8315 42.6017 39.8433 43.2939 39.8433 49.2835C39.8433 55.4256 39.8374 55.9712 39.7435 56.0416C39.6673 56.112 38.8225 56.1237 34.9155 56.1413C30.3104 56.1531 30.1754 56.1531 30.0405 56.0416L29.8997 55.9301V49.2718C29.8997 43.7222 29.9114 42.6017 29.9818 42.5254C30.0522 42.4316 30.4688 42.4257 34.8627 42.4257C39.1452 42.4257 39.6673 42.4374 39.7494 42.5195Z" fill="white"/>
                            <path d="M45.7217 48.1161C45.2875 48.2745 44.9004 48.5678 44.6364 48.9374C44.2316 49.5182 44.2375 49.4771 44.2551 53.1377L44.2727 56.417L44.4311 56.7514C44.6364 57.1973 44.9884 57.561 45.4283 57.8015L45.7979 58.001H51.1364H56.4748L56.7974 57.8426C57.214 57.6373 57.6363 57.2149 57.8417 56.7984L58.0001 56.4757V53.0145V49.5534L57.8006 49.1838C57.5601 48.7438 57.1964 48.3918 56.7505 48.1865L56.4161 48.0281L51.2244 48.0164C46.232 48.0046 46.0209 48.0046 45.7217 48.1161ZM56.0407 50.0344C56.1111 50.1107 56.1228 50.6562 56.1228 53.0263V55.9301L55.982 56.0416C55.8529 56.1472 55.6887 56.1531 51.1716 56.1531C47.5227 56.1531 46.4726 56.1355 46.3552 56.0768L46.2086 56.0005L46.191 53.0615C46.1792 50.4451 46.191 50.1107 46.2731 50.0285C46.3552 49.9464 46.8656 49.9347 51.1598 49.9347C55.5538 49.9347 55.9703 49.9405 56.0407 50.0344Z" fill="white"/>
                            <defs>
                               <linearGradient id="paint0_linear_1115_1763" x1="5" y1="5" x2="23.5632" y2="92.0368" gradientUnits="userSpaceOnUse">
                                  <stop stopColor="#71CE79"/>
                                  <stop offset="1" stopColor="#3E9433"/>
                               </linearGradient>
                            </defs>
                         </svg>
                      </div>
                   </NavLink>
                </div>
                <div className="bottom-nav-item">
                   <NavLink to="/mer-store">
                      <div className="bottom-nav-link">
                         <i className="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                               <g opacity="0.6">
                                  <path d="M6.10526 2.06749C5.61403 2.19468 5.23245 2.42275 4.83333 2.82626C4.40789 3.2517 4.22807 3.56748 4.08772 4.12011C4 4.46222 4 4.61134 4 13.2692V22.0762L4.12281 22.3087C4.21491 22.4929 4.29824 22.5806 4.48245 22.6902C4.71052 22.8262 4.74561 22.835 5.05263 22.8218L5.38157 22.8043L6.74999 21.9841C7.50437 21.5323 8.14911 21.1508 8.18858 21.1376C8.23244 21.1201 8.82893 21.4534 9.82016 22.0455C11.3509 22.9622 11.3859 22.9797 11.6579 22.9973C11.8114 23.006 11.9956 22.9929 12.0701 22.9709C12.1447 22.9446 12.8859 22.5148 13.7193 22.0192C14.728 21.4183 15.2587 21.1245 15.307 21.142C15.3508 21.1552 16 21.5367 16.7587 21.9885C18.0964 22.7823 18.1447 22.8043 18.4122 22.8218C18.5614 22.8306 18.7587 22.8174 18.8464 22.7867C19.057 22.7166 19.2894 22.5104 19.4078 22.2867L19.5043 22.1069V13.2254V4.3438L19.4035 4.01485C19.2412 3.49292 19.057 3.19468 18.671 2.80871C18.4078 2.54556 18.2368 2.41398 17.9912 2.29556C17.3421 1.97977 17.7193 1.99731 11.7368 2.0017C7.1842 2.0017 6.30701 2.01486 6.10526 2.06749ZM17.1228 3.50169C17.3991 3.61134 17.75 3.92713 17.8947 4.20783L18.0131 4.43152L18.0263 12.7297C18.0307 17.2955 18.0263 21.0323 18.0131 21.0323C18 21.0323 17.5219 20.7516 16.9473 20.4052C16.1052 19.8964 15.8508 19.7648 15.6315 19.7166C15.3026 19.6508 14.9035 19.6815 14.6359 19.7999C14.5351 19.8438 13.864 20.2297 13.1491 20.6552C12.4342 21.0806 11.8201 21.4402 11.7894 21.4534C11.7544 21.4666 11.0965 21.1025 10.3289 20.6464C8.79384 19.7297 8.60525 19.6464 8.12279 19.6815C7.7456 19.7078 7.54823 19.8043 6.41666 20.4841C5.91227 20.7867 5.48684 21.0323 5.47368 21.0323C5.45614 21.0323 5.45175 17.3087 5.45614 12.7561L5.46929 4.47538L5.5614 4.27362C5.75877 3.84818 6.17982 3.52362 6.62718 3.44468C6.73683 3.42713 9.10086 3.41397 11.8728 3.41836C16.6535 3.42275 16.9254 3.42713 17.1228 3.50169Z" fill=""/>
                                  <path d="M11.3246 5.76024C11.2413 5.79972 11.1272 5.90059 11.0746 5.98393C10.9825 6.11551 10.9737 6.18568 10.9737 6.6462V7.15936L10.7281 7.25585C9.43424 7.74708 8.85529 9.29093 9.4781 10.5804C9.87722 11.405 10.6053 11.8918 11.5921 11.9971C11.9123 12.0278 12.0395 12.0629 12.1974 12.1593C12.7281 12.4883 12.8421 13.2295 12.4342 13.7032C12.2281 13.9444 11.9869 14.0585 11.6755 14.0541C11.2588 14.0541 11.0176 13.883 10.6886 13.3523C10.5351 13.1067 10.386 13.0278 10.0746 13.0278C9.61845 13.0278 9.37284 13.269 9.37284 13.7251C9.37284 13.9576 9.39477 14.0321 9.52635 14.2514C9.81582 14.7295 10.2763 15.1243 10.7632 15.3041L10.9649 15.383L10.9825 15.9313L10.9956 16.4751L11.1755 16.6462C11.4869 16.9356 11.8641 16.9356 12.1755 16.6462L12.3553 16.4751L12.3684 15.9576L12.386 15.4357L12.7193 15.2865C13.3728 14.9971 13.9342 14.2997 14.0833 13.5848C14.307 12.5102 13.7763 11.405 12.7763 10.883C12.4342 10.7032 12.2281 10.6462 11.7632 10.5936C11.3509 10.5453 11.1272 10.4401 10.9123 10.1901C10.4562 9.65935 10.6623 8.88304 11.3377 8.59795C11.7632 8.42252 12.3246 8.65497 12.5527 9.10234C12.7369 9.46199 13.079 9.63304 13.4255 9.54093C13.9211 9.40936 14.1009 8.9269 13.8377 8.40936C13.6009 7.94006 12.9781 7.38305 12.5439 7.25147L12.3772 7.20322V6.66813C12.3772 6.18568 12.3684 6.11989 12.2763 5.98393C12.1491 5.79533 11.9255 5.6813 11.6755 5.6813C11.5658 5.6813 11.4079 5.71638 11.3246 5.76024Z" fill=""/>
                               </g>
                            </svg>
                         </i>
                         <span>Store</span>
                      </div>
                   </NavLink>
                </div>
                <div className="bottom-nav-item">
                   <div className="bottom-nav-link">
                      <div className="navbar-footer">
                         <button type="button" className="btn-bars">
                            <span>
                               <i className='bx bxs-widget'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                     <path d="M2.28125 5.32812C2.09375 5.44531 2 5.62109 2 5.875C2 6.12891 2.09375 6.30469 2.28125 6.42188C2.41016 6.5 2.5 6.5 12 6.5C21.5 6.5 21.5898 6.5 21.7188 6.42188C21.9062 6.30469 22 6.12891 22 5.875C22 5.62109 21.9062 5.44531 21.7188 5.32812C21.5898 5.25 21.5 5.25 12 5.25C2.5 5.25 2.41016 5.25 2.28125 5.32812Z" fill=""/>
                                     <path d="M2.28125 11.5781C2.09375 11.6953 2 11.8711 2 12.125C2 12.3789 2.09375 12.5547 2.28125 12.6719C2.41016 12.75 2.5 12.75 12 12.75C21.5 12.75 21.5898 12.75 21.7188 12.6719C21.9062 12.5547 22 12.3789 22 12.125C22 11.8711 21.9062 11.6953 21.7188 11.5781C21.5898 11.5 21.5 11.5 12 11.5C2.5 11.5 2.41016 11.5 2.28125 11.5781Z" fill=""/>
                                     <path d="M2.28125 17.8281C2.09375 17.9453 2 18.1211 2 18.375C2 18.6289 2.09375 18.8047 2.28125 18.9219C2.41016 19 2.5 19 12 19C21.5 19 21.5898 19 21.7188 18.9219C21.9062 18.8047 22 18.6289 22 18.375C22 18.1211 21.9062 17.9453 21.7188 17.8281C21.5898 17.75 21.5 17.75 12 17.75C2.5 17.75 2.41016 17.75 2.28125 17.8281Z" fill=""/>
                                  </svg>
                               </i>
                            </span>
                         </button>
                         <div className="navbar-collapse">
                            <div className="mdv-sdnv">
                               <span className="btn-close">
                               <i className='bx bx-x'></i>
                               </span>
                               <ul className="navbar-nav mfooter">
                                  <li className="my-3 text-start">
                                     <NavLink to="#" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/m-logo.svg'} className="img-fluid" alt="LOGO IMG"/></NavLink>
                                  </li>
                                  <div className="fbtnline"></div>
                                  <li className="fnav-item">
                                     <NavLink to="/mer-profile" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn1.svg'} className="img-fluid" alt="Side Nav Icon"/> Store Information</NavLink>
                                  </li>
                                  <li className="fnav-item">
                                     <NavLink to="/mer-employee-management" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn2.svg'} className="img-fluid" alt="Side Nav Icon"/> Employee Management</NavLink>
                                  </li>
                                  <li className="fnav-item">
                                     <NavLink to="/mer-subscription" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn3.svg'} className="img-fluid" alt="Side Nav Icon"/> Subscription Plans</NavLink>
                                  </li>
                                  <li className="fnav-item">
                                     <NavLink to="/mer-calendar" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn4.svg'} className="img-fluid" alt="Side Nav Icon"/> Calendar</NavLink>
                                  </li>
                                  {/*<li className="fnav-item">
                                     <NavLink to="#" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn5.svg'} className="img-fluid" alt="Side Nav Icon"/> Income & Forecast</NavLink>
                                  </li>*/}
								  <li className="fnav-item">
                                     <NavLink to="/mer-after-login-stripe-account-setup" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn55.svg'} className="img-fluid" alt="Side Nav Icon"/> Stripe Setting</NavLink>
                                  </li>
                                  <li className="fnav-item">
                                     <NavLink to="/mer-notification" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn6.svg'} className="img-fluid" alt="Side Nav Icon"/> Notifications</NavLink>
                                  </li>
								  <li className="fnav-item">
                                     <NavLink to="/mer-video" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn22.svg'} className="img-fluid" alt="Side Nav Icon"/> Training videos</NavLink>
                                  </li>
                                  <li className="fnav-item">
                                     <NavLink to="/mer-help" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn7.svg'} className="img-fluid" alt="Side Nav Icon"/> Help Center</NavLink>
                                  </li>
                                  <li className="fnav-item">
                                     <NavLink to="/mer-about" className="fnav-link"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn8.svg'} className="img-fluid" alt="Side Nav Icon"/> About BlueFaucet</NavLink>
                                  </li>
								  <li className="fnav-item">
                                    <NavLink to="#" className="fnav-link" onClick={logout}><img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/mside-icn9.svg'} className="img-fluid" alt="Side Nav Icon"/> Logout</NavLink>
                                 </li>
                               </ul>
                            </div>
                         </div>
                      </div>
                      <span>Menu</span>
                   </div>
                </div>
             </nav>
          </div>
       </header>
       <Col>
       </Col>        
    </Row>
   //{/* ========== Footer Endd ========== */}
  );
}
export default Footer;