import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button,Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserFvrtproduct.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

const UserFvrtproduct = () => {
	const [favorite, setFavorite] = useState([]);
	//const [name, setName] = useState('');
	const [loading,setLoading] = useState(true);
	
	const [show1, setShow1] = useState(false);
	const handleClose1 = () => setShow1(false);
	
	const [productimg, setProductimg] = useState([]);
	
useEffect(() => {
      document.title = `User Favorites | Blue Faucet`;
      document.getElementById('bodywrap').className='usrfvpgmain uf2';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

	 /* Start get favorite data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_favourite_product', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setFavorite(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get favorite data */
    
},[])  
/* Start like and dislike */
const like = (id,store_id) => {		
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('product_id', id);
	formData.append('store_id', store_id);
	API.post('product_favourite', formData)
		.then(response => {
		
		if(response.data.status === 0){
			
		}else{
		   window.location.reload(true);
		}                  
	}).catch(error => {
		 
	});   
}
/* End like and dislike */
/* Start view reating */
/*const ratingview = (count) => {
	var html='';
	for (let i = 1; i <= count; i++) {
			html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star"></i></label><input class="rating__input" name="rating"  defaultValue={i} type="radio"/>';
	}
	var cou=count+1;
	for(let a=cou;a <= 5;a++)
	{
		html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star rating-emt"></i></label><input class="rating__input" name="rating"   type="radio"/>';
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}*/
/* End view reating */
/* Start favorite search by name */
const searchByName = (event) => {
	setLoading(true);
	//setName(event.target.value);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('keyword', event.target.value);
	API.post('search_favourite_product', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			setFavorite([]);
		}else{
			setFavorite(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
}
/* End favorite search by name */

 /* Start product image */
const mulimg = (id) => {
	
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('product_id', id);
	API.post('get_image_by_product_id', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setProductimg(response.data.data);
			setShow1(true);
		}                  
	}).catch(error => {
		 
	});  
}
/* End product image */

    return (
        <>
         {/* ========== User Favorites Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Favorite Products / Services'}/>
            {/* ========== Navbar End ========== */}
               <Container>
               {/* ========== Category Box Start ========== */}
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl">
                     <div className="constrain hide-inpt">
                        <input className="input-elevated" type="text" placeholder="Search By Name" onChange={searchByName} />
                     </div>
                     </Col>
                  </Row>
               {/* ========== Category Box End ========== */}
                  <Row className="mx-0 mianhmdwncnt">
                  {/* ========== Category List Content Box Start ========== */}
				  {favorite.length >0 ? (
                     <Col className="mnhmcnt">
					 {favorite && favorite.map((val,index) => {
						return(
                     <Col md={12} xs={12} className="py-2">
                     <Col className="ustrlpg">
                     <Row className="mx-0 ustrl-box">
                        <Col md={2} xs={2} className="px-0">
						<NavLink to="#" onClick={() =>mulimg(val.product_id)}>
                        <img src={val.image} className="img-fluid" alt="Hm Img"/>
						</NavLink>
						</Col>
                        <Col md={7} xs={7} className="px-2 px-md-2">
                        <h3>{val.product_name}</h3>
							{/*<div id="str-trng">
                           <div className="rating-group strrtg-rating">
                              {ratingview(val.star)}
                           </div>
                           <span>({val.total_rating} ratings)</span>
							</div>*/}
                        <p className="mt-2">4.2 Km - Address Here</p>
                        {/*<p>Buffet Offers</p>*/}
                        </Col>
                        <Col md={3} xs={3} className="text-end px-0">
						
                        {val.type==='product' ?<NavLink to={`/product/${val.store_id}`}><Button variant="" type="submit">View</Button></NavLink>:<NavLink to={`/service/${val.store_id}`}><Button variant="" type="submit">View</Button></NavLink>}
                        </Col>
                     </Row>
                     <Col md={12} xs={12} className="ustrl-crdlst">
                     <ul>
                        <li>${val.price}</li>
							{/*<li>{val.offer}% Off</li>*/}
                     </ul>
                     <div className="ginlike">
                        <div className="">
                           {val.status ? <svg className={`lik lik${val.id}`} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(val.product_id,val.store_id)}>
								   <circle cx="12" cy="12" r="12" fill="white"/>
								   <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
								</svg>  : <svg className={`dis dis${val.store_id}`} width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
								   like(val.product_id,val.store_id)}>
								   <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
								   <g id="Black">
									  <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
								   </g>
								</svg>
						 } 
                        </div>
                     </div>
                     </Col>
                     </Col>
                     </Col>
					 )
					  })}
                     </Col>
					 ) : (
						<Col className="mnhmcnt">
							<p className="text-center mt-4">No data found</p>
						</Col>
					  )}
                     {/* ========== Category List Content Box End ========== */}
                  </Row>
				  
				  <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" centered>
                     <Modal.Header closeButton>
                        <h3 className="mdlttltxt">Image</h3>
                     </Modal.Header>
                     <Modal.Body>
                        <Col md={12} xs={12} className="ppinpt" >
<OwlCarousel className='owl-theme mb-5' loop margin={10} items={1} nav={false}>
							{productimg.map((val) => {
								return (
								<div className='item' style={{ height: '250px' }}>
									<img src={val.image} className="img-fluid" alt="Sld Img" style={{ width: '100%', height: '100%' }} />
								</div>
								)
								})
								}
							</OwlCarousel>
                        </Col>
                     </Modal.Body>
                  </Modal>
				  
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )}  
               </Container>
            </section>
         {/* ========== User Favorites Page End ========== */}
            {/*<Footer />*/}
        </>
    )
}

export default UserFvrtproduct;