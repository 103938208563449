import React, { useEffect, useState,useRef } from 'react';
import { Row, Col, Container, Button, Modal, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerEmp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {validateemp,validateempedit} from '../../../Utils/validate';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardForm } from "./CardForm";
import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS
import intlTelInput from 'intl-tel-input';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess } from '../../../Utils/Common';

const MerEmp = () => {
	const inputRef = useRef(null);
	const inputRef1 = useRef(null);
	const [disid, setDisid] = useState();
	const [showd, setShowd] = useState(false);
const handleClosed = () => setShowd(false);
	
const [loading,setLoading] = useState(false);
const [isChecked, setIsChecked] = useState(false);
const [isSunChecked, setSunIsChecked] = useState(false);
const [isMonChecked, setMonIsChecked] = useState(false);
const [isTueChecked, setTueIsChecked] = useState(false);
const [isWedChecked, setWedIsChecked] = useState(false);
const [isThuChecked, setThuIsChecked] = useState(false);
const [isFriChecked, setFriIsChecked] = useState(false);
const [isSatChecked, setSatIsChecked] = useState(false);

const [inputsf, setInputsF] = useState({day:'sun',start_hours:'12',start_minutes:'00',end_hours:'12',end_minutes:'00',status:0});
const [inputsSunday, setInputsSunday] = useState({day:'sunday',sunstart_hours:'12',sunstart_minutes:'00',sunend_hours:'12',sunend_minutes:'00',status:0});
const [inputsMonday, setInputsMonday] = useState({day:'monday',monstart_hours:'12',monstart_minutes:'00',monend_hours:'12',monend_minutes:'00',status:0});
const [inputsTuesday, setInputsTuesday] = useState({day:'tuesday',tuestart_hours:'12',tuestart_minutes:'00',tueend_hours:'12',tueend_minutes:'00',status:0});
const [inputsWednesday, setInputsWednesday] = useState({day:'wednesday',wedstart_hours:'12',wedstart_minutes:'00',wedend_hours:'12',wedend_minutes:'00',status:0});
const [inputsThursday, setInputsThursday] = useState({day:'thursday',thustart_hours:'12',thustart_minutes:'00',thuend_hours:'12',thuend_minutes:'00',status:0});
const [inputsFriday, setInputsFriday] = useState({day:'friday',fristart_hours:'12',fristart_minutes:'00',friend_hours:'12',friend_minutes:'00',status:0});
const [inputsSaturday, setInputsSaturday] = useState({day:'saturday',satstart_hours:'12',satstart_minutes:'00',satend_hours:'12',satend_minutes:'00',status:0});

const [values, setValues] = useState({password: "",showPassword: false,});
const [values1, setValues1] = useState({password: "",showPassword: false,});

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

const [inputs,setInputs] = useState({employee_id:'',employee_name:'',employee_email:'',employee_contact_no:'',country_code:'1',country_short_code:'us',date_of_birth:'',commission:'',password:'',password_confirmation:'',accepted_per_time_slot:4,auto_accept_during_working_hours:'auto_accept_during_working_hours',auto_refuse:0});
const [inputssearch,setInputssearch] = useState({employee_id:''});

const [error,setError] = useState();
const [success,setSuccess] = useState();
const [errors,setErrors] = useState({});

const [employee, setEmployee] = useState([]);

const [isCheckededit, setIsCheckededit] = useState(false);
const [isSunCheckededit, setSunIsCheckededit] = useState(false);
const [isMonCheckededit, setMonIsCheckededit] = useState(false);
const [isTueCheckededit, setTueIsCheckededit] = useState(false);
const [isWedCheckededit, setWedIsCheckededit] = useState(false);
const [isThuCheckededit, setThuIsCheckededit] = useState(false);
const [isFriCheckededit, setFriIsCheckededit] = useState(false);
const [isSatCheckededit, setSatIsCheckededit] = useState(false);

const [inputsfedit, setInputsFedit] = useState({dayedit:'sun',start_hoursedit:'12',start_minutesedit:'00',end_hoursedit:'12',end_minutesedit:'00',statusedit:0});
const [inputsSundayedit, setInputsSundayedit] = useState({dayedit:'sunday',sunstart_hoursedit:'12',sunstart_minutesedit:'00',sunend_hoursedit:'12',sunend_minutesedit:'00',statusedit:0});
const [inputsMondayedit, setInputsMondayedit] = useState({dayedit:'monday',monstart_hoursedit:'12',monstart_minutesedit:'00',monend_hoursedit:'12',monend_minutesedit:'00',statusedit:0});
const [inputsTuesdayedit, setInputsTuesdayedit] = useState({dayedit:'tuesday',tuestart_hoursedit:'12',tuestart_minutesedit:'00',tueend_hoursedit:'12',tueend_minutesedit:'00',statusedit:0});
const [inputsWednesdayedit, setInputsWednesdayedit] = useState({dayedit:'wednesday',wedstart_hoursedit:'12',wedstart_minutesedit:'00',wedend_hoursedit:'12',wedend_minutesedit:'00',statusedit:0});
const [inputsThursdayedit, setInputsThursdayedit] = useState({dayedit:'thursday',thustart_hoursedit:'12',thustart_minutesedit:'00',thuend_hoursedit:'12',thuend_minutesedit:'00',statusedit:0});
const [inputsFridayedit, setInputsFridayedit] = useState({dayedit:'friday',fristart_hoursedit:'12',fristart_minutesedit:'00',friend_hoursedit:'12',friend_minutesedit:'00',statusedit:0});
const [inputsSaturdayedit, setInputsSaturdayedit] = useState({dayedit:'saturday',satstart_hoursedit:'12',satstart_minutesedit:'00',satend_hoursedit:'12',satend_minutesedit:'00',statusedit:0}); 

const [show1edit, setShow1edit] = useState(false);
const handleClose1edit = () => setShow1edit(false);


const [show2edit, setShow2edit] = useState(false);
const handleClose2edit = () => setShow2edit(false);
const handleShow2edit = () => setShow2edit(true);

const [inputsedit,setInputsedit] = useState({idedit:'',employee_idedit:'',employee_nameedit:'',employee_emailedit:'',employee_contact_noedit:'',country_codeedit:'1',country_short_codeedit:'us',date_of_birthedit:'',commissionedit:'',accepted_per_time_slotedit:4,auto_accept_during_working_hoursedit:'',auto_refuseedit:0});

const [stripeset,setStripeset] = useState('');

const [actstatus,setActstatus] = useState(false);

useEffect(() => {
      document.title = `Merchant Employee Management | Blue Faucet`;
      document.getElementById('bodywrap').className='memplyscrnpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
	
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			API.post('get_employee_with_merchant_id', formData)
                .then(response => {
                if(response.data.status === 0){
                   
                }else{
					setEmployee(response.data.data.employee_array);
                }                  
            }).catch(error => {
                 
			});  
			
	       let formData1 = new FormData(); 
			formData1.append('token', localStorage.getItem("token"));
			API.post('get_employee_id', formData1)
                .then(response => {
                if(response.data.status === 0){
                   
                }else{
					var empidlbl="employee_id";
					setInputs(inputs => ({...inputs, [empidlbl]: response.data.data.id}));
                }                  
            }).catch(error => {
                 
			}); 

		/* Start get stripe details */
	let formData2 = new FormData(); 
		formData2.append('token', localStorage.getItem("token"));
		API.post('get_keys', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				const stripePromise = loadStripe(response.data.data.admin_stripe_public_key);
					setStripeset(stripePromise);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get stripe details */
     /* Start get stripe details */
	let formData3 = new FormData(); 
		formData3.append('token', localStorage.getItem("token"));
		API.post('check_plan_for_add_employee', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  if(response.data.data.status===1)
			  {
				setActstatus(true);
			  }
			}                  
		}).catch(error => {
			 
		});  
	 /* End get stripe details */	 
},[])  
useEffect(() => {
    if (show1) {
      const input = inputRef.current;
    const iti = intlTelInput(input, {
	  initialCountry:"us",
      separateDialCode:true,
    });

    // Listen for changes
    input.addEventListener('countrychange', () => {
      const selectedCountryData = iti.getSelectedCountryData();
	  var country_code_lbl='country_code';
	  setInputs(inputs => ({...inputs, [country_code_lbl]: selectedCountryData.dialCode}));
	  var country_short_code_lbl='country_short_code';
	  setInputs(inputs => ({...inputs, [country_short_code_lbl]: selectedCountryData.iso2}));
    });

    // Clean up when the component unmounts
    return () => {
      iti.destroy();
    };
    }
  }, [show1]);
  
  useEffect(() => {
    if (show1edit) {
      const input = inputRef1.current;
    const iti = intlTelInput(input, {
	  initialCountry:inputsedit.country_short_codeedit,
      separateDialCode:true,
    });

    // Listen for changes
    input.addEventListener('countrychange', () => {
      const selectedCountryData = iti.getSelectedCountryData();
	  var country_code_lbl='country_codeedit';
	  setInputsedit(inputsedit => ({...inputsedit, [country_code_lbl]: selectedCountryData.dialCode}));
	  var country_short_code_lbl='country_short_codeedit';
	  setInputsedit(inputsedit => ({...inputsedit, [country_short_code_lbl]: selectedCountryData.iso2}));
    });

    // Clean up when the component unmounts
    return () => {
      iti.destroy();
    }; 
    }
  }, [show1edit,inputsedit]);
/* Start Password Visible */
const handleClickShowPassword = () => {setValues({ ...values, showPassword: !values.showPassword });}
const handleClickShowPassword1 = () => {setValues1({ ...values1, showPassword: !values1.showPassword });}
/* End Password Visible */

/* Start Value set */
  const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
    }
	const handleInputChangeSlot = (event) => {
      event.persist();
	  var slot = (typeof event.target.value !== 'undefined' && event.target.value !== '' && !isNaN(event.target.value)) ? parseFloat(event.target.value) : 0;
      if(slot >0)
	  {
		setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
      }else{
			setInputs(inputs => ({...inputs, [event.target.name]: 4}));
	  }		  
    }
const formatAsPhoneNumber = (event) => {
	event.persist();
	var value=event.target.value;
	value = value.replace(/ /g, '');
	value = value.replace(/\D/g, '');
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	
	if(value.length > 3 && value.length < 7)
	{
	    value = value.slice(0, 3) + ' ' + value.slice(3, 6);
	}else if(value.length > 6)
	{
		
		value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
	}
	setInputs(inputs => ({...inputs, [event.target.name]: value})); 
}
const formatAsPhoneNumber1 = (event) => {
	event.persist();
	var value=event.target.value;
	value = value.replace(/ /g, '');
	value = value.replace(/\D/g, '');
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	
	if(value.length > 3 && value.length < 7)
	{
	    value = value.slice(0, 3) + ' ' + value.slice(3, 6);
	}else if(value.length > 6)
	{
		
		value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
	}
	setInputsedit(inputsedit => ({...inputsedit, [event.target.name]: value})); 
}
/* End Value set */
/* Start Search Value set */
  const handleInputChangeSearch = (event) => {
      event.persist();
      setInputssearch(setInputssearch => ({...inputssearch, [event.target.name]: event.target.value}));
	  
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('keyword', event.target.value);
		API.post('serach_employee', formData)
			.then(response => {
			if(response.data.status === 0){
			   
			}else{
				setEmployee(response.data.data.employee_array);
			}                  
		}).catch(error => {
			 
		});  
    }
/* End Search Value set */
/* Start working hour checked */
const handleCheckboxChange = (event) => {
	setIsChecked(!isChecked);
	var lbl='status';
	if(event.target.checked)
	{
		
		setInputsF(inputsf => ({...inputsf, [lbl]: 1}));
	}else{
		
		setInputsF(inputsf => ({...inputsf, [lbl]: 0}));
		
		
	}
};
const handleSunCheckboxChange = (event) => {
	setSunIsChecked(!isSunChecked);
	var sunlbl='status';
	if(event.target.checked)
	{
		
		setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 1}));
	}else{
		
		setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 0}));
		
		var starthlbl='sunstart_hours';
		var start_hours='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [starthlbl]: start_hours})); 
		
		var startmlbl='sunstart_minutes';
		var start_minutes='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [startmlbl]: start_minutes}));
		
		var endhlbl='sunend_hours';
		var end_hours='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [endhlbl]: end_hours}));
		
		var endmlbl='sunend_minutes';
		var end_minutes='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [endmlbl]: end_minutes}));
	
	}
};
const handleMonCheckboxChange = (event) => {
	setMonIsChecked(!isMonChecked);
	var monlbl='status';
	if(event.target.checked)
	{
		
		setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 1}));
	}else{
		setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 0}));
		
		var starthlbl='monstart_hours';
		var start_hours='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [starthlbl]: start_hours})); 
		
		var startmlbl='monstart_minutes';
		var start_minutes='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [startmlbl]: start_minutes}));
		
		
		var endhlbl='monend_hours';
		var end_hours='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [endhlbl]: end_hours}));
		
		var endmlbl='monend_minutes';
		var end_minutes='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [endmlbl]: end_minutes}));
		
	}
};
const handleTueCheckboxChange = (event) => {
	setTueIsChecked(!isTueChecked);
	var tuelbl='status';
	if(event.target.checked)
	{
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 1}));
	}else{
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 0}));
		
		var starthlbl='tuestart_hours';
		var start_hours='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [starthlbl]: start_hours})); 
		
		var startmlbl='tuestart_minutes';
		var start_minutes='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [startmlbl]: start_minutes}));
		
		var endhlbl='tueend_hours';
		var end_hours='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endhlbl]: end_hours}));
		
		var endmlbl='tueend_minutes';
		var end_minutes='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endmlbl]: end_minutes}));
		
	}
};
const handleWedCheckboxChange = (event) => {
	setWedIsChecked(!isWedChecked);
	var wedlbl='status';
	if(event.target.checked)
	{
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 1}));
	}else{
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 0}));
		
		var starthlbl='wedstart_hours';
		var start_hours='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [starthlbl]: start_hours})); 
		
		var startmlbl='wedstart_minutes';
		var start_minutes='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [startmlbl]: start_minutes}));
		
		
		var endhlbl='wedend_hours';
		var end_hours='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endhlbl]: end_hours}));
		
		var endmlbl='wedend_minutes';
		var end_minutes='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endmlbl]: end_minutes}));  
	}
};
const handleThuCheckboxChange = (event) => {
	setThuIsChecked(!isThuChecked);
	var thulbl='status';
	if(event.target.checked)
	{
		setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 1}));
	}else{
		setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 0}));
		
		var starthlbl='thustart_hours';
		var start_hours='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [starthlbl]: start_hours})); 
		
		var startmlbl='thustart_minutes';
		var start_minutes='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [startmlbl]: start_minutes}));
		
		var endhlbl='thuend_hours';
		var end_hours='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [endhlbl]: end_hours}));
		
		var endmlbl='thuend_minutes';
		var end_minutes='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [endmlbl]: end_minutes})); 
	}
};
const handleFriCheckboxChange = (event) => {
	setFriIsChecked(!isFriChecked);
	var frilbl='status';
	if(event.target.checked)
	{
		setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 1}));
	}else{
		setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 0}));
		
		var starthlbl='fristart_hours';
		var start_hours='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [starthlbl]: start_hours})); 
		
		var startmlbl='fristart_minutes';
		var start_minutes='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [startmlbl]: start_minutes}));
		
		var endhlbl='friend_hours';
		var end_hours='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [endhlbl]: end_hours}));
		
		var endmlbl='friend_minutes';
		var end_minutes='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [endmlbl]: end_minutes})); 
	}
};
const handleSatCheckboxChange = (event) => {
	setSatIsChecked(!isSatChecked);
	var satlbl='status';
	if(event.target.checked)
	{
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 1}));
	}else{
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 0}));
		
		var starthlbl='satstart_hours';
		var start_hours='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [starthlbl]: start_hours})); 
		
		var startmlbl='satstart_minutes';
		var start_minutes='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [startmlbl]: start_minutes}));
		
		var endhlbl='satend_hours';
		var end_hours='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endhlbl]: end_hours}));
		
		var endmlbl='satend_minutes';
		var end_minutes='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endmlbl]: end_minutes}));
	}
};
const handleFInputChange = (event) => {
  event.persist();
  setInputsF(inputsf => ({...inputsf, [event.target.name]: event.target.value}));       
}
const handleSunInputChange = (event) => {
  event.persist();
  setInputsSunday(inputsSunday => ({...inputsSunday, [event.target.name]: event.target.value})); 
  /*if(event.target.name==='sunstart')
  {
	var sustartlbl='start';
	setInputsSunday(inputs => ({...inputs, [sustartlbl]: event.target.value})); 
  }else if(event.target.name==='sunend')
  {
	  var suendlbl='end';
	  setInputsSunday(inputs => ({...inputs, [suendlbl]: event.target.value})); 
  }*/
        
}
const handleMonInputChange = (event) => {
  event.persist();
  setInputsMonday(inputsMonday => ({...inputsMonday, [event.target.name]: event.target.value})); 
  /*if(event.target.name==='monstart')
  {
	var mostartlbl='start';
	setInputsMonday(inputs => ({...inputs, [mostartlbl]: event.target.value}));
  }else if(event.target.name==='monend')
  {
	  var monendlbl='end';
	  setInputsMonday(inputs => ({...inputs, [monendlbl]: event.target.value}));
  }*/
         
}
const handleTueInputChange = (event) => {
  event.persist();
  setInputsTuesday(inputsTuesday => ({...inputsTuesday, [event.target.name]: event.target.value})); 
  /*if(event.target.name==='tuestart')
  {
	var tustartlbl='start';
	setInputsTuesday(inputs => ({...inputs, [tustartlbl]: event.target.value}));
  }else if(event.target.name==='tueend')
  {
	  var tuendlbl='end';
	  setInputsTuesday(inputs => ({...inputs, [tuendlbl]: event.target.value}));
  }*/
         
}
const handleWedInputChange = (event) => {
  event.persist();
  setInputsWednesday(inputsWednesday => ({...inputsWednesday, [event.target.name]: event.target.value}));
  /*if(event.target.name==='wedstart')
  {
	var westartlbl='start';
	setInputsWednesday(inputs => ({...inputs, [westartlbl]: event.target.value}));
  }else if(event.target.name==='wedend')
  {
	  var weendlbl='end';
	  setInputsWednesday(inputs => ({...inputs, [weendlbl]: event.target.value}));
  }*/
         
}
const handleThuInputChange = (event) => {
  event.persist();
  setInputsThursday(inputsThursday => ({...inputsThursday, [event.target.name]: event.target.value}));
  /*if(event.target.name==='thustart')
  {
	var thstartlbl='start';
	setInputsThursday(inputs => ({...inputs, [thstartlbl]: event.target.value})); 
  }else if(event.target.name==='thuend')
  {
	  var tuendlbl='end';
	  setInputsThursday(inputs => ({...inputs, [tuendlbl]: event.target.value})); 
  } */      
}
const handleFriInputChange = (event) => {
  event.persist();
  setInputsFriday(inputsFriday => ({...inputsFriday, [event.target.name]: event.target.value}));
  /*if(event.target.name==='fristart')
  {
	var frstartlbl='start';
	setInputsFriday(inputs => ({...inputs, [frstartlbl]: event.target.value})); 
  }else if(event.target.name==='friend')
  {
	  var frendlbl='end';
	  setInputsFriday(inputs => ({...inputs, [frendlbl]: event.target.value})); 
  }*/
        
}
const handleSatInputChange = (event) => {
  event.persist();
  setInputsSaturday(inputsSaturday => ({...inputsSaturday, [event.target.name]: event.target.value}));
  /*if(event.target.name==='satstart')
  {
	var sastartlbl='start';
	setInputsSaturday(inputs => ({...inputs, [sastartlbl]: event.target.value})); 
  }else if(event.target.name==='satend')
  {
	  var satendlbl='end';
	  setInputsSaturday(inputs => ({...inputs, [satendlbl]: event.target.value})); 
  }*/
        
}
/* End working hour checked */
/* start input refuse */
const handleOnChangerefuse = (event) => {
	var refuselbl="auto_refuse";
	if(event.target.checked)
	{	
		setInputs(inputs => ({...inputs, [refuselbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [refuselbl]: 0}));
	}
}
/* start input refuse */




/* Start Submit Emp */
const handleSubmit = (event) => {
	event.preventDefault();
		const validationErrors = validateemp(inputs,inputsSunday,inputsMonday,inputsTuesday,inputsWednesday,inputsThursday,inputsFriday,inputsSaturday);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let employee_id=inputs.employee_id;
			let employee_name=inputs.employee_name;
			let employee_email=inputs.employee_email;
			let employee_contact_no=inputs.employee_contact_no;
			let country_code=inputs.country_code;
			let country_short_code=inputs.country_short_code;
			let date_of_birth=inputs.date_of_birth;
			let commission=inputs.commission;
			let password=inputs.password;
			let password_confirmation=inputs.password_confirmation;
			let accepted_per_time_slot=inputs.accepted_per_time_slot;
			let auto_accept_during_working_hours=inputs.auto_accept_during_working_hours;
			let auto_refuse=inputs.auto_refuse;
			
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('employee_id', employee_id);
			formData.append('employee_name', employee_name);
			formData.append('employee_email', employee_email);
			formData.append('employee_contact_no', employee_contact_no);
			formData.append('country_code', country_code);
			formData.append('country_short_code', country_short_code);
			formData.append('date_of_birth', date_of_birth);
			formData.append('commission', commission);
			formData.append('password',password);
			formData.append('password_confirmation', password_confirmation);
			formData.append('accepted_per_time_slot', accepted_per_time_slot);
			formData.append('auto_accept_during_working_hours', auto_accept_during_working_hours);
			formData.append('auto_refuse', auto_refuse);
			formData.append('sunday', JSON.stringify(inputsSunday));
			formData.append('monday', JSON.stringify(inputsMonday));
			formData.append('tuesday', JSON.stringify(inputsTuesday));
			formData.append('wednesday', JSON.stringify(inputsWednesday));
			formData.append('thursday', JSON.stringify(inputsThursday));
			formData.append('friday', JSON.stringify(inputsFriday));
			formData.append('saturday', JSON.stringify(inputsSaturday));
			API.post('add_employee', formData)
                .then(response => {
					setLoading(false);
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
                }else{
					setShow1(false);
					setLoading(false);
					//alert(response.data.message);
				   //window.location.href = "/mer-employee-management";
				   gettoastsuccess(response.data.message,'/mer-employee-management');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			});  
		}
}
/* End Submit Emp */

/* Start view reating */
/*const ratingview = (count) => {
	var html='';
	for (let i = 1; i <= count; i++) {
			html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star"></i></label><input class="rating__input" name="rating"  defaultValue={i} type="radio"/>';
	
	}
	var cou=count+1;
	for(let a=cou;a <= 5;a++)
	{
		html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star rating-emt"></i></label><input class="rating__input" name="rating"   type="radio"/>';
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}*/
/* End view reating */


/* Start Edit Value set */
  const handleInputChangeedit = (event) => {
      event.persist();
      setInputsedit(inputsedit => ({...inputsedit, [event.target.name]: event.target.value}));       
    }
	const handleInputChangeSlotedit = (event) => {
      event.persist();
	  var slot = (typeof event.target.value !== 'undefined' && event.target.value !== '' && !isNaN(event.target.value)) ? parseFloat(event.target.value) : 0;
      if(slot >0)
	  {
		setInputsedit(inputsedit => ({...inputsedit, [event.target.name]: event.target.value}));
      }else{
			setInputsedit(inputsedit => ({...inputsedit, [event.target.name]: 4}));
	  }		  
    }
/* End Edit Value set */

/* start edit input refuse */
const handleOnChangerefuseedit = (event) => {
	var refuselbl="auto_refuseedit";
	if(event.target.checked)
	{	
		setInputsedit(inputsedit => ({...inputsedit, [refuselbl]: 1}));
	}else{
		setInputsedit(inputsedit => ({...inputsedit, [refuselbl]: 0}));
	}
}
/* start edit input refuse */

/* Start Edit working hour checked */
const handleCheckboxChangeedit = (event) => {
	setIsCheckededit(!isCheckededit);
	var lbl='statusedit';
	if(event.target.checked)
	{
		setInputsFedit(inputsfedit => ({...inputsfedit, [lbl]: 1}));
	}else{
		setInputsFedit(inputsfedit => ({...inputsfedit, [lbl]: 0}));
	}
};
const handleSunCheckboxChangeedit = (event) => {
	setSunIsCheckededit(!isSunCheckededit);
	var sunlbl='statusedit';
	if(event.target.checked)
	{
		
		setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [sunlbl]: 1}));
	}else{
		
		setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [sunlbl]: 0}));
		
		var starthlbl='sunstart_hoursedit';
		var start_hours='';
		setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [starthlbl]: start_hours})); 
		
		var startmlbl='sunstart_minutesedit';
		var start_minutes='';
		setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [startmlbl]: start_minutes}));
		
		var endhlbl='sunend_hoursedit';
		var end_hours='';
		setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [endhlbl]: end_hours}));
		
		var endmlbl='sunend_minutesedit';
		var end_minutes='';
		setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [endmlbl]: end_minutes}));
	}
};
const handleMonCheckboxChangeedit = (event) => {
	setMonIsCheckededit(!isMonCheckededit);
	var monlbl='statusedit';
	if(event.target.checked)
	{
		setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [monlbl]: 1}));
	}else{
		setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [monlbl]: 0}));
		
		var starthlbl='monstart_hoursedit';
		var start_hours='';
		setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [starthlbl]: start_hours})); 
		
		var startmlbl='monstart_minutesedit';
		var start_minutes='';
		setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [startmlbl]: start_minutes}));
		
		var endhlbl='monend_hoursedit';
		var end_hours='';
		setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [endhlbl]: end_hours}));
		
		var endmlbl='monend_minutesedit';
		var end_minutes='';
		setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [endmlbl]: end_minutes}));
	}
};
const handleTueCheckboxChangeedit = (event) => {
	setTueIsCheckededit(!isTueCheckededit);
	var tuelbl='statusedit';
	if(event.target.checked)
	{
		setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [tuelbl]: 1}));
	}else{
		setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [tuelbl]: 0}));
		
		var starthlbl='tuestart_hoursedit';
		var start_hours='';
		setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [starthlbl]: start_hours})); 
		
		var startmlbl='tuestart_minutesedit';
		var start_minutes='';
		setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [startmlbl]: start_minutes}));
		
		var endhlbl='tueend_hoursedit';
		var end_hours='';
		setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [endhlbl]: end_hours}));
		
		var endmlbl='tueend_minutesedit';
		var end_minutes='';
		setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [endmlbl]: end_minutes})); 
	}
};
const handleWedCheckboxChangeedit = (event) => {
	setWedIsCheckededit(!isWedCheckededit);
	var wedlbl='statusedit';
	if(event.target.checked)
	{
		setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [wedlbl]: 1}));
	}else{
		setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [wedlbl]: 0}));
		
		var starthlbl='wedstart_hoursedit';
		var start_hours='';
		setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [starthlbl]: start_hours})); 
		
		var startmlbl='wedstart_minutesedit';
		var start_minutes='';
		setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [startmlbl]: start_minutes}));
		
		var endhlbl='wedend_hoursedit';
		var end_hours='';
		setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [endhlbl]: end_hours}));
		
		var endmlbl='wedend_minutesedit';
		var end_minutes='';
		setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [endmlbl]: end_minutes}));
	}
};
const handleThuCheckboxChangeedit = (event) => {
	setThuIsCheckededit(!isThuCheckededit);
	var thulbl='statusedit';
	if(event.target.checked)
	{
		setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [thulbl]: 1}));
	}else{
		setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [thulbl]: 0}));
		
		var starthlbl='thustart_hoursedit';
		var start_hours='';
		setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [starthlbl]: start_hours})); 
		
		var startmlbl='thustart_minutesedit';
		var start_minutes='';
		setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [startmlbl]: start_minutes}));
		
		var endhlbl='thuend_hoursedit';
		var end_hours='';
		setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [endhlbl]: end_hours}));
		
		var endmlbl='thuend_minutesedit';
		var end_minutes='';
		setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [endmlbl]: end_minutes})) 
	}
};
const handleFriCheckboxChangeedit = (event) => {
	setFriIsCheckededit(!isFriCheckededit);
	var frilbl='statusedit';
	if(event.target.checked)
	{
		setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [frilbl]: 1}));
	}else{
		setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [frilbl]: 0}));
		
		var starthlbl='fristart_hoursedit';
		var start_hours='';
		setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [starthlbl]: start_hours})); 
		
		var startmlbl='fristart_minutesedit';
		var start_minutes='';
		setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [startmlbl]: start_minutes}));
		
		var endhlbl='friend_hoursedit';
		var end_hours='';
		setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [endhlbl]: end_hours}));
		
		var endmlbl='friend_minutesedit';
		var end_minutes='';
		setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [endmlbl]: end_minutes}));  
	}
};
const handleSatCheckboxChangeedit = (event) => {
	setSatIsCheckededit(!isSatCheckededit);
	var satlbl='statusedit';
	if(event.target.checked)
	{
		setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [satlbl]: 1}));
	}else{
		setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [satlbl]: 0}));
		
		var starthlbl='satstart_hoursedit';
		var start_hours='';
		setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [starthlbl]: start_hours})); 
		
		var startmlbl='satstart_minutesedit';
		var start_minutes='';
		setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [startmlbl]: start_minutes}));
		
		var endhlbl='satend_hoursedit';
		var end_hours='';
		setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [endhlbl]: end_hours}));
		
		var endmlbl='satend_minutesedit';
		var end_minutes='';
		setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [endmlbl]: end_minutes})); 
	}
};
const handleFInputChangeedit = (event) => {
  event.persist();
  setInputsFedit(inputsfedit => ({...inputsfedit, [event.target.name]: event.target.value}));       
}
const handleSunInputChangeedit = (event) => {
  event.persist();
  setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [event.target.name]: event.target.value})); 
  /*if(event.target.name==='sunstartedit')
  {
	var sustartlbl='startedit';
	setInputsSundayedit(inputsedit => ({...inputsedit, [sustartlbl]: event.target.value})); 
  }else if(event.target.name==='sunendedit')
  {
	  var suendlbl='endedit';
	  setInputsSundayedit(inputsedit => ({...inputsedit, [suendlbl]: event.target.value})); 
  }*/
        
}
const handleMonInputChangeedit = (event) => {
  event.persist();
  setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [event.target.name]: event.target.value}));
  /*if(event.target.name==='monstartedit')
  {
	var mostartlbl='startedit';
	setInputsMondayedit(inputsedit => ({...inputsedit, [mostartlbl]: event.target.value}));
  }else if(event.target.name==='monendedit')
  {
	  var monendlbl='endedit';
	  setInputsMondayedit(inputsedit => ({...inputsedit, [monendlbl]: event.target.value}));
  }*/   
}
const handleTueInputChangeedit = (event) => {
  event.persist();
  setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [event.target.name]: event.target.value})); 
  /*if(event.target.name==='tuestartedit')
  {
	var tustartlbl='startedit';
	setInputsTuesdayedit(inputsedit => ({...inputsedit, [tustartlbl]: event.target.value}));
  }else if(event.target.name==='tueendedit')
  {
	  var tuendlbl='endedit';
	  setInputsTuesdayedit(inputsedit => ({...inputsedit, [tuendlbl]: event.target.value}));
  }*/        
}
const handleWedInputChangeedit = (event) => {
  event.persist();
  setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [event.target.name]: event.target.value}));
  /*if(event.target.name==='wedstartedit')
  {
	var westartlbl='startedit';
	setInputsWednesdayedit(inputsedit => ({...inputsedit, [westartlbl]: event.target.value}));
  }else if(event.target.name==='wedendedit')
  {
	  var weendlbl='endedit';
	  setInputsWednesdayedit(inputsedit => ({...inputsedit, [weendlbl]: event.target.value}));
  }*/   
}
const handleThuInputChangeedit = (event) => {
  event.persist();
  setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [event.target.name]: event.target.value}));
  /*if(event.target.name==='thustartedit')
  {
	var thstartlbl='startedit';
	setInputsThursdayedit(inputsedit => ({...inputsedit, [thstartlbl]: event.target.value})); 
  }else if(event.target.name==='thuendedit')
  {
	  var tuendlbl='endedit';
	  setInputsThursdayedit(inputsedit => ({...inputsedit, [tuendlbl]: event.target.value})); 
  }*/       
}
const handleFriInputChangeedit = (event) => {
  event.persist();
  setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [event.target.name]: event.target.value}));
  /*if(event.target.name==='fristartedit')
  {
	var frstartlbl='startedit';
	setInputsFridayedit(inputsedit => ({...inputsedit, [frstartlbl]: event.target.value})); 
  }else if(event.target.name==='friendedit')
  {
	  var frendlbl='endedit';
	  setInputsFridayedit(inputsedit => ({...inputsedit, [frendlbl]: event.target.value})); 
  }*/
        
}
const handleSatInputChangeedit = (event) => {
  event.persist();
  setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [event.target.name]: event.target.value}));
  /*if(event.target.name==='satstartedit')
  {
	var sastartlbl='startedit';
	setInputsSaturdayedit(inputsedit => ({...inputsedit, [sastartlbl]: event.target.value})); 
  }else if(event.target.name==='satendedit')
  {
	  var satendlbl='endedit';
	  setInputsSaturdayedit(inputsedit => ({...inputsedit, [satendlbl]: event.target.value})); 
  }*/
        
}
/* End edit working hour checked */

/* Start Submit Edit Emp */
const handleSubmitedit = (event) => {
	event.preventDefault();
		const validationErrors = validateempedit(inputsedit,inputsSundayedit,inputsMondayedit,inputsTuesdayedit,inputsWednesdayedit,inputsThursdayedit,inputsFridayedit,inputsSaturdayedit);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let id=inputsedit.idedit;
			let employee_id=inputsedit.employee_idedit;
			let employee_name=inputsedit.employee_nameedit;
			let employee_email=inputsedit.employee_emailedit;
			let employee_country_code=inputsedit.country_codeedit;
			let employee_country_short_code=inputsedit.country_short_codeedit;
			let employee_contact_no=inputsedit.employee_contact_noedit;
			let date_of_birth=inputsedit.date_of_birthedit;
			let commission=inputsedit.commissionedit;
			let accepted_per_time_slot=inputsedit.accepted_per_time_slotedit;
			let auto_accept_during_working_hours=inputsedit.auto_accept_during_working_hoursedit;
			let auto_refuse=inputsedit.auto_refuseedit;
			
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('id', id);
			formData.append('employee_id', employee_id);
			formData.append('employee_name', employee_name);
			formData.append('employee_email', employee_email);
			formData.append('country_code', employee_country_code);
			formData.append('country_short_code', employee_country_short_code);
			formData.append('employee_contact_no', employee_contact_no);
			formData.append('date_of_birth', date_of_birth);
			formData.append('commission', commission);
			formData.append('accepted_per_time_slot', accepted_per_time_slot);
			formData.append('auto_accept_during_working_hours', auto_accept_during_working_hours);
			formData.append('auto_refuse', auto_refuse);
			formData.append('sunday', JSON.stringify(inputsSundayedit));
			formData.append('monday', JSON.stringify(inputsMondayedit));
			formData.append('tuesday', JSON.stringify(inputsTuesdayedit));
			formData.append('wednesday', JSON.stringify(inputsWednesdayedit));
			formData.append('thursday', JSON.stringify(inputsThursdayedit));
			formData.append('friday', JSON.stringify(inputsFridayedit));
			formData.append('saturday', JSON.stringify(inputsSaturdayedit));
			API.post('update_employee_detail_with_merchant', formData)
                .then(response => {
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
                }else{
					setShow1edit(false);
					setLoading(false);
					//alert(response.data.message);
				   //window.location.href = "/mer-employee-management";
				   gettoastsuccess(response.data.message,'/mer-employee-management');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			}); 
		}
}
/* End Submit Edit Emp */


/* Start Address edit */
const handleShow1Edit = (id) => {
	setLoading(true);
	let formData = new FormData(); 
	   formData.append('token', localStorage.getItem("token"));
		formData.append('id', id);
		API.post('get_employee_detail_with_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				handleHourPro(response.data.data.working_hours);
				var country_code=1;
				var country_short_code='us';
				if(response.data.data.country_short_code!=='')
				{
					country_code=response.data.data.country_code;
					country_short_code=response.data.data.country_short_code;
				}
			  setInputsedit({
				  idedit:id,
				  employee_idedit:response.data.data.emp_id,
				  employee_nameedit:response.data.data.name,
				  employee_emailedit:response.data.data.email,
				  employee_contact_noedit:response.data.data.emp_mobile,
				  country_codeedit:country_code,
				  country_short_codeedit:country_short_code,
				  date_of_birthedit:response.data.data.dob,
				  commissionedit:response.data.data.commission,
				  accepted_per_time_slotedit:response.data.data.accepted_per_time_slot,
				  auto_accept_during_working_hoursedit:response.data.data.auto_accept_during_working_hours,
				  auto_refuseedit:response.data.data.auto_refuse
			  });
			  setShow1edit(true);
			}                  
		}).catch(error => {
			 
		});  
	
}
/* End Address edit */
/* Start Edit working hour checked */
const handleHourPro = (val) => {
   
	val.forEach((item) => {
		if(item.day==='sunday')
		{
			const sunval= {
				dayedit:'sunday',
				sunstart_hoursedit:item.start_hr,
				sunstart_minutesedit:item.start_min,
				sunend_hoursedit:item.end_hr,
				sunend_minutesedit:item.end_min,
				statusedit:item.status
			  };
			setInputsSundayedit(sunval);
			if(item.status===1){
				setSunIsCheckededit(item.status);
			}
		}
		if(item.day==='monday')
		{
			const sunval= {
				dayedit:'monday',
				monstart_hoursedit:item.start_hr,
				monstart_minutesedit:item.start_min,
				monend_hoursedit:item.end_hr,
				monend_minutesedit:item.end_min,
				statusedit:item.status
			  };
			setInputsMondayedit(sunval);
			if(item.status===1){
				setMonIsCheckededit(item.status);
			}
		}
		if(item.day==='tuesday')
		{
			const sunval= {
				dayedit:'tuesday',
				tuestart_hoursedit:item.start_hr,
				tuestart_minutesedit:item.start_min,
				tueend_hoursedit:item.end_hr,
				tueend_minutesedit:item.end_min,
				statusedit:item.status
			  };
			setInputsTuesdayedit(sunval);
			if(item.status===1){
				setTueIsCheckededit(item.status);
			}
		}
		if(item.day==='wednesday')
		{
			const sunval= {
				dayedit:'wednesday',
				wedstart_hoursedit:item.start_hr,
				wedstart_minutesedit:item.start_min,
				wedend_hoursedit:item.end_hr,
				wedend_minutesedit:item.end_min,
				statusedit:item.status
			  };
			setInputsWednesdayedit(sunval);
			if(item.status===1){
				setWedIsCheckededit(item.status);
			}
		}
		if(item.day==='thursday')
		{
			const sunval= {
				dayedit:'thursday',
				thustart_hoursedit:item.start_hr,
				thustart_minutesedit:item.start_min,
				thuend_hoursedit:item.end_hr,
				thuend_minutesedit:item.end_min,
				statusedit:item.status
			  };
			setInputsThursdayedit(sunval);
			if(item.status===1){
				setThuIsCheckededit(item.status);
			}
		}
		if(item.day==='friday')
		{
			const sunval= {
				dayedit:'friday',
				fristart_hoursedit:item.start_hr,
				fristart_minutesedit:item.start_min,
				friend_hoursedit:item.end_hr,
				friend_minutesedit:item.end_min,
				statusedit:item.status
			  };
			setInputsFridayedit(sunval);
			if(item.status===1){
				setFriIsCheckededit(item.status);
			}
		}
		if(item.day==='saturday')
		{
			const sunval= {
				dayedit:'saturday',
				satstart_hoursedit:item.start_hr,
				satstart_minutesedit:item.start_min,
				satend_hoursedit:item.end_hr,
				satend_minutesedit:item.end_min,
				statusedit:item.status
			  };
			setInputsSaturdayedit(sunval);
			if(item.status===1){
				setSatIsCheckededit(item.status);
			}
		}
	});
	
  };
/* End Edit working hour checked */
const handleSetVal = () => {
	
	var start_hours=inputsf.start_hours;
	var start_minutes=inputsf.start_minutes;
	var end_hours=inputsf.end_hours;
	var end_minutes=inputsf.end_minutes;
	
	setSunIsChecked(true);
	setMonIsChecked(true);
	setTueIsChecked(true);
	setWedIsChecked(true);
	setThuIsChecked(true);
	setFriIsChecked(true);
	setSatIsChecked(true);
	
	var sunlbl='status';
	setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 1}));
	
	var monlbl='status';
	setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 1}));
	
	var tuelbl='status';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 1}));
	
	var wedlbl='status';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 1}));
	
	var thulbl='status';
	setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 1}));
	
	var frilbl='status';
	setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 1}));
	
	var satlbl='status';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 1}));
	
	var starthlbl='sunstart_hours';
	setInputsSunday(inputsSunday => ({...inputsSunday, [starthlbl]: start_hours})); 
	
	var startmlbl='sunstart_minutes';
	setInputsSunday(inputsSunday => ({...inputsSunday, [startmlbl]: start_minutes}));
	
	var endhlbl='sunend_hours';
	setInputsSunday(inputsSunday => ({...inputsSunday, [endhlbl]: end_hours}));
	
	var endmlbl='sunend_minutes';
	setInputsSunday(inputsSunday => ({...inputsSunday, [endmlbl]: end_minutes}));
	
	
	
	var starthlbl1='monstart_hours';
	setInputsMonday(inputsMonday => ({...inputsMonday, [starthlbl1]: start_hours})); 
	
	var startmlbl1='monstart_minutes';
	setInputsMonday(inputsMonday => ({...inputsMonday, [startmlbl1]: start_minutes}));
	
	var endhlbl1='monend_hours';
	setInputsMonday(inputsMonday => ({...inputsMonday, [endhlbl1]: end_hours}));
	
	var endmlbl1='monend_minutes';
	setInputsMonday(inputsMonday => ({...inputsMonday, [endmlbl1]: end_minutes}));
	
	
	var starthlbl2='tuestart_hours';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [starthlbl2]: start_hours})); 
	
	var startmlbl2='tuestart_minutes';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [startmlbl2]: start_minutes}));
	
	var endhlbl2='tueend_hours';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endhlbl2]: end_hours}));
	
	var endmlbl2='tueend_minutes';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endmlbl2]: end_minutes}));
	
	
	var starthlbl3='wedstart_hours';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [starthlbl3]: start_hours})); 
	
	var startmlbl3='wedstart_minutes';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [startmlbl3]: start_minutes}));
	
	var endhlbl3='wedend_hours';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endhlbl3]: end_hours}));
	
	var endmlbl3='wedend_minutes';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endmlbl3]: end_minutes}));
	
	
	var starthlbl4='thustart_hours';
	setInputsThursday(inputsThursday => ({...inputsThursday, [starthlbl4]: start_hours})); 
	
	var startmlbl4='thustart_minutes';
	setInputsThursday(inputsThursday => ({...inputsThursday, [startmlbl4]: start_minutes}));
	
	var endhlbl4='thuend_hours';
	setInputsThursday(inputsThursday => ({...inputsThursday, [endhlbl4]: end_hours}));
	
	var endmlbl4='thuend_minutes';
	setInputsThursday(inputsThursday => ({...inputsThursday, [endmlbl4]: end_minutes}));
	
	
	var starthlbl5='fristart_hours';
	setInputsFriday(inputsFriday => ({...inputsFriday, [starthlbl5]: start_hours})); 
	
	var startmlbl5='fristart_minutes';
	setInputsFriday(inputsFriday => ({...inputsFriday, [startmlbl5]: start_minutes}));
	
	var endhlbl5='friend_hours';
	setInputsFriday(inputsFriday => ({...inputsFriday, [endhlbl5]: end_hours}));
	
	var endmlbl5='friend_minutes';
	setInputsFriday(inputsFriday => ({...inputsFriday, [endmlbl5]: end_minutes}));
	
	var starthlbl6='satstart_hours';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [starthlbl6]: start_hours})); 
	
	var startmlbl6='satstart_minutes';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [startmlbl6]: start_minutes}));
	
	var endhlbl6='satend_hours';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endhlbl6]: end_hours}));
	
	var endmlbl6='satend_minutes';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endmlbl6]: end_minutes}));
}

const handleSetValedit = () => {
	var start_hours=inputsfedit.start_hoursedit;
	var start_minutes=inputsfedit.start_minutesedit;
	var end_hours=inputsfedit.end_hoursedit;
	var end_minutes=inputsfedit.end_minutesedit;
	
	setSunIsCheckededit(true);
	setMonIsCheckededit(true);
	setTueIsCheckededit(true);
	setWedIsCheckededit(true);
	setThuIsCheckededit(true);
	setFriIsCheckededit(true);
	setSatIsCheckededit(true);
	
	var sunlbl='statusedit';
	setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [sunlbl]: 1}));
	
	var monlbl='statusedit';
	setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [monlbl]: 1}));
	
	var tuelbl='statusedit';
	setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [tuelbl]: 1}));
	
	var wedlbl='statusedit';
	setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [wedlbl]: 1}));
	
	var thulbl='statusedit';
	setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [thulbl]: 1}));
	
	var frilbl='statusedit';
	setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [frilbl]: 1}));
	
	var satlbl='statusedit';
	setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [satlbl]: 1}));
	
	var starthlbl='sunstart_hoursedit';
	setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [starthlbl]: start_hours})); 
	
	var startmlbl='sunstart_minutesedit';
	setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [startmlbl]: start_minutes}));
	
	var endhlbl='sunend_hoursedit';
	setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [endhlbl]: end_hours}));
	
	var endmlbl='sunend_minutesedit';
	setInputsSundayedit(inputsSundayedit => ({...inputsSundayedit, [endmlbl]: end_minutes}));
	
	
	
	var starthlbl1='monstart_hoursedit';
	setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [starthlbl1]: start_hours})); 
	
	var startmlbl1='monstart_minutesedit';
	setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [startmlbl1]: start_minutes}));
	
	var endhlbl1='monend_hoursedit';
	setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [endhlbl1]: end_hours}));
	
	var endmlbl1='monend_minutesedit';
	setInputsMondayedit(inputsMondayedit => ({...inputsMondayedit, [endmlbl1]: end_minutes}));
	
	
	var starthlbl2='tuestart_hoursedit';
	setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [starthlbl2]: start_hours})); 
	
	var startmlbl2='tuestart_minutesedit';
	setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [startmlbl2]: start_minutes}));
	
	var endhlbl2='tueend_hoursedit';
	setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [endhlbl2]: end_hours}));
	
	var endmlbl2='tueend_minutesedit';
	setInputsTuesdayedit(inputsTuesdayedit => ({...inputsTuesdayedit, [endmlbl2]: end_minutes}));
	
	
	var starthlbl3='wedstart_hoursedit';
	setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [starthlbl3]: start_hours})); 
	
	var startmlbl3='wedstart_minutesedit';
	setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [startmlbl3]: start_minutes}));
	
	var endhlbl3='wedend_hoursedit';
	setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [endhlbl3]: end_hours}));
	
	var endmlbl3='wedend_minutesedit';
	setInputsWednesdayedit(inputsWednesdayedit => ({...inputsWednesdayedit, [endmlbl3]: end_minutes}));
	
	
	var starthlbl4='thustart_hoursedit';
	setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [starthlbl4]: start_hours})); 
	
	var startmlbl4='thustart_minutesedit';
	setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [startmlbl4]: start_minutes}));
	
	var endhlbl4='thuend_hoursedit';
	setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [endhlbl4]: end_hours}));
	
	var endmlbl4='thuend_minutesedit';
	setInputsThursdayedit(inputsThursdayedit => ({...inputsThursdayedit, [endmlbl4]: end_minutes}));
	
	
	var starthlbl5='fristart_hoursedit';
	setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [starthlbl5]: start_hours})); 
	
	var startmlbl5='fristart_minutesedit';
	setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [startmlbl5]: start_minutes}));
	
	
	var endhlbl5='friend_hoursedit';
	setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [endhlbl5]: end_hours}));
	
	var endmlbl5='friend_minutesedit';
	setInputsFridayedit(inputsFridayedit => ({...inputsFridayedit, [endmlbl5]: end_minutes}));
	
	var starthlbl6='satstart_hoursedit';
	setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [starthlbl6]: start_hours})); 
	
	var startmlbl6='satstart_minutesedit';
	setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [startmlbl6]: start_minutes}));
	
	
	var endhlbl6='satend_hoursedit';
	setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [endhlbl6]: end_hours}));
	
	var endmlbl6='satend_minutesedit';
	setInputsSaturdayedit(inputsSaturdayedit => ({...inputsSaturdayedit, [endmlbl6]: end_minutes}));
}

const handleDisable = () => {
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('emp_id', disid);
	API.post('emp_status_change', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setShowd(false);
			let updateData = employee.map((item, i) => {
			  return disid === item.id ? { ...item, emp_status: !item.emp_status } : item;
			});
			setEmployee(updateData);
		}                  
	}).catch(error => {
		 
	});  
}
const handleShowd = (id) => {
	setDisid(id);
	setShowd(true);
}

/*const handleDeactive = (id) => {
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('emp_id', id);
	formData.append('status', 1);
	API.post('update_employee_status', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			let updateData = employee.map((item, i) => {
			  return id === item.id ? { ...item, status: !item.status } : item;
			});
			setEmployee(updateData);
		}                  
	}).catch(error => {
		 
	});  
}*/

    return (
      
        <>
        {/* ========== Merchant Employee Management Page Start ========== */}
         <section className="gen-wrap">
		 <ToastContainer />
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={'Employees'}/>
         {/* ========== Navbar End ========== */}
         <Container>
         {/* ========== Employee Management Top Content Start ========== */}
            <Row className="uespgfixdv mx-0">
               <Col md={9} xs={7} className="align-self-center pe-0">
               <div className="constrain hide-inpt">
                  <input className="input-elevated" type="text" id="search" name="search" placeholder="Search here.." onChange={handleInputChangeSearch}/>
               </div>
               </Col>
               <Col md={3} xs={5} className="align-self-center text-end usctpbtn">
				   {actstatus ? (
						<NavLink to="#"><Button variant="" type="submit" onClick={handleShow1}>Add Employee</Button></NavLink>
				   ):(
						<NavLink to="#"><Button variant="" type="submit" onClick={handleShow}>Add Employee</Button></NavLink>
				   )}
               </Col>     
            </Row>
         {/* ========== Employee Management Top Content End ========== */}
         {/* ========== Employee Management Main Content Box Start ========== */}
            <Row className="mx-0 mianhmdwncnt main-emp-box">
			{employee.length >0 ? (
               <Col className="mnhmcnt">
               {employee.length > 0 && employee.map((item,index) => {
                 return(
			   <Col md={12} xs={12} className="py-2">
               
                  <Col className="ustrlpg">
                  <Row className="mx-0 ustrl-box uesbox">
				    
                     <Col md={2} xs={2} className="px-0 align-self-center prflimgues">
					{item.emp_status ?   <NavLink to="#" onClick={() => handleShow1Edit(item.id)}>
					 {item.image ? (
                          <> 
							<img src={item.image} className="img-fluid" alt="Hm Img"/>
						  </>
							) : (
							  <>
							<img src="assets/images/usrprfl.png" className="img-fluid" alt="Hm Img"/>
						</>
                          )}
                     <span></span>
					</NavLink> : <NavLink to="#">
					 {item.image ? (
                          <> 
							<img src={item.image} className="img-fluid" alt="Hm Img"/>
						  </>
							) : (
							  <>
							<img src="assets/images/usrprfl.png" className="img-fluid" alt="Hm Img"/>
						</>
                          )}
                     <span></span>
					</NavLink> }
					 </Col>
                     <Col md={8} xs={7} className="px-2 px-md-0 align-self-center">
					{item.emp_status ?  <NavLink to="#" onClick={() => handleShow1Edit(item.id)}>
                     <h3>{item.name}</h3>
                     </NavLink> : <NavLink to="#" >
                     <h3>{item.name}</h3>
					</NavLink> }
                     </Col>
					 <Col md={2} xs={3} className="text-end px-0 align-self-center useeslinedv">
						{item.emp_status ? <NavLink to="#" onClick={() => handleShowd(item.id)} className="btn-disabled">Disable</NavLink> : 'Disabled' }
					 </Col>
                     <Col md={12} xs={12} className="uesdwn-cntcrd">
					 <NavLink to="#" onClick={() => handleShow1Edit(item.id)}>
                     <p>{item.description}</p>
					 </NavLink>
                     </Col>
                     <Col md={6} xs={6} className="align-self-center mt-2 left-uespg d-none">
						 {/*<div id="str-trng" className="">
                        <div className="rating-group strrtg-rating">
                           {ratingview(item.rating)}
                        </div>
                     </div>
						 <p>{item.total_rating} Ratings</p>*/}
                     </Col>
                  </Row>
                  </Col>
               
               </Col>
			    )
                })} 
			   
               </Col>
			   ) : (
						<Col className="mnhmcnt">
							<p className="text-center mt-4">No data found</p>
						</Col>
				 )} 
            </Row>
         {/* ========== Employee Management Main Content Box End ========== */}
            <Col className="popupmsunscptn">
            <div className="frstpop">
         {/* ========== Employee Management Subscription Plan Popup Start ========== */}
               <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Subscription Plan</h3>
                  </Modal.Header>
                  <Modal.Body>
				     <Elements stripe={stripeset}>
								<CardForm data={inputs} />
							</Elements>
                     
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
         {/* ========== Employee Management Subscription Plan Popup End ========== */}
            </div>
            <div className="popupmrcntflow1">
         {/* ========== Employee Management Employee Setup Popup Start ========== */}
               <Modal show={show1} onHide={handleClose1} className="popupwrngcnt">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Employee Setup</h3>
                  </Modal.Header>
                  <Modal.Body>
				  <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                     <Col md={12} xs={12} className="ppinpt">
                       {error && (
					<div className="alert alert-danger">
					  {error}
					</div>)}
				  {success && (
					<div className="alert alert-success">
					  {success}
					</div>)}
                        <Form.Group className="mb-3">
                           <Form.Label>Employee ID</Form.Label>
                           <Form.Control type="text" placeholder="Enter employee ID" name="employee_id" onChange={handleInputChange} value={inputs.employee_id} disabled={true} />
							{errors.employee_id && (<p className="text-danger">{errors.employee_id}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Employee Name</Form.Label>
                           <Form.Control type="text" placeholder="Enter employee name" name="employee_name" onChange={handleInputChange} value={inputs.employee_name}/>
							{errors.employee_name && (<p className="text-danger">{errors.employee_name}</p>)}
						</Form.Group>
						<Form.Group className="mb-3">
                           <Form.Label>Email</Form.Label>
                           <Form.Control type="text" placeholder="Enter employee email" name="employee_email" onChange={handleInputChange} value={inputs.employee_email}/>
							{errors.employee_email && (<p className="text-danger">{errors.employee_email}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Employee Contact No. </Form.Label>
                           <Form.Control type="tel" placeholder="000 000 0000" name="employee_contact_no" ref={inputRef} onChange={formatAsPhoneNumber} value={inputs.employee_contact_no}/>
                           {errors.employee_contact_no && (<p className="text-danger">{errors.employee_contact_no}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Date of Birth</Form.Label>
                           <Form.Control type="date" placeholder="dd/mm/yy" name="date_of_birth" onChange={handleInputChange} value={inputs.date_of_birth} max={new Date().toJSON().slice(0, 10)}/>
							{errors.date_of_birth && (<p className="text-danger">{errors.date_of_birth}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Commission (in %)<span></span></Form.Label>
                           <Form.Control type="Number" placeholder="5%" min="0" name="commission" onChange={handleInputChange} value={inputs.commission}/>
                           {errors.commission && (<p className="text-danger">{errors.commission}</p>)}
						</Form.Group>
                        <div className="custm-add mb-3">
                           <NavLink to="#">
                              <div className="linetp-cutmadd"></div>
                              <Button variant="" onClick={handleShow2}>
                                 Working Hours Setup
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                                 </svg>
                              </Button>
                           </NavLink>
						   {errors.sunstart && (<p className="text-danger">{errors.sunstart}</p>)}
							{errors.sunend && (<p className="text-danger">{errors.sunend}</p>)}
							{errors.monstart && (<p className="text-danger">{errors.monstart}</p>)}
							{errors.monend && (<p className="text-danger">{errors.monend}</p>)}
							{errors.tuestart && (<p className="text-danger">{errors.tuestart}</p>)}
							{errors.tueend && (<p className="text-danger">{errors.tueend}</p>)}
							{errors.wedstart && (<p className="text-danger">{errors.wedstart}</p>)}
							{errors.wedend && (<p className="text-danger">{errors.wedend}</p>)}
							{errors.thustart && (<p className="text-danger">{errors.thustart}</p>)}
							{errors.thuend && (<p className="text-danger">{errors.thuend}</p>)}
							{errors.fristart && (<p className="text-danger">{errors.fristart}</p>)}
							{errors.friend && (<p className="text-danger">{errors.friend}</p>)}
							{errors.satstart && (<p className="text-danger">{errors.satstart}</p>)}
							{errors.satend && (<p className="text-danger">{errors.satend}</p>)}
                        </div>
                        <Form.Group className="mb-3 pswinpt">
                           <Form.Label>Password<span></span></Form.Label>
                           <Form.Control type={values.showPassword ? "text" : "password"} id="enpsw" placeholder="At least 8 characters" name="password" onChange={handleInputChange} value={inputs.password}/>
                           {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                          {errors.password && (<p className="text-danger">{errors.password}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3 pswinpt">
                           <Form.Label>Re-enter Password<span></span></Form.Label>
                           <Form.Control type={values1.showPassword ? "text" : "password"} id="renpsw" placeholder="At least 8 characters" name="password_confirmation" onChange={handleInputChange} value={inputs.password_confirmation}/>
                           {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
                           {errors.password_confirmation && (<p className="text-danger">{errors.password_confirmation}</p>)}
						</Form.Group>
                     </Col>
                     <Row className="pp-btn1">
                        <Col>
                       <Button className="blc" type="submit">Add</Button>
                        </Col>
                     </Row>
					 </Form>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
            {/* ========== Employee Management Employee Setup Popup End ========== */}
			{/* ========== Edit Employee Management Employee Setup Popup Start ========== */}
			<Modal show={show1edit} onHide={handleClose1edit} className="popupwrngcnt">
			  <Modal.Header closeButton>
				 <h3 className="mdlttltxt">Edit Employee Setup</h3>
			  </Modal.Header>
			  <Modal.Body>
			  <Form method="post" onSubmit={handleSubmitedit} enctype="multipart/form-data">
				 <Col md={12} xs={12} className="ppinpt">
				   {error && (
				<div className="alert alert-danger">
				  {error}
				</div>)}
			  {success && (
				<div className="alert alert-success">
				  {success}
				</div>)}
					<Form.Group className="mb-3">
					   <Form.Label>Employee ID</Form.Label>
					   <Form.Control type="text" placeholder="Enter employee ID" name="employee_idedit" onChange={handleInputChangeedit} value={inputsedit.employee_idedit} disabled={true} />
						{errors.employee_idedit && (<p className="text-danger">{errors.employee_idedit}</p>)}
					</Form.Group>
					<Form.Group className="mb-3">
					   <Form.Label>Employee Name</Form.Label>
					   <Form.Control type="text" placeholder="Enter employee name" name="employee_nameedit" onChange={handleInputChangeedit} value={inputsedit.employee_nameedit}/>
						{errors.employee_nameedit && (<p className="text-danger">{errors.employee_nameedit}</p>)}
					</Form.Group>
					<Form.Group className="mb-3">
					   <Form.Label>Email</Form.Label>
					   <Form.Control type="text" placeholder="Enter employee email" name="employee_emailedit" onChange={handleInputChangeedit} value={inputsedit.employee_emailedit}/>
						{errors.employee_emailedit && (<p className="text-danger">{errors.employee_emailedit}</p>)}
					</Form.Group>
					<Form.Group className="mb-3">
					   <Form.Label>Employee Contact No.</Form.Label>
					   <Form.Control type="tel" placeholder="000 000 0000" name="employee_contact_noedit" ref={inputRef1} onChange={formatAsPhoneNumber1} value={inputsedit.employee_contact_noedit}/>
					   {errors.employee_contact_noedit && (<p className="text-danger">{errors.employee_contact_noedit}</p>)}
					</Form.Group>
					<Form.Group className="mb-3">
					   <Form.Label>Date of Birth</Form.Label>
					   <Form.Control type="date" placeholder="dd/mm/yy" name="date_of_birthedit" onChange={handleInputChangeedit} value={inputsedit.date_of_birthedit} max={new Date().toJSON().slice(0, 10)}/>
						{errors.date_of_birthedit && (<p className="text-danger">{errors.date_of_birthedit}</p>)}
					</Form.Group>
					<Form.Group className="mb-3">
					   <Form.Label>Commission (in %)<span></span></Form.Label>
					   <Form.Control type="Number" placeholder="5%" min="0" name="commissionedit" onChange={handleInputChangeedit} value={inputsedit.commissionedit}/>
					   {errors.commissionedit && (<p className="text-danger">{errors.commissionedit}</p>)}
					</Form.Group>
					<div className="custm-add mb-3">
					   <NavLink to="#">
						  <div className="linetp-cutmadd"></div>
						  <Button variant="" onClick={handleShow2edit}>
							 Working Hours Setup
							 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
							 </svg>
						  </Button>
					   </NavLink>
					   {errors.sunstartedit && (<p className="text-danger">{errors.sunstartedit}</p>)}
						{errors.sunendedit && (<p className="text-danger">{errors.sunendedit}</p>)}
						{errors.monstartedit && (<p className="text-danger">{errors.monstartedit}</p>)}
						{errors.monendedit && (<p className="text-danger">{errors.monendedit}</p>)}
						{errors.tuestartedit && (<p className="text-danger">{errors.tuestartedit}</p>)}
						{errors.tueendedit && (<p className="text-danger">{errors.tueendedit}</p>)}
						{errors.wedstartedit && (<p className="text-danger">{errors.wedstartedit}</p>)}
						{errors.wedendedit && (<p className="text-danger">{errors.wedendedit}</p>)}
						{errors.thustartedit && (<p className="text-danger">{errors.thustartedit}</p>)}
						{errors.thuendedit && (<p className="text-danger">{errors.thuendedit}</p>)}
						{errors.fristartedit && (<p className="text-danger">{errors.fristartedit}</p>)}
						{errors.friendedit && (<p className="text-danger">{errors.friendedit}</p>)}
						{errors.satstartedit && (<p className="text-danger">{errors.satstartedit}</p>)}
						{errors.satendedit && (<p className="text-danger">{errors.satendedit}</p>)}
					</div>
				 </Col>
				 <Row className="pp-btn1">
					<Col>
				   <Button className="blc" type="submit">Save</Button>
					</Col>
				 </Row>
				 </Form>
			  </Modal.Body>
			  <Modal.Footer className="d-none"></Modal.Footer>
			</Modal>
			{/* ========== Edit Employee Management Employee Setup Popup End ========== */}
            </div>
            <div className="inmodal">
            {/* ========== Employee Management Working Hours Setup Popup Start ========== */}
               <Modal show={show2} onHide={handleClose2} className="popupwrngcnt mainpopwork">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Working Hours Setup </h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Row className="chkbtn chkbtnmain mx-0 mb-1">
					 <Col xs={10} className="align-self-center mb-1">
						  <Form.Label className="fw-bold">Set timing for all days together</Form.Label>
						  </Col>
						  <Col xs={2} className="align-self-center text-end mb-1">
						   <Form.Check type="switch" id="custom-switch" label="" checked={inputsf.status} onChange={handleCheckboxChange}/>
						  </Col>
					         
								{isChecked &&
								<Col xs={12}>
								<Row>
								 <Col xs={12} className="align-self-center mt-2">
								 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="start_hours" className="mb-2" aria-label="Default select example" value={inputsf.start_hours} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="start_minutes" className="mb-2" aria-label="Default select example" value={inputsf.start_minutes} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
								 
								 </Col>
								 <Col xs={12} className="align-self-center mt-2">
								 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="end_hours" className="mb-2" aria-label="Default select example" value={inputsf.end_hours} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="end_minutes" className="mb-2" aria-label="Default select example" value={inputsf.end_minutes} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								   <Col xs={12} className="align-self-center mb-3">
									<NavLink to="#" onClick={() => handleSetVal()}><Button className="blc profilefirst" variant="">Submit</Button></NavLink>
								   </Col>
								 </Row>

								 </Col>

								</Row>
								</Col>
								}
							</Row>
                            <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label className="fw-bold">Sunday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsSunday.status} onChange={handleSunCheckboxChange} />
                              </Col>
                              {isSunChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start </Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="sunstart_hours" className="mb-2" aria-label="Default select example" value={inputsSunday.sunstart_hours} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="sunstart_minutes" className="mb-2" aria-label="Default select example" value={inputsSunday.sunstart_minutes} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="sunstart" value={inputsSunday.start} onChange={handleSunInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="sunend_hours" className="mb-2" aria-label="Default select example" value={inputsSunday.sunend_hours} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="sunend_minutes" className="mb-2" aria-label="Default select example" value={inputsSunday.sunend_minutes} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="sunend" value={inputsSunday.end} onChange={handleSunInputChange} />*/}
                                 </Col>
                              </Row>
                              </Col>
                              }
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label className="fw-bold">Monday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsMonday.status} onChange={handleMonCheckboxChange} />
                              </Col>
                              {isMonChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="monstart_hours" className="mb-2" aria-label="Default select example" value={inputsMonday.monstart_hours} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="monstart_minutes" className="mb-2" aria-label="Default select example" value={inputsMonday.monstart_minutes} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="monstart" value={inputsMonday.start} onChange={handleMonInputChange}  />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="monend_hours" className="mb-2" aria-label="Default select example" value={inputsMonday.monend_hours} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="monend_minutes" className="mb-2" aria-label="Default select example" value={inputsMonday.monend_minutes} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="monend" value={inputsMonday.end} onChange={handleMonInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                              
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label className="fw-bold">Tuesday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsTuesday.status} onChange={handleTueCheckboxChange} />
                              </Col>
                              {isTueChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="tuestart_hours" className="mb-2" aria-label="Default select example" value={inputsTuesday.tuestart_hours} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="tuestart_minutes" className="mb-2" aria-label="Default select example" value={inputsTuesday.tuestart_minutes} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM"  name="tuestart" value={inputsTuesday.start} onChange={handleTueInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="tueend_hours" className="mb-2" aria-label="Default select example" value={inputsTuesday.tueend_hours} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="tueend_minutes" className="mb-2" aria-label="Default select example" value={inputsTuesday.tueend_minutes} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="tueend" value={inputsTuesday.end} onChange={handleTueInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label className="fw-bold">Wednesday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsWednesday.status} onChange={handleWedCheckboxChange}/>
                              </Col>
                              {isWedChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="wedstart_hours" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedstart_hours} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="wedstart_minutes" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedstart_minutes} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="wedstart" value={inputsWednesday.start} onChange={handleWedInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="wedend_hours" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedend_hours} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="wedend_minutes" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedend_minutes} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="wedend" value={inputsWednesday.end} onChange={handleWedInputChange} />*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label className="fw-bold">Thursday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsThursday.status} onChange={handleThuCheckboxChange}/>
                              </Col>
                              {isThuChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="thustart_hours" className="mb-2" aria-label="Default select example" value={inputsThursday.thustart_hours} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="thustart_minutes" className="mb-2" aria-label="Default select example" value={inputsThursday.thustart_minutes} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="thustart" value={inputsThursday.start} onChange={handleThuInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="thuend_hours" className="mb-2" aria-label="Default select example" value={inputsThursday.thuend_hours} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="thuend_minutes" className="mb-2" aria-label="Default select example" value={inputsThursday.thuend_minutes} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="thuend" value={inputsThursday.end} onChange={handleThuInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }         
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label className="fw-bold">Friday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsFriday.status} onChange={handleFriCheckboxChange}/>
                              </Col>
                              {isFriChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2" >
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="fristart_hours" className="mb-2" aria-label="Default select example" value={inputsFriday.fristart_hours} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="fristart_minutes" className="mb-2" aria-label="Default select example" value={inputsFriday.fristart_minutes} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM"  name="fristart" value={inputsFriday.start} onChange={handleFriInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="friend_hours" className="mb-2" aria-label="Default select example" value={inputsFriday.friend_hours} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="friend_minutes" className="mb-2" aria-label="Default select example" value={inputsFriday.friend_minutes} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="friend" value={inputsFriday.end} onChange={handleFriInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label className="fw-bold">Saturday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsSaturday.status} onChange={handleSatCheckboxChange}/>
                              </Col>
                              {isSatChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="satstart_hours" className="mb-2" aria-label="Default select example" value={inputsSaturday.satstart_hours} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="satstart_minutes" className="mb-2" aria-label="Default select example" value={inputsSaturday.satstart_minutes} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="satstart" value={inputsSaturday.start} onChange={handleSatInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="satend_hours" className="mb-2" aria-label="Default select example" value={inputsSaturday.satend_hours} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="satend_minutes" className="mb-2" aria-label="Default select example" value={inputsSaturday.satend_minutes} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="satend" value={inputsSaturday.end} onChange={handleSatInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={12} className="align-self-center px-0">
                              <h3 className="mdlttltxt float-start">Orders/Appointments settings</h3>
                              <span style={{ }}>
                                 <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => (
                                 <Tooltip {...props}>Get more information in FAQs!</Tooltip>
                                 )}placement="bottom">
                                 <NavLink to="/mer-help"> <span className="mdlttltxt text-danger">&nbsp;&#9432; </span></NavLink>
                                 </OverlayTrigger>
                              </span>
                              </Col>
                              <div className="linepop my-1"></div>
                              <Row className="chkbtn mx-0 mb-3 mt-3 px-0">
                                 <Col xs={9} className="align-self-center">
                                 <Form.Label>How Many customers can be accepted per time slot (per hour):</Form.Label>
                                 </Col>
                                 <Col xs={3} className="align-self-center">
                                  <Form.Control type="Number" placeholder="4" name="accepted_per_time_slot" min="1"  value={inputs.accepted_per_time_slot} onChange={handleInputChangeSlot}/>
                                 </Col>
                              </Row>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Auto accept during working hours</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang mb-2">
                              <Form.Check type="radio" className="chk-radio" name="auto_accept_during_working_hours" id="custom-switch" label="" value="auto_accept_during_working_hours" onChange={handleInputChange} checked={inputs.auto_accept_during_working_hours === 'auto_accept_during_working_hours'}/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Need Confirmation</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang mb-2">
                              <Form.Check type="radio" className="chk-radio" name="auto_accept_during_working_hours" id="custom-switch" label="" value="need_to_be_confirmed" onChange={handleInputChange} checked={inputs.auto_accept_during_working_hours === 'need_to_be_confirmed'}/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Auto Refuse (customers in the blocklist)</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang">
                              <Form.Check type="switch" name="auto_refuse" id="custom-switch" label="" onChange={handleOnChangerefuse}/>
                              </Col>
                           </Row>
                           <Row className="pp-btn1 mx-0">
                              <Col>
                              <NavLink to="#" onClick={() => handleClose2()}><Button className="blc workhours-confirm" variant="">Confirm</Button></NavLink>
                              </Col>
                           </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
            {/* ========== Employee Management Working Hours Setup Popup End ========== */}
			{/* ========== Edit Employee Management Working Hours Setup Popup Start ========== */}
<Modal show={show2edit} onHide={handleClose2edit} className="popupwrngcnt mainpopwork">
  <Modal.Header closeButton>
	 <h3 className="mdlttltxt">Working Hours Setup</h3>
  </Modal.Header>
  <Modal.Body>
	 <Row className="chkbtn chkbtnmain mx-0 mb-1">
	 <Col xs={10} className="align-self-center mb-1">
  <Form.Label className="fw-bold">Set timing for all days together</Form.Label>
  </Col>
  <Col xs={2} className="align-self-center text-end mb-1">
   <Form.Check type="switch" id="custom-switch" label="" checked={inputsfedit.status} onChange={handleCheckboxChangeedit}/>
  </Col>
	 

{isCheckededit &&
<Col xs={12}>
<Row>
 <Col xs={12} className="align-self-center mt-2">
 <Form.Label>Start </Form.Label>
 <Row>
  <Col xs={6}>
	  <Form.Select name="start_hoursedit" className="mb-2" aria-label="Default select example" value={inputsfedit.start_hoursedit} onChange={handleFInputChangeedit}>
	   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
	  </Form.Select>
  </Col>
  <Col xs={6}>
	  <Form.Select name="start_minutesedit" className="mb-2" aria-label="Default select example" value={inputsfedit.start_minutesedit} onChange={handleFInputChangeedit}>
	   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
	   <option value="24">24</option>
	   <option value="25">25</option>
	   <option value="26">26</option>
	   <option value="27">27</option>
	   <option value="28">28</option>
	   <option value="29">29</option>
	   <option value="30">30</option>
	   <option value="31">31</option>
	   <option value="32">32</option>
	   <option value="33">33</option>
	   <option value="34">34</option>
	   <option value="35">35</option>
	   <option value="36">36</option>
	   <option value="37">37</option>
	   <option value="38">38</option>
	   <option value="39">39</option>
	   <option value="40">40</option>
	   <option value="41">41</option>
	   <option value="42">42</option>
	   <option value="43">43</option>
	   <option value="44">44</option>
	   <option value="45">45</option>
	   <option value="46">46</option>
	   <option value="47">47</option>
	   <option value="48">48</option>
	   <option value="49">49</option>
	   <option value="50">50</option>
	   <option value="51">51</option>
	   <option value="52">52</option>
	   <option value="53">53</option>
	   <option value="54">54</option>
	   <option value="55">55</option>
	   <option value="56">56</option>
	   <option value="57">57</option>
	   <option value="58">58</option>
	   <option value="59">59</option>
	  </Form.Select>
  </Col>
  
 </Row>
 
 </Col>
 <Col xs={12} className="align-self-center mt-2">
 <Form.Label>End</Form.Label>
 <Row>
  <Col xs={6}>
	  <Form.Select name="end_hoursedit" className="mb-2" aria-label="Default select example" value={inputsfedit.end_hoursedit} onChange={handleFInputChangeedit}>
	   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
	  </Form.Select>
  </Col>
  <Col xs={6}>
	  <Form.Select name="end_minutesedit" className="mb-2" aria-label="Default select example" value={inputsfedit.end_minutesedit} onChange={handleFInputChangeedit}>
	   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
	   <option value="24">24</option>
	   <option value="25">25</option>
	   <option value="26">26</option>
	   <option value="27">27</option>
	   <option value="28">28</option>
	   <option value="29">29</option>
	   <option value="30">30</option>
	   <option value="31">31</option>
	   <option value="32">32</option>
	   <option value="33">33</option>
	   <option value="34">34</option>
	   <option value="35">35</option>
	   <option value="36">36</option>
	   <option value="37">37</option>
	   <option value="38">38</option>
	   <option value="39">39</option>
	   <option value="40">40</option>
	   <option value="41">41</option>
	   <option value="42">42</option>
	   <option value="43">43</option>
	   <option value="44">44</option>
	   <option value="45">45</option>
	   <option value="46">46</option>
	   <option value="47">47</option>
	   <option value="48">48</option>
	   <option value="49">49</option>
	   <option value="50">50</option>
	   <option value="51">51</option>
	   <option value="52">52</option>
	   <option value="53">53</option>
	   <option value="54">54</option>
	   <option value="55">55</option>
	   <option value="56">56</option>
	   <option value="57">57</option>
	   <option value="58">58</option>
	   <option value="59">59</option>
	  </Form.Select>
  </Col>
  
   <Col xs={12} className="align-self-center mb-3">
	<NavLink to="#" onClick={() => handleSetValedit()}><Button className="blc profilefirst" variant="">Submit</Button></NavLink>
   </Col>
 </Row>

 </Col>

</Row>
</Col>
}
</Row>
							  <Row className="chkbtn mx-0 mb-3">
  <Col xs={10} className="align-self-center">
  <Form.Label className="fw-bold">Sunday</Form.Label>
  </Col>
  <Col xs={2} className="align-self-center text-end">
  <Form.Check type="switch" id="custom-switch" label="" checked={inputsSundayedit.statusedit} onChange={handleSunCheckboxChangeedit} />
  </Col>
  {isSunCheckededit &&
  <Col xs={12}>
  <Row>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>Start </Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="sunstart_hoursedit" className="mb-2" aria-label="Default select example" value={inputsSundayedit.sunstart_hoursedit} onChange={handleSunInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="sunstart_minutesedit" className="mb-2" aria-label="Default select example" value={inputsSundayedit.sunstart_minutesedit} onChange={handleSunInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	
	 </Col>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>End</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="sunend_hoursedit" className="mb-2" aria-label="Default select example" value={inputsSundayedit.sunend_hoursedit} onChange={handleSunInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="sunend_minutesedit" className="mb-2" aria-label="Default select example" value={inputsSundayedit.sunend_minutesedit} onChange={handleSunInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
  </Row>
  </Col>
  }
</Row>
<Row className="chkbtn mx-0 mb-3">
  <Col xs={10} className="align-self-center">
  <Form.Label className="fw-bold">Monday</Form.Label>
  </Col>
  <Col xs={2} className="align-self-center text-end">
  <Form.Check type="switch" id="custom-switch" label="" checked={inputsMondayedit.statusedit} onChange={handleMonCheckboxChangeedit} />
  </Col>
  {isMonCheckededit &&
  <Col xs={12}>
  <Row>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>Start</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="monstart_hoursedit" className="mb-2" aria-label="Default select example" value={inputsMondayedit.monstart_hoursedit} onChange={handleMonInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="monstart_minutesedit" className="mb-2" aria-label="Default select example" value={inputsMondayedit.monstart_minutesedit} onChange={handleMonInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>End</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="monend_hoursedit" className="mb-2" aria-label="Default select example" value={inputsMondayedit.monend_hoursedit} onChange={handleMonInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="monend_minutesedit" className="mb-2" aria-label="Default select example" value={inputsMondayedit.monend_minutesedit} onChange={handleMonInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
  </Row>
  </Col>   
  }                              
</Row>
<Row className="chkbtn mx-0 mb-3">
  <Col xs={10} className="align-self-center">
  <Form.Label className="fw-bold">Tuesday</Form.Label>
  </Col>
  <Col xs={2} className="align-self-center text-end">
  <Form.Check type="switch" id="custom-switch" label="" checked={inputsTuesdayedit.statusedit} onChange={handleTueCheckboxChangeedit} />
  </Col>
  {isTueCheckededit &&
  <Col xs={12}>
  <Row>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>Start</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="tuestart_hoursedit" className="mb-2" aria-label="Default select example" value={inputsTuesdayedit.tuestart_hoursedit} onChange={handleTueInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="tuestart_minutesedit" className="mb-2" aria-label="Default select example" value={inputsTuesdayedit.tuestart_minutesedit} onChange={handleTueInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>End</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="tueend_hoursedit" className="mb-2" aria-label="Default select example" value={inputsTuesdayedit.tueend_hoursedit} onChange={handleTueInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="tueend_minutesedit" className="mb-2" aria-label="Default select example" value={inputsTuesdayedit.tueend_minutesedit} onChange={handleTueInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 
	 </Col>
  </Row>
  </Col>   
  }                                  
</Row>
<Row className="chkbtn mx-0 mb-3">
  <Col xs={10} className=" mb-2 align-self-center">
  <Form.Label className="fw-bold">Wednesday</Form.Label>
  </Col>
  <Col xs={2} className=" mb-2 align-self-center text-end">
  <Form.Check type="switch" id="custom-switch" label="" checked={inputsWednesdayedit.statusedit} onChange={handleWedCheckboxChangeedit}/>
  </Col>
  {isWedCheckededit &&
  <Col xs={12}>
  <Row>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>Start</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="wedstart_hoursedit" className="mb-2" aria-label="Default select example" value={inputsWednesdayedit.wedstart_hoursedit} onChange={handleWedInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="wedstart_minutesedit" className="mb-2" aria-label="Default select example" value={inputsWednesdayedit.wedstart_minutesedit} onChange={handleWedInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 
	 </Col>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>End</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="wedend_hoursedit" className="mb-2" aria-label="Default select example" value={inputsWednesdayedit.wedend_hoursedit} onChange={handleWedInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="wedend_minutesedit" className="mb-2" aria-label="Default select example" value={inputsWednesdayedit.wedend_minutesedit} onChange={handleWedInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
  </Row>
  </Col>   
  }    
</Row>
<Row className="chkbtn mx-0 mb-3">
  <Col xs={10} className=" mb-2 align-self-center">
  <Form.Label className="fw-bold">Thursday</Form.Label>
  </Col>
  <Col xs={2} className=" mb-2 align-self-center text-end">
  <Form.Check type="switch" id="custom-switch" label="" checked={inputsThursdayedit.statusedit} onChange={handleThuCheckboxChangeedit}/>
  </Col>
  {isThuCheckededit &&
  <Col xs={12}>
  <Row>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>Start</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="thustart_hoursedit" className="mb-2" aria-label="Default select example" value={inputsThursdayedit.thustart_hoursedit} onChange={handleThuInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="thustart_minutesedit" className="mb-2" aria-label="Default select example" value={inputsThursdayedit.thustart_minutesedit} onChange={handleThuInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>End</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="thuend_hoursedit" className="mb-2" aria-label="Default select example" value={inputsThursdayedit.thuend_hoursedit} onChange={handleThuInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="thuend_minutesedit" className="mb-2" aria-label="Default select example" value={inputsThursdayedit.thuend_minutesedit} onChange={handleThuInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
  </Row>
  </Col>   
  }         
</Row>
<Row className="chkbtn mx-0 mb-3">
  <Col xs={10} className=" mb-2 align-self-center">
  <Form.Label className="fw-bold">Friday</Form.Label>
  </Col>
  <Col xs={2} className=" mb-2 align-self-center text-end">
  <Form.Check type="switch" id="custom-switch" label="" checked={inputsFridayedit.statusedit} onChange={handleFriCheckboxChangeedit}/>
  </Col>
  {isFriCheckededit &&
  <Col xs={12}>
  <Row>
	 <Col xs={12} className="align-self-center mt-2" >
	 <Form.Label>Start</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="fristart_hoursedit" className="mb-2" aria-label="Default select example" value={inputsFridayedit.fristart_hoursedit} onChange={handleFriInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="fristart_minutesedit" className="mb-2" aria-label="Default select example" value={inputsFridayedit.fristart_minutesedit} onChange={handleFriInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>End</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="friend_hoursedit" className="mb-2" aria-label="Default select example" value={inputsFridayedit.friend_hoursedit} onChange={handleFriInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="friend_minutesedit" className="mb-2" aria-label="Default select example" value={inputsFridayedit.friend_minutesedit} onChange={handleFriInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
  </Row>
  </Col>   
  }    
</Row>
<Row className="chkbtn mx-0 mb-3">
  <Col xs={10} className=" mb-2 align-self-center">
  <Form.Label className="fw-bold">Saturday</Form.Label>
  </Col>
  <Col xs={2} className=" mb-2 align-self-center text-end">
  <Form.Check type="switch" id="custom-switch" label="" checked={inputsSaturdayedit.statusedit} onChange={handleSatCheckboxChangeedit}/>
  </Col>
  {isSatCheckededit &&
  <Col xs={12}>
  <Row>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>Start</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="satstart_hoursedit" className="mb-2" aria-label="Default select example" value={inputsSaturdayedit.satstart_hoursedit} onChange={handleSatInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="satstart_minutesedit" className="mb-2" aria-label="Default select example" value={inputsSaturdayedit.satstart_minutesedit} onChange={handleSatInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 
	 </Col>
	 <Col xs={12} className="align-self-center mt-2">
	 <Form.Label>End</Form.Label>
	 <Row>
	  <Col xs={6}>
		  <Form.Select name="satend_hoursedit" className="mb-2" aria-label="Default select example" value={inputsSaturdayedit.satend_hoursedit} onChange={handleSatInputChangeedit}>
		   <option value="00">00</option>
	   <option value="01">01</option>
	   <option value="02">02</option>
	   <option value="03">03</option>
	   <option value="04">04</option>
	   <option value="05">05</option>
	   <option value="06">06</option>
	   <option value="07">07</option>
	   <option value="08">08</option>
	   <option value="09">09</option>
	   <option value="10">10</option>
	   <option value="11">11</option>
	   <option value="12">12</option>
	   <option value="13">13</option>
	   <option value="14">14</option>
	   <option value="15">15</option>
	   <option value="16">16</option>
	   <option value="17">17</option>
	   <option value="18">18</option>
	   <option value="19">19</option>
	   <option value="20">20</option>
	   <option value="21">21</option>
	   <option value="22">22</option>
	   <option value="23">23</option>
		  </Form.Select>
	  </Col>
	  <Col xs={6}>
		  <Form.Select name="satend_minutesedit" className="mb-2" aria-label="Default select example" value={inputsSaturdayedit.satend_minutesedit} onChange={handleSatInputChangeedit}>
		   <option value="00">00</option>
		   <option value="01">01</option>
		   <option value="02">02</option>
		   <option value="03">03</option>
		   <option value="04">04</option>
		   <option value="05">05</option>
		   <option value="06">06</option>
		   <option value="07">07</option>
		   <option value="08">08</option>
		   <option value="09">09</option>
		   <option value="10">10</option>
		   <option value="11">11</option>
		   <option value="12">12</option>
		   <option value="13">13</option>
		   <option value="14">14</option>
		   <option value="15">15</option>
		   <option value="16">16</option>
		   <option value="17">17</option>
		   <option value="18">18</option>
		   <option value="19">19</option>
		   <option value="20">20</option>
		   <option value="21">21</option>
		   <option value="22">22</option>
		   <option value="23">23</option>
		   <option value="24">24</option>
		   <option value="25">25</option>
		   <option value="26">26</option>
		   <option value="27">27</option>
		   <option value="28">28</option>
		   <option value="29">29</option>
		   <option value="30">30</option>
		   <option value="31">31</option>
		   <option value="32">32</option>
		   <option value="33">33</option>
		   <option value="34">34</option>
		   <option value="35">35</option>
		   <option value="36">36</option>
		   <option value="37">37</option>
		   <option value="38">38</option>
		   <option value="39">39</option>
		   <option value="40">40</option>
		   <option value="41">41</option>
		   <option value="42">42</option>
		   <option value="43">43</option>
		   <option value="44">44</option>
		   <option value="45">45</option>
		   <option value="46">46</option>
		   <option value="47">47</option>
		   <option value="48">48</option>
		   <option value="49">49</option>
		   <option value="50">50</option>
		   <option value="51">51</option>
		   <option value="52">52</option>
		   <option value="53">53</option>
		   <option value="54">54</option>
		   <option value="55">55</option>
		   <option value="56">56</option>
		   <option value="57">57</option>
		   <option value="58">58</option>
		   <option value="59">59</option>
		  </Form.Select>
	  </Col>
	  
	 </Row>
	 </Col>
  </Row>
  </Col>   
  }                                  
</Row>
		   <Row className="chkbtn mx-0 mb-3">
			  <Col xs={12} className="align-self-center">
			  <h3 className="mdlttltxt float-start">Orders/Appointments settings</h3>
			  <span style={{ }}>
				 <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => (
				 <Tooltip {...props}>Get more information in FAQs!</Tooltip>
				 )}placement="bottom">
				 <NavLink to="/mer-help"> <span className="mdlttltxt text-danger">&nbsp;? </span></NavLink>
				 </OverlayTrigger>
			  </span>
			  </Col>
			  <div className="linepop my-1"></div>
			  <Row className="chkbtn mx-0 mb-3 mt-3 px-0">
				 <Col xs={9} className="align-self-center">
				 <Form.Label>How Many customers can be accepted per time slot (per hour):</Form.Label>
				 </Col>
				 <Col xs={3} className="align-self-center">
				  <Form.Control type="Number" placeholder="4" name="accepted_per_time_slotedit" min="1"  value={inputsedit.accepted_per_time_slotedit} onChange={handleInputChangeSlotedit}/>
				 </Col>
			  </Row>
			  <Col xs={10} className="align-self-center mb-0">
			  <Form.Label>Auto accept during working hours</Form.Label>
			  </Col>
			  <Col xs={2} className="align-self-center text-end radio-text-dang mb-2">
			  <Form.Check type="radio" className="chk-radio" name="auto_accept_during_working_hoursedit" id="custom-switch" label="" value="auto_accept_during_working_hourse" onChange={handleInputChangeedit} checked={inputsedit.auto_accept_during_working_hoursedit === 'auto_accept_during_working_hours'}/>
			  </Col>
			  <Col xs={10} className="align-self-center mb-0">
			  <Form.Label>Need Confirmation</Form.Label>
			  </Col>
			  <Col xs={2} className="align-self-center text-end radio-text-dang mb-2">
			  <Form.Check type="radio" className="chk-radio" name="auto_accept_during_working_hoursedit" id="custom-switch" label="" value="need_to_be_confirmed" onChange={handleInputChangeedit} checked={inputsedit.auto_accept_during_working_hoursedit === 'need_to_be_confirmed'}/>
			  </Col>
			  <Col xs={10} className="align-self-center mb-0">
			  <Form.Label>Auto Refuse (customers in the blocklist)</Form.Label>
			  </Col>
			  <Col xs={2} className="align-self-center text-end radio-text-dang">
			  <Form.Check type="switch" name="auto_refuseedit" id="custom-switch" label="" onChange={handleOnChangerefuseedit}/>
			  </Col>
		   </Row>
		   <Row className="pp-btn1 mx-0">
			  <Col>
			  <NavLink to="#" onClick={() => handleClose2edit()}><Button className="blc workhours-confirm" variant="">Confirm</Button></NavLink>
			  </Col>
		   </Row>
  </Modal.Body>
  <Modal.Footer className="d-none"></Modal.Footer>
</Modal>
{/* ========== Edit Employee Management Working Hours Setup Popup End ========== */}
{/* ========== Employee Disable Popup Start ========== */}
                    <Modal show={showd} onHide={handleClosed} className="popupwrngcnt" backdrop="static">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Are you sure to want to disable account?</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col className="btn-clr">
                             <NavLink to="#" onClick={handleDisable}><Button variant="">Yes</Button></NavLink>
                             </Col>
                             <Col className="btn-dv">
                             <NavLink to="#" onClick={handleClosed}><Button variant="">No</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Employee Disable Popup End ========== */}
            </div>
            </Col>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      <Footer/>
      {/* ========== Merchant Employee Management Page Start ========== */}
        </>
    )
}
export default MerEmp;