import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { NavLink,useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './UserCtgrylst.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const UserCtgrylst = () => {
	const navigate = useNavigate();
	const [subcategory, setSubcategory] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [loading,setLoading] = useState(true);
	 
useEffect(() => {
      document.title = `User Category List | Blue Faucet`;
      document.getElementById('bodywrap').className='uctgrylstpgmain srcblk';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get sub category data */
		API.post('subcategories')
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setSubcategory(response.data.data);
			}                  
		}).catch(error => {
			 
		});   
	 /* End get sub category data */
	
},[]) 
/* Start subcategory search keyword wise */
const handleInputChange = (event) => {
	setLoading(true);
	setSearchTerm(event.target.value);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('keyword', event.target.value);
	API.post('search_subcategory', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setSubcategory(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
};
/* End subcategory search keyword wise */
/* Start subcategory sorting */
const searchSort = (type) => {	
		setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('keyword', searchTerm);
	formData.append('type', type);
	API.post('search_subcategory', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setSubcategory(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
}
/* End subcategory sorting */
 
    return (
        <>
            {/* ========== User Ctagory List Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Row className="nvcus-main">
					<Col md={1} xs={1} className="align-self-center bcnv-arwbtn px-0">
						 <button onClick={() => navigate(-1)} className="back-btn">
						  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
							<path d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28533 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
						  </svg>
						</button>
					</Col>
					<Col md={10} xs={9} className="align-self-center">
					  <div className="constrain hide-inpt" >
						<input className="input-elevated" type="text" placeholder="Search here.." onChange={handleInputChange}/>
					  </div>
					</Col>
					<Col md={1} xs={2} className="align-self-center nvbtn">
					  <Button href={void(0)} className="d-none" variant=""><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/msgicn.svg'} className="img-fluid" alt="Logo"/><span></span></Button>{' '}
					  <Button href={process.env.REACT_APP_BASE_URL + 'notification'} variant=""><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/notiicn.svg'} className="img-fluid" alt="Logo"/><span></span></Button>{' '}
					</Col>
				</Row>
            {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl uclsrldv">
                     <ul className="hmtp-scldv">
                        <Row className="mx-0 ucl-sfbtn">
                           <Col>
                           <NavLink to="#">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic"><span><img src="assets/images/svg/ucl-srtimg.svg" className="img-fluid" alt="Usl Img"/></span>Sort</Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Dropdown.Item href={void(0)} onClick={() =>searchSort('high')}>Price Low to high</Dropdown.Item>
                                    <Dropdown.Item href={void(0)} onClick={() =>searchSort('low')}>Price High to Low</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </NavLink>
                           </Col>
                           <Col>
                           <NavLink to="#"><Button variant="" type="submit"><span><img src="assets/images/svg/ucl-fltrimg.svg" className="img-fluid" alt="Usl Img"/></span>Filter</Button></NavLink>
                           </Col>
                        </Row>
                     </ul>
                     </Col>
                  </Row>
                  {/* ========== Ctagory List Box Start ========== */}
                  <Row className="mx-0 mianhmdwncnt">
				  {subcategory.length >0 ? (
                     <Col className="mnhmcnt">
					 {subcategory && subcategory.map((val,index) => {
					   return(
                     <Col md={12} xs={12} className="py-2">
                     <Row className="mx-0 ucl-box">
                        <Col md={1} xs={2} className="align-self-center px-0 text-center">
                        <img src={val.image} className="img-fluid" alt="Hm Img"/></Col>
                        <Col md={9} xs={7} className="align-self-center px-3 px-md-3">
                        <h3>{val.name}</h3>
                        <p>Starting from <span>${val.price}</span></p>
                        </Col>
                        <Col md={2} xs={3} className="align-self-center text-end px-0">
                        <NavLink to={`/store-list/${val.category_id}`}><Button variant="" type="submit">View All</Button></NavLink>
                        </Col>
                     </Row>
                     </Col>
					 )
					  })}
                     
                     </Col>
				   ) : (
						<Col className="mnhmcnt">
							<p className="text-center mt-4">No data found</p>
						</Col>
					  )}
                  </Row>
                  {/* ========== Ctagory List Box Start ========== */}
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )} 
               </Container>
            </section>
            {/* ========== User Ctagory List Page End ========== */}
        </>
    )
}

export default UserCtgrylst;