import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Accordion } from 'react-bootstrap';
import './UserHelp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const UserHelp = () => {
	const [loading,setLoading] = useState(true);
	const [help, setHelp] = useState([]);
useEffect(() => {
      document.title = `User Help | Blue Faucet`;
      document.getElementById('bodywrap').className='usrhlppgmain';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get help */
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('type', 'customer');
		API.post('get_help', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
				setHelp(response.data.data.help);
			}                  
		}).catch(error => {
			 
		});  
	/* End get help */
	
},[])  
 
    return (
        <>
         {/* ========== User Help Page Start ========== */}
          <section className="gen-wrap">
           <Navbar headerText={'Help'}/>
           <Container>
              <Row className="mx-0 mianhmdwncnt">
                 <Col className="mnhmcnt">
                 <Col md={12} xs={12} className="py-3">
                 {/* ========== User Help content Start ========== */}
                 <Row className="mx-0">
                    <Col md={12} xs={12} className="px-0 usrhlp">
                    <Accordion defaultActiveKey={0}>
					  {help && help.map((val,index) => {
						return(
						   <Accordion.Item eventKey={index}>
							  <Accordion.Header>{val.title} </Accordion.Header>
							  <Accordion.Body>
								 {val.description}
							  </Accordion.Body>
						   </Accordion.Item>
					   )
					  })}
                    </Accordion>
                    </Col>
                 </Row>
                 {/* ========== User Help content End ========== */}
                 </Col>
                 </Col>
              </Row>
			  {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
           </Container>
        </section>
        {/* ========== User Help Page End ========== */}
        </>
    )
}

export default UserHelp;