import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

export default function PrivateLoginRouteEmp() {
    let  userid = localStorage.getItem("token") == null ? true : false;
	let type = parseInt(localStorage.getItem("type"));
	var usertype = 3;
    if (userid === false) {
        usertype = type;
    }
	if(usertype===3)
	{
		return (
			<>
				{userid ? <Outlet  /> : <Navigate to="/emp-dashboard" />}
			</>

		)
	}else if(usertype===1)
	{
		return <>{usertype === 1 ? <Navigate to="/login" /> : <Navigate to="/login" />}</>;
	}else if(usertype===2)
	{
		return <>{usertype === 2 ? <Navigate to="/mer-login" /> : <Navigate to="/mer-login" />}</>;
	}

}