import React, { useEffect,useState } from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import './SalWithdrawalHis.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const SalWithdrawalHis = () => {
const [loading,setLoading] = useState(true);	
const [withhistory,setWithhistory] = useState([]);
useEffect(() => {
      document.title = `Sales Communication | Blue Faucet`;
      document.getElementById('bodywrap').className='scmisionpgmain ylwthem';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
    /* Start get dashboard */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_withdrawal_history', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setWithhistory(response.data.data);
			}			  
		}).catch(error => {
			 
		});  
	 /* End get dashboard */
},[])  

    return (
        <>
        <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
              <Navbar headerText={'Withdrawal History'}/>
      {/* ========== Navbar End ========== */}
               <Container>

                  <Row className="mx-0 mianhmdwncnt">
                   <Col className="mnhmcnt mt-4">
                     {/* ========== Withdrawal History Box Content Start ========== */}
						{withhistory && withhistory.map((val,index) => {
							return(
						  <Col md={12} xs={12} className="mcusbx">
							<Row className="mx-0">
							  <Col md={2} xs={2} className="align-self-center px-1"><img src={val.image} className="img-fluid" alt="Mcust Img"/></Col>
							  <Col md={7} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
								<h3>{val.name}</h3>
								<p>{val.date}</p>
							  </Col>
							 
							  <Col md={3} xs={3} className="align-self-center text-end">
							   <span className="arwtxt">{val.status_name} </span>
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
								  <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
								</svg>
							  </Col>
							</Row>
						  </Col>
						 )
						})}  		  
					{/* ========== Withdrawal History Box Content End ========== */}
                    </Col>
                  </Row>
			 {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )}  
               </Container>
            </section>
      {/* ========== Footer Start ========== */}
            <Footer/>
      {/* ========== Footer End ========== */}
        </>
    )
}

export default SalWithdrawalHis;