import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Table, Stack } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import './MerInvoice.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';

const MerInvoice = () => {
const { id } = useParams();
const [loading,setLoading] = useState(false);
const [orderdetail,setOrderdetail] = useState([]);
const [inputs,setInputs] = useState({store_name:'',store_address:'',store_mobile:'',order_number:'',date:'',store_image:'',store_zip:'',subtotal:'',tips:'',amount:'',user_address_title:'',user_address_street_name:'',user_address_state:'',user_address_city:'',user_address_zip_code:'',user_mobile:'',user_name:''});

useEffect(() => {
      document.title = `Merchant Invoice | Blue Faucet`;
      document.getElementById('bodywrap').className='usrinvcpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
	/* Start get receipt data */
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	API.post('get_product_or_service_order_with_order_id', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setOrderdetail(response.data.data.order_detail);
			setInputs({
				store_name:response.data.data.store_name,
				store_address:response.data.data.store_address,
				store_mobile:response.data.data.store_mobile,
				order_number:response.data.data.order_number,
				date:response.data.data.date,
				store_image:response.data.data.store_image,
				store_zip:response.data.data.store_zip,
				subtotal:response.data.data.subtotal,
				tips:response.data.data.tips,
				amount:response.data.data.amount,
				user_address_title:response.data.data.user_address_title,
				user_address_street_name:response.data.data.user_address_street_name,
				user_address_state:response.data.data.user_address_state,
				user_address_city:response.data.data.user_address_city,
				user_address_zip_code:response.data.data.user_address_zip_code,
				user_mobile:response.data.data.user_mobile,
				user_name:response.data.data.user_name
			});
		}                  
	}).catch(error => {
		 
	});  
	 /* End get receipt data */
	
},[id]) 

/*const addressview = (user_address_street_name,user_address_state,user_address_city,user_address_zip_code) => {
	var html='';
	if(user_address_street_name!=='')
	{
		html+=user_address_street_name+', ';
	}
	if(user_address_state!=='')
	{
		html+=user_address_state+', ';
	}
	if(user_address_city!=='')
	{
		html+=user_address_city+', ';
	}
	if(user_address_zip_code!=='')
	{
		html+=user_address_zip_code;
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}*/

 
    return (
      
      <>
      {/* ========== Merchant Invoice Page Start ========== */}
        <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Invoice'}/>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-2">
      {/* ========== Invoice Content Start ========== */}
               <Row className="mx-0 maincnt-invc">
                  <Col sm={8} xs={12} className="mitop">
                  <h3>{inputs.store_name}</h3>
                  <p>{inputs.store_address} , {inputs.store_zip}</p>
                  <p>Phone: {inputs.store_mobile}</p>
				  
				  {/*<h2 className="mt-4 cus-add-lab">Customer Address</h2>
				  <h3>{inputs.user_name}</h3>
				  <h3>{inputs.user_address_title}</h3>
                  <p>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</p>
                  <p>Phone: {inputs.user_mobile}</p>*/}
				  
                  <div className="dwncmt">
                     {/*<h4>Serviced by: <span>xxxxxxxxxxxx</span></h4>*/}
                     <h4>Receipts {inputs.order_number}</h4>
                     <h4>Date: <span>{inputs.date}</span></h4>
                  </div>
                  </Col>
                  <Col sm={4} md={12} className="text-end mimid">
                  <img src={inputs.store_image} className="img-fluid ps-2" alt="Hm Img"/></Col>
                  <Col md={12} xs={12} className="tblinvcpg mibot">
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
                           <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
                        {orderdetail && orderdetail.map((val,index) => {
						   return(
							<tr>
							   <td>{index+1}</td>
							   <td>{val.product_name}</td>
							   <td>${val.product_price}</td>
							   <td>1</td>
							   <td>${val.product_price}</td>
							</tr>
							 )
						  })}
                        
                     </tbody>
                  </Table>
                  <div className="text-end tbldwndv">
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>SubTotal</p>
                        </div>
                        <div className="pb-1"><span>${inputs.subtotal}</span></div>
                     </Stack>
                     {/*<Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Tax(10.25%)</p>
                        </div>
                        <div className="pb-1"><span>$10</span></div>
                     </Stack>*/}
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon/Discount Code</p>
                        </div>
                        <div className="pb-1">-<span>$xx</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Total</p>
                        </div>
                        <div className="pb-1"><span>${inputs.subtotal}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4}>
                        <div className="pb-1 pe-1 ms-auto">
                           <p>Add Tips</p>
                        </div>
                        <div className="pb-1 ps-3"><span> {inputs.tips} </span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Balance</h6>
                        </div>
                        <div className="">
                           <h6>${inputs.amount}</h6>
                        </div>
                     </Stack>
                  </div>
                  </Col>
               </Row>
      {/* ========== Invoice Content End ========== */}
               </Col>
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      {/* ========== Merchant Invoice Page End ========== */}
      </>
    )
}
export default MerInvoice;