import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerLogin.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate,useLocation } from 'react-router-dom';
import {validatelogin} from '../../../Utils/validate';
import API from '../../../Utils/Api';
import { setUserSession } from '../../../Utils/Common';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const MerLogin = () => {
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
var key='';
if(searchParams.get('key'))
{
	key = searchParams.get('key');
}
	
const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
const navigate = useNavigate();
const [error,setError] = useState();
const [success,setSuccess] = useState();
const [inputs,setInputs] = useState({email_or_phone:'',password:'',remember_me:false});
const [errors,setErrors] = useState({});
const [loading,setLoading] = useState(false);


useEffect(() => {
      document.title = `Merchant Login | Blue Faucet`;
      document.getElementById('bodywrap').className='msguppgmain grenicn grnbg';

      window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	if(localStorage.getItem("mer_remember_me"))
	{
			setInputs({
				email_or_phone:localStorage.getItem("mer_email_or_phone"),
				password:localStorage.getItem("mer_password"),
				remember_me:localStorage.getItem("mer_remember_me")
			});
	}
},[]) 
 useEffect(() => {
	 if(key !=='')
	{
	 varifyaccount(key);
	}
},[key])  

/* Start varify account */
const varifyaccount = (key) => {
	if(key!=='')
	{
		setError('');
		setSuccess('');
		setErrors({});
		setLoading(true);
			let formData = new FormData(); 
			formData.append('token', key);
			formData.append('type', 'merchant');
			API.post('verified', formData)
				.then(response => {
					setLoading(false);
				if(response.data.status === 0){
					//alert(response.data.message);
					//setLoading(false); 
					//window.location.href = "/mer-login";
					gettoastwarning(response.data.message,'/mer-login');
				}else{
				   //alert(response.data.message);
				   //setLoading(false);
				   //window.location.href = "/mer-login";
				   gettoastsuccess(response.data.message,'/mer-login');
				}                  
			}).catch(error => {
				 setLoading(false);
				  //alert("Something went wrong. Please try again later.");
				  //window.location.href = "/mer-login";
				  gettoastwarning("Something went wrong. Please try again later.",'/mer-login');
			});  
	}
}
/* End varify account */
/* Start Password Visible */
 const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
 }
/* End Password Visible */
 /* Start Value set */
const handleInputChange = (event) => {
  event.persist();
  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
}
/* End Value set */
//Start Login check
const handleSubmit = (event) => {
	event.preventDefault();
	
	const validationErrors = validatelogin(inputs);
	const noErrors = Object.keys(validationErrors).length === 0;
	setError('');
	setSuccess('');
	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setLoading(true);
		let email_or_phone=inputs.email_or_phone;
		let password=inputs.password;
		
		let formData = new FormData(); 
		formData.append('email_or_phone', email_or_phone);
		formData.append('password', password);
		formData.append('type', 'merchant');
		API.post('login', formData)
			.then(response => {
				
			if(response.data.status === 0){
				setError(response.data.message);
				setLoading(false); 
			}else{
			   setError('');
			   setLoading(false);
			   console.log(response.data.data);
			   setUserSession(response.data.data.token,response.data.data.profile_setup,response.data.data.type);
			   if(inputs.remember_me)
			   {
				   localStorage.setItem("mer_remember_me", true);
				   localStorage.setItem("mer_email_or_phone", email_or_phone);
				   localStorage.setItem("mer_password", password);
			   }else{
				   localStorage.removeItem("mer_remember_me");
				   localStorage.removeItem("mer_email_or_phone");
				   localStorage.removeItem("mer_password");
			   }
			   navigate("/mer-video");
			  /*if(response.data.data.profile_setup===2){
				  navigate("/mer-stripe-account-setup");
			  }else{
				navigate("/mer-dashboard");
			  }*/
			}                  
		}).catch(error => {
			 setLoading(false);
			  setError("Something went wrong. Please try again later.");
		});  
	}
}
//End Login check
/* start input remember me */
const handleCheckboxChange = (event) => {
	 var remember_me_lbl='remember_me';
     setInputs(inputs => ({...inputs, [remember_me_lbl]: event.target.checked}));
 }
 /* end input remember me */
 
    return (
      
        <>
         {/* ========== Merchant Login Page Start ========== */}
         <section className="gen-wrap gen-main">
		 <ToastContainer />
         {/* ========== Navbar Start ========== */}
           <Row className="maincnttpnw mx-0">
              <Col xs={2} md={2} className="align-self-center px-0">
              <button onClick={() =>
                 navigate(-1)} className="back-btn d-none">
                 <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                 </svg>
              </button>
              </Col>
              <Col xs={8} md={8} className="align-self-center text-center">
              <img src="assets/images/logo.svg" className="img-fluid w-75 mb-3" alt="Logo"/>
              <p>Your local Market place for <span className="fw-bold">Buy/Sell and Services</span></p>
              </Col>
              <Col xs={2} md={2} className="align-self-center">
              <p></p>
              </Col>
           </Row>
         {/* ========== Navbar End ========== */}
           <Container>
              <Row className="genscrnmrcnt genscrn">
         {/* ========== Login Content Start ========== */}
                 <Col md={12} lg={12} xl={12} xxl={12} className="toptxtcnt px-0">
                 <div className="maincntsrcn">
					{error && (
					<div className="alert alert-danger">
					  {error}
					</div>)}
					{success && (
					<div className="alert alert-success">
					  {success}
					</div>)}
                    <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                       <Form.Group className="mb-3">
                          <Form.Label>Email or Phone<span>*</span></Form.Label>
                          <Form.Control type="text" name="email_or_phone" placeholder="Enter email or phone number" onChange={handleInputChange} value={inputs.email_or_phone} autoComplete="off"  />
						  {errors.email_or_phone && (<p className="text-danger">{errors.email_or_phone}</p>)}
                       </Form.Group>
                       <Form.Group className="mb-3 pswinpt">
                          <Form.Label>Password<span>*</span></Form.Label>
                          <Form.Label className="frgtpswd">
                             <NavLink to="/mer-forgot-password">
                                <p>Forgot Password?</p>
                             </NavLink>
                          </Form.Label>
                          <Form.Control type={values.showPassword ? "text" : "password"} name="password" id="password" placeholder="At least 8 characters" onChange={handleInputChange} value={inputs.password} autoComplete="off" />
                          {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
						  {errors.email_or_phone && (<p className="text-danger">{errors.email_or_phone}</p>)}
					   </Form.Group>
                       <Form.Group className="mb-3">
                          <Form.Check aria-label="option 1" label="Remember me" className="form-check-input-mer" value="1" checked={inputs.remember_me} onChange={handleCheckboxChange}/>
                       </Form.Group>
                       <div className="mb-3 btn-dv">
                          <Button type="submit">Login</Button>
                       </div>
                       <div className="mb-3 text-center lgncnt">
                          <div className="lgnlsttxt">
                             <p>
                                Don’t have an account? 
                                <NavLink to="/mer-store-setup"> Sign Up</NavLink>
                             </p>
                          </div>
                       </div>
                    </Form>
                 </div>
                 </Col>
         {/* ========== Login Content End ========== */}
              </Row>
			  {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
           </Container>
        </section>
        {/* ========== Merchant Login Page End ========== */}
        </>
    )
}
export default MerLogin;