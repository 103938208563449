import React, { useEffect, useState,createRef } from 'react';
import { Row, Col, Container, Button, Form, Modal, Card } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerDash.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import copy from 'clipboard-copy';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import ContentEditable from 'react-contenteditable';
import moment from 'moment';



import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend);
export const options = {responsive: true,plugins: {legend: {position: 'top',display: false,},title: {display: false,text: '',},},};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
//export const data = {labels,datasets: [{fill: true,label: '',data: [12, 19, 3, 5,15,20,25],borderColor: 'rgba(63, 149, 52, 1)',backgroundColor: 'rgb(63 149 52 / 55%)',borderDash: [10,5],},],};
//import $ from 'jquery';
const MerDash = () => {
	const contentEditable = createRef();
const [value, onChange] = useState(new Date());

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
//const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);


const [loading,setLoading] = useState(true);



const [inputsdash,setInputsdash] = useState({order_total:'',order_last_week_total:'',order_last_month_total:'',coupon_total:'',coupon_last_week_total:'',coupon_last_month_total:'',customer_total:'',customer_last_week_total:'',customer_last_month_total:'',income_forecast_total:'',income_forecast_last_week_total:'',income_forecast_last_month_total:'',total:''});

const [data, setData] = useState({labels,datasets: [{fill: true,label: '',data: [12, 19, 3, 5,15,20,25],borderColor: 'rgba(63, 149, 52, 1)',backgroundColor: 'rgb(63 149 52 / 55%)',borderDash: [10,5],},],});

const [type, setType] = useState('customer');


const [inputslnk,setInputslnk] = useState({url:'',twitterLink:'',instagramLink:'',facebooklink:'',whatsappLink:'',emailLink:'',phonelink:''});
const [content,setContent] = useState({content:"",content_text:"",name:''});

const [showtext, setShowtext] = useState(false);

const [filtertype, setFiltertype] = useState('year');

const [ord, setord] = useState([]);

const [mark, setMark] = useState([]);



useEffect(() => {
      document.title = `Merchant Dashbord | Blue Faucet`;
      document.getElementById('bodywrap').className='Homepgmain mdsbrdpgmain grnbg srcblk icnset';
	  
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });      

	var url=process.env.REACT_APP_BASE_URL+'signup?key='+localStorage.getItem("token")+'&type=direct';
		 
		 
		 const twitterLink = `https://twitter.com/intent/tweet?text=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
 const instagramLink = `instagram://library?AssetPath=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
 const facebooklink=`https://www.facebook.com/sharer/sharer.php?u=Heres%20the%20link%20I%20wanted%20to%20share:%20`+url;
 const whatsappLink = `https://api.whatsapp.com/send?text=Check%20out%20this%20link:%20`+url;
 
 const phonelink=`sms:?body=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
 const emailLink = `mailto:?subject=Check%20out%20this%20link&body=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
	//const phone_link=`tel:`+url;	 
		setInputslnk({
				  url:url,
				  twitterLink:twitterLink,
				  instagramLink:instagramLink,
				  facebooklink:facebooklink,
				  whatsappLink:whatsappLink,
				  emailLink:emailLink,
				  phonelink:phonelink
			  });
			  
	/* Start get profile */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			 var name=response.data.data.name;
			  var text_tag="Dear "+name+",<br>Thanks for being a loyal customer for many years. We've got some exciting news to share: [Store Name] is now part of BlueFaucet, an app that helps you save time and money. We think you're going to love it! We invite you to join our membership so we can offer you an even better experience at "+name+".<br>We understand that you may experience difficulty reaching us when making appointments or orders, requiring multiple attempts to connect. Additionally, when we have promotions or send out coupons, we cannot contact you firsthand. We don't want you to miss any more chances to save. BlueFaucet is the answer to our call.<br>With BlueFaucet, you can book appointments or place orders right from the app. No more waiting on hold or struggling to get through! And when your order is ready, we'll send you a message so you can pick it up and go. It's that easy!<br>Plus, we'll send exclusive coupons and promotions directly to your in-app Coupon Box, so you'll never miss a good deal. And the best part? BlueFaucet is totally free for you to join and use!<br>Are you ready to join our BlueFaucet Membership and start enjoying all these amazing benefits? Just click  here to get started.<br>Thanks again for being a part of the [Store Name] family. We look forward to serving you soon.<br>Best regards,<br>"+name+" team";
			  //const regex = /(<([^>]+)>)/ig;
			  //const result = text_tag.replace(regex, '');
			  var result = text_tag.replaceAll('<br>', '%0D%0A');
			  
			  //const regex = /(<([^>]+)>)/ig;
			  //const result = text_tag.replace(regex, '%0D%0A');
			
			  
				var lbl='content_text';
				var result1=result+'%0D%0A'+url;
				setContent(content => ({...content, [lbl]: result1}));
				var deslbl='content';
				setContent(content => ({...content, [deslbl]: text_tag})); 
			  
			}			  
		}).catch(error => {
			 
		});  
	 /* End get profile */
	 
	 /* Start get dashboard */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'customer');
		formData.append('filter_type', 'year');
		API.post('mer_dashboard', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setMark(response.data.data.order_date);
			  setInputsdash({
				  order_total:response.data.data.order_total,
				  order_last_week_total:response.data.data.order_last_week_total,
				  order_last_month_total:response.data.data.order_last_month_total,
				  coupon_total:response.data.data.coupon_total,
				  coupon_last_week_total:response.data.data.coupon_last_week_total,
				  coupon_last_month_total:response.data.data.coupon_last_month_total,
				  customer_total:response.data.data.customer_total,
				  customer_last_week_total:response.data.data.customer_last_week_total,
				  customer_last_month_total:response.data.data.customer_last_month_total,
				  income_forecast_total:response.data.data.income_forecast_total,
				  income_forecast_last_week_total:response.data.data.income_forecast_last_week_total,
				  income_forecast_last_month_total:response.data.data.income_forecast_last_month_total,
				  total:response.data.data.total
			 });
const labels = response.data.data.month_array;
const valt=response.data.data.total_array;
				setData({labels,datasets: [{fill: true,label: '',data: valt,borderColor: 'rgba(63, 149, 52, 1)',backgroundColor: 'rgb(63 149 52 / 55%)',borderDash: [10,5],},],});
			 
			}			  
		}).catch(error => {
			 
		});  
	 /* End get dashboard */
	
},[])  

const copyToClipboard = () => {
    const linkToCopy = inputslnk.url;

    copy(linkToCopy)
      .then(() => {
        let copyText = document.querySelector(".copy-text");
		copyText.classList.add("active");
		setTimeout(function () {
			copyText.classList.remove("active");
		  }, 2500);
      })
      .catch((error) => {
        console.error('Copy failed: ', error);
      });
};

const handleInputChange = (event) => {
      event.persist();
      setType(event.target.value);
	  let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', event.target.value);
		formData.append('filter_type', filtertype);
		API.post('mer_dashboard', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  var lbl_total='total';
			  setInputsdash(inputsdash => ({...inputsdash, [lbl_total]: response.data.data.total}));  
const labels = response.data.data.month_array;
//const valt=[10,15,20,40,41,30,31,42,15,30,22,40];
const valt=response.data.data.total_array;
				setData({labels,datasets: [{fill: true,label: '',data: valt,borderColor: 'rgba(63, 149, 52, 1)',backgroundColor: 'rgb(63 149 52 / 55%)',borderDash: [10,5],},],});
			}			  
		}).catch(error => {
			 
		});  
    }
const handleInputChangeSelect = (event) => {
      event.persist();
      setFiltertype(event.target.value);
	  let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', type);
		formData.append('filter_type', event.target.value);
		API.post('mer_dashboard', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				var lbl_total='total';
			  setInputsdash(inputsdash => ({...inputsdash, [lbl_total]: response.data.data.total}));  
const labels = response.data.data.month_array;
//const valt=[10,15,20,40,41,30,31,42,15,30,22,40];
const valt=response.data.data.total_array;
				setData({labels,datasets: [{fill: true,label: '',data: valt,borderColor: 'rgba(63, 149, 52, 1)',backgroundColor: 'rgb(63 149 52 / 55%)',borderDash: [10,5],},],});
			 
			}			  
		}).catch(error => {
			 
		});  
    }
const onChangeDate = (event) => {
	onChange(event);
	setLoading(true);
	var date = new Date(event);
        const YYYY = date.getFullYear();
        let DD = date.getMonth()+1;
        let MM = date.getDate();
	var datecur=YYYY+'-'+DD+'-'+MM;
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('date', datecur);
		API.post('get_order_by_date_and_marchant_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setord(response.data.data);
				setShow1(true);
			}			  
		}).catch(error => {
			 
		});  
}
const handleSubmitcopy = () => {
	/* Start get profile */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setShowtext(true);
			}			  
		}).catch(error => {
			 
		});  
	 /* End get profile */
}
const handleInputChangeDesShare = (event) => {
	var text_tag=event.target.value
	//const regex = /(<([^>]+)>)/ig;
    //const result = text_tag.replace(regex, '');
	
	const regex = /(<([^>]+)>)/ig;	  
    const result = text_tag.replace(regex, '%0D%0A');
	
	
	
	var result1=result+'%0D%0A'+inputslnk.url;
	//console.log(result1);
	var deslbl='content';
    setContent(content => ({...content, [deslbl]: event.target.value})); 
	
  var lbl='content_text';
  setContent(content => ({...content, [lbl]: result1}));  
}

    return (
      <>
      
      {/* ========== Merchant Dashbord Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar/>
         {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               {/* ========== Dashbord Order Box Start ========== */}
               <Row xs={{ cols:2, gutter: 2 }} lg={{ cols: 2, gutter: 3}} className="mt-2 g-2 g-md-3">
               <Col>
               <Card className="maindvmdsbrd">
               <Card.Body className="p-0">
                  <Row className="rw mx-0">
					    <Col md={12} xs={12} className="mdtp-toptxt"><h5>Orders / Appointment</h5></Col>
                   <Col md={3} xs={4} className="mdtp-lft text-center">
                   <img src="assets/images/merchant/svg/mdsbrd-icn1.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                   <Col md={9} xs={8} className="mdtp-rgt">
                   <h3>{inputsdash.order_total}</h3>
                    <NavLink to="/mer-order-appointment"><p>New Orders / Appointments</p></NavLink>
                   </Col>
                   <div className="mbrdr-spce my-2"></div>
                   <Col md={12} xs={12} className="mdcnt-lft">
                   <NavLink to="/mer-order-appointment-statistics"><p>Orders / Appointments Statistics</p></NavLink>
                   </Col>
                   <div className="mbrdr-spce my-2"></div>
                  </Row>
               </Card.Body>
               <Card.Footer className="text-center border-0 p-0 grfdv">
                      <img src="assets/images/merchant/svg/grph-img1.svg" className="img-fluid" alt="Graph Img"/>
               </Card.Footer>
               </Card>
               </Col>
               <Col>
               <Card className="maindvmdsbrd">
               <Card.Body className="p-0">
                  <Row className="rw mx-0">
					    <Col md={12} xs={12} className="mdtp-toptxt"><h5>Income & Forecast</h5></Col>
                   <Col md={3} xs={4} className="mdtp-lft text-center"><img src="assets/images/merchant/svg/mdsbrd-icn2.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                   <Col md={9} xs={8} className="mdtp-rgt blutxt align-self-center"><h3>${inputsdash.income_forecast_total}</h3></Col>
                   <div className="mbrdr-spce my-2"></div>
                   <Col md={12} xs={12} className="mdcnt-lft">
                   <NavLink to="/mer-income-forecast"><p>Income & Forecast</p></NavLink>
                   </Col>
                   <div className="mbrdr-spce my-2"></div>
                  </Row>
               </Card.Body>
               <Card.Footer className="text-center border-0 p-0 grfdv">
                 <img src="assets/images/merchant/svg/grph-img2.svg" className="img-fluid" alt="Graph Img"/>
               </Card.Footer>
               </Card>
               </Col>
               <Col>
               <Card className="maindvmdsbrd">
               <Card.Body className="p-0">
                  <Row className="rw mx-0">
					    <Col md={12} xs={12} className="mdtp-toptxt"><h5>Coupon vs. Revenue</h5></Col>
                   <Col md={3} xs={4} className="mdtp-lft text-center"><img src="assets/images/merchant/svg/mdsbrd-icn3.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                   <Col md={9} xs={8} className="mdtp-rgt grntxt align-self-center"><h3>{inputsdash.coupon_total}</h3></Col>
                   <div className="mbrdr-spce my-2"></div>
                   <Col md={12} xs={12} className="mdcnt-lft linergt"><NavLink to="/mer-coupon-revenue"><p>Coupon vs. Revenue Statistics</p></NavLink></Col>
                   <div className="mbrdr-spce my-2"></div>
                  </Row>
               </Card.Body>
               <Card.Footer className="text-center border-0 p-0 grfdv"><img src="assets/images/merchant/svg/grph-img3.svg" className="img-fluid" alt="Graph Img"/></Card.Footer>
               </Card>
               </Col>
               <Col>
               <Card className="maindvmdsbrd">
               <Card.Body className="p-0">
                  <Row className="rw mx-0">
					    <Col md={12} xs={12} className="mdtp-toptxt"><h5>Membership Statistics</h5></Col>
                   <Col md={3} xs={4} className="mdtp-lft text-center"><img src="assets/images/merchant/svg/mdsbrd-icn4.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                     <Col md={9} xs={8} className="mdtp-rgt ylwtxt align-self-center"><h3>{inputsdash.customer_total}</h3></Col>
                     <div className="mbrdr-spce my-2"></div>
                     <Col md={12} xs={12} className="mdcnt-lft linergt "><NavLink to="/mer-membership-statistics"><p>Membership Statistics</p></NavLink></Col>
                     <div className="mbrdr-spce my-2"></div>
                  </Row>
               </Card.Body>
               <Card.Footer className="text-center border-0 p-0 grfdv"> <img src="assets/images/merchant/svg/grph-img4.svg" className="img-fluid" alt="Graph Img"/></Card.Footer>
               </Card>
               </Col>
            </Row>
            {/* ========== Dashbord Order Box End ========== */}
            <Col md={12} xs={12} className="mbtndv mt-3">
            <NavLink to="/mer-customer"  ><Button variant=""><i className="fas fa-plus me-1"></i> Invite Customers</Button>{' '}</NavLink>
            </Col>
            {/* ========== Dashbord Customers Box Start ========== */}
            <Col md={12} xs={12} className="mdsbrdchrtdv mb-3">
            <Row className="mx-0">
               <Col md={12} xs={12} className="cusslctbtn mb-3 px-0">
               <div className="cusradio">
                  <input className="radio-label" type="radio" name="type" id="one" value="customer" checked={type === 'customer'} onChange={handleInputChange}/>
                  <label className="button-label" htmlFor="one">Customers</label>
                  <input className="radio-label" type="radio" name="type" id="two" value="reservations" checked={type === 'reservations'} onChange={handleInputChange} />
                  <label className="button-label" htmlFor="two">Reservations</label>
                  <input className="radio-label" type="radio" name="type" id="three" value="order" checked={type === 'order'} onChange={handleInputChange}/>
                  <label className="button-label" htmlFor="three">Orders</label>
               </div>
               </Col>
               <Col md={8} xs={8} className="mb-3">
               <Row className="dwngrftptxt">
                  <Col md={3} xs={3} className="text-center">
                  <img src="assets/images/merchant/user-img.png" className="img-fluid" alt="Graph Img"/></Col>
                  <Col md={9} xs={9}>
                  <p>Total {type}</p>
                  <h3>{inputsdash.total}</h3>
                  </Col>
               </Row>
               </Col>
               <Col md={4} xs={4}>
               <Form.Select aria-label="Default select example" name="filter_type" value={filtertype} onChange={handleInputChangeSelect}>
                  <option value='days'>Day</option>
                  <option value='week'>Week</option>
                  <option value='month'>Month</option>
                  <option value='year'>Year</option>
               </Form.Select>
               </Col>
            </Row>
            {/* ========== Dashbord Chat Start ========== */}
            <Line options={options} data={data} />
            {/* ========== Dashbord Chat End ========== */}
            </Col>
            {/* ========== Dashbord Customers Box End ========== */}
            {/* ========== Dashbord Calendar Box Start ========== */}
            <Col className="mcallenderdv">
            <Row className="mx-0 tpcntmc">
               <Col>
               <h3>Add Calendar</h3>
               </Col>
               <Col className="text-end">
               <img src="assets/images/merchant/svg/calander.svg" className="img-fluid" alt="Graph Img"/></Col>
            </Row>
            <div>
            {/* ========== Calendar Start ========== */}
               <Calendar onChange={onChangeDate} value={value} tileClassName={({ date, view }) => {
      if(mark.find(x=>x===moment(date).format("DD-MM-YYYY"))){
       return  'date-highlight'
      }
    }} />
            {/* ========== Calendar Start ========== */}
            </div>
            </Col>
            {/* ========== Dashbord Calendar Box Start ========== */}
            <Col className="modalpop">
            <div className="mrcustmrmodal">
            {/* ========== Dashbord Invite Customers Popup Box Start ========== */}
               <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                  <Modal.Body>
                     <Row className="mx-0 topppdtlmcstmr">
                        <Col md={6} xs={6} className="align-self-center">
                        <h3>Invite Customers</h3>
                        </Col>
                        <Col md={6} xs={6} className="grntxt">
							{/*<input type="Hidden" className="text" value={inputs.url} disabled/>
							<h3 onClick={copyToClipboard}>Copy Link</h3>*/}
							<div className="copy-text mer-copy-text">
                       
                       <button onClick={copyToClipboard}>Copy Link</button>
                    </div>
                        </Col>
                     </Row>
                     <Row className="mx-0 shrelist">
                        <Col md={12} xs={12} className="my-2">
                        <h3>Import your customers from the following, and send an invitation to them.</h3>
                        </Col>
                        <Col md={3} xs={3} className="shrelist-mincnt text-center">
                        <NavLink to={inputslnk.phonelink}>
                           <img src= {'assets/images/svg/shr-icn1.svg'} className="img-fluid" alt="Sld Img"/>
                           <div className="nm-shr">
                              <p>To Contact</p>
                           </div>
                        </NavLink>
                        </Col>
						{showtext ? (
						   <>
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
							   <NavLink to={`mailto:?subject=Check%20out%20this%20link&body=${content.content_text}`} >
							   
                              <img src= {'assets/images/svg/shr-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Gmail</p>
                              </div>
							  
							  </NavLink>
                           </Col>
						   </>
						):(
						<>
						  <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={`mailto:?subject=Check%20out%20this%20link&body=${content.content_text}`} target="_blank">
                              <img src= {'assets/images/svg/shr-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Gmail</p>
                              </div>
                           </NavLink>
                           </Col>
						</>
						   )}
						   
						{showtext ? (
						   <>
							   <Col md={3} xs={3} className="shrelist-mincnt text-center">
							   <NavLink to={`mailto:?subject=Check%20out%20this%20link&body=${content.content_text}`}>
								  <img src= {'assets/images/svg/shr-icn3.svg'} className="img-fluid" alt="Sld Img"/>
								  <div className="nm-shr">
									 <p>Outlook </p>
								  </div>
							   </NavLink>
							   </Col>
						   </>
						):(
						<>
							<Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={`mailto:?subject=Check%20out%20this%20link&body=${content.content_text}`}>
                              <img src= {'assets/images/svg/shr-icn3.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Outlook </p>
                              </div>
                           </NavLink>
                           </Col>
						</>
						   )}
						  {showtext ? (
						  <> 
                           <Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={`https://api.whatsapp.com/send?text=${content.content_text}`}>
                              <img src= {'assets/images/svg/shr-icn4.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Whatsapp</p>
                              </div>
                           </NavLink>
                           </Col>
                           </>
						):(
						<>
							<Col md={3} xs={3} className="shrelist-mincnt text-center">
                           <NavLink to={`https://api.whatsapp.com/send?text=${content.content_text}`}>
                              <img src= {'assets/images/svg/shr-icn4.svg'} className="img-fluid" alt="Sld Img"/>
                              <div className="nm-shr">
                                 <p>Whatsapp</p>
                              </div>
                           </NavLink>
                           </Col>
						</>
						  )}
                        <Col md={12} xs={12} className="oppbxcntmcustmr mb-3">
                        <h4>Invitation Message</h4>
                        <ContentEditable
							  innerRef={contentEditable}
							  html={content.content} // innerHTML of the editable div
							  disabled={false}       // use true to disable editing
							  onChange={handleInputChangeDesShare} // handle innerHTML change
							  tagName='article' // Use a custom HTML tag (uses a div by default)
							/>
                        </Col>
                        <Col md={12} xs={12} className="btnpopmcstmr my-2">
                        <NavLink to="#"> <Button variant="black" type="submit" onClick={handleSubmitcopy}>Confirm</Button></NavLink>
                        </Col>
                     </Row>
                  </Modal.Body>
               </Modal>
               {/* ========== Dashbord Invite Customers Popup Start ========== */}
			   {/* ========== Dashbord Order Popup Box Start ========== */}
               <Modal size="lg" show={show1} onHide={handleClose1} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                  <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Order</h3>
                       </Modal.Header>
				  <Modal.Body>
                     <Row className="mx-0 topppdtlmcstmr">
					 {ord.length >0 ? (
					 <div>
                        {ord && ord.map((val,index) => {
						return(
                        <Col md={12} xs={12} className="mcusbx">
						{val.status===1 ? (
                        <NavLink to={`/mer-receipts/${val.id}`}>
                           <Row className="mx-0 main-ord-box">
                              <Col md={2} xs={2} className="align-self-center px-1">
                              <img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></Col>
                              <Col md={7} xs={7} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                              <h3>{val.name}</h3>
                              <p>{val.date}</p>
                              </Col>
                              <Col md={3} xs={3} className="align-self-center text-end">
							  <span className="arwtxt">{val.status_name}</span>
                              
                              </Col>
                           </Row>
                        </NavLink> ) : (
							<NavLink to={`/mer-order-detail/${val.id}`}>
							   <Row className="mx-0 main-ord-box">
								  <Col md={2} xs={2} className="align-self-center px-1">
								  <img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></Col>
								  <Col md={7} xs={7} className="align-self-center mcusbx-txtcnt ps-md-2 ">
								  <h3>{val.name}</h3>
								  <p>{val.date}</p>
								  </Col>
								  <Col md={3} xs={3} className="align-self-center text-end">
								  <span className="arwtxt">{val.status_name}</span>
								  
								  </Col>
							   </Row>
							</NavLink>
						)}
                        </Col>
                        )
					  })} 
					  </div>
					  ) : (
						<Row className="mx-0">
							<p className="text-center mt-4">No data found</p>
						</Row>
				 )} 
                     </Row>
                  </Modal.Body>
               </Modal>
               {/* ========== Dashbord Order Popup Start ========== */}
            </div>
            </Col>
            </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
         <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Dashbord Page End ========== */}
        </>
    )
}
export default MerDash;