import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserFvrt.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import PlacesAutocomplete, { geocodeByAddress,getLatLng  } from 'react-places-autocomplete';
import loader from '../../../assests/loader.gif';


const UserFvrt = () => {
	const [category, setCategory] = useState([]);
	const [favorite, setFavorite] = useState([]);
	const [name, setName] = useState('');
	const [categoryid, setCategoryid] = useState('');
	const [address, setAddress] = useState("");
	const [lat, setLat] = useState("");
	const [lng, setLng] = useState("");
	const [loading,setLoading] = useState(true);
	

const searchOptions = {
    componentRestrictions: { country: 'us' }
  };	
useEffect(() => {
      document.title = `User Favorites | Blue Faucet`;
      document.getElementById('bodywrap').className='usrfvpgmain uf2';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

	/* Start get category data */
		API.get('category')
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setCategory(response.data.data);
			}                  
		}).catch(error => {
			 
		});   
	 /* End get category data */
	 /* Start get favorite data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_favourite_store', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setFavorite(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get favorite data */
},[])  
/* Start like and dislike */
const like = (id) => {		
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('store_id', id);
	API.post('store_favourite', formData)
		.then(response => {
		
		if(response.data.status === 0){
			
		}else{
			window.location.reload(true);
			/*let updateData = favorite.map((item, i) => {
			  return id === item.store_id ? { ...item, status: !item.status } : item;
			});
			setFavorite(updateData);*/
		}                  
	}).catch(error => {
		 
	});  
}
/* End like and dislike */
/* Start view reating */
/*const ratingview = (count) => {
	var html='';
	for (let i = 1; i <= count; i++) {
			html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star"></i></label><input class="rating__input" name="rating"  defaultValue={i} type="radio"/>';
	
	}
	var cou=count+1;
	for(let a=cou;a <= 5;a++)
	{
		html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star rating-emt"></i></label><input class="rating__input" name="rating"   type="radio"/>';
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}*/
/* End view reating */
/* Start favorite search by name */
const searchByName = (event) => {
	setLoading(true);
	setName(event.target.value);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('name', event.target.value);
	formData.append('category_id', categoryid);
	formData.append('lat', lat);
	formData.append('long', lng);
	API.post('search_favourite_store', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			setFavorite([]);
		}else{
			setFavorite(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
}
/* End favorite search by name */
/* Start favorite search by cat */
const searchByCat = (id) => {
	setLoading(true);
	setCategoryid(id);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('name', name);
	formData.append('category_id', id);
	formData.append('lat', lat);
	formData.append('long', lng);
	API.post('search_favourite_store', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			setFavorite([]);
		}else{
			setFavorite(response.data.data);
		}                  
	}).catch(error => {
		 
	}); 
}
/* End favorite search by cat */
/* Start favorite search by address */
const handleSelect = async (address) => {
	setLoading(true);
	setAddress(address);
	const results = await geocodeByAddress(address);
	const latLng = await getLatLng(results[0]);
	let lat1=latLng.lat;
	let lng1=latLng.lng;
	setLat(lat1);
	setLng(lng1);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('name', name);
	formData.append('category_id', categoryid);
	formData.append('lat', lat1);
	formData.append('long', lng1);
	API.post('search_favourite_store', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			setFavorite([]);
		}else{
			setFavorite(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
}
/* End favorite search by address */
/* Start favorite search by current location */
const CurrentLocation = () => {
	navigator.geolocation.getCurrentPosition(
      (position) => {
		searchByCurrentLocation(position.coords.latitude,position.coords.longitude);
		var latitude=position.coords.latitude;
		var longitude=position.coords.longitude;
		// Fetch the address and zip code using the Google Maps Geocoding API
      const apiKey = "AIzaSyCQczb6EDbU29iO1aCLOwZVVWIyD17Yc_c";
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
			
          if (data.results.length > 0) {
			  
			  
            var com1='';
			var com2='';
			var com3='';
			var com4='';
			
			for (var i = 0; i < data.results[0].address_components.length; i++) {
			
			  for (var j = 0; j < data.results[0].address_components[i].types.length; j++) {
				 
				if (data.results[0].address_components[i].types[j] === "administrative_area_level_3") { 
				  com1=data.results[0].address_components[i].long_name;
				}
				if (data.results[0].address_components[i].types[j] === "administrative_area_level_1") {
				  com2=data.results[0].address_components[i].long_name;
				}
				if (data.results[0].address_components[i].types[j] === "country") {
				  com3=data.results[0].address_components[i].long_name;
				}
				if (data.results[0].address_components[i].types[j] === "postal_code") {
				  com4=data.results[0].address_components[i].long_name;
				}
			  }
		}
			var add=com1+' '+com2+' '+com3+' '+com4;
			
			setAddress(add);
            
          } else {
            setAddress("Address not found");
            // setZipcode("N/A");
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error.message);
        });
		
      },
      (error) => {
        console.error("Error getting location:", error.message);
      }
    );
}
const searchByCurrentLocation = (lat,lng) => {
	setLoading(true);
	let lat1=lat;
	let lng1=lng;
	setLat(lat1);
	setLng(lng1);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('name', name);
	formData.append('category_id', categoryid);
	formData.append('lat', lat1);
	formData.append('long', lng1);
	API.post('search_favourite_store', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			setFavorite([]);
		}else{
			setFavorite(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
}
/* End favorite search by current location */

const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault(); // Prevent the default Enter key behavior
      // You can add any custom logic here if needed
    }
  };



    return (
        <>
         {/* ========== User Favorites Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Favorite Stores'}/>
            {/* ========== Navbar End ========== */}
               <Container>
               {/* ========== Category Box Start ========== */}
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl">
                     <div className="constrain hide-inpt">
                        <input className="input-elevated" type="text" placeholder="Search By Name" onChange={searchByName} />
                     </div>
                     <div className="constrain hide-inpt con-search-add">
						<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect} searchOptions={searchOptions}>
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input {...getInputProps({ placeholder: 'Search By Location' })} className="input-elevated" onKeyDown={handleKeyDown}/>
                          <NavLink to="#" className="crt_location" onClick={CurrentLocation}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg></NavLink>
						  <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                              };
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}
                                </div>
                              );
                            })}
							
                          </div>
						  
                        </div>
                      )}
					  
                    </PlacesAutocomplete>
					
                     </div>
					 
                     <ul className="hmtp-scldv">
					 {category && category.map((val,index) => {
					   return(
                        <NavLink to="#" className="active intFilter" data-val="all" onClick={() =>searchByCat(val.id)}>
                           <li>
                              <img src={val.image} className="img-fluid" alt="Hm Img"/>
                              <p>{val.name}</p>
                           </li>
                        </NavLink>
						)
					  })}
                        
                     </ul>
                     </Col>
                  </Row>
               {/* ========== Category Box End ========== */}
                  <Row className="mx-0 mianhmdwncnt">
                  {/* ========== Category List Content Box Start ========== */}
				  {favorite.length >0 ? (
                     <Col className="mnhmcnt">
					 {favorite && favorite.map((val,index) => {
						return(
                     <Col md={12} xs={12} className="py-2">
                     <Col className="ustrlpg">
                     <Row className="mx-0 ustrl-box">
                        <Col md={2} xs={2} className="px-0">
							<img src={val.image} className="img-fluid" alt="Hm Img"/>
						</Col>
                        <Col md={7} xs={7} className="px-2 px-md-2">
                        <h3>{val.store_name}</h3>
							{/*<div id="str-trng">
                           <div className="rating-group strrtg-rating">
                              {ratingview(val.star)}
                           </div>
                           <span>({val.total_rating} ratings)</span>
							</div>*/}
                        <p className="mt-2">4.2 Km - Address Here</p>
                        {/*<p>Buffet Offers</p>*/}
                        </Col>
                        <Col md={3} xs={3} className="text-end px-0">
                        <NavLink to={`/store-details/${val.store_id}`}><Button variant="" type="submit">View</Button></NavLink>
                        </Col>
                     </Row>
                     <Col md={12} xs={12} className="ustrl-crdlst">
                     <ul>
                        <li>${val.price}</li>
							{/*<li>{val.offer}% Off</li>*/}
                     </ul>
                     <div className="ginlike">
                        <div className="">
                           {val.status ? <svg className={`lik lik${val.id}`} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(val.store_id)}>
								   <circle cx="12" cy="12" r="12" fill="white"/>
								   <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
								</svg>  : <svg className={`dis dis${val.store_id}`} width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
								   like(val.store_id)}>
								   <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
								   <g id="Black">
									  <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
								   </g>
								</svg>
						 } 
                        </div>
                     </div>
                     </Col>
                     </Col>
                     </Col>
					 )
					  })}
                     </Col>
					 ) : (
						<Col className="mnhmcnt">
							<p className="text-center mt-4">No data found</p>
						</Col>
					  )}
                     {/* ========== Category List Content Box End ========== */}
                  </Row>
				  
				 
				  
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )}  
               </Container>
            </section>
         {/* ========== User Favorites Page End ========== */}
            {/*<Footer />*/}
        </>
    )
}

export default UserFvrt;