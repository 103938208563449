import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerNoti.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const MerNoti = () => {
const [loading,setLoading] = useState(true);
const [show, setShow] = useState(false);


const [notification, setNotification] = useState([]);
const [id, setId] = useState();

useEffect(() => {
      document.title = `Merchant Notifications | Blue Faucet`;
      document.getElementById('bodywrap').className='mnotipgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
	/* Start get notification data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'merchant');
		API.post('get_notification', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setNotification(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get notification data */
},[])  

/* Start show delete varification modal */
const handleShow = (id) => {	
		setId(id);
	setShow(true);
}
/* End show delete varification modal */
/* Start close delete varification modal */
const handleClose = () => setShow(false);
/* End close delete varification modal */
/* Start delete notification */
const handleDelete = () => {
	setLoading(true);
		setShow(false);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	API.post('delete_notification', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			const updatedNotifications = notification.filter((notif) => notif.id !== id);
				setNotification(updatedNotifications);
		}                  
	}).catch(error => {
		 
	});  
}
/* End delete notification */

    return (
      
      <>
      {/* ========== Merchant Notifications Page Start ========== */}
         <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Notifications'}/>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-3">
      {/* ========== Notifications Content Start ========== */}
               {notification.length >0 ? (
                 <Row className="mx-0">
				 {notification && notification.map((val,index) => {
						return(
                    <Col md={12} xs={12} className="ntfctn-bxcnt px-1">
                    <Row className="mx-0 fsttbcnt">
                       <Col md={1} xs={2} className="align-self-center imgbx">
                       <img src= {val.user_image} className="img-fluid" alt="Sld Img"/></Col>
                       <Col md={10} xs={9} className="align-self-center">
                       <h3>{val.title}</h3>
                       <p>{val.time}</p>
                       </Col>
                       <Col md={1} xs={1} className="align-self-end text-end">
                       <NavLink to="#" onClick={() =>handleShow(val.id)}>
                          <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
                       </NavLink>
                       </Col>
                    </Row>
                    </Col>
                  )
					  })}  
                 </Row>
				 ) : (
						<Row className="mx-0">
							<p className="text-center mt-4">No data found</p>
						</Row>
				 )} 
      {/* ========== Notifications Content End ========== */}
               </Col>
               <Col className="md={12} xs={12}">
               <div className="inmodal">
      {/* ========== delete Popup Start ========== */}
                  <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Are you sure you want to delete?</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col className="btn-clr">
                             <NavLink to="#" onClick={handleDelete}><Button variant="">Yes</Button></NavLink>
                             </Col>
                             <Col className="btn-dv">
                             <NavLink to="#" onClick={handleClose}><Button variant="">No</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
      {/* ========== delete Popup End ========== */}
               </div>
               </Col>
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      {/* ========== Merchant Notifications Page End ========== */}
        </>
    )
}

export default MerNoti;