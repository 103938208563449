import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal, Accordion } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserCupnscrn.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
// import $ from 'jquery';

const UserCupnscrn = () => {

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

useEffect(() => {
      document.title = `User Coupon Screen | Blue Faucet`;
      document.getElementById('bodywrap').className='usrcupnscnpgmain';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });      
    
},[])  
    return (
        <>
          {/* ========== User Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
             <Navbar headerText={'Employees'}/>
            {/* ========== Navbar End ========== */}
             <Container>
             <Row className="uespgfixdv mx-0 ucs-btntp">
               <Col md={6} xs={6} className="align-self-center">
                <NavLink to="#"><Button variant="">Create Coupons</Button>{' '}</NavLink> 
              </Col>
              <Col md={6} xs={6} className="align-self-center">
                <NavLink to="#"><Button variant="">Expired Coupon</Button>{' '}</NavLink> 
              </Col>     
             </Row>
                <Row className="mx-0 mianhmdwncnt">
                   <Col className="mnhmcnt">
                   <Col md={12} xs={12} className="tptxtucs"><h3>Coupon List</h3></Col>
                   {/* ========== Coupon Box Start ========== */}
                     <Col md={12} xs={12} className="py-2 cupn-crd" onClick={handleShow}>
                        <div className="coupon">
                          <div className="left">
                            <div>Discount</div>
                          </div>
                          <div className="center">
                            <div>
                              <h2>5% Off</h2>
                              <h4 className="h3line">2KZYVZD1PP3F</h4>
                              <p>Get 5% Discount on your first order</p>
                              <p>Start Date : <span>2023/07/03</span> End Date :<span>2023/07/03</span> </p>
                            </div>
                          </div>
                          <div className="right">
                            <div>
                              <Form.Group className="ckbox-servicepg">
                               <div key="default-1" className="">
                                 <Form.Check type="checkbox" id="usrcpn-1" label=""/>
                               </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                     </Col>
                     <Col md={12} xs={12} className="py-2 cupn-crd">
                        <div className="coupon">
                          <div className="left">
                            <div>Discount</div>
                          </div>
                          <div className="center">
                            <div>
                              <h2>5% Off</h2>
                              <h4 className="h3line">2KZYVZD1PP3F</h4>
                              <p>Get 5% Discount Refer a Friend</p>
                              <p>Start Date : <span>2023/07/03</span> End Date :<span>2023/07/03</span> </p>
                            </div>
                          </div>
                          <div className="right">
                            <div>
                              <Form.Group className="ckbox-servicepg" controlId="">
                               <div key="default-1" className="">
                                 <Form.Check type="checkbox" id="usrcpn-2" label=""/>
                               </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                     </Col>
                     <Col md={12} xs={12} className="py-2 cupn-crd">
                        <div className="coupon">
                          <div className="left">
                            <div>Discount</div>
                          </div>
                          <div className="center">
                            <div>
                              <h2>5% Off</h2>
                              <h4 className="h3line">2KZYVZD1PP3F</h4>
                              <p>Use BlueFaucet to Make Reservation</p>
                              <p>Start Date : <span>2023/07/03</span> End Date :<span>2023/07/03</span> </p>
                            </div>
                          </div>
                          <div className="right">
                            <div>
                              <Form.Group className="ckbox-servicepg">
                               <div key="default-1" className="">
                                 <Form.Check type="checkbox" id="usrcpn-3" label=""/>
                               </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                     </Col>
                     {/* ========== Coupon Box End ========== */}
                     <Col md={12} xs={12}>
                        <div className="modalcupnscrn">
                      {/* ========== Customer Search Popup Start ========== */}
                       <Modal size="" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg" className="frthmodal" id="trdmdl4">
                          <Modal.Header closeButton>
                             <h3 className="mdlttltxt">Customer Search</h3>
                          </Modal.Header>
                          <Modal.Body className="cupnscrn">
                           <Form.Group as={Row} className="mb-3">
                             <Form.Label column sm={3}>
                               Purchased
                             </Form.Label>
                             <Col sm={3}>
                               <Form.Control type="text" placeholder="Email" />
                             </Col>
                             <Form.Label column sm={2}>
                               in past
                             </Form.Label>
                             <Col sm={4}>
                               <Form.Select aria-label="Default select example">
                                 <option>7 Days</option>
                                 <option defaultValue="1">7 Days</option>
                                 <option defaultValue="2">7 Days</option>
                                 <option defaultValue="3">7 Days</option>
                               </Form.Select>
                             </Col>
                           </Form.Group>
                           <Form.Group as={Row} className="mb-3">
                             <Form.Label column sm={2}>
                               and/or
                             </Form.Label>
                             <Col sm={3}>
                               <Form.Control type="text" placeholder="$50"/>
                             </Col>
                             <Form.Label column sm={4}>Dollars and Above Spending</Form.Label>
                             <Col sm={3}>
                               <Form.Select aria-label="Default select example">
                                 <option>7 Days</option>
                                 <option defaultValue="1">7 Days</option>
                                 <option defaultValue="2">7 Days</option>
                                 <option defaultValue="3">7 Days</option>
                               </Form.Select>
                             </Col>
                           </Form.Group>
                           <Form.Group as={Row} className="mb-3">
                             <Form.Label column sm={4}>or Customer</Form.Label>
                             <Col sm={3}>
                               <Form.Select aria-label="Default select example">
                                 <option>All</option>
                                 <option defaultValue="1">All</option>
                                 <option defaultValue="2">All</option>
                                 <option defaultValue="3">All</option>
                               </Form.Select>
                             </Col>
                           </Form.Group>
                           <Accordion defaultActiveKey={['0','1']} alwaysOpen>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>Sort</Accordion.Header>
                                <Accordion.Body>
                                 <Col md={12} xs={12} className="mb-2">
                                    <Row>
                                     <Col md={1} xs={1}>
                                       <Form.Group className="ck-grn">
                                        <div key="default-1" className="">
                                          <Form.Check type="checkbox" id="default-1" label=""/>
                                        </div>
                                       </Form.Group>
                                     </Col>
                                     <Col md={11} xs={11} className="align-self-center popsdcnt">
                                       <div>
                                          <img src= {'assets/images/merchant/svg/pop-icn1.svg'} className="img-fluid" alt="Sld Img"/>
                                          <span>Giana Lipshutz</span>
                                       </div>
                                     </Col>
                                    </Row>
                                 </Col>
                                 <Col md={12} xs={12} className="mb-2">
                                    <Row>
                                     <Col md={1} xs={1}>
                                       <Form.Group className="ck-grn">
                                        <div key="default-1" className="">
                                          <Form.Check type="checkbox" id="default-2" label=""/>
                                        </div>
                                       </Form.Group>
                                     </Col>
                                     <Col md={11} xs={11} className="align-self-center popsdcnt">
                                       <div>
                                          <img src= {'assets/images/merchant/svg/pop-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                                          <span>James George</span>
                                       </div>
                                     </Col>
                                    </Row>
                                 </Col>
                                 <Col md={12} xs={12} className="mb-2">
                                    <Row>
                                     <Col md={1} xs={1}>
                                       <Form.Group className="ck-grn">
                                        <div key="default-1" className="">
                                          <Form.Check type="checkbox" id="default-3" label=""/>
                                        </div>
                                       </Form.Group>
                                     </Col>
                                     <Col md={11} xs={11} className="align-self-center popsdcnt">
                                       <div>
                                          <img src= {'assets/images/merchant/svg/pop-icn3.svg'} className="img-fluid" alt="Sld Img"/>
                                          <span>Jordyn George</span>
                                       </div>
                                     </Col>
                                    </Row>
                                 </Col>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>Select Coupon</Accordion.Header>
                                <Accordion.Body>
                                  <Form.Group className="ck-grn mb-2">
                                    <div key="default-1" className="">
                                      <Form.Check type="checkbox" id="default-4" label=" Get 5% Discount on your first order"/>
                                    </div>
                                  </Form.Group>
                                  <Form.Group className="ck-grn mb-2">
                                    <div key="default-2" className="">
                                      <Form.Check type="checkbox" id="default-5" label=" Get 5% Discount Refer a Friend"/>
                                    </div>
                                  </Form.Group>
                                  <Form.Group className="ck-grn mb-2">
                                    <div key="default-3" className="">
                                      <Form.Check type="checkbox" id="default-6" label=" Get 5% Discount Use Blue Faucet to Make Reservation"/>
                                    </div>
                                  </Form.Group>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                             <Row className="pp-btn1">
                                <Col>
                                <NavLink to="#"><Button className="blc" variant="">Add Account</Button></NavLink></Col>
                             </Row>
                          </Modal.Body>
                          <Modal.Footer className="d-none"></Modal.Footer>
                       </Modal>
                      {/* ========== Customer Search Popup End ========== */}
                    </div>
                     </Col>
                   </Col>
                </Row>
             </Container>
          </section>
          <Footer/>
          {/* ========== User Coupon Screen Page End ========== */}
        </>
    )
}

export default UserCupnscrn;