import React from "react";
import { Outlet, Navigate,useLocation } from "react-router-dom";

export default function PrivateRouteMer() {
    let userid = localStorage.getItem("token") == null ? false : true;
	let profile_setup = parseInt(localStorage.getItem("profile_setup"));
	let type = parseInt(localStorage.getItem("type"));
	const location = useLocation();
	
	var usertype = 2;
    if (userid === true) {
        usertype = type;
    }
	if(usertype===2)
	{
		var user_type = 0;
		if (userid === true) {
			user_type = 2;
		}
		if(user_type===2){
			const pagename=location.pathname;
			if(pagename==='/mer-stripe-account-setup')
			{
				if(profile_setup===2){
					return <>{user_type === 2 ? <Outlet /> : <Navigate to="/mer-login" />}</>;
				}else{
					return <>{user_type === 2 ? <Navigate to="/mer-dashboard" /> : <Navigate to="/mer-login" />}</>;
				}
			}else{
				if(profile_setup===2){
					return <>{user_type === 2 ? <Navigate to="/mer-stripe-account-setup" /> : <Navigate to="/mer-login" />}</>;
				}else{
					return <>{user_type === 2 ? <Outlet /> : <Navigate to="/mer-login" />}</>;
				}
			}
		}else{
			return <>{user_type === 2 ? <Outlet /> : <Navigate to="/mer-login" />}</>;
		}
	}else if(usertype===1){
		return <>{usertype === 2 ? <Navigate to="/login" /> : <Navigate to="/login" />}</>;
	}else if(usertype===3){
		return <>{usertype === 3 ? <Navigate to="/emp-login" /> : <Navigate to="/emp-login" />}</>;
	}
}
