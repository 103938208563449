/* Start User login */
export const validatelogin = (inputs) => {
    const errors = {};
    if (!inputs.email_or_phone) {
      errors.email_or_phone = 'Please enter your email or phone number';
    } 

    //Password Errors
    if(!inputs.password){
      errors.password = 'Please enter your password';
    }
    return errors;
}
/* End User login */
/* Start User singup */
export const validate = (inputs) => {
    const errors = {};
    if (!inputs.name) {
      errors.name = 'Please enter your full name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter your email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid your email';
    }
    if(!inputs.mobile){
      errors.mobile = 'Please enter phone number';
    }
	/*if(!inputs.dob){
      errors.dob = 'Please enter date of birth';
    }*/
	if(!inputs.user_name){
      errors.user_name = 'Please enter User Name';
    }
	if(!inputs.password){
      errors.password = 'Please enter password';
    }else if (inputs.password && inputs.password.length < 8){
      errors.password = 'Password must be 8 character long';
    }else{
		var pass_err_arr = [];
		if (inputs.password.search(/[a-z]/) < 0) {
			pass_err_arr.push('at least one lower case letter');
		}
		if(inputs.password.search(/[A-Z]/) < 0)
		{
			pass_err_arr.push('one upper case letter');
		}
		if(inputs.password.search(/[0-9]/) < 0)
		{
			pass_err_arr.push('one digit');
		}
		if(inputs.password.search(/[!@#$%^&*_]/) < 0)
		{
			pass_err_arr.push('one special char from -[ ! @ # $ % ^ & * _ ]');
		}
		
		if(pass_err_arr.length > 0){
			var pass_err = pass_err_arr.join(', ');
			errors.password = 'Your password must contain '+pass_err;
		}
	}
	if(!inputs.password_confirmation){
      errors.password_confirmation = 'Please enter password confirmation';
    }else if(inputs.password !== inputs.password_confirmation){
      errors.password_confirmation = 'Password & confirm password must be same';
    }
    return errors;
}
/* End User singup */
/* start User forgot email */
export const validatefogemail = (inputs) => {
    const errors = {};
    if (!inputs.email) {
      errors.email = 'Please enter email address';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid email address';
    }
    return errors;
}
/* End User forgot email */
/* start User forgot email */
export const validateforotp = (inputs) => {
    const errors = {};
    if (!inputs.no1) {
      errors.otp = 'Please enter otp';
    }
	if (!inputs.no2) {
      errors.otp = 'Please enter otp';
    }
	if (!inputs.no3) {
      errors.otp = 'Please enter otp';
    }
	if (!inputs.no4) {
      errors.otp = 'Please enter otp';
    }
	if (!inputs.no5) {
      errors.otp = 'Please enter otp';
    }
	if (!inputs.no6) {
      errors.otp = 'Please enter otp';
    }
    return errors;
}
/* End User forgot email */

/* Start User forgot pass */
export const validatefogpass = (inputs) => {
    const errors = {};
    //Password Errors
    if(!inputs.newPassword){
      errors.newPassword = 'Please enter your password';
    }else if (inputs.newPassword && inputs.newPassword.length < 8){
      errors.newPassword = 'Password must be 8 character long';
    }else{
		var pass_err_arr = [];
		if (inputs.newPassword.search(/[a-z]/) < 0) {
			pass_err_arr.push('at least one lower case letter');
		}
		if(inputs.newPassword.search(/[A-Z]/) < 0)
		{
			pass_err_arr.push('one upper case letter');
		}
		if(inputs.newPassword.search(/[0-9]/) < 0)
		{
			pass_err_arr.push('one digit');
		}
		if(inputs.newPassword.search(/[!@#$%^&*_]/) < 0)
		{
			pass_err_arr.push('one special char from -[ ! @ # $ % ^ & * _ ]');
		}
		
		if(pass_err_arr.length > 0){
			var pass_err = pass_err_arr.join(', ');
			errors.newPassword = 'Your password must contain '+pass_err;
		}
	}

    if(!inputs.confirmPassword){
      errors.confirmPassword = 'Please enter Re-enter New Password';
    }

    if(inputs.newPassword !== inputs.confirmPassword){
      errors.confirmPassword = 'Password & Confirm Password does not match.';
    }
    return errors;
}
/* End User forgot pass */
/* Start User profile */
export const validateprofile = (inputs,disaddress) => {
    const errors = {};
    if (!inputs.name) {
      errors.name = 'Please enter your full name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter your email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid your email';
    }
    if(!inputs.mobile){
      errors.mobile = 'Please enter phone number';
    }
	/*if(!inputs.dob){
      errors.dob = 'Please enter date of birth';
    }*/
	if(!inputs.user_name){
      errors.user_name = 'Please enter User Name';
    }
	/*if(!inputs.gender){
      errors.gender = 'Please enter gender';
    }*/
	/*if (disaddress.length===0) {
      errors.address = 'Please enter address';
    } */
    return errors;
}
/* End User profile */
/* Start User card */
export const validatecard = (inputs) => {
    const errors = {};
	var i=0;
	
	if(inputs.credit_card_name!=='')
	{
		i++;
	}
	if(inputs.credit_card_number!=='')
	{
		i++;
	}
	if(inputs.credit_month!=='')
	{
		i++;
	}
	if(inputs.credit_year!=='')
	{
		i++;
	}

	var a=0;
	if(inputs.debite_card_name!=='')
	{
		a++;
	}
	if(inputs.debite_card_number!=='')
	{
		a++;
	}
	if(inputs.debite_month!=='')
	{
		a++;
	}
	if(inputs.debite_year!=='')
	{
		a++;
	}
	
	if(i < 4)
	{
		if(a < 4){
			errors.card = 'Please enter credit or debite card';
		}else{
			if(i > 0)
			{
				errors.card = 'Please enter credit card';
			}
			
			
			
		}
	}else{
			if(a > 0)
			{
				if(a < 4)
				{
					errors.card = 'Please enter debite card';
				}
			}
	}
	
	if(i===4)
	{
		var date=inputs.credit_year+'-'+inputs.credit_month+'-25';
		
		const enteredDatecredit = new Date(date);
		const currentDatecredit = new Date();
		if(!isNaN(enteredDatecredit.getTime()))
		{
			 var entyearcredit=parseInt(enteredDatecredit.getFullYear());
			 var curyearcredit=parseInt(currentDatecredit.getFullYear());
			 
			if(entyearcredit > curyearcredit)
			{
				
			}else{
				if(entyearcredit===curyearcredit)
				{
					const entmonthcredit = enteredDatecredit.getMonth();
					const curmonthcredit = currentDatecredit.getMonth();
					if(entmonthcredit >= curmonthcredit)
					{
						
					}else{
						errors.card = 'Enter valid Credit card Exp details. ';
					}
				}else{
					errors.card = 'Enter valid Credit card Exp details. ';
				}
			}
		}else{
			errors.card = 'Enter valid Credit card Exp details. ';
		}
	}
	if(a===4)
	{
		//var dated='25-'+inputs.debite_month+'-'+inputs.debite_year;
		var dated=inputs.debite_year+'-'+inputs.debite_month+'-25';
		const enteredDatedebit = new Date(dated);
		const currentDatedebit = new Date();
		
	
		if(!isNaN(enteredDatedebit.getTime()))
		{
			 var entyeardebit=parseInt(enteredDatedebit.getFullYear());
			 var curyeardebit=parseInt(currentDatedebit.getFullYear());
			 
			if(entyeardebit > curyeardebit)
			{
				
			}else{
				if(entyeardebit===curyeardebit)
				{
					const entmonthcredit = enteredDatedebit.getMonth();
					const curmonthcredit = currentDatedebit.getMonth();
					if(entmonthcredit >= curmonthcredit)
					{
						
					}else{
						errors.card = 'Enter valid Debit card Exp details. ';
					}
				}else{
					errors.card = 'Enter valid Debit card Exp details. ';
				}
			}
		}else{
			errors.card = 'Enter valid Debit card Exp details. ';
		}
	}
	 
    return errors;
}
/* End User card */
/* Start User profile */
export const validatepro = (inputs,disaddress,logintype) => {
    const errors = {};
    if (!inputs.name) {
      errors.name = 'Please enter your full name';
    } 
	/*if (!inputs.gender) {
      errors.gender = 'Please enter gender';
    }*/ 
	if (!inputs.email) {
      errors.email = 'Please enter your email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid your email';
    }
    if(!inputs.mobile){
      errors.mobile = 'Please enter phone number';
    }
	/*if(!inputs.dob){
      errors.dob = 'Please enter date of birth';
    }*/
	if(!inputs.user_name){
      errors.user_name = 'Please enter User Name';
    }
	if(logintype===true)
	{
		if(!inputs.password){
		  //errors.password = 'Please enter password';
		}else if (inputs.password && inputs.password.length < 8){
		  errors.password = 'Password must be 8 character long';
		}else{
			var pass_err_arr = [];
			if (inputs.password.search(/[a-z]/) < 0) {
				pass_err_arr.push('at least one lower case letter');
			}
			if(inputs.password.search(/[A-Z]/) < 0)
			{
				pass_err_arr.push('one upper case letter');
			}
			if(inputs.password.search(/[0-9]/) < 0)
			{
				pass_err_arr.push('one digit');
			}
			if(inputs.password.search(/[!@#$%^&*_]/) < 0)
			{
				pass_err_arr.push('one special char from -[ ! @ # $ % ^ & * _ ]');
			}
			
			if(pass_err_arr.length > 0){
				var pass_err = pass_err_arr.join(', ');
				errors.password = 'Your password must contain '+pass_err;
			}
		}
		if(!inputs.password_confirmation){
		  //errors.password_confirmation = 'Please enter password confirmation';
		}else if(inputs.password !== inputs.password_confirmation){
		  errors.password_confirmation = 'Password & confirm password must be same';
		}
	}
	/*if (disaddress.length===0) {
      errors.address = 'Please enter address';
    }*/ 
    return errors;
}

/* End User profile */
/* Start User Address Profile */
export const validateaddressprofile = (inputs) => {
    const errors = {};
    //Password Errors
    if(!inputs.title){
      errors.title = 'Please enter title';
    }
    if(!inputs.street_name){
      errors.street_name = 'Please enter address';
    }
	if(!inputs.latitude){
      errors.street_name = 'Please enter address';
    }
	if(!inputs.longitude){
      errors.street_name = 'Please enter address';
    }
	if(!inputs.state_id){
      errors.state_id = 'Please enter state';
    }
	if(!inputs.city_id){
      errors.city_id = 'Please enter city';
    }
	if(!inputs.zip_code){
      errors.zip_code = 'Please enter zip code';
    }
	
	

    return errors;
}
/* End User Address Profile */
/* Start User singup */
export const validatew9form = (inputs,signature_of_us_person,pdfurl) => {
    const errors = {};
    if (!inputs.sales_name) {
      errors.sales_name = 'Please enter sales name';
    } 
	if(inputs.type!=='individual')
	{
		if (!inputs.business_name) {
		  errors.business_name = 'Please enter business name';
		}
	}
    if(!inputs.type){
      errors.type = 'Please enter type';
    }
	if(inputs.type!=='individual')
	{
		if(!inputs.payee_code){
		  errors.payee_code = 'Please enter payee code';
		}
		if(!inputs.fatca_reporting){
		  errors.fatca_reporting = 'Please enter fatca reporting';
		}
	}
	if(!inputs.address){
      errors.address = 'Please enter address';
    }
	if(!inputs.zip_code){
      errors.zip_code = 'Please enter zip code';
    }
	/*if(!inputs.account_number){
      errors.account_number = 'Please enter account number';
    }*/
	/*if(!inputs.address2){
      errors.address2 = 'Please enter address';
    }*/

	if(!inputs.security_number && !inputs.identification_number)
	{
		errors.security_number = 'Please enter security number or identification number';
	}else{
		if(!inputs.security_number){
		  //errors.security_number = 'Please enter security number';
		}else{ 
			if(inputs.security_number && inputs.security_number.length < 9){
			  errors.security_number = 'Security Number must be 9 character long';
			}
		}
		if(!inputs.identification_number){
		  //errors.identification_number = 'Please enter identification number';
		}else{ 
			if(inputs.identification_number && inputs.identification_number.length < 9){
			  errors.identification_number = 'identification number must be 9 character long';
			}
		}
	}
	if(!inputs.date){
      errors.date = 'Please enter date';
    }
	if(pdfurl==='')
	{
		if(!signature_of_us_person){
		  errors.signature_of_us_person = 'Please select signature of us person';
		}
	}
    return errors;
}
/* End User singup */
/* Start User singup Mer */
export const validatesignupmer = (inputs,subcat,othertext,image,inputsSunday,inputsMonday,inputsTuesday,inputsWednesday,inputsThursday,inputsFriday,inputsSaturday) => {
    const errors = {};
    if (!inputs.store_name) {
      errors.store_name = 'Please enter store name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter your email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid your email';
    }
    if(!inputs.store_owner_name){
      errors.store_owner_name = 'Please enter store owner name';
    }
	if(!inputs.store_location){
      errors.store_location = 'Please enter service location';
    }
	if(inputs.store_location==="Store's Location"){
		if(!inputs.street_name){
		  errors.street_name = 'Please enter number and street name';
		}
		if(!inputs.lat){
		  errors.lat = 'Please enter number and street name';
		}
		if(!inputs.long){
		  errors.long = 'Please enter number and street name';
		}
	
	/*if(!inputs.area){
      errors.area = 'Please enter area';
    }*/
	if(!inputs.state){
      errors.state = 'Please enter state';
    }
	if(!inputs.city){
      errors.city = 'Please enter city';
    }
	if(!inputs.zip_code){
      errors.zip_code = 'Please enter zip code';
    }
	}
	if(!inputs.store_phone_number){
      errors.store_phone_number = 'Please enter phone number';
    }
	if(!inputs.business_category){
      errors.business_category = 'Please enter category';
    }
	if(!inputs.store_policy){
      errors.store_policy = 'Please enter policy';
    }
	if(!inputs.sales_tax_rate){
      errors.sales_tax_rate = 'Please enter your Sales Tax Rate';
    }
	if(!inputs.password){
      errors.password = 'Please enter password';
    }else if (inputs.password && inputs.password.length < 8){
      errors.password = 'Password must be 8 character long';
    }else{
		var pass_err_arr = [];
		if (inputs.password.search(/[a-z]/) < 0) {
			pass_err_arr.push('at least one lower case letter');
		}
		if(inputs.password.search(/[A-Z]/) < 0)
		{
			pass_err_arr.push('one upper case letter');
		}
		if(inputs.password.search(/[0-9]/) < 0)
		{
			pass_err_arr.push('one digit');
		}
		if(inputs.password.search(/[!@#$%^&*_]/) < 0)
		{
			pass_err_arr.push('one special char from -[ ! @ # $ % ^ & * _ ]');
		}
		
		if(pass_err_arr.length > 0){
			var pass_err = pass_err_arr.join(', ');
			errors.password = 'Your password must contain '+pass_err;
		}
	}
	if(!inputs.password_confirmation){
      errors.password_confirmation = 'Please enter password confirmation';
    }else if(inputs.password !== inputs.password_confirmation){
      errors.password_confirmation = 'Password & confirm password must be same';
    }
	if(subcat.length < 1){
      errors.subcategory = 'Please enter sub category';
    }
	if(othertext===true)
	{
		if(!inputs.other_text)
		{
			errors.other_text = 'Please enter other text';
		}
	}
	if(!image.preview){
      errors.image = 'Please select image';
    }
	var a=0;
	if(inputsSunday.status===1){
		a++;
		/*if(inputsSunday.start==='')
		{
			errors.sunstart = 'Please enter sunday start time';
		}
		if(inputsSunday.end==='')
		{
			errors.sunend = 'Please enter sunday end time';
		}*/
	}
	if(inputsMonday.status===1){
		a++;
		/*if(inputsMonday.start==='')
		{
			errors.monstart = 'Please enter monday start time';
		}
		if(inputsMonday.end==='')
		{
			errors.monend = 'Please enter monday end time';
		}*/
	}
	if(inputsTuesday.status===1){
		a++;
		/*if(inputsTuesday.start==='')
		{
			errors.tuestart = 'Please enter tuesday start time';
		}
		if(inputsTuesday.end==='')
		{
			errors.tueend = 'Please enter tuesday end time';
		}*/
	}
	if(inputsWednesday.status===1){
		a++;
		/*if(inputsWednesday.start==='')
		{
			errors.wedstart = 'Please enter wednesday start time';
		}
		if(inputsWednesday.end==='')
		{
			errors.wedend = 'Please enter wednesday end time';
		}*/
	}
	if(inputsThursday.status===1){
		a++;
		/*if(inputsThursday.start==='')
		{
			errors.thustart = 'Please enter thursday start time';
		}
		if(inputsThursday.end==='')
		{
			errors.thuend = 'Please enter thursday end time';
		}*/
	}
	if(inputsFriday.status===1){
		a++;
		/*if(inputsFriday.start==='')
		{
			errors.fristart = 'Please enter friday start time';
		}
		if(inputsFriday.end==='')
		{
			errors.friend = 'Please enter friday end time';
		}*/
	}
	if(inputsSaturday.status===1){
		a++;
		/*if(inputsSaturday.start==='')
		{
			errors.satstart = 'Please enter saturday start time';
		}
		if(inputsSaturday.end==='')
		{
			errors.satend = 'Please enter Saturday end time';
		}*/
	}
	if(a===0){
		errors.sunstart = 'Please select time';
	}
	//console.log(errors);
    return errors;
}
/* End User singup Mer */
/* Start User profile Mer */
export const validateprofilemer = (inputs,subcat,othertext,image,inputsSunday,inputsMonday,inputsTuesday,inputsWednesday,inputsThursday,inputsFriday,inputsSaturday) => {
    const errors = {};
    if (!inputs.store_name) {
      errors.store_name = 'Please enter store name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter your email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid your email';
    }
    if(!inputs.store_owner_name){
      errors.store_owner_name = 'Please enter store owner name';
    }
	if(!inputs.store_location){
      errors.store_location = 'Please enter service location';
    }
	if(inputs.store_location==="Store's Location"){
		if(!inputs.street_name){
		  errors.street_name = 'Please enter number and street name';
		}
		if(!inputs.lat){
		  errors.lat = 'Please enter number and street name';
		}
		if(!inputs.long){
		  errors.long = 'Please enter number and street name';
		}
	
	/*if(!inputs.area){
      errors.area = 'Please enter area';
    }*/
	if(!inputs.state){
      errors.state = 'Please enter state';
    }
	if(!inputs.city){
      errors.city = 'Please enter city';
    }
	if(!inputs.zip_code){
      errors.zip_code = 'Please enter zip code';
    }
	}
	if(!inputs.store_phone_number){
      errors.store_phone_number = 'Please enter phone number';
    }
	if(!inputs.business_category){
      errors.business_category = 'Please enter category';
    }
	if(!inputs.store_policy){
      errors.store_policy = 'Please enter policy';
    }
	if(!inputs.sales_tax_rate){
      errors.sales_tax_rate = 'Please enter your Sales Tax Rate';
    }
	if(subcat.length < 1){
      errors.subcategory = 'Please enter sub category';
    }
	if(othertext===true)
	{
		if(!inputs.other_text)
		{
			errors.other_text = 'Please enter other text';
		}
	}
	if(!image.preview){
      errors.image = 'Please select image';
    }
	if(!inputs.password){
	}else{
		if (inputs.password && inputs.password.length < 8){
		  errors.password = 'Password must be 8 character long';
		}else{
			var pass_err_arr = [];
			if (inputs.password.search(/[a-z]/) < 0) {
				pass_err_arr.push('at least one lower case letter');
			}
			if(inputs.password.search(/[A-Z]/) < 0)
			{
				pass_err_arr.push('one upper case letter');
			}
			if(inputs.password.search(/[0-9]/) < 0)
			{
				pass_err_arr.push('one digit');
			}
			if(inputs.password.search(/[!@#$%^&*_]/) < 0)
			{
				pass_err_arr.push('one special char from -[ ! @ # $ % ^ & * _ ]');
			}
			
			if(pass_err_arr.length > 0){
				var pass_err = pass_err_arr.join(', ');
				errors.password = 'Your password must contain '+pass_err;
			}else{
				if(inputs.password !== inputs.password_confirmation)
				{
						errors.password_confirmation = 'Password & confirm password must be same';
				}
			}
		}
    }
	
	var a=0;
	if(inputsSunday.status===1){
		a++;
		/*if(inputsSunday.start==='')
		{
			errors.sunstart = 'Please enter sunday start time';
		}
		if(inputsSunday.end==='')
		{
			errors.sunend = 'Please enter sunday end time';
		}*/
	}
	if(inputsMonday.status===1){
		a++;
		/*if(inputsMonday.start==='')
		{
			errors.monstart = 'Please enter monday start time';
		}
		if(inputsMonday.end==='')
		{
			errors.monend = 'Please enter monday end time';
		}*/
	}
	if(inputsTuesday.status===1){
		a++;
		/*if(inputsTuesday.start==='')
		{
			errors.tuestart = 'Please enter tuesday start time';
		}
		if(inputsTuesday.end==='')
		{
			errors.tueend = 'Please enter tuesday end time';
		}*/
	}
	if(inputsWednesday.status===1){
		a++;
		/*if(inputsWednesday.start==='')
		{
			errors.wedstart = 'Please enter wednesday start time';
		}
		if(inputsWednesday.end==='')
		{
			errors.wedend = 'Please enter wednesday end time';
		}*/
	}
	if(inputsThursday.status===1){
		a++;
		/*if(inputsThursday.start==='')
		{
			errors.thustart = 'Please enter thursday start time';
		}
		if(inputsThursday.end==='')
		{
			errors.thuend = 'Please enter thursday end time';
		}*/
	}
	if(inputsFriday.status===1){
		a++;
		/*if(inputsFriday.start==='')
		{
			errors.fristart = 'Please enter friday start time';
		}
		if(inputsFriday.end==='')
		{
			errors.friend = 'Please enter friday end time';
		}*/
	}
	if(inputsSaturday.status===1){
		a++;
		/*if(inputsSaturday.start==='')
		{
			errors.satstart = 'Please enter saturday start time';
		}
		if(inputsSaturday.end==='')
		{
			errors.satend = 'Please enter Saturday end time';
		}*/
	}
	if(a===0){
		errors.sunstart = 'Please select time';
	}
	//console.log(errors);
    return errors;
}
/* End User profile Mer */
/* Start Order */
export const validateorder = (inputs,key) => {
    const errors = {};
	if(key==='second')
	{
		if (!inputs.credit_card_name) {
		  errors.credit_card_name = 'Please enter card name';
		}
		if (!inputs.credit_card_number) {
		  errors.credit_card_number = 'Please enter card number';
		} 
		if (!inputs.credit_card_month) {
		  errors.credit_card_month = 'Please enter card month';
		} 
		if (!inputs.credit_card_year) {
		  errors.credit_card_year = 'Please enter card year';
		}
		if (!inputs.credit_card_cvv) {
		  errors.credit_card_cvv = 'Please enter card cvv';
		}
	}
	if(key==='third')
	{
		if (!inputs.debit_card_name) {
		  errors.debit_card_name = 'Please enter card name';
		}
		if (!inputs.debit_card_number) {
		  errors.debit_card_number = 'Please enter card number';
		} 
		if (!inputs.debit_card_month) {
		  errors.debit_card_month = 'Please enter card month';
		} 
		if (!inputs.debit_card_year) {
		  errors.debit_card_year = 'Please enter card year';
		}
		if (!inputs.debit_card_cvv) {
		  errors.debit_card_cvv = 'Please enter card cvv';
		}
	}
	return errors;
}
/* End Order */
/* Start Cart service */
export const validatecartservice = (service) => {
    const errors = {};
	var i=0;
    service.forEach((item) => {
		if(item.isSelected===true)
		{
			i++;
		}
	});
	if(i===0){
		 errors.service_sel = 'Please select service';
	}
    return errors;
}
/* End Cart service */
/* Start Cart product */
export const validatecartproduct = (product) => {
    const errors = {};
	var i=0;
    product.forEach((item) => {
		if(item.isSelected===true)
		{
			i++;
		}
	});
	if(i===0){
		 errors.product_sel = 'Please select product';
	}
    return errors;
}
/* End Cart product */
/* Start Order */
export const validateorderser = () => {
    const errors = {};
	
	if(!localStorage.getItem("book_start_date"))
	{
		errors.order = 'Something went wrong. Please try again later.';
	}
	if(!localStorage.getItem("book_end_date"))
	{
		errors.order = 'Something went wrong. Please try again later.';
	}
	if(!localStorage.getItem("book_store_id"))
	{
		errors.order = 'Something went wrong. Please try again later.';
	}
	/*if(!localStorage.getItem("book_emp_id"))
	{
		errors.order = 'Something went wrong. Please try again later.';
	}*/
	if(!localStorage.getItem("book_type"))
	{
		errors.order = 'Something went wrong. Please try again later.';
	}

	return errors;
}
/* Start Order service */
export const validateorderserpay = (inputs,key) => {
    const errors = {};
	if(key==='second')
	{
		if (!inputs.credit_card_name) {
		  errors.credit_card_name = 'Please enter card name';
		}
		if (!inputs.credit_card_number) {
		  errors.credit_card_number = 'Please enter card number';
		} 
		if (!inputs.credit_card_month) {
		  errors.credit_card_month = 'Please enter card month';
		} 
		if (!inputs.credit_card_year) {
		  errors.credit_card_year = 'Please enter card year';
		}
		if (!inputs.credit_card_cvv) {
		  errors.credit_card_cvv = 'Please enter card cvv';
		}
	}
	if(key==='third')
	{
		if (!inputs.debit_card_name) {
		  errors.debit_card_name = 'Please enter card name';
		}
		if (!inputs.debit_card_number) {
		  errors.debit_card_number = 'Please enter card number';
		} 
		if (!inputs.debit_card_month) {
		  errors.debit_card_month = 'Please enter card month';
		} 
		if (!inputs.debit_card_year) {
		  errors.debit_card_year = 'Please enter card year';
		}
		if (!inputs.debit_card_cvv) {
		  errors.debit_card_cvv = 'Please enter card cvv';
		}
	}
	return errors;
}
/* End Order service */
/* Start invite merchant */
export const validateInvite = (inputs) => {
    const errors = {};
    if (!inputs.name) {
      errors.name = 'Please enter name';
    } 
	if (!inputs.owner_name) {
      errors.owner_name = 'Please enter owner name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter your email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid your email';
    }
    if(!inputs.phone_number){
      errors.phone_number = 'Please enter phone number';
    }
	if(inputs.content_type==='custom')
	{
		if(!inputs.custom_content){
		  errors.custom_content = 'Please enter custom content';
		}
	}
    return errors;
}
/* End invite merchant */
/* Start Stripe */
export const validatestripe = (inputs) => {
    const errors = {};
    if (!inputs.stripe_live_pk) {
      errors.stripe_live_pk = 'Please enter stripe public key';
    } 

    //Password Errors
    if(!inputs.stripe_live_sk){
      errors.stripe_live_sk = 'Please enter stripe secret key';
    }
    return errors;
}
/* End Stripe */
/* Start Product */
export const validateproduct = (inputs,image1,image2,image3,image4,image5) => {
    const errors = {};
    if (!inputs.name) {
      errors.name = 'Please enter product name';
    } 
    if(!inputs.category_id){
      errors.category_id = 'Please select category';
    }
	if(!inputs.price){
      errors.price = 'Please enter price';
    }
	
	if(inputs.unlimited_quantity===0)
	{
		if(!inputs.quantity){
		  errors.quantity = 'Please enter availability';
		}
	}
	if(!inputs.description){
      errors.description = 'Please enter description';
    }
	if(!inputs.special_instructions){
      errors.special_instructions = 'Please enter special instructions';
    }
	var i=0;
	if(!image1.preview){
      
    }else{
		i++;
	}
	if(!image2.preview){
     
    }else{
		i++;
	}
	if(!image3.preview){
      
    }else{
		i++;
	}
	if(!image4.preview){
     
    }else{
		i++;
	}
	if(!image5.preview){
    }else{
		i++;
	}
	if(i===0){
		errors.image1 = 'Please select image';
	}
    return errors;
}
/* End Product */
/* Start Edit Product */
export const validateproductedit = (inputs,image1,image2,image3,image4,image5) => {
    const errors = {};
    if (!inputs.nameedit) {
      errors.nameedit = 'Please enter product name';
    } 
    if(!inputs.category_idedit){
      errors.category_idedit = 'Please select category';
    }
	if(!inputs.priceedit){
      errors.priceedit = 'Please enter price';
    }
	if(inputs.unlimited_quantityedit===0)
	{
		if(!inputs.quantityedit){
		  errors.quantityedit = 'Please enter availability';
		}
	}
	if(!inputs.descriptionedit){
      errors.descriptionedit = 'Please enter description';
    }
	if(!inputs.special_instructionsedit){
      errors.special_instructionsedit = 'Please enter special instructions';
    }

    return errors;
}
/* End Edit Product */
/* Start Service */
export const validateservice = (inputs,imageser1,imageser2,imageser3,imageser4,imageser5) => {
    const errors = {};
    if (!inputs.nameser) {
      errors.nameser = 'Please enter product name';
    } 
	if(!inputs.priceser){
      errors.priceser = 'Please enter price';
    }
	if(!inputs.duration_in_minutesser){
      errors.duration_in_minutesser = 'Please enter duration in minutes';
    }
	
	if(!inputs.descriptionser){
      errors.descriptionser = 'Please enter description';
    }
	if(!inputs.special_instructionsser){
      errors.special_instructionsser = 'Please enter special instructions';
    }
	var i=0;
	if(!imageser1.preview){
		
    }else{
		i++;
	}
	if(!imageser2.preview){
		
    }else{
		i++;
	}
	if(!imageser3.preview){
      
    }else{
		i++;
	}
	if(!imageser4.preview){
      
    }else{
		i++;
	}
	if(!imageser5.preview){
      
    }else{
		i++;
	}
	if(i===0){
		errors.imageser1 = 'Please select image';
	}
    return errors;
}
/* End Service */
/* Start Edit Service */
export const validateserviceedit = (inputs,imageser1,imageser2,imageser3,imageser4,imageser5) => {
    const errors = {};
    if (!inputs.nameseredit) {
      errors.nameseredit = 'Please enter product name';
    } 
	if(!inputs.priceseredit){
      errors.priceseredit = 'Please enter price';
    }
	if(!inputs.duration_in_minutesseredit){
      errors.duration_in_minutesseredit = 'Please enter duration in minutes';
    }
	
	if(!inputs.descriptionseredit){
      errors.descriptionseredit = 'Please enter description';
    }
	if(!inputs.special_instructionsseredit){
      errors.special_instructionsseredit = 'Please enter special instructions';
    }
	
    return errors;
}
/* End Edit Service */
/* Start emp */
export const validateemp = (inputs,inputsSunday,inputsMonday,inputsTuesday,inputsWednesday,inputsThursday,inputsFriday,inputsSaturday) => {
    const errors = {};
   
	if (!inputs.employee_name) {
      errors.employee_name = 'Please enter employee name';
    }
	if (!inputs.employee_email) {
      errors.employee_email = 'Please enter employee email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.employee_email)
    ){
      errors.employee_email = 'Invalid employee email';
    }
    if(!inputs.employee_contact_no){
      errors.employee_contact_no = 'Please enter employee contact no';
    }
	if(!inputs.date_of_birth){
      errors.date_of_birth = 'Please enter date of birth';
    }
	if(!inputs.commission){
      errors.commission = 'Please enter commission';
    }
	if(!inputs.password){
      errors.password = 'Please enter password';
    }else if (inputs.password && inputs.password.length < 8){
      errors.password = 'Password must be 8 character long';
    }else{
		var pass_err_arr = [];
		if (inputs.password.search(/[a-z]/) < 0) {
			pass_err_arr.push('at least one lower case letter');
		}
		if(inputs.password.search(/[A-Z]/) < 0)
		{
			pass_err_arr.push('one upper case letter');
		}
		if(inputs.password.search(/[0-9]/) < 0)
		{
			pass_err_arr.push('one digit');
		}
		if(inputs.password.search(/[!@#$%^&*_]/) < 0)
		{
			pass_err_arr.push('one special char from -[ ! @ # $ % ^ & * _ ]');
		}
		
		if(pass_err_arr.length > 0){
			var pass_err = pass_err_arr.join(', ');
			errors.password = 'Your password must contain '+pass_err;
		}
	}
	if(!inputs.password_confirmation){
      errors.password_confirmation = 'Please enter password confirmation';
    }else if(inputs.password !== inputs.password_confirmation){
      errors.password_confirmation = 'Password & confirm password must be same';
    }
	var a=0;
	if(inputsSunday.status===1){
		a++;
		/*if(inputsSunday.start==='')
		{
			errors.sunstart = 'Please enter sunday start time';
		}
		if(inputsSunday.end==='')
		{
			errors.sunend = 'Please enter sunday end time';
		}*/
	}
	if(inputsMonday.status===1){
		a++;
		/*if(inputsMonday.start==='')
		{
			errors.monstart = 'Please enter monday start time';
		}
		if(inputsMonday.end==='')
		{
			errors.monend = 'Please enter monday end time';
		}*/
	}
	if(inputsTuesday.status===1){
		a++;
		/*if(inputsTuesday.start==='')
		{
			errors.tuestart = 'Please enter tuesday start time';
		}
		if(inputsTuesday.end==='')
		{
			errors.tueend = 'Please enter tuesday end time';
		}*/
	}
	if(inputsWednesday.status===1){
		a++;
		/*if(inputsWednesday.start==='')
		{
			errors.wedstart = 'Please enter wednesday start time';
		}
		if(inputsWednesday.end==='')
		{
			errors.wedend = 'Please enter wednesday end time';
		}*/
	}
	if(inputsThursday.status===1){
		a++;
		/*if(inputsThursday.start==='')
		{
			errors.thustart = 'Please enter thursday start time';
		}
		if(inputsThursday.end==='')
		{
			errors.thuend = 'Please enter thursday end time';
		}*/
	}
	if(inputsFriday.status===1){
		a++;
		/*if(inputsFriday.start==='')
		{
			errors.fristart = 'Please enter friday start time';
		}
		if(inputsFriday.end==='')
		{
			errors.friend = 'Please enter friday end time';
		}*/
	}
	if(inputsSaturday.status===1){
		a++;
		/*if(inputsSaturday.start==='')
		{
			errors.satstart = 'Please enter saturday start time';
		}
		if(inputsSaturday.end==='')
		{
			errors.satend = 'Please enter Saturday end time';
		}*/
	}
	if(a===0){
		errors.sunstart = 'Please select time';
	}
	//console.log(errors);
    return errors;
}
/* End emp */
/* Start User Edit Address Profile */
export const validateaddressprofileedit = (inputs) => {
    const errors = {};
    //Password Errors
    if(!inputs.titleedit){
      errors.titleedit = 'Please enter title';
    }
    if(!inputs.street_nameedit){
      errors.street_nameedit = 'Please enter address';
    }
	if(!inputs.latitudeedit){
      errors.street_nameedit = 'Please enter address';
    }
	if(!inputs.longitudeedit){
      errors.street_nameedit = 'Please enter address';
    }
	if(!inputs.state_idedit){
      errors.state_idedit = 'Please enter state';
    }
	if(!inputs.city_idedit){
      errors.city_idedit = 'Please enter city';
    }
	if(!inputs.zip_codeedit){
      errors.zip_codeedit = 'Please enter zip code';
    }
    return errors;
}
/* End User Edit Address Profile */
/* Start Edit emp */
export const validateempedit = (inputsedit,inputsSundayedit,inputsMondayedit,inputsTuesdayedit,inputsWednesdayedit,inputsThursdayedit,inputsFridayedit,inputsSaturdayedit) => {
    const errors = {};
    if (!inputsedit.employee_idedit) {
      errors.employee_idedit = 'Please enter employee id';
    } 
	if (!inputsedit.employee_nameedit) {
      errors.employee_nameedit = 'Please enter employee name';
    }
	if (!inputsedit.employee_emailedit) {
      errors.employee_emailedit = 'Please enter employee email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputsedit.employee_emailedit)
    ){
      errors.employee_emailedit = 'Invalid employee email';
    }
    if(!inputsedit.employee_contact_noedit){
      errors.employee_contact_noedit = 'Please enter employee contact no';
    }
	if(!inputsedit.date_of_birthedit){
      errors.date_of_birthedit = 'Please enter date of birth';
    }
	if(!inputsedit.commissionedit){
      errors.commissionedit = 'Please enter commission';
    }
	var a=0;
	if(inputsSundayedit.statusedit===1){
		a++;
		/*if(inputsSundayedit.startedit==='')
		{
			errors.sunstartedit = 'Please enter sunday start time';
		}
		if(inputsSundayedit.endedit==='')
		{
			errors.sunendedit = 'Please enter sunday end time';
		}*/
	}
	if(inputsMondayedit.statusedit===1){
		a++;
		/*if(inputsMondayedit.startedit==='')
		{
			errors.monstartedit = 'Please enter monday start time';
		}
		if(inputsMondayedit.endedit==='')
		{
			errors.monendedit = 'Please enter monday end time';
		}*/
	}
	if(inputsTuesdayedit.statusedit===1){
		a++;
		/*if(inputsTuesdayedit.startedit==='')
		{
			errors.tuestartedit = 'Please enter tuesday start time';
		}
		if(inputsTuesdayedit.endedit==='')
		{
			errors.tueendedit = 'Please enter tuesday end time';
		}*/
	}
	if(inputsWednesdayedit.statusedit===1){
		a++;
		/*if(inputsWednesdayedit.startedit==='')
		{
			errors.wedstartedit = 'Please enter wednesday start time';
		}
		if(inputsWednesdayedit.endedit==='')
		{
			errors.wedendedit = 'Please enter wednesday end time';
		}*/
	}
	if(inputsThursdayedit.statusedit===1){
		a++;
		/*if(inputsThursdayedit.startedit==='')
		{
			errors.thustartedit = 'Please enter thursday start time';
		}
		if(inputsThursdayedit.endedit==='')
		{
			errors.thuendedit = 'Please enter thursday end time';
		}*/
	}
	if(inputsFridayedit.statusedit===1){
		a++;
		/*if(inputsFridayedit.startedit==='')
		{
			errors.fristartedit = 'Please enter friday start time';
		}
		if(inputsFridayedit.endedit==='')
		{
			errors.friendedit = 'Please enter friday end time';
		}*/
	}
	if(inputsSaturdayedit.statusedit===1){
		a++;
		/*if(inputsSaturdayedit.startedit==='')
		{
			errors.satstartedit = 'Please enter saturday start time';
		}
		if(inputsSaturdayedit.endedit==='')
		{
			errors.satendedit = 'Please enter Saturday end time';
		}*/
	}
	if(a===0){
		errors.sunstart = 'Please select time';
	}
	//console.log(errors);
    return errors;
}
/* End emp */
/* Start User profile Mer */
export const validateprofileemp = (inputs,image,inputsSunday,inputsMonday,inputsTuesday,inputsWednesday,inputsThursday,inputsFriday,inputsSaturday) => {
    const errors = {};
    if (!inputs.name) {
      errors.store_name = 'Please enter name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid email';
    }
	if (!inputs.mobile) {
      errors.mobile = 'Please enter phone number';
    } 
	if (!inputs.service_description) {
      errors.service_description = 'Please enter service description';
    } 
	if(!image.preview){
      errors.image = 'Please select image';
    }
	var a=0;
	if(inputsSunday.status===1){
		a++;
		/*if(inputsSunday.start==='')
		{
			errors.sunstart = 'Please enter sunday start time';
		}
		if(inputsSunday.end==='')
		{
			errors.sunend = 'Please enter sunday end time';
		}*/
	}
	if(inputsMonday.status===1){
		a++;
		/*if(inputsMonday.start==='')
		{
			errors.monstart = 'Please enter monday start time';
		}
		if(inputsMonday.end==='')
		{
			errors.monend = 'Please enter monday end time';
		}*/
	}
	if(inputsTuesday.status===1){
		a++;
		/*if(inputsTuesday.start==='')
		{
			errors.tuestart = 'Please enter tuesday start time';
		}
		if(inputsTuesday.end==='')
		{
			errors.tueend = 'Please enter tuesday end time';
		}*/
	}
	if(inputsWednesday.status===1){
		a++;
		/*if(inputsWednesday.start==='')
		{
			errors.wedstart = 'Please enter wednesday start time';
		}
		if(inputsWednesday.end==='')
		{
			errors.wedend = 'Please enter wednesday end time';
		}*/
	}
	if(inputsThursday.status===1){
		a++;
		/*if(inputsThursday.start==='')
		{
			errors.thustart = 'Please enter thursday start time';
		}
		if(inputsThursday.end==='')
		{
			errors.thuend = 'Please enter thursday end time';
		}*/
	}
	if(inputsFriday.status===1){
		a++;
		/*if(inputsFriday.start==='')
		{
			errors.fristart = 'Please enter friday start time';
		}
		if(inputsFriday.end==='')
		{
			errors.friend = 'Please enter friday end time';
		}*/
	}
	if(inputsSaturday.status===1){
		a++;
		/*if(inputsSaturday.start==='')
		{
			errors.satstart = 'Please enter saturday start time';
		}
		if(inputsSaturday.end==='')
		{
			errors.satend = 'Please enter Saturday end time';
		}*/
	}
	if(a===0){
		errors.sunstart = 'Please select time';
	}
	if(!inputs.password){
      
    }else if (inputs.password && inputs.password.length < 8){
      errors.password = 'Password must be 8 character long';
    }else{
		var pass_err_arr = [];
		if (inputs.password.search(/[a-z]/) < 0) {
			pass_err_arr.push('at least one lower case letter');
		}
		if(inputs.password.search(/[A-Z]/) < 0)
		{
			pass_err_arr.push('one upper case letter');
		}
		if(inputs.password.search(/[0-9]/) < 0)
		{
			pass_err_arr.push('one digit');
		}
		if(inputs.password.search(/[!@#$%^&*_]/) < 0)
		{
			pass_err_arr.push('one special char from -[ ! @ # $ % ^ & * _ ]');
		}
		
		if(pass_err_arr.length > 0){
			var pass_err = pass_err_arr.join(', ');
			errors.password = 'Your password must contain '+pass_err;
		}
		if(inputs.password !== inputs.password_confirmation){
		  errors.password_confirmation = 'Password & confirm password must be same';
		}
	}
	
	
	//console.log(errors);
    return errors;
}
/* End User profile Mer */
/* Start User profile Emp */
export const validateprofileempaftlogin = (inputs,inputsSunday,inputsMonday,inputsTuesday,inputsWednesday,inputsThursday,inputsFriday,inputsSaturday) => {
    const errors = {};
    if (!inputs.name){ 
      errors.store_name = 'Please enter name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid email';
    }
	if (!inputs.mobile) {
      errors.mobile = 'Please enter phone number';
    } 
	if (!inputs.service_description) {
      errors.service_description = 'Please enter service description';
    } 
	var a=0;
	if(inputsSunday.status===1){
		a++;
		/*if(inputsSunday.start==='')
		{
			errors.sunstart = 'Please enter sunday start time';
		}
		if(inputsSunday.end==='')
		{
			errors.sunend = 'Please enter sunday end time';
		}*/
	}
	if(inputsMonday.status===1){
		a++;
		/*if(inputsMonday.start==='')
		{
			errors.monstart = 'Please enter monday start time';
		}
		if(inputsMonday.end==='')
		{
			errors.monend = 'Please enter monday end time';
		}*/
	}
	if(inputsTuesday.status===1){
		a++;
		/*if(inputsTuesday.start==='')
		{
			errors.tuestart = 'Please enter tuesday start time';
		}
		if(inputsTuesday.end==='')
		{
			errors.tueend = 'Please enter tuesday end time';
		}*/
	}
	if(inputsWednesday.status===1){
		a++;
		/*if(inputsWednesday.start==='')
		{
			errors.wedstart = 'Please enter wednesday start time';
		}
		if(inputsWednesday.end==='')
		{
			errors.wedend = 'Please enter wednesday end time';
		}*/
	}
	if(inputsThursday.status===1){
		a++;
		/*if(inputsThursday.start==='')
		{
			errors.thustart = 'Please enter thursday start time';
		}
		if(inputsThursday.end==='')
		{
			errors.thuend = 'Please enter thursday end time';
		}*/
	}
	if(inputsFriday.status===1){
		a++;
		/*if(inputsFriday.start==='')
		{
			errors.fristart = 'Please enter friday start time';
		}
		if(inputsFriday.end==='')
		{
			errors.friend = 'Please enter friday end time';
		}*/
	}
	if(inputsSaturday.status===1){
		a++;
		/*if(inputsSaturday.start==='')
		{
			errors.satstart = 'Please enter saturday start time';
		}
		if(inputsSaturday.end==='')
		{
			errors.satend = 'Please enter Saturday end time';
		}*/
	}
	if(a===0){
		errors.sunstart = 'Please select time';
	}
	//console.log(errors);
    return errors;
}
/* End User profile Emp */
/* Start Customer */
export const validatecustomer = (inputs) => {
    const errors = {};
    if (!inputs.name) {
      errors.name = 'Please enter your full name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter your email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid your email';
    }
    if(!inputs.mobile){
      errors.mobile = 'Please enter your phone number';
    }
	if(!inputs.content){
      errors.content = 'Please enter your message';
    }
    return errors;
}
/* End Customer */
/* Start Customer */
export const validateexistingcustomer = (inputs) => {
    const errors = {};
	if (!inputs.email) {
      errors.email = 'Please enter your email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid your email';
    }
    if(!inputs.mobile){
      errors.mobile = 'Please enter your phone number';
    }
	
    return errors;
}
/* End Customer */
/* Start Coupon step 1 */
export const validatecouponstep1 = (inputs) => {
    const errors = {};
    if (!inputs.coupon_name) {
      errors.coupon_name = 'Please enter coupon name';
    } 

   if(inputs.date_type==='Start & End')
   {
		if(!inputs.start_date){
		  errors.start_date = 'Please enter start date';
		}
		if(!inputs.end_date){
		  errors.end_date = 'Please enter end date';
		}
   }else{
	  if(!inputs.return_before){
		  errors.return_before = 'Please enter return before';
	  } 
   }
  
    return errors;
}
/* End Coupon step 1 */
/* Start Coupon step 2 */
export const validatecouponstep2 = (inputs) => {
    const errors = {};
   if(inputs.coupon_type==='Buy & Get')
   {
	   if(!inputs.buy_qty){
		  errors.buy_qty = 'Please enter buy quantity';
		}
		if(!inputs.item_name_buy){
		  errors.item_name_buy = 'Please enter item name';
		}
	   if(!inputs.get_qty){
		  errors.get_qty = 'Please enter get quantity';
		}
		if(!inputs.item_name){
		  errors.item_name = 'Please enter item name';
		}
		if(!inputs.buy_description){
		  errors.buy_description = 'Please enter description';
		}
		if(!inputs.image){
		  errors.image = 'Please enter image';
		}
   }else if(inputs.coupon_type==='Discount Offer')
   {
	    if(!inputs.discount_percentage){
		  errors.discount_percentage = 'Please enter discount percentage';
		}
		if(!inputs.minimum_purchase_amount){
		  errors.minimum_purchase_amount = 'Please enter minimum purchase amount';
		}
		if(!inputs.applicable_item_if_any){
		  errors.applicable_item_if_any = 'Please enter applicable item if any';
		}
		if(!inputs.discount_description){
		  errors.discount_description = 'Please enter description';
		}
		if(!inputs.image){
		  errors.image = 'Please enter image';
		}
   }else if(inputs.coupon_type==='Loyalty Setup'){
	   if(!inputs.buy_item_name){
		  errors.buy_item_name = 'Please enter buy item name';
		}
		if(!inputs.buy_qty_need){
		  errors.buy_qty_need = 'Please enter buy quantity needed';
		}
		if(!inputs.get_item_name){
		  errors.get_item_name = 'Please enter get item name';
		}
		if(!inputs.loyalty_description){
		  errors.loyalty_description = 'Please enter description';
		}
		if(!inputs.image){
		  errors.image = 'Please enter image';
		}
   }else if(inputs.coupon_type==='Invitation')
   {
		if(!inputs.item_name){
		  errors.item_name = 'Please enter item name';
		}
		if(!inputs.buy_description){
		  errors.buy_description = 'Please enter description';
		}
		if(!inputs.image){
		  errors.image = 'Please enter image';
		}
   }
    return errors;
}
/* End Coupon step 2 */
/* Start Coupon step 3 */
export const validatecouponstep3 = (inputs,selected) => {
    const errors = {};
    /*if(!inputs.promotion_name){
	   errors.promotion_name = 'Please enter promotion name';
	}*/
	if(!inputs.select_option){
	   errors.select_option = 'Please enter select option';
	}
	if(inputs.target_customers==='Based on Criteria')
    {
		if(inputs.days_type==='search_customer')
		{
			if(!inputs.past_days){
				errors.past_days = 'Please enter visited in the past days';
		    }
			if(!inputs.amount){
				errors.amount = 'Please enter Spent Over Amount';
		    }
		}else if(inputs.days_type==='not_returned'){
			if(!inputs.days){
				errors.days = 'Please enter returned in the past days';
		    }
		}
    }else if(inputs.target_customers==='Selected Customer'){
		if(selected.length < 1)
		{
			errors.zip_code = 'Please enter zip codes';
		}
	}
    return errors;
}
/* End Coupon step 3 */
/* Start Coupon step 3 */
export const validatemarketing = (inputs) => {
    const errors = {};
    if(!inputs.discount_offer){
	   errors.discount_offer = 'Please enter discount offer';
	}
	
    return errors;
}
/* End Coupon step 3 */