import React, { useEffect,useState } from 'react';
import { Row, Col, Container,} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserIntmrct.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import copy from 'clipboard-copy';

const UserIntmrct = () => {
const [inputs,setInputs] = useState({url:'',emailLink:'',whatsappLink:'',phonelink:''});



useEffect(() => {
      document.title = `User Invite Merchants | Blue Faucet`;
      document.getElementById('bodywrap').className='usrivmrcntpgmain';

		 var url=process.env.REACT_APP_BASE_URL+'mer-store-setup?key='+localStorage.getItem("token")+'&type=direct';
		 
		 const emailLink = `mailto:?subject=Check%20out%20this%20link&body=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
 const whatsappLink = `https://api.whatsapp.com/send?text=Check%20out%20this%20link:%20`+url;
 const phonelink=`sms:?body=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
	//const phone_link=`tel:`+url;	 
		setInputs({
				  url:url,
				  emailLink:emailLink,
				  whatsappLink:whatsappLink,
				  phonelink:phonelink
			  }); 

     /*let copyText = document.querySelector(".copy-text");
copyText.querySelector("button").addEventListener("click", function () {
  let input = copyText.querySelector("input.text");
  input.select();
  document.execCommand("copy");
  copyText.classList.add("active");
  window.getSelection().removeAllRanges();
  setTimeout(function () {
    copyText.classList.remove("active");
  }, 2500);
});*/


    window.scrollTo({top: 0,left: 0,behavior: 'smooth', }); 
    
},[]) 

const copyToClipboard = () => {
    const linkToCopy = inputs.url

    copy(linkToCopy)
      .then(() => {
        let copyText = document.querySelector(".copy-text");
		copyText.classList.add("active");
		setTimeout(function () {
			copyText.classList.remove("active");
		  }, 2500);
      })
      .catch((error) => {
        console.error('Copy failed: ', error);
      });
  };
 

    return (
        <>
        {/* ========== User Invite Merchant Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={'Invite Merchants'}/>
         {/* ========== Navbar End ========== */}
           <Container>
              <Row className="mx-0 mianhmdwncnt">
                 <Col className="mnhmcnt">
                 <Col md={12} xs={12} className="py-3">
                 <Col className="mb-3 px-0 usrmrcnt-tpcnt">
                  <Row className="mx-0 cuscoupn cupndwnhm">
                    <Col className="align-self-start">
                     <h3>Make$60 Commission! Click here to find out more detail</h3>
                    </Col>
                  </Row>
                 </Col>
                 {/* ========== Share Box Start ========== */}
                 <Row className="mx-0 shrelist">
                    <Col md={12} xs={12} className="my-2">
                    <h3>Share Via</h3>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to={inputs.phonelink}>
                       <img src= {'assets/images/svg/shr-icn1.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>To Contact</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to={inputs.emailLink}>
                       <img src= {'assets/images/svg/shr-icn2.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Gmail</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to={inputs.emailLink}>
                       <img src= {'assets/images/svg/shr-icn3.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Outlook</p>
                       </div>
                    </NavLink>
                    </Col>
                    <Col md={3} xs={3} className="shrelist-mincnt text-center">
                    <NavLink to={inputs.whatsappLink}>
                       <img src= {'assets/images/svg/shr-icn4.svg'} className="img-fluid" alt="Sld Img"/>
                       <div className="nm-shr">
                          <p>Whatsapp</p>
                       </div>
                    </NavLink>
                    </Col>
                    
                    
                    <Col md={12} xs={12} className="cpytxtdv">
                    <h4>Or copy link</h4>
                    <div className="copy-text">
                       <input type="text" className="text" value={inputs.url} disabled/>
                       <button onClick={copyToClipboard}>Copy</button>
                    </div>
                    </Col>
                 </Row>
                 {/* ========== Share Box End ========== */}
                 </Col>
                 </Col>
              </Row>
           </Container>
        </section>
        {/* ========== User Invite Merchant Page End ========== */}
        </>
    )
}

export default UserIntmrct;