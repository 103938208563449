import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Nav, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import './MerSelling.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
import API from '../../../Utils/Api';
import {validateproduct,validateservice,validateproductedit,validateserviceedit} from '../../../Utils/validate';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const MerSelling = () => {
const [loading,setLoading] = useState(true);
const navigate = useNavigate();

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);


const [showedit, setShowedit] = useState(false);
const handleCloseedit = () => setShowedit(false);

const [showunl, setShowunl] = useState(true);
const [showunledit, setShowunledit] = useState(true);

const [show1edit, setShow1edit] = useState(false);
const handleClose1edit = () => setShow1edit(false);



const [category, setCategory] = useState([]);

const [inputs,setInputs] = useState({name:'',category_id:'',price:'',quantity:'',unlimited_quantity:0,description:'',special_instructions:'',type:'all'});
const [image1, setImage1] = useState({preview: '', raw: ''});
const [image2, setImage2] = useState({preview: '', raw: ''});
const [image3, setImage3] = useState({preview: '', raw: ''});
const [image4, setImage4] = useState({preview: '', raw: ''});
const [image5, setImage5] = useState({preview: '', raw: ''});

const [inputsser,setInputsser] = useState({nameser:'',priceser:'',duration_in_minutesser:'',descriptionser:'',special_instructionsser:''});
const [imageser1, setImageser1] = useState({preview: '', raw: ''});
const [imageser2, setImageser2] = useState({preview: '', raw: ''});
const [imageser3, setImageser3] = useState({preview: '', raw: ''});
const [imageser4, setImageser4] = useState({preview: '', raw: ''});
const [imageser5, setImageser5] = useState({preview: '', raw: ''});


const [inputsedit,setInputsedit] = useState({idedit:'',nameedit:'',category_idedit:'',priceedit:'',quantityedit:'',unlimited_quantityedit:0,descriptionedit:'',special_instructionsedit:''});
const [image1edit, setImage1edit] = useState({preview: '', raw: ''});
const [image2edit, setImage2edit] = useState({preview: '', raw: ''});
const [image3edit, setImage3edit] = useState({preview: '', raw: ''});
const [image4edit, setImage4edit] = useState({preview: '', raw: ''});
const [image5edit, setImage5edit] = useState({preview: '', raw: ''});

const [inputsseredit,setInputsseredit] = useState({idseredit:'',nameseredit:'',priceseredit:'',duration_in_minutesseredit:'',descriptionseredit:'',special_instructionsseredit:''});
const [imageser1edit, setImageser1edit] = useState({preview: '', raw: ''});
const [imageser2edit, setImageser2edit] = useState({preview: '', raw: ''});
const [imageser3edit, setImageser3edit] = useState({preview: '', raw: ''});
const [imageser4edit, setImageser4edit] = useState({preview: '', raw: ''});
const [imageser5edit, setImageser5edit] = useState({preview: '', raw: ''});

const [error,setError] = useState();
const [success,setSuccess] = useState();
const [errors,setErrors] = useState({});

const [product, setProduct] = useState([]);
const [id, setId] = useState();

useEffect(() => {
      document.title = ` Merchant Selling | Blue Faucet`;
      document.getElementById('bodywrap').className='slngpgmain grnbg grenicn';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });

	/* Start get category */
		API.get('category')
			.then(response => {
				
			if(response.data.status === 0){
				
			}else{
			  setCategory(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	/* End get category */
	
	/* Start get product data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'all');
		API.post('get_all_product_or_service_by_merchant_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setProduct(response.data.data);
			}                  
		}).catch(error => {
			 setLoading(false);
		});  
	 /* End get product data */
	 
	 /* Start get profile */
	let formData2 = new FormData(); 
		formData2.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData2)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				
			setInputs({name:'',category_id:response.data.data.category_id,price:'',quantity:'',unlimited_quantity:0,description:'',special_instructions:'',type:'all'});
			}                  
		}).catch(error => {
			 
		});  
	 /* End get profile */
	
},[])  


/* Start Search Value set */
const handleInputSearchChange = (event) => {
	 setLoading(true);
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value})); 
	  let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', event.target.value);
		API.post('get_all_product_or_service_by_merchant_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				setProduct([]);
			}else{
			   setProduct(response.data.data);
			}                  
		}).catch(error => {
			 setLoading(false);
		});  
}
/* End Search Value set */

/* Start Value set */
const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value})); 
}


const handleInputChangedes = (event) => {
	   event.persist();
	const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputs(inputs => ({...inputs, [event.target.name]: inputText}));
    }	  
}
const handleInputChangeIns = (event) => {
	   event.persist();
	const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputs(inputs => ({...inputs, [event.target.name]: inputText}));
    }	  
}


const handlePriceInputChange = (event) => {
      event.persist();
	  const inputValue = event.target.value;
	  
	  if(inputValue===''){
		 
		  setInputs(inputs => ({...inputs, [event.target.name]: ''}));
	  }else{
		  
	  if (inputValue.match(/^[\d.]+$/)) {
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
	  }
	  }	  
}
const handleCheckboxChange = (event) => {
	var lbl='unlimited_quantity';
	if(event.target.checked)
	{
		setInputs(inputs => ({...inputs, [lbl]: 1}));
		setShowunl(false);
	}else{
		setInputs(inputs => ({...inputs, [lbl]: 0}));
		setShowunl(true);
	}
};
const handleCheckboxChangeedit = (event) => {
	var lbl='unlimited_quantityedit';
	if(event.target.checked)
	{
		setInputsedit(inputsedit => ({...inputsedit, [lbl]: 1}));
		setShowunledit(false);
	}else{
		setInputsedit(inputsedit => ({...inputsedit, [lbl]: 0}));
		setShowunledit(true);
	}
};
const handleInputChangeedit = (event) => {
      event.persist();
      setInputsedit(inputs => ({...inputs, [event.target.name]: event.target.value})); 
}
const handleInputChangeeditdes = (event) => {
      event.persist();
	  const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputsedit(inputsedit => ({...inputsedit, [event.target.name]: inputText}));
    }	  
}
const handleInputChangeeditins = (event) => {
      event.persist();
	  const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputsedit(inputsedit => ({...inputsedit, [event.target.name]: inputText}));
    }	  
}
const handlePriceInputChangeedit = (event) => {
      event.persist();
	  const inputValue = event.target.value;
	  
	  
	  if(inputValue===''){
		 
		  setInputsedit(inputs => ({...inputs, [event.target.name]: ''}));
	  }else{
		  
	  if (inputValue.match(/^[\d.]+$/)) {
        setInputsedit(inputs => ({...inputs, [event.target.name]: event.target.value}));
	  }
	  }	 
}
/* End Value set */
/* Start image set */
const handleChange1 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
	  setImage1({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});		 
	}
}
const handleChange2 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
	  setImage2({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChange3 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImage3({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});	
	}
}
const handleChange4 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		setImage4({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});	  
	}
}
const handleChange5 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImage5({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});	  
	}
}
const handleChange1edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
	  setImage1edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});		 
	}
}
const handleChange2edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
	  setImage2edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChange3edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImage3edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});	
	}
}
const handleChange4edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		setImage4edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});	  
	}
}
const handleChange5edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImage5edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});	  
	}
}
/* End image set */
/* Start Service Value set */
const handleInputChangeser = (event) => {
      event.persist();
      setInputsser(inputsser => ({...inputsser, [event.target.name]: event.target.value})); 
}
const handleInputChangeserdes = (event) => {
     event.persist();
	  
	  const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputsser(inputsser => ({...inputsser, [event.target.name]: inputText}));
    }	   
}

const handleInputChangeserins = (event) => {
     event.persist();
	  
	  const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputsser(inputsser => ({...inputsser, [event.target.name]: inputText}));
    }	   
}


const handlePriceInputChangeser = (event) => {
      event.persist();
	  const inputValue = event.target.value;

	  if(inputValue===''){
		 
		  setInputsser(inputsser => ({...inputsser, [event.target.name]: ''}));
	  }else{
		  
	  if (inputValue.match(/^[\d.]+$/)) {
        setInputsser(inputsser => ({...inputsser, [event.target.name]: event.target.value}));
	  }
	  }	  
}
const handleInputChangeseredit = (event) => {
      event.persist();
      setInputsseredit(inputsseredit => ({...inputsseredit, [event.target.name]: event.target.value})); 
}
const handleInputChangesereditdes = (event) => {
      event.persist();
	  
	  const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputsseredit(inputsseredit => ({...inputsseredit, [event.target.name]: inputText}));
    }	  
}
const handleInputChangesereditins = (event) => {
      event.persist();
	  
	  const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputsseredit(inputsseredit => ({...inputsseredit, [event.target.name]: inputText}));
    }	  
}
const handlePriceInputChangeseredit = (event) => {
      event.persist();
	  const inputValue = event.target.value;
		
		if(inputValue===''){
		 
		  setInputsseredit(inputsseredit => ({...inputsseredit, [event.target.name]: ''}));
	  }else{
		  
	  if (inputValue.match(/^[\d.]+$/)) {
        setInputsseredit(inputsseredit => ({...inputsseredit, [event.target.name]: event.target.value}));
	  }
	  }	    
}
/* End Service Value set */
/* Start Service image set */
const handleChangeser1 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
	  setImageser1({
		preview: URL.createObjectURL(event.target.files[0]),
		raw: event.target.files[0]
	});
	}
}
const handleChangeser2 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImageser2({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChangeser3 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImageser3({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});		
	}
}
const handleChangeser4 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImageser4({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChangeser5 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImageser5({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});		
	}
}
const handleChangeser1edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
	  setImageser1edit({
		preview: URL.createObjectURL(event.target.files[0]),
		raw: event.target.files[0]
	});
	}
}
const handleChangeser2edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImageser2edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChangeser3edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImageser3edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});		
	}
}
const handleChangeser4edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImageser4edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChangeser5edit = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImageser5edit({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});		
	}
}
/* End Service image set */
/* Start Submit Product */
	const handleSubmit = (event) => {
		event.preventDefault();
		const validationErrors = validateproduct(inputs,image1,image2,image3,image4,image5);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let name=inputs.name;
			let category_id=inputs.category_id;
			let price=inputs.price;
			let quantity=inputs.quantity;
			let unlimited_quantity=inputs.unlimited_quantity;
			let description=inputs.description;
			let special_instructions=inputs.special_instructions;
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('name', name);
			formData.append('category_id', category_id);
			formData.append('price', price);
			formData.append('quantity', quantity);
			formData.append('unlimited_quantity', unlimited_quantity);
			formData.append('description', description);
			formData.append('special_instructions', special_instructions);
			formData.append('product_type', 'product');
			
			if(image1.raw!=='')
			{
				formData.append('image1', image1.raw);
			}
			if(image2.raw!=='')
			{
				formData.append('image2', image2.raw);
			}
			if(image3.raw!=='')
			{
				formData.append('image3', image3.raw);
			}
			if(image4.raw!=='')
			{
				formData.append('image4', image4.raw);
			}
			if(image5.raw!=='')
			{
				formData.append('image5', image5.raw);
			}
			API.post('add_product_or_service', formData)
                .then(response => {
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
					 setShow(false);
                }else{
					setLoading(false);
					 //alert(response.data.message);
				    setShow(false);
					//window.location.href = "/mer-store";
					gettoastsuccess(response.data.message,'/mer-store');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
				   setShow(false);
			});  
		}
	}
/* End Submit Product */
/* Start product edit */
const handleShowedit = (id) => {
	setLoading(true);
	
	setShowedit(true);
	
	let formData = new FormData(); 
	   formData.append('token', localStorage.getItem("token"));
		formData.append('product_id', id);
		API.post('get_product_or_serivce_by_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				if(response.data.data.unlimited_quantity===1)
				{
					setShowunledit(false);
				}
			  setInputsedit({
				  idedit:id,
				  nameedit:response.data.data.name,
				  category_idedit:response.data.data.category_id,
				  priceedit:response.data.data.price,
				  quantityedit:response.data.data.quantity,
				  descriptionedit:response.data.data.description,
				  special_instructionsedit:response.data.data.special_instructions,
				  unlimited_quantityedit:response.data.data.unlimited_quantity,
			  });
			  if(response.data.data.image1!=='')
			  {
				  setImage1edit({
						preview: response.data.data.image1,
						raw:'' 
					});
              }
			  if(response.data.data.image2!=='')
			  {
				  setImage2edit({
						preview: response.data.data.image2,
						raw:'' 
					});
              }
              if(response.data.data.image3!=='')
			  {
				  setImage3edit({
						preview: response.data.data.image3,
						raw:'' 
					});
              }
              if(response.data.data.image4!=='')
			  {
				  setImage4edit({
						preview: response.data.data.image4,
						raw:'' 
					});
              }	
              if(response.data.data.image5!=='')
			  {
				  setImage5edit({
						preview: response.data.data.image5,
						raw:'' 
					});
              }				  
			}                  
		}).catch(error => {
			 
		});  
	
}
/* End product edit */
/* Start Submit update Product */
	const handleSubmitedit = (event) => {
		event.preventDefault();
		const validationErrors = validateproductedit(inputsedit,image1edit,image2edit,image3edit,image4edit,image5edit);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let id=inputsedit.idedit;
			let name=inputsedit.nameedit;
			let category_id=inputsedit.category_idedit;
			let price=inputsedit.priceedit;
			let quantity=inputsedit.quantityedit;
			let unlimited_quantity=inputsedit.unlimited_quantityedit;
			let description=inputsedit.descriptionedit;
			let special_instructions=inputsedit.special_instructionsedit;
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('id', id);
			formData.append('name', name);
			formData.append('category_id', category_id);
			formData.append('price', price);
			formData.append('quantity', quantity);
			formData.append('unlimited_quantity', unlimited_quantity);
			formData.append('description', description);
			formData.append('special_instructions', special_instructions);
			formData.append('product_type', 'product');
			
			if(image1edit.raw!=='')
			{
				formData.append('image1', image1edit.raw);
			}
			if(image2edit.raw!=='')
			{
				formData.append('image2', image2edit.raw);
			}
			if(image3edit.raw!=='')
			{
				formData.append('image3', image3edit.raw);
			}
			if(image4edit.raw!=='')
			{
				formData.append('image4', image4edit.raw);
			}
			if(image5.raw!=='')
			{
				formData.append('image5', image5edit.raw);
			}
			API.post('edit_product_or_service', formData)
                .then(response => {
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
					 setShow(false);
                }else{
					setLoading(false);
					 //alert(response.data.message);
				    setShow(false);
					//window.location.href = "/mer-store";
					gettoastsuccess(response.data.message,'/mer-store');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
				   setShow(false);
			});  
		}
	}
/* End Submit update Product */
/* Start Submit Service */
	const handleSerSubmit = (event) => {
		event.preventDefault();
		const validationErrors = validateservice(inputsser,imageser1,imageser2,imageser3,imageser4,imageser5);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let name=inputsser.nameser;
			let price=inputsser.priceser;
			let duration_in_minutes=inputsser.duration_in_minutesser;
			let description=inputsser.descriptionser;
			let special_instructions=inputsser.special_instructionsser;
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('name', name);
			formData.append('price', price);
			formData.append('duration_in_minutes', duration_in_minutes);
			formData.append('description', description);
			formData.append('special_instructions', special_instructions);
			formData.append('product_type', 'service');
			
			if(imageser1.raw!=='')
			{
				formData.append('image1', imageser1.raw);
			}
			if(imageser2.raw!=='')
			{
				formData.append('image2', imageser2.raw);
			}
			if(imageser3.raw!=='')
			{
				formData.append('image3', imageser3.raw);
			}
			if(imageser4.raw!=='')
			{
				formData.append('image4', imageser4.raw);
			}
			if(imageser5.raw!=='')
			{
				formData.append('image5', imageser5.raw);
			}
			API.post('add_product_or_service', formData)
                .then(response => {
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
					 setShow1(false);
                }else{
					//setLoading(false);
					 //alert(response.data.message);
				    setShow1(false);
					//window.location.href = "/mer-store";
					gettoastsuccess(response.data.message,'/mer-store');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
				   setShow1(false);
			});  
		}
	}
/* End Submit Service */
/* Start service edit */
const handleShow1edit = (id) => {
	setLoading(true);
	
	setShow1edit(true);
	
	let formData = new FormData(); 
	   formData.append('token', localStorage.getItem("token"));
		formData.append('product_id', id);
		API.post('get_product_or_serivce_by_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			
			  setInputsseredit({
				  idseredit:id,
				  nameseredit:response.data.data.name,
				  priceseredit:response.data.data.price,
				  duration_in_minutesseredit:response.data.data.duration_in_minutes,
				  descriptionseredit:response.data.data.description,
				  special_instructionsseredit:response.data.data.special_instructions
			  });
			  if(response.data.data.image1!=='')
			  {
				  setImageser1edit({
						preview: response.data.data.image1,
						raw:'' 
					});
              }
			  if(response.data.data.image2!=='')
			  {
				  setImageser2edit({
						preview: response.data.data.image2,
						raw:'' 
					});
              }
              if(response.data.data.image3!=='')
			  {
				  setImageser3edit({
						preview: response.data.data.image3,
						raw:'' 
					});
              }
              if(response.data.data.image4!=='')
			  {
				  setImageser4edit({
						preview: response.data.data.image4,
						raw:'' 
					});
              }	
              if(response.data.data.image5!=='')
			  {
				  setImageser5edit({
						preview: response.data.data.image5,
						raw:'' 
					});
              }				  
			}                  
		}).catch(error => {
			 
		});  
	
}
/* End service edit */
/* Start Submit Edit Service */
	const handleSerSubmitedit = (event) => {
		event.preventDefault();
		const validationErrors = validateserviceedit(inputsseredit,imageser1edit,imageser2edit,imageser3edit,imageser4edit,imageser5edit);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let id=inputsseredit.idseredit;
			let name=inputsseredit.nameseredit;
			let price=inputsseredit.priceseredit;
			let duration_in_minutes=inputsseredit.duration_in_minutesseredit;
			let description=inputsseredit.descriptionseredit;
			let special_instructions=inputsseredit.special_instructionsseredit;
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('id', id);
			formData.append('name', name);
			formData.append('price', price);
			formData.append('duration_in_minutes', duration_in_minutes);
			formData.append('description', description);
			formData.append('special_instructions', special_instructions);
			formData.append('product_type', 'service');
			
			if(imageser1edit.raw!=='')
			{
				formData.append('image1', imageser1edit.raw);
			}
			if(imageser2edit.raw!=='')
			{
				formData.append('image2', imageser2edit.raw);
			}
			if(imageser3edit.raw!=='')
			{
				formData.append('image3', imageser3edit.raw);
			}
			if(imageser4edit.raw!=='')
			{
				formData.append('image4', imageser4edit.raw);
			}
			if(imageser5edit.raw!=='')
			{
				formData.append('image5', imageser5edit.raw);
			}
			API.post('edit_product_or_service', formData)
                .then(response => {
					setLoading(false);
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
					 setShow1edit(false);
                }else{
					//setLoading(false);
					 //alert(response.data.message);
				    setShow1edit(false);
					//window.location.href = "/mer-store";
					gettoastsuccess(response.data.message,'/mer-store');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
				   setShow1edit(false);
			});  
		}
	}
/* End Submit Edit Service */
/* Start show delete varification modal */
const handleShow2 = (id) => {	
		setId(id);
	setShow2(true);
}
/* End show delete varification modal */
/* Start delete product */
const handleDelete = () => {
	setLoading(true);
	setShow2(false);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('product_id', id);
	API.post('delete_product_or_service_by_product_id', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			const updatedProduct = product.filter((pro) => pro.id !== id);
				setProduct(updatedProduct);
		}                  
	}).catch(error => {
		setError("Something went wrong. Please try again later.");
		 setLoading(false);
	});  
}
/* End delete product */

    return (
        <>
      {/* ========== Merchant Selling Page Start ========== */}
         <section className="gen-wrap">
		 <ToastContainer />
      {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt">
            <Col xs={2} md={2} className="align-self-center px-0">
            <button onClick={() =>
               navigate(-1)} className="back-btn d-none">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </button>
            </Col>
            <Col xs={12} md={8} className="align-self-center text-center">
            <h3>Product/Service Setup</h3>
            </Col>
            <Col xs={2} md={2} className="align-self-center text-end mcustmrsw slctbtnsw">
            <Button id="btn2" className="" href={void(0)} variant="" onClick={handleShow}><img src="assets/images/merchant/svg/plus-icn.svg" className="img-fluid" alt="Logo"/></Button>{' '}
            </Col>
         </Row>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="tpfixgrn">
               <Col sm={5} xs={8} className="">
               <Form.Select aria-label="Default select example" name="type" onChange={handleInputSearchChange}>
                  <option value='all'>Display All</option>
                  <option value="service">Display Services</option>
                  <option value="product">Display Products</option>
               </Form.Select>
               </Col>
               <Col md={7} xs={7} className="text-end btntpfx">
               </Col>
			   <Col md={12} xs={12} >
			   {error && (
					<div className="alert alert-danger">
					  {error}
					</div>)}
				  {success && (
					<div className="alert alert-success">
					  {success}
					</div>)}
			  </Col>
            </Row>
      {/* ========== Selling Tab Start ========== */}
            <Tab.Container id="" defaultActiveKey="first">
               <Nav variant="pills" className="tbgren">
                  <Row className="tbrw mx-0">
                     <Col md={6} xs={6} className="">
                     <Nav.Item>
                        <Nav.Link eventKey="first" onClick={handleShow1}>Add Service</Nav.Link>
                     </Nav.Item>
                     </Col>
                     <Col md={6} xs={6} className="">
                     <Nav.Item>
                        <Nav.Link eventKey="second" onClick={handleShow}>Add Product</Nav.Link>
                     </Nav.Item>
                     </Col>
                  </Row>
               </Nav>
            </Tab.Container>
      {/* ========== Selling Tab Start ========== */}
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt ">
      {/* ========== Selling Main Content Start ========== */}
	          {product.length >0 ? (
               <Col md={12} xs={12} className="">
              {product && product.map((val,index) => {
				return(
               <Col md={12} xs={12} className="mcusbx mer-sp-box">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-0 pe-md-1">
					  {val.product_type==='service' ?(
							<NavLink to="#" onClick={() => handleShow1edit(val.id)}><img src={val.image[0].image} className="img-fluid img-mer" alt="Mcust Img"/></NavLink>
						):(
							<NavLink to="#" onClick={() => handleShowedit(val.id)}><img src={val.image[0].image} className="img-fluid img-mer" alt="Mcust Img"/></NavLink>
					   )}
                  </Col>
                  <Col md={8} xs={8} className="align-self-center mcusbx-txtcnt ps-md-1 ">
                  <h3>
				  {val.product_type==='service' ?(
                     <NavLink to="#" onClick={() => handleShow1edit(val.id)}>{val.name}</NavLink>
				  ):(
					<NavLink to="#" onClick={() => handleShowedit(val.id)}>{val.name}</NavLink>
				  )}
                  </h3>
                  <p>${val.price}</p>
                  </Col>
                  <Col md={2} xs={2} className="align-self-center text-end">
                  <NavLink to="#" onClick={() =>handleShow2(val.id)}><img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/></NavLink>
                  </Col>
               </Row>
               </Col>
              )
			  })}
               </Col>
			   ) : (
				<Col md={12} xs={12} className="">
					<p className="text-center mt-4">No data found</p>
				</Col>
			  )} 
               </Col>
      {/* ========== Selling Main Content End ========== */}
            </Row>
            <Col>
            <div className="madcustmrpop">
      {/* ========== Selling Add Product Popup Start ========== */}
               <Modal show={show} onHide={handleClose} className="popupwrngcnt" centered>
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Add Product</h3>
                  </Modal.Header>
                  <Modal.Body>
				  <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                     <Col md={12} xs={12} className="ppinpt">
                     
                        <Form.Group className="mb-3">
                           <Form.Label>Product Name</Form.Label>
                           <Form.Control type="text" name="name" placeholder="Enter product name" value={inputs.name} onChange={handleInputChange}/>
						   {errors.name && (<p className="text-danger">{errors.name}</p>)}
                        </Form.Group>
                        <Col className="upladcustm">
                        <Form.Label>Select Image</Form.Label>
                        <Row>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image1.preview ? (
							  <> 
								<img src={image1.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="image1" accept=".png, .jpg, .jpeg" onChange={handleChange1} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image2.preview ? (
							  <> 
								<img src={image2.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							  </>
								)}
							  </div>
                              <input type="file" name="image2" accept=".png, .jpg, .jpeg" onChange={handleChange2}/>
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image3.preview ? (
							  <> 
								<img src={image3.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="image3" accept=".png, .jpg, .jpeg" onChange={handleChange3} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							   {image4.preview ? (
							  <> 
								<img src={image4.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
							     <img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> 
							   </>
								)}
							  </div>
                              <input type="file" name="image4" accept=".png, .jpg, .jpeg" onChange={handleChange4} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							   {image5.preview ? (
							  <> 
								<img src={image5.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
							    <img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> 
							   </>
								)}
							  </div>
                              <input type="file" name="image5" accept=".png, .jpg, .jpeg" onChange={handleChange5} />
                           </div>
						   
                           </Col>  
							{errors.image1 && (<p className="text-danger">{errors.image1}</p>)}
						   {errors.image2 && (<p className="text-danger">{errors.image2}</p>)}
						   {errors.image3 && (<p className="text-danger">{errors.image3}</p>)}
						   {errors.image4 && (<p className="text-danger">{errors.image4}</p>)}
						   {errors.image5 && (<p className="text-danger">{errors.image5}</p>)}						   
                        </Row>
                        </Col>
                        <Form.Group className="mb-3">
                           <Form.Label>Category</Form.Label>
                           <Form.Select aria-label="Default select example" name="category_id" value={inputs.category_id} onChange={handleInputChange} disabled >
                              <option value="">Select Category</option>
							  {category.map((val) => {
							return (
                              <option value={val.id}>{val.name}</option>
							 )
							})
							}
                           </Form.Select>
						   {errors.category_id && (<p className="text-danger">{errors.category_id}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Price ($)</Form.Label>
                           <Form.Control type="text" placeholder="$00.00" name="price" value={inputs.price} onChange={handlePriceInputChange}/>
							{errors.price && (<p className="text-danger">{errors.price}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Availability   </Form.Label>
						   <span className="float-end"><input type="Checkbox" name="unlimited_quantity" onChange={handleCheckboxChange} checked={inputs.unlimited_quantity} /> Unlimited Quantity</span>
							   {showunl && ( 
						   <Form.Control type="text" placeholder="Enter Availability" name="quantity" value={inputs.quantity} onChange={handlePriceInputChange}/>
							   )}
							{errors.quantity && (<p className="text-danger">{errors.quantity}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Description (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your description here" name="description" value={inputs.description} onChange={handleInputChangedes}/>
							{errors.description && (<p className="text-danger">{errors.description}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Special Instructions (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your special instruction here" name="special_instructions" value={inputs.special_instructions} onChange={handleInputChangeIns}/>
							{errors.special_instructions && (<p className="text-danger">{errors.special_instructions}</p>)}
						</Form.Group>
                     
                     </Col>
                     <Col md={12} xs={12} className="btnpopmcstmr my-3">
						<Button type="submit">Save</Button>
                     </Col>
					 </Form>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Selling Add Product Popup End ========== */}
	  {/* ========== Selling Edit Product Popup Start ========== */}
               <Modal show={showedit} onHide={handleCloseedit} className="popupwrngcnt" centered>
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Edit Product</h3>
                  </Modal.Header>
                  <Modal.Body>
				  <Form method="post" onSubmit={handleSubmitedit} enctype="multipart/form-data">
                     <Col md={12} xs={12} className="ppinpt">
                     
                        <Form.Group className="mb-3">
                           <Form.Label>Product Name</Form.Label>
                           <Form.Control type="text" name="nameedit" placeholder="Enter product name" value={inputsedit.nameedit} onChange={handleInputChangeedit}/>
						   {errors.nameedit && (<p className="text-danger">{errors.nameedit}</p>)}
                        </Form.Group>
                        <Col className="upladcustm">
                        <Form.Label>Select Image</Form.Label>
                        <Row>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image1edit.preview ? (
							  <> 
								<img src={image1edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="image1edit" accept=".png, .jpg, .jpeg" onChange={handleChange1edit} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image2edit.preview ? (
							  <> 
								<img src={image2edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							  </>
								)}
							  </div>
                              <input type="file" name="image2edit" accept=".png, .jpg, .jpeg" onChange={handleChange2edit}/>
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image3edit.preview ? (
							  <> 
								<img src={image3edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="image3edit" accept=".png, .jpg, .jpeg" onChange={handleChange3edit} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							   {image4edit.preview ? (
							  <> 
								<img src={image4edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
							     <img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> 
							   </>
								)}
							  </div>
                              <input type="file" name="image4edit" accept=".png, .jpg, .jpeg" onChange={handleChange4edit} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							   {image5edit.preview ? (
							  <> 
								<img src={image5edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
							    <img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> 
							   </>
								)}
							  </div>
                              <input type="file" name="image5edit" accept=".png, .jpg, .jpeg" onChange={handleChange5edit} />
                           </div>
						   
                           </Col>  
							{errors.image1edit && (<p className="text-danger">{errors.image1edit}</p>)}
						   {errors.image2edit && (<p className="text-danger">{errors.image2edit}</p>)}
						   {errors.image3edit && (<p className="text-danger">{errors.image3edit}</p>)}
						   {errors.image4edit && (<p className="text-danger">{errors.image4edit}</p>)}
						   {errors.image5edit && (<p className="text-danger">{errors.image5edit}</p>)}						   
                        </Row>
                        </Col>
                        <Form.Group className="mb-3">
                           <Form.Label>Category</Form.Label>
                           <Form.Select aria-label="Default select example" name="category_idedit" value={inputsedit.category_idedit} onChange={handleInputChangeedit} disabled>
                              <option value="">Select Category</option>
							  {category.map((val) => {
							return (
                              <option value={val.id}>{val.name}</option>
							 )
							})
							}
                           </Form.Select>
						   {errors.category_idedit && (<p className="text-danger">{errors.category_idedit}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Price ($)</Form.Label>
                           <Form.Control type="text" placeholder="$00.00" name="priceedit" value={inputsedit.priceedit} onChange={handlePriceInputChangeedit}/>
							{errors.priceedit && (<p className="text-danger">{errors.priceedit}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Availability</Form.Label>
						   <span className="float-end"><input type="Checkbox" name="unlimited_quantityedit" onChange={handleCheckboxChangeedit} checked={inputsedit.unlimited_quantityedit} /> Unlimited Quantity</span>
							   {showunledit && (
						   <Form.Control type="text" placeholder="Enter Availability" name="quantityedit" value={inputsedit.quantityedit} onChange={handlePriceInputChangeedit}/>
							   )}
							{errors.quantityedit && (<p className="text-danger">{errors.quantityedit}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Description (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your description here" name="descriptionedit" value={inputsedit.descriptionedit} onChange={handleInputChangeeditdes}/>
							{errors.descriptionedit && (<p className="text-danger">{errors.descriptionedit}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Special Instructions (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your special instruction here" name="special_instructionsedit" value={inputsedit.special_instructionsedit} onChange={handleInputChangeeditins}/>
							{errors.special_instructionsedit && (<p className="text-danger">{errors.special_instructionsedit}</p>)}
						</Form.Group>
                     
                     </Col>
                     <Col md={12} xs={12} className="btnpopmcstmr my-3">
						<Button type="submit">Save</Button>
                     </Col>
					 </Form>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Selling Edit Product Popup End ========== */}
            </div>
            <div className="madcustmrpop2">
      {/* ========== Selling Add Service Popup Start ========== */}
               <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" centered>
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Add Service</h3>
                  </Modal.Header>
                  <Modal.Body>
				  <Form method="post" onSubmit={handleSerSubmit} enctype="multipart/form-data">
                     <Col md={12} xs={12} className="ppinpt">
                     
                        <Form.Group className="mb-3">
                           <Form.Label>Service Name</Form.Label>
                           <Form.Control type="text" placeholder="Enter product or service name" name="nameser" value={inputsser.nameser} onChange={handleInputChangeser}/>
							{errors.nameser && (<p className="text-danger">{errors.nameser}</p>)}
						</Form.Group>
                        <Col className="upladcustm">
                        <Form.Label>Select Image</Form.Label>
                        <Row>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser1.preview ? (
							  <> 
								<img src={imageser1.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser1" accept=".png, .jpg, .jpeg" onChange={handleChangeser1} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser2.preview ? (
							  <> 
								<img src={imageser2.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser2" accept=".png, .jpg, .jpeg" onChange={handleChangeser2} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser3.preview ? (
							  <> 
								<img src={imageser3.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser3" accept=".png, .jpg, .jpeg" onChange={handleChangeser3} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser4.preview ? (
							  <> 
								<img src={imageser4.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser4" accept=".png, .jpg, .jpeg" onChange={handleChangeser4} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser5.preview ? (
							  <> 
								<img src={imageser5.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser5" accept=".png, .jpg, .jpeg" onChange={handleChangeser5} />
                           </div>
                           </Col>  
							{errors.imageser1 && (<p className="text-danger">{errors.imageser1}</p>)}
						   {errors.imageser2 && (<p className="text-danger">{errors.imageser2}</p>)}
						   {errors.imageser3 && (<p className="text-danger">{errors.imageser3}</p>)}
						   {errors.imageser4 && (<p className="text-danger">{errors.imageser4}</p>)}
						   {errors.imageser5 && (<p className="text-danger">{errors.imageser5}</p>)}							   
                        </Row>
                        </Col>
                        
                        <Form.Group className="mb-3">
                           <Form.Label>Price ($)</Form.Label>
                           <Form.Control type="text" placeholder="$00.00" name="priceser" value={inputsser.priceser} onChange={handlePriceInputChangeser}/>
							{errors.priceser && (<p className="text-danger">{errors.priceser}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Duration in Minutes</Form.Label>
                           <Form.Control type="text" placeholder="5 Minutes" name="duration_in_minutesser" value={inputsser.duration_in_minutesser} onChange={handlePriceInputChangeser} />
							{errors.duration_in_minutesser && (<p className="text-danger">{errors.duration_in_minutesser}</p>)}
						</Form.Group>
                        
                        <Form.Group className="mb-3">
                           <Form.Label>Description (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your description here" name="descriptionser" value={inputsser.descriptionser} onChange={handleInputChangeserdes}/>
							{errors.descriptionser && (<p className="text-danger">{errors.descriptionser}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Special Instructions (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your special instruction here" name="special_instructionsser" value={inputsser.special_instructionsser} onChange={handleInputChangeserins}/>
                           {errors.special_instructionsser && (<p className="text-danger">{errors.special_instructionsser}</p>)}
						</Form.Group>
                     
                     </Col>
                     <Col md={12} xs={12} className="btnpopmcstmr my-3">
                     <Button  type="submit">Save</Button>
                     </Col>
					 </Form>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Selling Add Service Popup End ========== */}
	  {/* ========== Selling Edit Service Popup Start ========== */}
               <Modal show={show1edit} onHide={handleClose1edit} className="popupwrngcnt" centered>
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Edit Service</h3>
                  </Modal.Header>
                  <Modal.Body>
				  <Form method="post" onSubmit={handleSerSubmitedit} enctype="multipart/form-data">
                     <Col md={12} xs={12} className="ppinpt">
                     
                        <Form.Group className="mb-3">
                           <Form.Label>Service Name</Form.Label>
                           <Form.Control type="text" placeholder="Enter product or service name" name="nameseredit" value={inputsseredit.nameseredit} onChange={handleInputChangeseredit}/>
							{errors.nameseredit && (<p className="text-danger">{errors.nameseredit}</p>)}
						</Form.Group>
                        <Col className="upladcustm">
                        <Form.Label>Select Image</Form.Label>
                        <Row>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser1edit.preview ? (
							  <> 
								<img src={imageser1edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser1edit" accept=".png, .jpg, .jpeg" onChange={handleChangeser1edit} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser2edit.preview ? (
							  <> 
								<img src={imageser2edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser2edit" accept=".png, .jpg, .jpeg" onChange={handleChangeser2edit} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser3edit.preview ? (
							  <> 
								<img src={imageser3edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser3edit" accept=".png, .jpg, .jpeg" onChange={handleChangeser3edit} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser4edit.preview ? (
							  <> 
								<img src={imageser4edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser4edit" accept=".png, .jpg, .jpeg" onChange={handleChangeser4edit} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {imageser5edit.preview ? (
							  <> 
								<img src={imageser5edit.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="imageser5edit" accept=".png, .jpg, .jpeg" onChange={handleChangeser5edit} />
                           </div>
                           </Col>  
							{errors.imageser1edit && (<p className="text-danger">{errors.imageser1edit}</p>)}
						   {errors.imageser2edit && (<p className="text-danger">{errors.imageser2edit}</p>)}
						   {errors.imageser3edit && (<p className="text-danger">{errors.imageser3edit}</p>)}
						   {errors.imageser4edit && (<p className="text-danger">{errors.imageser4edit}</p>)}
						   {errors.imageser5edit && (<p className="text-danger">{errors.imageser5edit}</p>)}							   
                        </Row>
                        </Col>
                        
                        <Form.Group className="mb-3">
                           <Form.Label>Price ($)</Form.Label>
                           <Form.Control type="text" placeholder="$00.00" name="priceseredit" value={inputsseredit.priceseredit} onChange={handlePriceInputChangeseredit}/>
							{errors.priceseredit && (<p className="text-danger">{errors.priceseredit}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Duration in Minutes</Form.Label>
                           <Form.Control type="text" placeholder="5 Minutes" name="duration_in_minutesseredit" value={inputsseredit.duration_in_minutesseredit} onChange={handlePriceInputChangeseredit} />
							{errors.duration_in_minutesseredit && (<p className="text-danger">{errors.duration_in_minutesseredit}</p>)}
						</Form.Group>
                        
                        <Form.Group className="mb-3">
                           <Form.Label>Description (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your description here" name="descriptionseredit" value={inputsseredit.descriptionseredit} onChange={handleInputChangesereditdes}/>
							{errors.descriptionseredit && (<p className="text-danger">{errors.descriptionseredit}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Special Instructions (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Enter your special instruction here" name="special_instructionsseredit" value={inputsseredit.special_instructionsseredit} onChange={handleInputChangesereditins}/>
                           {errors.special_instructionsseredit && (<p className="text-danger">{errors.special_instructionsseredit}</p>)}
						</Form.Group>
                     
                     </Col>
                     <Col md={12} xs={12} className="btnpopmcstmr my-3">
                     <Button  type="submit">Save</Button>
                     </Col>
					 </Form>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Selling Edit Service Popup End ========== */}
            </div>
            <div className="inmodal">
      {/* ========== Selling delete Popup Start ========== */}
               <Modal show={show2} onHide={handleClose2} className="popupwrngcnt">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Are you sure you want to delete?</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Row>
                        <Col className="btn-clr">
                        <NavLink to="#" onClick={handleDelete}><Button variant="">Yes</Button></NavLink>
                        </Col>
                        <Col className="btn-dv">
                        <NavLink to="#" onClick={handleClose2}><Button variant="">No</Button></NavLink>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Selling delete Popup End ========== */}
            </div>
            </Col>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
   {/* ========== Footer Start ========== */}
      <Footer/>
   {/* ========== Footer Start ========== */}
   {/* ========== Merchant Selling Page End ========== */}
        </>
    )
}
export default MerSelling;