import React, { useEffect, useState } from 'react';
import { Row, Col, Container,  Form,Accordion} from 'react-bootstrap';
import './MerStripeAccountSetup.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import loader from '../../../assests/loader.gif';
import {validatestripe} from '../../../Utils/validate';
import { setUserSession } from '../../../Utils/Common';
import API from '../../../Utils/Api';

const MerStripeAccountSetup = () => {
const navigate = useNavigate();	
const [loading,setLoading] = useState(false);

const [error,setError] = useState();
const [success,setSuccess] = useState();
const [errors,setErrors] = useState({});

const [inputs,setInputs] = useState({stripe_live_pk:'',stripe_live_sk:''});

useEffect(() => {
      document.title = `Merchant Store Setup | Blue Faucet`;
      document.getElementById('bodywrap').className='strstppgmain grenicn grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
},[]) 
//Start Login check

 /* Start Value set */
const handleInputChange = (event) => {
  event.persist();
  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
}
/* End Value set */

const handleSubmit = (event) => {
	event.preventDefault();
	const validationErrors = validatestripe(inputs);
	const noErrors = Object.keys(validationErrors).length === 0;
	setError('');
	setSuccess('');
	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setLoading(true);
		let stripe_live_pk=inputs.stripe_live_pk;
		let stripe_live_sk=inputs.stripe_live_sk;
		
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('stripe_live_pk', stripe_live_pk);
		formData.append('stripe_live_sk', stripe_live_sk);
		API.post('marchant_payment_setup', formData)
			.then(response => {
				
			if(response.data.status === 0){
				setError(response.data.message);
				setLoading(false); 
			}else{
			   setError('');
			   setLoading(false);
			   setUserSession(response.data.data.token,response.data.data.profile_setup,response.data.data.type);
			  navigate("/mer-dashboard");
			}                  
		}).catch(error => {
			 setLoading(false);
			  setError("Something went wrong. Please try again later.");
		});
	}
}
const handleSubmitSkip = () => {
		setLoading(true);
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'skip');
		API.post('payment_setup', formData)
			.then(response => {
				
			if(response.data.status === 0){
				setError(response.data.message);
				setLoading(false); 
			}else{
			   setError('');
			   setLoading(false);
			   setUserSession(response.data.data.token,response.data.data.profile_setup,response.data.data.type);
			  navigate("/mer-dashboard");
			}                  
		}).catch(error => {
			 setLoading(false);
			  setError("Something went wrong. Please try again later.");
		});
	
}
    return (
        <>
      {/* ========== Merchant Store Setup Page Start ========== */}
         <section className="gen-main">
      {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt mx-0">
            <Col xs={2} md={2} className="align-self-center px-0">
            
            </Col>
            <Col xs={8} md={8} className="align-self-center text-center">
            <h3>Stripe Account Details</h3>
            </Col>
            <Col xs={2} md={2} className="align-self-center">
            <p></p>
            </Col>
         </Row>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row md={12} className="genscrnmrcnt">
               <Col>
               <Row md={12} className="maincntsrcnmrcnt">
                  <Col className="px-0">
      {/* ========== Profile Upload Content Start ========== */}
	              {error && (
					<div className="alert alert-danger">
					  {error}
					</div>)}
				  {success && (
					<div className="alert alert-success">
					  {success}
					</div>)}
      {/* ========== Profile Upload Content Start ========== */}
                  <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                     <Form.Group className="mb-3">
                        <Form.Label>Stripe Public Key<span>*</span></Form.Label>
                        <Form.Control type="text" name="stripe_live_pk" placeholder="Enter Stripe Public Key" onChange={handleInputChange} value={inputs.stripe_live_pk} />
						{errors.stripe_live_pk && (<p className="text-danger">{errors.stripe_live_pk}</p>)}
                     </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Stripe Secret Key<span>*</span></Form.Label>
                        <Form.Control type="text" name="stripe_live_sk" placeholder="Enter Stripe Secret Key" onChange={handleInputChange} value={inputs.stripe_live_sk} />
						{errors.stripe_live_sk && (<p className="text-danger">{errors.stripe_live_sk}</p>)}
					 </Form.Group>
                     
                     <div className="mb-3 btn-dv">
					 <Row>
					 <Col xs={6} md={6}>
                        <button type="button" className="btn" onClick={handleSubmitSkip}> Skip</button> 
					</Col>
					<Col xs={6} md={6}>
						<button type="submit" className="btn"> Submit</button>
					</Col>
					</Row>
                     </div>
                    
                  </Form>
                  </Col>
				  <Col xs={12} md={12} className="px-0 stripe-accordion">
					<Accordion defaultActiveKey={1}>
						<Accordion.Item eventKey={1}>
							  <Accordion.Header>Why do you need my Stripe Public and Secret Key?</Accordion.Header>
							  <Accordion.Body>
								 By sharing your Stripe keys with third-party service providers like BlueFaucet, you enable smoother operations. This allows for the automation of payment-related tasks, such as invoicing, subscription management, and refunds, without the need for manual intervention.
							  </Accordion.Body>
						   </Accordion.Item>
						 <Accordion.Item eventKey={2}>
							  <Accordion.Header>What if I don’t have a Stripe Account? </Accordion.Header>
							  <Accordion.Body>
								 If you don't have a Stripe account, BlueFaucet won't be able to process credit or debit card payments for you.
							  </Accordion.Body>
						   </Accordion.Item>
						 <Accordion.Item eventKey={3}>
							  <Accordion.Header>How do you protect the keys?  </Accordion.Header>
							  <Accordion.Body>
								 BlueFaucet ensures data security compliance by securely storing your Stripe keys in a protected location.
							  </Accordion.Body>
						   </Accordion.Item>
					</Accordion>
				  </Col>
               </Row>
               </Col>
               
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      {/* ========== Merchant Store Setup Page Start ========== */}
      </>
    )
}
export default MerStripeAccountSetup;