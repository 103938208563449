import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Modal,Form } from 'react-bootstrap';
import { NavLink,useParams,useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './UserPrdct.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../footer";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {validatecartproduct} from '../../../Utils/validate';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gettoastwarning } from '../../../Utils/Common';

const UserPrdct = () => {
	const navigate = useNavigate();
	
	const [searchTerm, setSearchTerm] = useState('');
	
	const { id } = useParams()
    const [product, setProduct] = useState([]);
	const [loading,setLoading] = useState(true);
	
	const [show1, setShow1] = useState(false);
	const handleClose1 = () => setShow1(false);
	
	const [productimg, setProductimg] = useState([]);
	
	const [noticount, setNoticount] = useState(false);
	
useEffect(() => {
      document.title = `User Product | Blue Faucet`;
      document.getElementById('bodywrap').className='ustrlpgmain usrprdctsrvc';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
    
	/* Start get product data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('store_id', id);
		formData.append('type', 'product');
		API.post('get_product', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				var prod=response.data.data;
				//rec(serv)
				const  tempArr = prod.map(item => ({...item, isSelected: false}))
			   setProduct(tempArr);
			}                  
		}).catch(error => {
			 setLoading(false);
		});  
	 /* End get product data */
	
	/* Start get notification count */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('notification_count', formData1)
			.then(response => {
		
			if(response.data.data > 0){
				setNoticount(true);
			}                
		}).catch(error => {
			 
		});  
	 /* End get notification count */
	
},[id])  
/* Start like and dislike */
const like = (id,store_id) => {		
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('product_id', id);
	formData.append('store_id', store_id);
	API.post('product_favourite', formData)
		.then(response => {
		
		if(response.data.status === 0){
			
		}else{
			let updateData = product.map((item, i) => {
			  return id === item.id ? { ...item, status: !item.status } : item;
			});
			setProduct(updateData);
		}                  
	}).catch(error => {
		 
	});  
}
/* End like and dislike */
/* Start view reating */
/*const ratingview = (count) => {
	var html='';
	for (let i = 1; i <= count; i++) {
			html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star"></i></label><input class="rating__input" name="rating"  defaultValue={i} type="radio"/>';
	
	}
	var cou=count+1;
	for(let a=cou;a <= 5;a++)
	{
		html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star rating-emt"></i></label><input class="rating__input" name="rating"   type="radio"/>';
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}*/
/* End view reating */

/* Start product qty increment */
const incrementCount = (id) => {	
	let updateData = product.map((item, i) => {
		return id === item.id && item.quantity > item.min_qty ? { ...item, min_qty: item.min_qty+1 } : item;
	});
	setProduct(updateData);
}
/* End product qty increment */
/* Start product qty decrement */
const decrementCount = (id) => {	
	let updateData = product.map((item, i) => {
		return id === item.id && item.min_qty > 1 ? { ...item, min_qty: item.min_qty-1 } : item;
	});
	setProduct(updateData);
}
/* End product qty decrement */
/* Start product search keyword wise */
const handleInputChange = (event) => {
	setLoading(true);
	
	setSearchTerm(event.target.value);
	
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('type', 'product');
	formData.append('keyword', event.target.value);
	formData.append('store_id', id);
	API.post('search_product_service', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setProduct(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
};
/* End product search keyword wise */
 /* Start addtocart */
 const addtocart = () => {
	 const validationErrors = validatecartproduct(product);
        const noErrors = Object.keys(validationErrors).length === 0;
	
		
		
		if(noErrors){
		 const product_id = [];
		  const product_qty = [];
		product.forEach((item) => {
			 if(item.isSelected===true)
				{
					product_id.push(item.id);
					product_qty.push(item.min_qty);
				}
		 });
		 setLoading(true);
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('store_id', id);
			formData.append('product_id', product_id);
			formData.append('quantity', product_qty);
			formData.append('type', 'product');
			API.post('temp_add_to_card', formData)
				.then(response => {
				setLoading(false);
				if(response.data.status === 0){
					//alert(response.data.message);
					gettoastwarning(response.data.message,'');
				}else{
					//window.location.href = '/order-summary';
					window.location.href = '/calendar-prod/'+id;
				}                  
			}).catch(error => {
				setLoading(false);
				 //alert('Something went wrong. Please try again later.');
				 gettoastwarning('Something went wrong. Please try again later.','');
			}); 
		}else{
			//alert(validationErrors.product_sel);
			gettoastwarning(validationErrors.product_sel,'');
		}			
	 
 }
 /* End addtocart */
/* Start product sorting */
const searchSort = (filter_type) => {	
		setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('type', 'product');
	formData.append('keyword', searchTerm);
	formData.append('store_id', id);
	formData.append('filter_type', filter_type);
	API.post('search_product_service', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setProduct(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
}
/* End product sorting */
 /* Start product image */
const mulimg = (id) => {
	
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('product_id', id);
	API.post('get_image_by_product_id', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setProductimg(response.data.data);
			setShow1(true);
		}                  
	}).catch(error => {
		 
	});  
}
/* End product image */
 
/* Start select product */
const handleSelectChange = (id) => {
	let updateData = product.map((item, i) => {
		  return id === item.id ? { ...item, isSelected: !item.isSelected } : item;
		});
		setProduct(updateData);
}
/* End select product */ 
 
    return (
        <>
         {/* ========== User Product Page Start ========== */}
            <section className="gen-wrap">
			<ToastContainer />
            {/* ========== Navbar Start ========== */}
				<Row className="nvcus-main">
					<Col md={1} xs={1} className="align-self-center bcnv-arwbtn px-0">
						 <button onClick={() => navigate(-1)} className="back-btn">
						  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
							<path d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28533 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
						  </svg>
						</button>
					</Col>
					<Col md={10} xs={9} className="align-self-center">
					  <div className="constrain hide-inpt" >
						<input className="input-elevated" type="text" placeholder="Search here.." onChange={handleInputChange}/>
					  </div>
					</Col>
					<Col md={1} xs={2} className="align-self-center nvbtn">
					  <Button href={void(0)} className="d-none" variant=""><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/msgicn.svg'} className="img-fluid" alt="Logo"/><span></span></Button>{' '}
					  <Button href={process.env.REACT_APP_BASE_URL + 'notification'} variant=""><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/notiicn.svg'} className="img-fluid" alt="Logo"/>{noticount && <span></span> }</Button>{' '}
					</Col>
				</Row>
            {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl uclsrldv">
                     <ul className="hmtp-scldv">
                        <Row className="mx-0 ucl-sfbtn">
                           <Col>
                           <NavLink to="#">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic"><span><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/ucl-srtimg.svg'} className="img-fluid" alt="Usl Img"/></span>Sort</Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Dropdown.Item href={void(0)} onClick={() =>searchSort('popular')}>Popular</Dropdown.Item>
									<Dropdown.Item href={void(0)} onClick={() =>searchSort('high')}>Price Low to high</Dropdown.Item>
									<Dropdown.Item href={void(0)} onClick={() =>searchSort('low')}>Price High to Low</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </NavLink>
                           </Col>
                           <Col>
                           <NavLink to="#"><Button variant="" type="submit"><span><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/ucl-fltrimg.svg'} className="img-fluid" alt="Usl Img"/></span>Filter</Button></NavLink>
                           </Col>
                        </Row>
                     </ul>
                     </Col>
                  </Row>
                  <Row className="mx-0 mianhmdwncnt main-product-box">
                    {/* ========== Poduct Detail Box Start ========== */}
					{product.length >0 ? (
                     <Col className="mnhmcnt">
					 {product && product.map((val,index) => {
						return(
                     <Col md={12} xs={12} className="py-2">
                     <Col className="ustrlpg">
                     <Row className="mx-0 ustrl-box">
                        <Col md={2} xs={2} className="px-0">
						<NavLink to="#" onClick={() =>mulimg(val.id)}>
							<img src={val.image_thuumb} className="img-fluid" alt="Hm Img"/>
						</NavLink>
						</Col>
                        <Col md={9} xs={9} className="px-2 px-md-2">
                        <h3>{val.name}</h3>
							{/*<div id="str-trng">
                           <div className="rating-group strrtg-rating">
                              {ratingview(val.star)}
                           </div>
                           <span>({val.total_order_done} Order Done)</span>
							</div>*/}
                        <p className="mt-2">{val.description}</p>
						<p className="mt-2">{val.special_instructions}</p>
							{/*<p>Buffet Offers</p>*/}
                        <div className="dwnnewpgcnt">
                           <ul>
                              <li>${val.price}</li>
								  {/*<li>$69.99</li>*/}
						    {(() => {
								if (val.isSelected) {
                              return (<li>
                                 <div className="qty-btn">
                                    <button onClick={() =>incrementCount(val.id)} className="qty-btn-minus btn-primary btn-rounded mr-1" type="button"><i className="fa fa-plus"></i></button>
                                    <input type="text" name="qty" value={val.min_qty} className="input-qty input-rounded"/>
                                    <button onClick={() =>decrementCount(val.id)} className="qty-btn-plus btn-primary btn-rounded ml-1" type="button"><i className="fa fa-minus"></i></button>
                                 </div>
                              </li> )
								 }    
							  })()}
                           </ul>
                        </div>
                        </Col>
                        <Col md={1} xs={1} className="text-end px-0 likedv">
                        <div className="ginlike">
                           <div className="active">
                              {val.status ? <svg className={`lik lik${val.id}`} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(val.id,val.store_id)}>
								   <circle cx="12" cy="12" r="12" fill="white"/>
								   <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
								</svg>  : <svg className={`dis dis${val.id}`} width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
								   like(val.id,val.store_id)}>
								   <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
								   <g id="Black">
									  <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
								   </g>
								</svg>
						 } 
                           </div>
                        </div>
                        </Col>
                     </Row>
                     <Col md={12} xs={12} className="ustrl-crdlst newpgcl">
                     <div>
                        <p>Availability: <span>{val.availability}</span></p>
                     </div>
					 <Form.Group className="ckbox-servicepg" controlId="">
                       <div key="default-1" className="">
                          <Form.Check type="checkbox" id="default-1" label="" checked={val.isSelected} onClick={() =>
                           handleSelectChange(val.id)}/>
                       </div>
                    </Form.Group>
					 { /*val.availability_status ? (
                     <NavLink to="#"> <Button variant="" type="submit" onClick={() =>addtocart(val.id,val.store_id)}>Add to cart</Button></NavLink>
					  ) : (
					  <NavLink to="#" disabled={true}> <Button variant="" type="submit" disabled={true}>Add to cart</Button></NavLink>
					 )*/}
                     </Col>
                     </Col>
                     </Col>
					 )
					  })}
					  <Col className="btn-dv mt-3">
					 <Button  type="button" onClick={addtocart}>Add to cart</Button>
					 </Col>
                     </Col>
					
					) : (
						<Col className="mnhmcnt">
							<p className="text-center mt-4">No data found</p>
						</Col>
					  )} 
                  {/* ========== Poduct Detail Box End ========== */}
                  </Row>
				  <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" centered>
                     <Modal.Header closeButton>
                        <h3 className="mdlttltxt">Image</h3>
                     </Modal.Header>
                     <Modal.Body>
                        <Col md={12} xs={12} className="ppinpt" >
							<OwlCarousel className='owl-theme mb-5' items={1} loop margin={10} nav={false}>
							{productimg.map((val) => {
								return (
								<div className='item' style={{ height: '250px' }}>
									<img src={val.image} className="img-fluid" alt="Sld Img" style={{ width: '100%', height: '100%' }} />
								</div>
								)
								})
								}
							</OwlCarousel>
                        </Col>
                     </Modal.Body>
                  </Modal>
				  
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )} 
               </Container>
            </section>
            {/* ========== Footer Start ========== */}
               <Footer/>
            {/* ========== Footer End ========== */}
         {/* ========== User Product Page End ========== */}
        </>
    )
}

export default UserPrdct;