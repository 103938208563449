import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button, Table, Stack,Modal } from 'react-bootstrap';
import { NavLink,useParams } from "react-router-dom";
import './EmpReceipts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';

const EmpReceipts = () => {
const navigate = useNavigate();   
const { id } = useParams();
const [loading,setLoading] = useState(false);
const [orderdetail,setOrderdetail] = useState([]);
const [inputs,setInputs] = useState({store_name:'',store_address:'',store_mobile:'',order_number:'',date:'',store_image:'',store_zip:'',subtotal:'',total:'',tips:'',saving_amount:0,sales_tax_rate_per:0,sales_tax_rate:0,amount:'',user_address_title:'',user_address_street_name:'',user_address_state:'',user_address_city:'',user_address_zip_code:'',user_mobile:'',payment_txn_id:'',payment_type:'',payment_card_name:'',payment_card_number:'',payment_card_month:'',payment_card_year:'',store_policy:''});
const [coupon,setCoupon] = useState([]);


const [showr, setShowr] = useState(false);
const handleCloser = () => setShowr(false);
const handleShowr = () => setShowr(true);

useEffect(() => {
      document.title = `Employee Receipts | Blue Faucet`;
      document.getElementById('bodywrap').className='usrrcptcpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get receipt data */
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	API.post('get_employee_product_or_service_order_with_order_id', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setOrderdetail(response.data.data.order_detail);
			var sales_tax_rate_per=response.data.data.sales_tax_rate_per;
			var sales_tax_rate=response.data.data.sales_tax_rate;
			
			var saving_amount=0;
			if(response.data.data.saving_amount!=='')
			{
				saving_amount=parseFloat(response.data.data.saving_amount);
			}
			var subtotal=parseFloat(response.data.data.subtotal);
			var total=subtotal-saving_amount;
			var tips=parseFloat(response.data.data.tips);
			var total_due=total+tips+sales_tax_rate;
			
			subtotal=subtotal.toFixed(2);
			total=total.toFixed(2);
			total_due=total_due.toFixed(2);
			tips=tips.toFixed(2);
			saving_amount=saving_amount.toFixed(2);
			sales_tax_rate=sales_tax_rate.toFixed(2);
			setInputs({
				store_name:response.data.data.store_name,
				store_address:response.data.data.store_address,
				store_mobile:response.data.data.store_mobile,
				order_number:response.data.data.order_number,
				date:response.data.data.date,
				store_image:response.data.data.store_image,
				store_zip:response.data.data.store_zip,
				subtotal:subtotal,
				total:total,
			    total_due:total_due,
				tips:tips,
				saving_amount:saving_amount,
				sales_tax_rate_per:sales_tax_rate_per,
				sales_tax_rate:sales_tax_rate,
				amount:response.data.data.amount,
				user_address_title:response.data.data.user_address_title,
				user_address_street_name:response.data.data.user_address_street_name,
				user_address_state:response.data.data.user_address_state,
				user_address_city:response.data.data.user_address_city,
				user_address_zip_code:response.data.data.user_address_zip_code,
				user_mobile:response.data.data.user_mobile,
				payment_txn_id:response.data.data.payment_txn_id,
				payment_type:response.data.data.payment_type,
				payment_card_name:response.data.data.payment_card_name,
				payment_card_number:response.data.data.payment_card_number,
				payment_card_month:response.data.data.payment_card_month,
				payment_card_year:response.data.data.payment_card_year,
				store_policy:response.data.data.store_policy,
			});
			setCoupon(response.data.data.coupon);
		}                  
	}).catch(error => {
		 
	});  
	 /* End get receipt data */
},[id])  

const addressview = (user_address_street_name,user_address_state,user_address_city,user_address_zip_code) => {
	var html='';
	if(user_address_street_name!=='')
	{
		html+=user_address_street_name+', ';
	}
	if(user_address_state!=='')
	{
		html+=user_address_state+', ';
	}
	if(user_address_city!=='')
	{
		html+=user_address_city+', ';
	}
	if(user_address_zip_code!=='')
	{
		html+=user_address_zip_code;
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}


    return (
        <>
         {/* ========== Employee Receipts Page Start ========== */}
         <section className="gen-main">
         {/* ========== Navbar Start ========== */}
            <Row className="maincntmrcnt mx-0">
               <Col xs={2} md={2} className="align-self-center px-0">
               <button onClick={() =>
                  navigate(-1)} className="back-btn">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                  </svg>
               </button>
               </Col>
               <Col xs={8} md={8} className="align-self-center text-center">
               <h3>Receipts</h3>
               </Col>
               <Col xs={2} md={2} className="align-self-center">
               <p></p>
               </Col>
            </Row>   
         {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
         {/* ========== Employee Receipts Content Start ========== */}
               <Col md={12} xs={12} className="py-2">
      {/* ========== Receipt Content Start ========== */}
               <Row className="mx-0 maincnt-recepts">
                  <Col sm={10} xs={12} className="erone">
                  <h3>{inputs.store_name}</h3>
                  <p>{inputs.store_address} , {inputs.store_zip}</p>
                  <p>Phone: {inputs.store_mobile} </p>
				  
				  {/*<h2 className="mt-4 cus-add-lab">Customer Address</h2>
				  <h3>{inputs.user_name}</h3>
				  <h3>{inputs.user_address_title}</h3>
                  <p>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</p>
                  <p>Phone: {inputs.user_mobile}</p>*/}
				  
				  <div className="left-cnt-rcptr">
					<h4>Customer Name: <span>{inputs.user_name}</span></h4>
					<h4>Contact info: <span>{inputs.user_mobile}</span></h4>
					{inputs.user_address_street_name!=='' ?
					<h4>Customer address: <span>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</span></h4>
						: null }
				  </div>
				  
                  <div className="dwncmt">
                     {/*<h4>Serviced by: <span>xxxxxxxxxxxx</span></h4>*/}
                     <h5>Information:</h5>
					 <h4>Schedule date & time: <span>{inputs.due_date}</span></h4>
                     <h4>Invoice No.<span>{inputs.order_number}</span></h4>
                     <h4>Invoice Date: <span>{inputs.date}</span></h4>
                  </div>
                  </Col>
                  <Col sm={2} xs={12} className="text-end left-cnt-rcpt ertwo">
                  <img src={inputs.store_image} className="img-fluid ps-2" alt="Hm Img"/>
                  {/*<h4>Due Date:<span>20May 2023</span></h4>*/}
                  </Col>
                  <Col md={12} xs={12} className="tblinvcpg erthr">
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
                           <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
                        {orderdetail && orderdetail.map((val,index) => {
					   return(
                        <tr>
                           <td>{index+1}</td>
                           <td>{val.product_name}</td>
                           <td>${val.product_price}</td>
                           <td>1</td>
                           <td>${val.product_price}</td>
                        </tr>
						 )
					  })}
                     </tbody>
                  </Table>
                  <div className="text-end tbldwndv">
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Subtotal</p>
                        </div>
                        <div className="pb-1"><span>${inputs.subtotal}</span></div>
                     </Stack>
                     {/*<Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Tax(10.25%)</p>
                        </div>
                        <div className="pb-1"><span>$10</span></div>
                     </Stack>*/}
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon or discount</p>
                        </div>
                        <div className="pb-1">-<span>${inputs.saving_amount}</span></div>
                     </Stack>
                     {/*<Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Total</p>
                        </div>
                        <div className="pb-1"><span>${inputs.total}</span></div>
                     </Stack>*/}
					 <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Tax ({inputs.sales_tax_rate_per}%)</p>
                        </div>
                        <div className="pb-1"><span>${inputs.sales_tax_rate}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Gratuity</p>
                        </div>
                        <div className="pb-1 ps-3"><span> {inputs.tips} </span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Due</h6>
                        </div>
                        <div className="">
                           <h6>${inputs.total_due}</h6>
                        </div>
                     </Stack>
                  </div>
                  </Col>
                  <Col md={6} xs={7} className="dwn-cntrecpt erfou">
                  <h3>Transaction Records</h3>
                  {inputs.payment_type==='cash' ? (
				     <>
					  <p>Payment Type: {inputs.payment_type}</p>
					  </>
                  ) : (
					<>
					  <p>Payment Type: {inputs.payment_type}</p>
					  <p>Transaction Id: {inputs.payment_txn_id}</p>
				     {/*<p>Card Name: {inputs.payment_card_name}</p>*/}
					  <p>Card Number: xxxx xxxx xxxx {inputs.payment_card_number}</p>
					  <p>Card Expiry: {inputs.payment_card_month} / {inputs.payment_card_year}</p>
					  </>
				  )}
                  </Col>
                  <Row className="mx-0 btnlst-usrrcpt erfiv">
                     <Col md={6} xs={6} className="px-0">
						 {/*<NavLink to="#"><Button variant="" type="submit">Approved</Button></NavLink>*/}
                     </Col>
                     <Col md={6} xs={6} className="px-0 text-end">
                     <NavLink to="#" onClick={handleShowr}>Refund Policy</NavLink>
                     </Col>
                  </Row>
				  {coupon.length >0 &&(
						<div className="mb-3 mt-3">
						<label className="form-label mb-4">Coupon</label>
					  <Row>
					  
						<Col md={12} xs={12}>
							<ul className="sldr-add">
								{coupon.length > 0 && coupon.map((item,index) => {
							return(<li className="sldr-in-add">
								<div className="row mincrd">
								<Col md={12} xs={12} className="align-self-center mb-2">
									<h4>{item.coupon_type}</h4>
								   </Col>
									
									<Col md={12}>
										{(() => {
										if (item.coupon_type==='Buy & Get') {
											return (<p>Buy {item.buy_quantity} get {item.get_quantity} free,for {item.product_item_name}</p>)
										}else if(item.coupon_type==='Discount Offer'){
											return (  <p>{item.discount_percentage} % Discount When Purchase Over ${item.minimum_purchase_amount}</p>)
										}else if(item.coupon_type==='Loyalty Setup'){
											return (<p>Buy {item.buy_quantity_needed} {item.product_applicable_item_if_any} get {item.product_get_item_name} free</p>)
										}else if(item.coupon_type==='Invitation'){
											return (<p>{item.product_item_name}</p>)
										}else if(item.coupon_type==='Welcome coupon'){
											return (<p>{item.discount_percentage}% for all new members joining your membership has been set up</p>)
										}								
										})()}
									   {(() => {
										if (item.coupon_type!=='Welcome coupon') {
											return (<p>{item.description}</p>)
										}									
										})()}
									</Col>
									</div>
								</li>
								)})}
							</ul>
						</Col>
						
						</Row>
						</div>
					  )}
               </Row>
               <Col className="rcptbtn mt-3 d-none"><NavLink to="#"><Button variant="" type="submit"> Apply For Refund</Button></NavLink></Col>
               </Col>
      {/* ========== Employee Receipts Content Start ========== */}
               </Col>
            </Row>
			
			{/* ========== Tabel Popup Start ========== */}
                    <Modal show={showr} onHide={handleCloser} className="popupwrngcnt" centered>
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Refund Policy</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col md={12} xs={12} className="tblinvcpg">
                               <p>{inputs.store_policy}</p>
                             </Col>
							 
                             
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Tabel Popup End ========== */}
			
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
			   
			   
			   
         </Container>
      </section>
      {/* ========== Employee Receipts Page End ========== */}
        </>
    )
}
export default EmpReceipts;