import React from "react";
import { Outlet, Navigate,useLocation } from "react-router-dom";

export default function PrivateRouteEmp() {
    let userid = localStorage.getItem("token") == null ? false : true;
	let profile_setup = parseInt(localStorage.getItem("profile_setup"));
	let type = parseInt(localStorage.getItem("type"));
	const location = useLocation();
	
	var usertype = 3;
    if (userid === true) {
        usertype = type;
    }
	if(usertype===3)
	{
		var user_type = 0;
		if (userid === true) {
			user_type = 3;
		}
		if(user_type===3){
			 const pagename=location.pathname;
			if(pagename==='/emp-profile')
			{
				if(profile_setup===1)
				{
					return <>{user_type === 3 ? <Outlet /> : <Navigate to="/login" />}</>;
					
				}else{
					return <>{user_type === 3 ? <Navigate to="/emp-dashboard" /> : <Navigate to="/emp-login" />}</>;	
				}
			}else{
				if(profile_setup===1)
				{
					return <>{user_type === 3 ? <Navigate to="/emp-profile" /> : <Navigate to="/login" />}</>;
				}else{
					
					return <>{user_type === 3 ? <Outlet /> : <Navigate to="/login" />}</>;
				}
			}	  
		}else{
			return <>{user_type === 3 ? <Outlet /> : <Navigate to="/login" />}</>;
		}
	}else if(usertype===1){
		return <>{usertype === 1 ? <Navigate to="/login" /> : <Navigate to="/login" />}</>;
	}else if(usertype===2){
		return <>{usertype === 2 ? <Navigate to="/mer-login" /> : <Navigate to="/mer-login" />}</>;
	}
}
