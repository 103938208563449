import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import './SalWithdraw.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
import Navbar from "../navbar";
import Footer from "../footer";
// import $ from 'jquery';

const SalWithdraw = () => {
useEffect(() => {
      document.title = `Sales Withdraw | Blue Faucet`;
      document.getElementById('bodywrap').className='wthdrwpgmain ylwthem';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });       
},[])  
    return (
        <>
        {/* ========== Sales Withdraw Page Start ========== */}
          <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
              <Navbar headerText={'Withdraw'}/>
        {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
                   <Col className="mnhmcnt">
        {/* ========== Sales Withdraw Content Start ========== */}
                     <Col md={12} xs={12} className="">
                      <Row className="mx-0 maintpwtdrw mt-2">
                        <Col md={12} xs={12} className="text-end">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                            <path d="M2.74219 0.218645C1.425 0.509272 0.421875 1.4749 0.0890625 2.76866C0.0046875 3.10147 0 3.3921 0 10.0859C0 16.7797 0.0046875 17.0703 0.0890625 17.4031C0.39375 18.5984 1.275 19.5125 2.48438 19.8922L2.83594 20H12H21.1641L21.5156 19.8922C22.725 19.5125 23.6063 18.5984 23.9109 17.4031C23.9953 17.0703 24 16.7797 24 10.0859C24 3.3921 23.9953 3.10147 23.9109 2.76866C23.6063 1.58271 22.7531 0.692085 21.5391 0.279583L21.2109 0.17177L12.1172 0.162395C4.69688 0.157707 2.97188 0.167082 2.74219 0.218645ZM20.775 1.59678C21.5625 1.6999 22.2094 2.23897 22.4953 3.03116C22.5469 3.17647 22.5656 3.58898 22.5797 4.91554L22.5984 6.61712H19.8516C16.7812 6.61712 16.7484 6.6218 16.0547 6.94056C15.1875 7.34837 14.5266 8.09837 14.2125 9.04525C14.1047 9.36869 14.0906 9.48119 14.0906 10.0859C14.0906 10.6906 14.1047 10.8031 14.2125 11.1265C14.5266 12.0734 15.1875 12.8234 16.0547 13.2312C16.7484 13.55 16.7812 13.5547 19.8516 13.5547H22.5984L22.5797 15.2515C22.5656 16.5828 22.5469 16.9953 22.4953 17.1406C22.2094 17.9328 21.5625 18.4719 20.775 18.575C20.3625 18.6266 3.6375 18.6266 3.225 18.575C2.41875 18.4719 1.725 17.8719 1.49531 17.0937C1.39688 16.7422 1.39688 3.4296 1.49531 3.07804C1.71563 2.32334 2.42813 1.6999 3.18281 1.60147C3.5625 1.5499 20.4 1.5499 20.775 1.59678ZM22.5938 10.0859V12.1484H20.0531C18.6422 12.1484 17.3859 12.1296 17.2313 12.1015C16.1344 11.9234 15.375 10.8921 15.525 9.79526C15.6375 8.96088 16.3641 8.21087 17.1891 8.07025C17.3109 8.0515 18.5813 8.03275 20.0063 8.02806L22.5938 8.02337V10.0859Z" fill="#666666"/>
                            <path d="M17.1515 9.49062C17.0624 9.54687 16.9546 9.66874 16.9124 9.75781C16.7484 10.0859 16.8515 10.4797 17.1515 10.6812C17.2968 10.7797 17.3624 10.7891 17.9999 10.7891C18.6374 10.7891 18.7031 10.7797 18.8484 10.6812C18.9374 10.625 19.0453 10.5031 19.0874 10.4141C19.2515 10.0859 19.1484 9.69218 18.8484 9.49062C18.7031 9.39218 18.6374 9.3828 17.9999 9.3828C17.3624 9.3828 17.2968 9.39218 17.1515 9.49062Z" fill="#666666"/>
                          </svg>
                        </Col>
                        <Col md={12} xs={12} className="text-center textwdrwcont">
                          <h3>$12,536</h3>
                          <p>BlueFaucet Balance</p>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Control type="text" placeholder="Enter amount to be Transferred here" />
                        </Form.Group>
                        </Col>
                        <Col md={12} xs={12} className="btnpopmcstmr"><NavLink to="#"><Button variant="black" type="submit">Withdraw</Button></NavLink></Col>
                      </Row>
                        
                      </Col>
                      <Col md={12} xs={12} className="wtcnttxt"><p>Balance History</p></Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={1} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={7} xs={6} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                            <h3>Merchant Name</h3>
                            <p>2023-May-21</p>
                          </Col>
                          <Col md={4} xs={4} className="align-self-center text-end wdrwtxtcrd"><p>+$30.00</p></Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={1} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={7} xs={6} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                            <h3>Merchant Name</h3>
                            <p>2023-May-21</p>
                          </Col>
                          <Col md={4} xs={4} className="align-self-center text-end wdrwtxtcrd"><p>+$30.00</p></Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={1} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={7} xs={6} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                            <h3>Merchant Name</h3>
                            <p>2023-May-21</p>
                          </Col>
                          <Col md={4} xs={4} className="align-self-center text-end wdrwtxtcrd"><p>+$30.00</p></Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={1} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={7} xs={6} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                            <h3>Merchant Name</h3>
                            <p>2023-May-21</p>
                          </Col>
                          <Col md={4} xs={4} className="align-self-center text-end wdrwtxtcrd"><p>+$30.00</p></Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={1} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={7} xs={6} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                            <h3>Merchant Name</h3>
                            <p>2023-May-21</p>
                          </Col>
                          <Col md={4} xs={4} className="align-self-center text-end wdrwtxtcrd"><p>+$30.00</p></Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={1} xs={2} className="align-self-center px-1"><img src= {'assets/images/employee/usrrund-img.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={7} xs={6} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                            <h3>Merchant Name</h3>
                            <p>2023-May-21</p>
                          </Col>
                          <Col md={4} xs={4} className="align-self-center text-end wdrwtxtcrd"><p>+$30.00</p></Col>
                        </Row>
                      </Col>
        {/* ========== Sales Withdraw Content End ========== */}
                     </Col>
                  </Row>
               </Container>
            </section>
        {/* ========== Footer Start ========== */}
            <Footer/>
        {/* ========== Footer End ========== */}
        {/* ========== Sales Withdraw Page End ========== */}  
        </>
    )
}
export default SalWithdraw;