import React, { useEffect,useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './EmpCommi.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
//import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';
const EmpCommi = () => {
	const [loading,setLoading] = useState(true);
	const [inputs,setInputs] = useState({employee_name:'',employee_email:'',employee_image:'',employee_stripe_live_pk:'',employee_stripe_live_sk:'',total_Balance:'',total_withdraw:'',total_order:'',total_commission_history:''});
	const [commissionhistory,setCommissionhistory] = useState([]);
	//const [amount,setAmount] = useState(0);
useEffect(() => {
      document.title = `Employee Commission | Blue Faucet`;
      document.getElementById('bodywrap').className='ecmisionpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
    /* Start get dashboard */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('emp_commission', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setInputs({
				   employee_name:response.data.data.employee_name,
				   employee_email:response.data.data.employee_email,
				   employee_image:response.data.data.employee_image,
				   employee_stripe_live_pk:response.data.data.employee_stripe_live_pk,
				   employee_stripe_live_sk:response.data.data.employee_stripe_live_sk,
				   total_Balance:response.data.data.total_Balance,
				   total_withdraw:response.data.data.total_withdraw,
				   total_order:response.data.data.total_order,
				   total_commission_history:response.data.data.total_commission_history,
			   });
			   setCommissionhistory(response.data.data.commission_history);
			}			  
		}).catch(error => {
			 
		});  
	 /* End get dashboard */	
},[]) 

/* Start Value set */
/*const handleInputChange = (event) => {
    event.persist();
    setAmount(event.target.value);       
}*/
 /* End Value set */
/*const handleSubmit = () => {
	var amount1 = (typeof amount !== 'undefined' && amount !== '' && !isNaN(amount)) ? parseInt(amount) : 0;
	if(amount1 > 0){
		
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('amount', amount1);
		formData.append('type', 'employee');
		API.post('send_withdrawal_request', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				//alert(response.data.message);
				gettoastwarning(response.data.message,'');
				
			}else{
				//alert(response.data.message);
				//window.location.href = "/emp-commission";
				gettoastsuccess(response.data.message,'/emp-commission');
			}			  
		}).catch(error => {
			 
		});  
	
	}else{
		setLoading(false);
		//alert('Please enter amount');
		gettoastwarning('Please enter amount','');
	}
}*/
 
    return (
        <>
        {/* ========== Employee Commission Page Start ========== */}
          <section className="gen-wrap">
		  <ToastContainer />
        {/* ========== Navbar Start ========== */}
              <Navbar headerText={'Commissions'}/>
        {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
                   <Col className="mnhmcnt">
        {/* ========== Employee Commission Content Start ========== */}
                     <Col md={12} xs={12} className="">
						 {/*<Row className="mx-0 maintpcmsnbox">
                       <Col md={2} xs={2} className="px-1"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={8} xs={8} className="mcmsn-txtcnt ps-md-0 ">
                          <p>Bank Account Information</p>
                            <h3>John Jane Doe</h3>
                            <Row className="mx-0 intxt-cmsnbox">
                             <Col md={4} xs={6} className="lft-txt">Account Name</Col>
                             <Col md={8} xs={6} className="rgt-txt">: Lorem Ispum</Col>
                             <Col md={4} xs={6} className="lft-txt">Account Number</Col>
                             <Col md={8} xs={6} className="rgt-txt">: Lorem Ispum</Col>
                             <Col md={4} xs={6} className="lft-txt">IFSC Code</Col>
                             <Col md={8} xs={6} className="rgt-txt">: Lorem Ispum</Col>
                            </Row>
                          </Col>
                          <Col md={2} xs={2} className="text-end">
                            <img src= {'assets/images/employee/edt-icn.png'} className="img-fluid" alt="Mcust Img"/>
                          </Col>
						 </Row>*/}
						 <Row className="mx-0 edsbrdtp">
						   <Col md={2} xs={2} className="img-cnt"><img src= {inputs.employee_image} className="img-fluid" alt="Mcust Img"/></Col>
						   <Col md={10} xs={10} className="text-cnt">
							 <h3>{inputs.employee_name}</h3>
							 <p>{inputs.employee_email}</p>
							 
						   </Col>
						   
						 </Row>
        {/* ========== Employee Commission Box Content Start ========== */}
                      <Row xs={{ cols:2, gutter: 2 }} lg={{ cols: 2, gutter: 3}} className="mt-2 ecmsnbox">
                        <Col>
                         <div className=" minbx-flw">
                          <Row className="mx-0">
                            <Col md={3} xs={4} className="align-self-center px-0"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                            <Col md={9} xs={8} className="align-self-center">
                              <h3>{inputs.total_Balance}</h3>
                              <p>Total Balance</p>
                            </Col>
                          </Row>
                         </div>
                        </Col>
                        <Col>
						<NavLink to="/emp-withdrawal-history">
                         <div className="p-3 minbx-flw">
                          <Row className="mx-0">
                            <Col md={3} xs={4} className="align-self-center px-0"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                            <Col md={9} xs={8} className="align-self-center">
                              <h3>{inputs.total_withdraw}</h3>
                              <p>Withdraw</p>
                            </Col>
                          </Row>
                         </div>
						 </NavLink>
                        </Col>
                        <Col>
						
                         <div className="p-3 minbx-flw">
                          <Row className="mx-0">
                            <Col md={3} xs={4} className="align-self-center px-0"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                            <Col md={9} xs={8} className="align-self-center">
                              <h3>{inputs.total_order}</h3>
                              <p>Total Order</p>
                            </Col>
                          </Row>
                         </div>
                        </Col>
                        <Col>
						
                         <div className="p-3 minbx-flw">
                          <Row className="mx-0">
                            <Col md={3} xs={4} className="align-self-center px-0"><img src= {'assets/images/employee/prfl-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                            <Col md={9} xs={8} className="align-self-center">
                              <h3>{inputs.total_commission_history}</h3>
                              <p>Commission History</p>
                            </Col>
                          </Row>
                         </div>
						 
                        </Col>
                      </Row>
        {/* ========== Employee Commission Box Content End ========== */}  
		{/* ========== Communication Transferred Content Box Start ========== */}
			{/*<Row className="mx-0 maintpcmsnbox">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Control type="text" name="amount" placeholder="Enter amount to be Transferred here" onChange={handleInputChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} />
                        </Form.Group>
                        <Col md={12} xs={12} className="btnpopmcstmr"><NavLink to="#" onClick={handleSubmit}><Button variant="black" type="submit">Withdraw</Button></NavLink></Col>
			</Row>*/}
      {/* ========== Communication Transferred Content Box End ========== */}
                      </Col>
        {/* ========== Employee Commission Customer Box Content Start ========== */}
				{commissionhistory && commissionhistory.map((val,index) => {
						return(
                      <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1">
						   {(() => {
								if (val.status===1 || val.status===8) {
									return (<NavLink to={`/emp-receipts/${val.id}`}><img src= {val.image} className="img-fluid" alt="Mcust Img"/></NavLink>)
								}else if (val.status===5) {
									return (<NavLink to={`/emp-refund-receipts/${val.id}`}><img src= {val.image} className="img-fluid" alt="Mcust Img"/></NavLink>)
								}else if (val.status===7) {
									return (<NavLink to={`/emp-refund-request/${val.id}`}><img src= {val.image} className="img-fluid" alt="Mcust Img"/></NavLink>)
								}else{
									return (<NavLink to={`/emp-order-detail/${val.id}`}><img src= {val.image} className="img-fluid" alt="Mcust Img"/></NavLink>)
								}
							})()}
						  </Col>
                          <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>{val.name}</h3>
								<p>Conf. No. {val.order_number}</p>
								<p>Ordered on {val.order_date}</p>
                          </Col>
                          <Col md={3} xs={3} className="align-self-center cnt-txt text-end">
							  <p>{val.status_name}</p>
                          </Col>
                          <Col md={2} xs={2} className="align-self-center text-end">
                           ${val.commission}
							
						   {/*<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                              <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                            </svg>*/}
                          </Col>
                        </Row>
                      </Col>
                      )
					})}   
        {/* ========== Employee Commission Customer Box Content End ========== */}
        {/* ========== Employee Commission Content End ========== */}
                     </Col>
                  </Row>
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )}  
               </Container>
            </section>
        {/* ========== Footer Start ========== */}
            <Footer/>
        {/* ========== Footer End ========== */}
        {/* ========== Employee Commission Page End ========== */}
        </>
    )
}
export default EmpCommi;