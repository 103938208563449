import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

export default function PrivateLoginRoute() {
    let  userid = localStorage.getItem("token") == null ? true : false;
	let type = parseInt(localStorage.getItem("type"));
	var usertype = 1;
    if (userid === false) {
        usertype = type;
    }
	if(usertype===1)
	{
		return (
			<>
				{userid ? <Outlet  /> : <Navigate to="/home" />}
			</>

		)
	}else if(usertype===2){
		return <>{usertype === 2 ? <Navigate to="/mer-login" /> : <Navigate to="/mer-login" />}</>;
	}else if(usertype===3){
		return <>{usertype === 3 ? <Navigate to="/emp-login" /> : <Navigate to="/emp-login" />}</>;
	}

}