import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserLogin.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate,useLocation  } from 'react-router-dom';
import loader from '../../../assests/loader.gif';
import {validatelogin} from '../../../Utils/validate';
import API from '../../../Utils/Api';
import { setUserSession } from '../../../Utils/Common';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const UserLogin = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	var key='';
	if(searchParams.get('key'))
	{
		key = searchParams.get('key');
	}
	const navigate = useNavigate();
	const [values, setValues] = useState({
			password: "",
			showPassword: false,
		});
	const [error,setError] = useState();
	const [success,setSuccess] = useState();
	const [inputs,setInputs] = useState({email_or_phone:'',password:'',remember_me:false});
	const [errors,setErrors] = useState({});
	const [loading,setLoading] = useState(false);

	useEffect(() => {
		  document.title = `User Login | Blue Faucet`;
		  document.getElementById('bodywrap').className='sguppgmain iphoneset';
			window.scrollTo({
			  top: 0,
			  left: 0,
			  behavior: 'smooth', 
			}); 
		    if(localStorage.getItem("remember_me"))
			{
					setInputs({
						email_or_phone:localStorage.getItem("email_or_phone"),
						password:localStorage.getItem("password"),
						remember_me:localStorage.getItem("remember_me")
					});
			}
			
	},[])  
 useEffect(() => {
	 if(key !=='')
	{
	 varifyaccount(key);
	}
},[key])  	 
//Start Login check
const handleSubmit = (event) => {
	event.preventDefault();
	const validationErrors = validatelogin(inputs);
	const noErrors = Object.keys(validationErrors).length === 0;
	setError('');
	setSuccess('');
	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setLoading(true);
		let email_or_phone=inputs.email_or_phone;
		let password=inputs.password;
		
		let formData = new FormData(); 
		formData.append('email_or_phone', email_or_phone);
		formData.append('password', password);
		formData.append('type', 'customer');
		API.post('login', formData)
			.then(response => {
				
			if(response.data.status === 0){
				setError(response.data.message);
				setLoading(false); 
			}else{
			   setError('');
			   setLoading(false);
			   //console.log(response.data.data);
			   setUserSession(response.data.data.token,response.data.data.profile_setup,response.data.data.type);
			   if(inputs.remember_me)
			   {
				   localStorage.setItem("remember_me", true);
				   localStorage.setItem("email_or_phone", email_or_phone);
				   localStorage.setItem("password", password);
			   }else{
				   localStorage.removeItem("remember_me");
				   localStorage.removeItem("email_or_phone");
				   localStorage.removeItem("password");
			   }
			   navigate("/user-video");
			   /*if(response.data.data.profile_setup===1)
			   {
					navigate("/profile-setup");
			   }else if(response.data.data.profile_setup===2){
				   navigate("/payment");
			   }else{
				   navigate("/home");
			   }*/
			}                  
		}).catch(error => {
			 setLoading(false);
			  setError("Something went wrong. Please try again later.");
		});  
	}
}
//End Login check
	/* Start Value set */
	const handleInputChange = (event) => {
	  event.persist();
	  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
	}
	/* End Value set */
	/* Start Password Visible */
	 const handleClickShowPassword = () => {
			setValues({ ...values, showPassword: !values.showPassword });
		}
	/* End Password Visible */

/* Start varify account */
const varifyaccount = (key) => {
	if(key!=='')
	{
		setError('');
		setSuccess('');
		setErrors({});
		setLoading(true);
			let formData = new FormData(); 
			formData.append('token', key);
			formData.append('type', 'customer');
			API.post('verified', formData)
				.then(response => {
					setLoading(false); 
				if(response.data.status === 0){
					//alert(response.data.message);
					//setLoading(false); 
					//window.location.href = "/login";
					gettoastwarning(response.data.message,'/login');
				}else{
				   //alert(response.data.message);
				   //setLoading(false);
				   //window.location.href = "/login";
				   gettoastsuccess(response.data.message,'/login');
				   
				}                  
			}).catch(error => {
				 setLoading(false);
				  //alert("Something went wrong. Please try again later.");
				  //window.location.href = "/login";
				  gettoastwarning("Something went wrong. Please try again later.",'/login');
			});  
	}
}
/* End varify account */

// Start Gmail Social Login
const handleLoginSuccess = (response) => {
    //console.log('Login successful:', response.profileObj.googleId);
	var email=response.profileObj.email;
	var googleId=response.profileObj.googleId;
	var name=response.profileObj.name;
		setError('');
		setSuccess('');
		setErrors({});
		setLoading(true);
			let formData = new FormData(); 
			formData.append('email', email);
			formData.append('social_token', googleId);
			formData.append('type', 'google');
			formData.append('name', name);
			API.post('social_login', formData)
				.then(response => {
					
				if(response.data.status === 0){
					setError(response.data.message);
					setLoading(false); 
				}else{
				   setError('');
				   setLoading(false);
				   setUserSession(response.data.data.token,response.data.data.profile_setup,response.data.data.type); 
				   navigate("/home"); 
				}                  
			}).catch(error => {
				 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			});  
  };
  const handleLoginFailure = (error) => {
    //console.error('Login failed:', error);
	setError(error);
	
	if(error.error==='popup_closed_by_user')
	{
	  window.location.href = '/login';
	}
  };
// End Gmail Social Login
// Start Facebook Social Login
const responseFacebook = (response) => {
	console.log(response);
	if(response.status)
	{
		setLoading(false);
	    setError("Something went wrong. Please try again later.");
		window.location.href = '/login';
	}else{
		var email='';
		if(response.email)
		{
			email=response.email;
		}
	var id=response.id;
	var name=response.name;
		setError('');
		setSuccess('');
		setErrors({});
		setLoading(true);
			let formData = new FormData(); 
			formData.append('email', email);
			formData.append('social_token', id);
			formData.append('type', 'facebook');
			formData.append('name', name);
			API.post('social_login', formData)
				.then(response => {
					
				if(response.data.status === 0){
					setError(response.data.message);
					setLoading(false); 
				}else{
				   setError('');
				   setLoading(false);
				   setUserSession(response.data.data.token,response.data.data.profile_setup,response.data.data.type); 
				   navigate("/home"); 
				}                  
			}).catch(error => {
				 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			});  
	}
  };
// End Facebook Social Login
 
 const handleCheckboxChange = (event) => {
	 var remember_me_lbl='remember_me';
     setInputs(inputs => ({...inputs, [remember_me_lbl]: event.target.checked}));
 }
 
    return (
        <>
          {/* ========== Login Page Start ========== */}
          <section className="gen-wrap gen-main">
		  <ToastContainer />
            <Row className="maincnttpnw mx-0">
                <Col xs={2} md={2} className="align-self-center px-0">
                </Col>
                <Col xs={8} md={8} className="align-self-center text-center">
                  <img src={`${process.env.REACT_APP_BASE_URL}assets/images/logo.svg`} className="img-fluid w-75 mb-3" alt="Logo"/>
                  <p>Your local Market place for <span className="fw-bold">Buy/Sell and Services</span></p>
                </Col>
                <Col xs={2} md={2} className="align-self-center"><p></p></Col>
              </Row>
               <Container>
                  <Row className="genscrn">
                  <Col md={12} lg={12} xl={12} xxl={12} className="toptxtcnt px-0">
                  <div className="maincntsrcn">
					{error && (
					<div className="alert alert-danger">
					  {error}
					</div>)}
					{success && (
						<div className="alert alert-success">
						  {success}
					</div>)}
                     <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                        <Form.Group className="mb-3">
                          <Form.Label>Email or Phone <span>*</span></Form.Label>
                          <Form.Control type="text" name="email_or_phone" placeholder="Enter email or phone number" onChange={handleInputChange} value={inputs.email_or_phone} autoComplete="off" />
						  {errors.email_or_phone && (<p className="text-danger">{errors.email_or_phone}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3 pswinpt">
                         <Form.Label>Password <span>*</span></Form.Label>
                         <Form.Label className="frgtpswd"><NavLink to={`${process.env.REACT_APP_BASE_URL}forgot-password`}><p>Forgot Password?</p></NavLink></Form.Label>
                        <Form.Control type={values.showPassword ? "text" : "password"} name="password" id="password" placeholder="At least 8 characters" onChange={handleInputChange} value={inputs.password}/>
                          {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
						  {errors.email_or_phone && (<p className="text-danger">{errors.email_or_phone}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                        <Form.Check aria-label="option 1" name="remember_me" label="Remember me" className="form-check-input-user" value="1" checked={inputs.remember_me} onChange={handleCheckboxChange}/>
                        </Form.Group>
                        <div className="mb-3 btn-dv">
                           <Button type="submit">Login</Button>
                        </div>
                        <div className="mb-3 text-center lgncnt">
                           <p>or</p>
                        <Row className="athrlgn mx-0">
                          <Col xs={6} md={6} className="px-1 px-md-2">
                          <NavLink to="#">
                           <div className="clkdv google-btn-box">
							<GoogleLogin 
							  clientId={`${process.env.REACT_APP_GMAIL_CLIENT_ID}`}
							  buttonText="Google"
							  onSuccess={handleLoginSuccess}
							  onFailure={handleLoginFailure}
							  cookiePolicy={'single_host_origin'}
							/> 
                           </div>
                          </NavLink>
                          </Col>
                          <Col xs={6} md={6} className="px-1 px-md-2">
                          <NavLink to="#">
                           <div className="clkdv facebook-btn-box">
							<FacebookLogin
							  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
							  autoLoad={false}
							  fields="name,email,picture"
							  callback={responseFacebook}
							  icon="fa-facebook"
							 textButton="Facebook"
							/>
                           </div>
                          </NavLink>
                          </Col>
                        </Row>
                        <div className="lgnlsttxt"><p>Don’t have an account? <NavLink to={`${process.env.REACT_APP_BASE_URL}signup`}>Sign Up</NavLink></p></div>
                        </div>
                      </Form>
                      </div>
                  </Col>
                  </Row>
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )}  
               </Container>
            </section>
            {/* ========== Login Page End ========== */}
        </>
    )
}
export default UserLogin;