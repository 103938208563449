import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form} from 'react-bootstrap';
import { useNavigate, NavLink } from 'react-router-dom';
import API from '../../Utils/Api';

const Navbar = (props) => {
const navigate = useNavigate();
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [noticount, setNoticount] = useState(false);



useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });  
const noticount = () => {
	/* Start get notification count */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('notification_count', formData1)
			.then(response => {
		setNoticount(false);
			if(response.data.data > 0){
				setNoticount(true);
			}                
		}).catch(error => {
			 
		});  
	 /* End get notification count */
	}
	noticount();
	const intervalId = setInterval(noticount, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);

	
},[])  
 return (
        <>
{/* ========== Nevbar Content Start ========== */}
<Row className="nvcus-main">
   <Col md={12} className="mb-2 tpnv-cntnon">
   <img src="assets/images/logo.png" className="img-fluid" alt="Logo"/>
   <div className="demo d-none">
      <select>
         <option>India</option>
         <option>Canada</option>
         <option>Germany</option>
         <option>Australia</option>
      </select>
   </div>
   </Col>
   <Col md={1} xs={1} className="align-self-center bcnv-arwbtn px-0">
   <button onClick={() =>
      navigate(-1)} className="back-btn">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
         <path d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28533 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
      </svg>
   </button>
   </Col>
   <Col md={10} xs={9} className="align-self-center">
   <div className="constrain hide-inpt">
      <h3 className="svdo">Your Dashboard !</h3>
   </div>
   <h3 className="showtxt cnttxtnev">{props.headerText}</h3>
   </Col>
   <Col md={1} xs={2} className="align-self-center nvbtn">
   <Button className="mdsbrdshow" href="sales-notification" variant=""><img src="assets/images/sales//svg/nitiofication.svg" className="img-fluid" alt="Logo"/>{noticount && <span></span> }</Button>{' '}
   </Col>
   <Col className="d-none">
   <div className="popupwrng d-none">
      <Button variant="primary" onClick={handleShow}>Launch demo modal</Button>
      <Modal show={show} onHide={handleClose} className="popupwrngcnt">
         <Modal.Header closeButton>
            <h3 className="mdlttltxt">Invitation Form</h3>
         </Modal.Header>
         <Modal.Body>
            <Col md={12} xs={12} className="pptp">
            <h3>Congratulations!</h3>
            <p>The merchant you are looking for has not yet joined BlueFaucet. Please refer to the <NavLink to="#">Sales Program </NavLink> for detail to send an invitation. Remember, if you don't, someone else might seize the chance.</p>
            </Col>
            <Col md={12} xs={12} className="ppinpt">
            <Form>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Default Name Here" />
               </Form.Group>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter customer email" />
               </Form.Group>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Phone Number<span>*</span></Form.Label>
                  <Form.Control type="email" placeholder="987 654 321 00" />
               </Form.Group>
            </Form>
            </Col>
            <Col md={12} xs={12} className="ppintn">
            <h3>Invitation Letter</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages...</p>
            </Col>
         </Modal.Body>
         <Modal.Footer className="d-none">
            <Button variant="secondary" onClick={handleClose}>
            Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
            Save Changes
            </Button>
         </Modal.Footer>
      </Modal>
   </div>
   </Col>
</Row>
{/* ========== Nevbar Content End ========== */}
</>
  )
}
export default Navbar;