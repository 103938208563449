import React, { useEffect,useState } from 'react';
import { Row, Col, Container,Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './SelectRole.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import logopwa from "../../../assests/logopwa.png";
import uploadpwa from "../../../assests/upload.png";

const SelectRole = () => {

const [showandroid, setShowandroid] = useState(false);
	
const [showiphone, setShowiphone] = useState(false);

const [promptEvent, setPromptEvent] = useState(null);

useEffect(() => {
      document.title = `Login/Signup | Blue Faucet`;
      document.getElementById('bodywrap').className='vdopgmain newscrnpgmain';
	  
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
	
    if (window.matchMedia('(display-mode: standalone)').matches) {
      //alert("This is running as standalone.");
    } else {
      var sd = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      if (sd === 'iPhone' || sd === 'iPad' || sd === 'iPod') {
		  /*setTimeout(function(){ 
		      var hours = 120; // Reset when storage is more than 24hours
				var now = new Date().getTime();
				var setupTime = localStorage.getItem('setupTime');	
				var tt=0;
			   if(setupTime==null)
			   {
				   
			   }else{
					var tt=parseInt(setupTime);
			   }
				var t=now-tt;
				if(t > hours*60*60*1000) {
				   setShowiphone(true);
				}
			}, 2000);*/
			setShowiphone(true);	
	  }
	}

	window.addEventListener('beforeinstallprompt', (e) => {
         // Prevent Chrome 67 and earlier from automatically showing the prompt
         e.preventDefault();
         // Stash the event so it can be triggered later.
         /* */
         const dp = getPWADisplayMode()
         //console.log(dp)
         // $('#acessando_em').html(dp)
         //
         if (dp === 'browser') {
			  setShowandroid(true);
           /*setTimeout(function(){ 
		      var hours = 120; // Reset when storage is more than 24hours
				var now = new Date().getTime();
				var setupTime = localStorage.getItem('setupTime');	
				var tt=0;
			   if(setupTime==null)
			   {
				   
			   }else{
					var tt=parseInt(setupTime);
			   }
				var t=now-tt;
				if(t > hours*60*60*1000) {
				   setShowandroid(true);
				}
			}, 2000);*/
		 }
	});

	window.addEventListener('beforeinstallprompt', (event) => {
			event.preventDefault();
      setPromptEvent(event);
	});
	
      const splash = document.querySelector('.splash'); 
      const timer = setTimeout(() => {
      splash.classList.add('display-none');
      var element = document.getElementById("showaftr");
      element.classList.remove("nonebfr");
    }, 2500);
    return () => clearTimeout(timer);
	
},[])  

function getPWADisplayMode() {
	const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
	if (document.referrer.startsWith('android-app://')) {
		return 'twa';
	} else if (isStandalone) {
		return 'standalone';
	}
	return 'browser';
}

function showInstallPrompt() {
	setShowandroid(false);
	if (promptEvent){
    // Show the install prompt
    promptEvent.prompt();
    // Wait for user choice
    promptEvent.userChoice
      .then(choiceResult => {
        // Log the result
        console.log('User Choice', choiceResult);
      })
      .catch(error => {
        // Handle any errors
        console.error('Error handling installation', error);
      });
  }	
}

const handleCloseandroid = () =>{
	setShowandroid(false);
	/*var now:any;
	now= new Date().getTime();
	localStorage.setItem('setupTime', now);*/
}

const handleCloseiphone = () =>{
	setShowiphone(false);
	/*var now:any;
	now= new Date().getTime();
	localStorage.setItem('setupTime', now);*/
}

    return (
        <>
        {/* ========== NEW PAGE Page Start ========== */}
        <div className="splash text-center">
           <img src="assets/images/logo.svg" className="img-fluid" alt="Logo"/>
        </div>
        <section className="gen-wrap nonebfr" id="showaftr">
           <Container>
            {/* ========== Navbar Start ========== */}
              <Row className="gen-box">
                 <Col md={12} lg={12} xl={12} xxl={12} className="tpcntgen">
                 <Row className="maincnttp">
                    <Col xs={2} md={2}>
						{/*<button onClick={() => navigate(-1)} className="back-btn">
                       <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                       </svg>
						</button>*/}
                    </Col>
                    <Col xs={8} md={8}>
                    <h3>Login/Signup as</h3>
                    </Col>
                    <Col xs={2} md={2}>
                    <NavLink to="/signup">
                       <p></p>
                    </NavLink>
                    </Col>
                 </Row>
                 </Col>
              </Row>
            {/* ========== Navbar End ========== */}
            {/* ========== Content Start ========== */}
              <Row className="">
                 <Col md={12} lg={12} xl={12} xxl={12} className="toptxtcnt">
                  <Row className="all-screan-cont mx-0">
                     <Col md={12} xs={12}><NavLink to="/login" className="blue-clr">Customer</NavLink></Col>
                     <Col md={12} xs={12}><NavLink to="/mer-login" className="green-clr">Merchant</NavLink></Col>
                     <Col md={12} xs={12}><NavLink to="/emp-login" className="green-clr">Employee</NavLink></Col>
                  </Row>
                 </Col>
              </Row>
              {/* ========== Content End ========== */}
           </Container>
        </section>
        {/* ========== NEW PAGE Page End ========== */}
		
		 {/* ========== Android Popup Start ========== */}
			<Modal show={showandroid} onHide={handleCloseandroid} className="msmodal modal-sm modal-pwa" centered>
				<div className="modal-header">
				  <h5 className="modal-title">Install App</h5>
				  <button className="close-button btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseandroid}>
					
				  </button>
				</div>
			   <Modal.Body>
					<div className="row">
                    <div className="col-12 px-3">
                        <div className="float-left pwa_w">
                            <img src={logopwa} className="img-fluid pwa_img" alt="Logo" />
                        </div>
                        <div className="float-left px-2 pwa_w_cal">
                            <h4 className="mb-0 pwa_font_size">Bluefaucet</h4>
                            <p className="">bluefaucet.com</p>
                        </div>
                    </div>
                    </div>
					<p className="pwa_p" >
                    <button className="btn btn-primary w-100 rounded-pill pwa_btn" type="button" onClick={() =>showInstallPrompt()}>
                        Install APP
                      </button>
                    </p>   
			   </Modal.Body>
			   <Modal.Footer className="text-center d-block">
					<button type="button " className="btn btn-secondary pwa_foot_btn" data-bs-dismiss="modal" onClick={() =>handleCloseandroid()}  >Close</button>
			   </Modal.Footer>
			</Modal>
	  {/* ========== Android Popup End ========== */}
	  {/* ========== iphone Popup Start ========== */}
			<Modal show={showiphone} onHide={handleCloseiphone} className="msmodal modal-sm modal-pwa" centered>
			   <div className="modal-header">
				  <h5 className="modal-title">Install App</h5>
				  <button className="close-button btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseiphone}>
					
				  </button>
				</div>
			   <Modal.Body>
				<div className="row">
                <div className="col-12 px-3">
                    <div className="float-left pwa_w">
                        <img src={logopwa} className="img-fluid pwa_img" alt="Logo" />
                    </div>
                    <div className="float-left px-2 pwa_w_cal" >
                        <h4 className="mb-0 pwa_font_size">Bluefaucet</h4>
                        <p className="">bluefaucet.com</p>
                    </div>
                </div>
                </div>
			   
			   <p className="aroid_p">
					<h4>Install App</h4>
                	<h5 className="pwa_h5">Use Safari Browser & Tap <img src={uploadpwa} height="20px" width="20px" alt="Logo" /> then "Add to Home Screen"</h5>
                </p>
			   
			   </Modal.Body>
			    <Modal.Footer className="text-center">
					<button type="button" className="btn btn-secondary pwa_foot_btn" data-bs-dismiss="modal" onClick={() =>handleCloseiphone()}  >Close</button>
			   </Modal.Footer>
			</Modal>
	  {/* ========== iphone Popup End ========== */}
		
        </>
    )
}

export default SelectRole;