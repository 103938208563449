import React, { useEffect,useState } from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import { NavLink,useParams } from "react-router-dom";
import './MerCustOrder.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';

const MerCustOrder = () => {
const { id } = useParams();
const [loading,setLoading] = useState(true);

const [inputs,setInputs] = useState({email:'',name:'',image:''});

const [order,setOrder] = useState([]);

useEffect(() => {
      document.title = `Merchant Order Appointment | Blue Faucet`;
      document.getElementById('bodywrap').className='ordrapmntpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
	
	/* Start get order data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('user_id', id);
		API.post('get_customer_invitation_order_history', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setInputs({
				   email:response.data.data.email,
				   name:response.data.data.name,
				   image:response.data.data.image,
			   });
			   setOrder(response.data.data.order);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order data */
	
},[id])  


const linksetser = (id,status) => {
	var html='';
	if(status===1 || status===8){
		html='/mer-receipts/'+id;
	}else if(status===5){
		html='/mer-refund-receipts/'+id;
	}else if(status===7){
		html='/mer-refund-request/'+id;
	}else{
		html='/mer-order-detail/'+id;
	}
	return html;
}

    return (
      <>
      {/* ========== Merchant Order Appointment Page Start ========== */}
         <section className="gen-wrap">
		
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Orders History'}/>
      {/* ========== Navbar End ========== */}
         <Container>
			<Row className="mx-0 mianhmdwncnt">
                  <Col className="mnhmcnt">
					  <Col md={12} xs={12} className="mt-4">
						<Col md={12} xs={12}>
							<p className="text-center mb-0">{inputs.name}</p>
							<p className="text-center">{inputs.email}</p>
						</Col>
						 {order.length >0 ? (
						<div id="mcobox">
						{order && order.map((val,index) => {
						return(
						<Col md={12} xs={12} className="mcusbx">
                        <NavLink to={linksetser(val.id,val.status)} >
                           <Row className="mx-0 main-ord-box">
                              <Col md={2} xs={2} className="align-self-center px-1">
                              <img src={val.image} className="img-fluid main-img" alt="Mcust Img"/></Col>
                              <Col md={6} xs={6} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                              <h3>{val.name}</h3>
                              <p>{val.date}</p>
                              </Col>
                              <Col md={4} xs={4} className="align-self-center text-end">
							  <span className="arwtxt">{val.status_name}</span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                                 <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                              </svg>
                              </Col>
                           </Row>
                        </NavLink> 
                        </Col>
						)
					  })} 
					  </div>
					  ) : (
						<Row className="mx-0">
							<p className="text-center mt-4">No data found</p>
						</Row>	
                       )} 		
					  </Col>
				  </Col>
		   </Row> 

		 {loading && (
		   <div className="loadingClass">
				<img src={loader} alt="loader"/>
			</div>
		   )}  
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
      <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Order Appointment Page End ========== */}
        </>
    )
}
export default MerCustOrder;