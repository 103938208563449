import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

export default function PrivateRoleLoginRoute() {
    let  userid = localStorage.getItem("token") == null ? true : false;
	let type = parseInt(localStorage.getItem("type"));
	var usertype = 1;
    if (userid === false) {
        usertype = type;
    }
	if(usertype===1)
	{
		return (
			<>
				{userid ? <Outlet  /> : <Navigate to="/home" />}
			</>

		)
	}else if(usertype===2){
		return (
			<>
				{userid ? <Outlet  /> : <Navigate to="/mer-dashboard" />}
			</>

		)
	}else if(usertype===3){
		return (
			<>
				{userid ? <Outlet  /> : <Navigate to="/emp-dashboard" />}
			</>

		)
	}
}