import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerCoupon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import copy from 'clipboard-copy';


const MerCoupon = () => {
const [loading,setLoading] = useState(true);
const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);


const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);


const [show3, setShow3] = useState(false);
const handleClose3 = () => setShow3(false);


const [show22, setShow22] = useState(false);
const handleClose22 = () => setShow22(false);
const handleShow22 = () => setShow22(true);
const [inputslnk,setInputslnk] = useState({url:'',twitterLink:'',instagramLink:'',facebooklink:'',whatsappLink:'',emailLink:'',phonelink:''});
const [inputspro,setInputspro] = useState({name:'',email:'',image:'',name_chg:''});

const [coupon, setCoupon] = useState([]);

const [id, setId] = useState();

useEffect(() => {
      document.title = `Merchant Sending Coupons | Blue Faucet`;
      document.getElementById('bodywrap').className='msndcpnpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
     var url=process.env.REACT_APP_BASE_URL+'signup?key='+localStorage.getItem("token")+'&type=direct';
	 
 /* Start get profile */
let formData1 = new FormData(); 
	formData1.append('token', localStorage.getItem("token"));
	API.post('get_profile', formData1)
		.then(response => {
			setLoading(false);
		if(response.data.status === 0){
			
		}else{
			var name_chg=response.data.data.name.replace(' ', '%20');
			name_chg=name_chg.replace(' ', '%20');
			 const twitterLink = `https://twitter.com/intent/tweet?text=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
const instagramLink = `instagram://library?AssetPath=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
const facebooklink=`https://www.facebook.com/sharer/sharer.php?u=Heres%20the%20link%20I%20wanted%20to%20share:%20`+url;
const whatsappLink = `https://api.whatsapp.com/send?text=Check%20out%20this%20link:%20`+url;

const phonelink=`sms:?body=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
const emailLink = `mailto:?subject=You%20Got%20an%20Invitation%20from%20${name_chg}&body=Here's%20the%20link%20I%20wanted%20to%20share:%20`+url;
//const phone_link=`tel:`+url;	 
	setInputslnk({
			  url:url,
			  twitterLink:twitterLink,
			  instagramLink:instagramLink,
			  facebooklink:facebooklink,
			  whatsappLink:whatsappLink,
			  emailLink:emailLink,
			  phonelink:phonelink
		  }); 
		  setInputspro({
			  name: response.data.data.name,
			  email: response.data.data.email,
			  image: response.data.data.image,
			  name_chg:name_chg
		  }); 
		}			  
	}).catch(error => {
		 
	});  
 /* End get profile */
	 
	 /* Start get coupons data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('coupons', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setCoupon(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get coupons data */
	 
},[]) 
const handleShow2 = (id) => {	
		setId(id);
	setShow2(true);
} 
/* Start delete coupon */
const handleDelete = () => {
	setLoading(true);
		setShow2(false);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('coupon_id', id);
	API.post('delete_coupon', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			const updatedCou = coupon.filter((coup) => coup.id !== id);
				setCoupon(updatedCou);
		}                  
	}).catch(error => {
		 
	});  
}
/* End delete coupon */
const getCoupontype = (val,discount_percentage,minimum_purchase_amount,item_name_buy,product_item_name,buy_qty,get_qty) => {
	var type='';
	if(val==='Buy & Get')
	{
		type='Buy  '+buy_qty+' '+item_name_buy+' and Get '+get_qty+' '+product_item_name+' free of Charge';
	}else if(val==='Discount Offer'){
		type=discount_percentage+'% discount when purchase over $'+minimum_purchase_amount;
	}else{
		type=val;
	}
	return type;
}

const shareAll = () => {
	var title='You Got an Invitation from '+inputspro.name;
  var url1=inputslnk.url;
	try {
      navigator.share({
        title: title,
        text: "Here's the link I wanted to share:",
        url: url1,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
}
const copyToClipboard = () => {
    const linkToCopy = inputslnk.url

    copy(linkToCopy)
      .then(() => {
        
      })
      .catch((error) => {
        console.error('Copy failed: ', error);
      });
};

    return (
      
      <>
      {/* ========== Merchant Sending Coupons Page Start ========== */}
         <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Marketing'}/>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
      {/* ========== Sending Coupons Content Start ========== */}
               <Col md={12} xs={12} className="tptxtucs mt-3">
               </Col>
               <Col md={12} xs={12} className="py-2 miansndngcupn">
               <Row className="msndngcpnbtn my-4">
			   <Col xs={4} md={4} className="btn-clr">
			     <NavLink to="#" onClick={handleShow22} className="btn-share-via btn_mobail_set">Share via…</NavLink>
				 </Col>
                  <Col xs={4} md={4} className="px-0 btn-clr">
                  <NavLink to="/mer-marketing"><Button variant="" className="btn_mobail_set">Welcome Coupon</Button>{' '}</NavLink>
                  </Col>
                  <Col xs={4} md={4} className="btn-dv">
                  <NavLink to="/create-coupon" ><Button variant="" className="btn_mobail_set">Create Coupon</Button></NavLink>
                  </Col>
               </Row>
      {/* ==========  Select Coupon Start ========== */}
               <Col md={12} xs={12} className="">
               <h3 className="mdlttltxt">Coupon List</h3>
               </Col>
			   {coupon.length >0 ? (
                 <Row className="mx-0">
				{coupon && coupon.map((val,index) => {
						return (
						<div>
						  {(() => {
							if (val.image==='1') {
							  return (<Col md={12} xs={12} className="py-2 cupn-crd mb-3">
							   <div className="coupon">
								  <div className="left">
									 <div>Discount</div>
								  </div>
								  <div className="center">
									 <div>
										<h2>{val.coupon_name}</h2>
										<p>Welcome to {val.store_name}</p>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
											{val.return_befor ==='' ? (
												<p>Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p>Return Before : <span>{val.return_befor}</span> </p>
											)}
									 </div>
								  </div>
								  <div className="right">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
									 
								 </div>
							   </div>
							   </Col> )
							}else if (val.image==='2') {
							  return (<Col className="mb-3 main-copn">
								  <Row className="mx-0 second-coupan mb-2">
								  <Col className="align-self-center copn-text">
								    <h5>{val.coupon_name}</h5>
									   <p>Welcome to {val.store_name}</p>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
										{val.return_befor ==='' ? (
										  <>
											<p>Start Date : <span>{val.start_date}</span> </p>
											<p>End Date :<span>{val.start_date}</span> </p>
											</>
										) : (
												<p>Return Before : <span>{val.return_befor}</span> </p>
										)}
								  </Col>
								  <Col className="align-self-end text-end"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/cupn-img.png'} className="img-fluid w-100" alt="Hmtp Postr"/></Col>
								 </Row>
								 <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
									 
								 </div>
							 </Col>)
							}else if (val.image==='3') {
							  return (<Col className="mb-3 main-copn">
								<Row className="mx-0 third-copn ">
								   <Col className="align-self-end text-white col copn-text">
									<h5>{val.coupon_name}</h5>
									<p>Welcome to {val.store_name}</p>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
											{val.return_befor ==='' ? (
												<p>Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p>Return Before : <span>{val.return_befor}</span> </p>
											)}
								   </Col>
								   
								  </Row>
								  <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
									 
								 </div>
							</Col>)
							}else if (val.image==='4') {
							  return (<Col className="mb-3 main-copn">
									{(() => {
								if (val.coupon_type==='Welcome coupon'){
									return (<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">{val.discount_percentage}% for all new members joining your membership has been set up</p>
									   </Col>
									  </Row>)
									}else{
										return (<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">{val.coupon_name}</h5>
										<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
												<p className="text-dark">Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Before : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>)
									}										
								})()}  
								{(() => {
								if (val.coupon_type!=='Welcome coupon'){
									  return (<div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
								     </div>)
								}										
								})()} 
								</Col>)
							}else if (val.image==='5') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 five-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
										  <p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
									  <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
								     </div>
								</Col>)
							}else if (val.image==='6') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 six-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
										 <p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
									  <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
								     </div>
								</Col>)
							}else if (val.image==='7') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 seven-copn justify-content-end ">
									   <Col md={6} xs={8} className="align-self-center text-white col copn-text text-end col-6 mt-5">
										<h5 className="">{val.coupon_name}</h5>
										<p>Welcome to {val.store_name}</p>
											<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
										{val.return_befor ==='' ? (
										  <>
											<p>Start Date : <span>{val.start_date}</span> </p>
											<p>End Date :<span>{val.start_date}</span> </p>
											</>
										) : (
												<p>Return Before : <span>{val.return_befor}</span> </p>
										)}
									   </Col>
									  </Row>
									  <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
								     </div>
								</Col>)
							}else if (val.image==='8') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eight-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
										 <p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
									  <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
								     </div>
								</Col>)
							}else if (val.image==='9') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 nine-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
										 <p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
									  <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
								     </div>
								</Col>)
							}else if (val.image==='10') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 ten-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
										<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
									  <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
								     </div>
								</Col>)
							}else if (val.image==='11') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eleven-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
										<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
									  <div className="coup-delete">
									 <NavLink to="#" onClick={() =>handleShow2(val.id)}>
										<img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
									 </NavLink>
								     </div>
								</Col>)
							}															
						  })()}
						</div>
					  )
					 
					  })}  
                 </Row>
				 ) : (
						<Row className="mx-0">
							<p className="text-center mt-4">No data found</p>
						</Row>
				 )} 
               
      {/* ========== Select Coupon End ========== */}
		  {/*<Row className="mt-3 mb-5">
                  <Col className="btndvcupn">
                  <NavLink to="#" onClick={handleShow3}>
                     <Button type="button" className="btn">Submit</Button>
                  </NavLink>
                  </Col>
		  </Row>*/}
               </Col>
               <div className="madcustmrpop">
                  <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" centered>
                     <Modal.Header closeButton>
                        <h3 className="mdlttltxt">Create Coupon</h3>
                     </Modal.Header>
                     <Modal.Body>
                        <Col md={12} xs={12} className="ppinpt">
                        <Form>
                           <Form.Group className="mb-3">
                              <Form.Label className="fw-bold">Name of Coupon</Form.Label>
                              <Form.Control type="text" placeholder="Enter name of coupon" />
                           </Form.Group>
                           <Row className="mb-3">
                              <Col>
                              <Form.Group className="">
                                 <Form.Label className="fw-bold">Start (On 00:00 AM)</Form.Label>
                                 <Form.Control type="date" placeholder="" />
                              </Form.Group>
                              </Col>
                              <Col>
                              <Form.Group className="">
                                 <Form.Label className="fw-bold">End (On 12:59 PM)</Form.Label>
                                 <Form.Control type="date" placeholder="" />
                              </Form.Group>
                              </Col>
                           </Row>
                           <Col md={12} xs={12} className="mb-2">
                           <Form.Label column sm={12} className="px-md-1 fw-bold">Choose Your Offer</Form.Label>
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="checkbox"label="Double Coupon is “Not” Allowed."name="formHorizontalRadios"id="formHorizontalRadios1"/>
                              </span>
                              <span>
                                 <Form.Check type="checkbox"label="One-time use only"name="formHorizontalRadios"id="formHorizontalRadios2"/>
                              </span>
                              <span>Item only</span>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <Form.Label column sm={12} className="px-md-1 fw-bold">Choose Your Offer</Form.Label>
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="Buy" name="formHorizontalRadiosmsc1" id="formHorizontalRadiosmsc1"/>
                              </span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>Get</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>Free, For</span>
                              <span>
                                 <Form.Control className="thdcrctrmore" type="text"/>
                              </span>
                              <span>Item only</span>
                           </div>
                           </Col>
                           <Col className="my-3">
                           <Form.Label className="">Ex.: Buy <u>1</u> get <u>1</u> free, for <u>Coca</u> item only. OR Buy <u>Cola</u> get <u>Soda</u> free, for ___ item only.</Form.Label>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="" name="formHorizontalRadiosmsc1" id="formHorizontalRadiosmsc1"/>
                              </span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>% Discount When Purchase Over $</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="Return before" name="formHorizontalRadiosmsc1" id="formHorizontalRadiosmsc1"/>
                              </span>
                              <span>
                                 <Form.Control type="date"/>
                              </span>
                              <span>and get an</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>% Discount</span>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="Or use your own words:" name="formHorizontalRadiosmsc1" id="formHorizontalRadiosmsc1"/>
                              </span>
                              <span>
                                 <Form.Control className="fifthwdtmore" type="text"/>
                              </span>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="mb-2">
                           <Form.Label column sm={12} className="px-md-1 fw-bold">Set Loyalty Program:</Form.Label>
                           <div className="inlnflexbox">
                              <span>
                                 <Form.Check type="radio"label="Buy" name="formHorizontalRadiosmsc2" id="formHorizontalRadiosmsc2"/>
                              </span>
                              <span>
                                 <Form.Control className="thdcrctrmore" type="text"/>
                              </span>
                              <span>Item</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>Times and Get</span>
                              <span>
                                 <Form.Control type="text"/>
                              </span>
                              <span>for free</span>
                           </div>
                           </Col>
						   <Form.Label className="fw-bold">Choose Coupon Image</Form.Label>
                           <Col md={12} xm={12} className="selctimg-mrktng">
                           <div className="cusradio cupn-scrlmn">
                              <input className="radio-label" type="radio" name="yesno" id="yesno1" /><label className="button-label cupn-scldv" htmlFor="yesno1"><img src= {'assets/images/coupan.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                              <input className="radio-label" type="radio" name="yesno" id="yesno2"/><label className="button-label cupn-scldv" htmlFor="yesno2"><img src= {'assets/images/coupan-2.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							  <input className="radio-label" type="radio" name="yesno" id="yesno3"/><label className="button-label cupn-scldv" htmlFor="yesno3"><img src= {'assets/images/coupan-3.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							  <input className="radio-label" type="radio" name="yesno" id="yesno4"/><label className="button-label cupn-scldv" htmlFor="yesno4"><img src= {'assets/images/coupan-4.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							  <input className="radio-label" type="radio" name="yesno" id="yesno5"/><label className="button-label cupn-scldv" htmlFor="yesno5"><img src= {'assets/images/coupan-5.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							  <input className="radio-label" type="radio" name="yesno" id="yesno6"/><label className="button-label cupn-scldv" htmlFor="yesno6"><img src= {'assets/images/coupan-6.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							  <input className="radio-label" type="radio" name="yesno" id="yesno7"/><label className="button-label cupn-scldv" htmlFor="yesno7"><img src= {'assets/images/coupan-7.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							  <input className="radio-label" type="radio" name="yesno" id="yesno8"/><label className="button-label cupn-scldv" htmlFor="yesno8"><img src= {'assets/images/coupan-8.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                           </div>
						 
                           </Col>
                           <Form.Group className="mb-3">
                              <Form.Label className="fw-bold">Description</Form.Label>
                              <Form.Control as="textarea" rows={3} placeholder="Enter coupon description" />
                           </Form.Group>
                           <Col md={12} xs={12} className="btnpopmcstmr my-3">
                           <NavLink to="#" onClick={() => handleClose1()} ><Button variant="black" type="button">Create Coupon</Button></NavLink>
                           </Col>
                           <Col className="d-none">
                           <Form.Group className="mb-3">
                              <Form.Label>Category</Form.Label>
                              <Form.Select aria-label="Default select example">
                                 <option>Category</option>
                                 <option defaultValue="1">Select Category</option>
                              </Form.Select>
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Status</Form.Label>
                              <Form.Select aria-label="Default select example">
                                 <option>Active</option>
                                 <option defaultValue="1">deactivate</option>
                              </Form.Select>
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>$ Amount or % Discount</Form.Label>
                              <Form.Control type="text" placeholder="$ or %" />
                           </Form.Group>
                           <Col className="upladcustm">
                           <Form.Label>Select Image</Form.Label>
                           <div id="dropzone">
                              <div><img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> Upload Image</div>
                              <input type="file" accept="image/png, application/pdf" />
                           </div>
                           </Col>
                           </Col>
                        </Form>
                        </Col>
                     </Modal.Body>
                  </Modal>
                  <div className="inmodal">
      {/* ========== Delete Popup Start ========== */}
                     <Modal show={show2} onHide={handleClose2} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Are you sure you want to delete?</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Row>
                              <Col className="btn-clr">
                              <NavLink to="#" onClick={handleDelete}><Button variant="">Yes</Button></NavLink>
                              </Col>
                              <Col className="btn-dv">
                              <NavLink to="#" onClick={handleClose2}><Button variant="">No</Button></NavLink>
                              </Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Delete Popup End ========== */}
                 <Col className="md={12} xs={12}">
                 <div className="inmodal">
                  {/* ========== Delete Popup Start ========== */}
                    <Modal show={show3} onHide={handleClose3} className="popupwrngcnt">
                       <Modal.Header closeButton></Modal.Header>
                       <Modal.Body>
                         <Row className="cnfmtnpg justify-content-center mx-0">
                           <Col md={12} xs={12} className="px-0 ">
                            <div className="text-center ">
                              <img src= {'assets/images//svg/confrmation-check.svg'} className="img-fluid mb-3" alt="Cnfrmation Img"/> 
                              <h3>Coupon Sent</h3>
                            </div>
                           </Col>
                           <Col md={8} xs={8} className="btn-ordrcnfrmation text-center mt-3 mb-5">
                            <NavLink to="/mer-dashboard"><Button variant="home">Done</Button>{' '}</NavLink>
                           </Col>
                         </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Delete Popup End ========== */}
				  {/* ========== Share Popup Start ========== */}
				  <Modal size="lg" show={show22} onHide={handleClose22} aria-labelledby="example-modal-sizes-title-lg" className="trdmodal" id="trdmdl3">
                     <Modal.Body>
                        <Row className="mx-0 tpcrd-pop3">
                           <Col md={2} xs={2} className="align-self-center">
                           <img src={inputspro.image} className="img-fluid rounded-circle" alt="Hm Img"/></Col>
                           <Col md={10} xs={10} className="align-self-center mb-3">
                           <h3>{inputspro.name}</h3>
                           <p>{inputspro.email}</p>
                           </Col>
                           <Col md={12} xs={12} className="cpytxtdv mt-3">
                           <h4 className="mdlttltxt">Copy your store link and paste to the social media</h4>
                           <div className="copy-text">
                              <input type="text" className="text" value={inputslnk.url} disabled />
                              <button onClick={copyToClipboard}>Copy</button>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="dwncnt3">
                           <h4>Share via</h4>
                           <ul>
						   {/*<NavLink to="#">
                                 <li>
                                    <img src="assets/images/merchant/icn-1.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Yelp</p>
                                 </li>
						   </NavLink>*/}
                              <NavLink to={inputslnk.twitterLink}>
                                 <li>
                                    <img src="assets/images/merchant/icn-2.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Twitter</p>
                                 </li>
                              </NavLink>
                              <NavLink to={inputslnk.instagramLink}>
                                 <li>
                                    <img src="assets/images/merchant/icn-3.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Instagram</p>
                                 </li>
                              </NavLink>
                              <NavLink to={inputslnk.facebooklink}>
                                 <li>
                                    <img src="assets/images/merchant/icn-4.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Meta</p>
                                 </li>
                              </NavLink>
                              <NavLink to={inputslnk.whatsappLink}>
                                 <li>
                                    <img src="assets/images/merchant/icn-5.png" className="img-fluid" alt="Hm Img"/>
                                    <p>WhatsApp</p>
                                 </li>
                              </NavLink>
							  <NavLink to="#" onClick={shareAll}>
                                 <li>
                                    <img src="assets/images/merchant/share.png" className="img-fluid" alt="Hm Img"/>
                                    <p>Share</p>
                                 </li>
                              </NavLink>
                           </ul>
                           </Col>
                        </Row>
                     </Modal.Body>
                  </Modal>
				  {/* ========== Share Popup End ========== */}
                 </div>
                 </Col>
                  </div>
               </div>
      {/* ========== Sending Coupons Content End ========== */}
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
      <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Sending Coupons Page End ========== */}
        </>
    )
}
export default MerCoupon;