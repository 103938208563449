//import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useState  } from 'react';
import {  Row, Col} from 'react-bootstrap';
import { NavLink,useNavigate } from "react-router-dom";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";


export const PaymentForm = (props) => {
	
  const stripe = useStripe();
  const elements = useElements();
  const [loading,setLoading] = useState(false);
const navigate = useNavigate();
 const [checkoutError, setCheckoutError] = useState(); 
 
const cardNumberElementOptions = {
    // Set default values here
    placeholder: 'Enter card number',
    style: {
      base: {
        fontSize: '16px',
      },
    },
  };
 
  const handleSubmit = async () => {

	const cardElement = elements.getElement(CardNumberElement)
   stripe.createToken(cardElement)
      .then((payload) => {
		  if(payload.error){
			  alert(payload.error.message);
		  }else{
			
				var stripeToken=payload.token.id;
				setLoading(true);
				let store_id=props.data.store_id;
				let tips=props.data.tips;
				var payment_type='';
				if(props.keydata==='first')
				{
					payment_type='cash';
				}else if(props.keydata==='second')
				{
					payment_type='credit';
				}else if(props.keydata==='third')
				{
					payment_type='debit';
				}
				var card_name='';
				var card_number=payload.token.card.last4;
				var card_month=payload.token.card.exp_month;
				var card_year=payload.token.card.exp_year;
				var card_cvv='';
				
				
				let formData = new FormData();
				formData.append('token', localStorage.getItem("token"));
				formData.append('stripeToken', stripeToken);
				formData.append('amount', props.data.payable_total);
				formData.append('payment_type', payment_type);
				formData.append('card_name', card_name);
				formData.append('card_number', card_number);
				formData.append('card_month', card_month);
				formData.append('card_year',card_year);
				formData.append('card_cvv',card_cvv);
				formData.append('tips', tips);
				formData.append('store_id', store_id);
				formData.append('order_id', props.data.order_id);
				formData.append('sales_tax_rate', props.data.sales_tax_rate);
				API.post('booked_order', formData)
					.then(response => {
					if(response.data.status === 0){
						alert(response.data.message);
						setLoading(false); 
					}else{
						setLoading(false);
					   //navigate('/product-order-confirmation');
					   navigate('/product-order-confirmation',{ state: { message:response.data.message } });
					}                  
				}).catch(error => {
					 setLoading(false);
					  alert("Something went wrong. Please try again later.");
				});
		  }
	  
	  });
	  
  };
  
 const handleChange = (e) => {
	 console.log(e.error);
    e.error ? setCheckoutError(e.error.message) : setCheckoutError();
  };
 
  
  return (
    
	<Row xs={{ gutterX: 1 }} className="mx-0">
		<Col md={12} xs={12} >
		 <div className="mb-3">
			<CardNumberElement  className="mb-3 form-control"  options={cardNumberElementOptions}  onChange={(e) => handleChange(e)} />
			
		 </div>
		</Col>
	   <Col md={12} xs={12} >
		 <div className="mb-3">
			<CardExpiryElement  className="mb-3 form-control"  onChange={(e) => handleChange(e)} />
			
		 </div>
		</Col>
		<Col md={12} xs={12} >
		 <div className="mb-3">
			<CardCvcElement  className="mb-3 form-control" onChange={(e) => handleChange(e)}/>
			
		 </div>
		</Col>
          <Col md={12}>
		  <p>Note: There is no card details you added. <NavLink to="/payment-after-login">CLICK HERE</NavLink> to add your card details.</p>
		</Col> 
	          <div className="text-danger mb-2">{checkoutError && <div>{checkoutError}</div>}</div>
			<button type="button" className="btn btn-primary" onClick={handleSubmit}>Pay The Bill </button>
	 {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
	 </Row>
	
  );
}
