import React, { useEffect,useState } from 'react';
import { Row,Col, Container,Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserMembershipInvitation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess } from '../../../Utils/Common';

const UserMembershipInvitation = () => {
	
	const [loading,setLoading] = useState(true);
	const [membership, setMembership] = useState([]);

	
useEffect(() => {
      document.title = `User Favorites | Blue Faucet`;
      document.getElementById('bodywrap').className='usrfvpgmain uf2';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
 /* Start get membership invitation data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_member_invitation_by_user', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setMembership(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get membership invitation data */
	
},[])  

/* Start accept */
const accept = (id) => {		
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	formData.append('status', 1);
	API.post('change_member_invitation_status_by_user', formData)
		.then(response => {
		
		if(response.data.status === 0){
			
		}else{
			gettoastsuccess(response.data.message,'');
			let updateData = membership.map((item, i) => {
			  return id === item.id ? { ...item, request_status: 1, fev_status: 1 } : item;
			});
			setMembership(updateData);
		}                  
	}).catch(error => {
		 
	});  
}
/* End accept */
/* Start reject */
const reject = (id) => {		
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	formData.append('status', 2);
	API.post('change_member_invitation_status_by_user', formData)
		.then(response => {
		
		if(response.data.status === 0){
			
		}else{
			gettoastsuccess(response.data.message,'');
			let updateData = membership.map((item, i) => {
			  return id === item.id ? { ...item, request_status: 2 } : item;
			});
			setMembership(updateData);
		}                  
	}).catch(error => {
		 
	});  
}
/* End reject */

    return (
        <>
         {/* ========== User Membership Invitation Page Start ========== */}
            <section className="gen-wrap">
			<ToastContainer />
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Membership Invitation'}/>
            {/* ========== Navbar End ========== */}
               <Container>
               
               {/* ========== Membership Invitation Box End ========== */}
                  <Row className="mx-0 mianhmdwncnt">
                  {/* ========== Membership Invitation List Content Box Start ========== */}
				 {membership.length >0 ? (
                     <Col className="mnhmcnt">
					 {membership && membership.map((val,index) => {
						return(
                     <Col md={12} xs={12} className="py-2">
                     <Col className="ustrlpg ustrlpgmi">
                     <Row className="mx-0 ustrl-box">
                        <Col md={2} xs={3} className="px-0">
							<img src={val.image} className="img-fluid" alt="Hm Img"/>
						</Col>
                        <Col md={8} xs={6} className="px-2 px-md-2">
                        <h3>{val.name}</h3>
							
                        <p className="mt-2">{val.distance} - Address Here</p>
                        {/*<p>Buffet Offers</p>*/}
                        </Col>
                        <Col md={2} xs={3} className="text-end px-0">
						{(() => {
							if (val.request_status===0){
							return (
							  <>
								<NavLink to="#" onClick={() =>accept(val.id)}><Button variant="" type="submit" className="btn-first">Accept</Button></NavLink><NavLink to="#" onClick={() =>reject(val.id)}><Button variant="" type="submit" className="mt-3 btn-second">Reject</Button></NavLink>
								</>
								)
							}else if (val.request_status===1) {
								return (
									<h3 className="text-accept">Accepted</h3>
								)
							}else if (val.request_status===2) {
								return (
								  <h3 className="text-reject">Rejected</h3>
								)
							}								
						  })()}
						</Col>
                     </Row>
					 {(() => {
							if (val.request_status===0){
							return (
							  <>
								<Col md={12} xs={12} className="ustrl-crdlst text-center">
                     <ul>
					 {val.request_status===0 ? ( <li>Merchant invitation for membership</li> ):(<li></li>)}	
                     </ul>
                     
                     </Col>
								</>
								)
							}else if (val.request_status===1) {
								return (
									<Col md={12} xs={12} className="ustrl-crdlst">
									 <ul>
									 <li></li>
									 </ul>
									 {val.fev_status===1 && (
									 <div className="ginlike">
										<div className="">
										  <svg className={`lik lik${val.id}`} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
												   <circle cx="12" cy="12" r="12" fill="white"/>
												   <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
												</svg>  
										 
										</div>
									 </div>
									 )}
									 </Col>
								)
							}else if (val.request_status===2) {
								return (
									<Col md={12} xs={12} className="ustrl-crdlst">
									 <ul>
									 <li></li>
									 </ul>
									 
									 </Col>
								)
							}								
						  })()}
                     
					 
                     </Col>
                     </Col>
					 )
					  })}
                     </Col>
					 ) : (
						<Col className="mnhmcnt">
							<p className="text-center mt-4">No data found</p>
						</Col>
					  )}
				 
                     {/* ========== Membership Invitation List Content Box End ========== */}
                  </Row>
				  
				 
				  
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )}  
               </Container>
            </section>
         {/* ========== User Membership Invitation Page End ========== */}
            {/*<Footer />*/}
        </>
    )
}

export default UserMembershipInvitation;