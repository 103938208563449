import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerMarketing.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
import {validatemarketing} from '../../../Utils/validate';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const MerMarketing = () => {
const [loading,setLoading] = useState(false);
const navigate = useNavigate();
const [errors,setErrors] = useState({});
const [inputs,setInputs] = useState({all_new_member:1,discount_offer:''});

useEffect(() => {
      document.title = ` Merchant Marketing | Blue Faucet`;
      document.getElementById('bodywrap').className='mmrktngpgmain grnbg grenicn';  
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });   

	/* Start get marketing data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_setup_promotion', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setInputs({
				   all_new_member:response.data.data.all_new_member,
				   discount_offer:response.data.data.discount_offer
			   });
			}                  
		}).catch(error => {
			 
		});  
	 /* End get marketing data */
},[]) 
 
/* Start Value set */
const handleCheckboxChange = (event) => {
	var lbl='all_new_member';
	if(event.target.checked)
	{
		setInputs(inputs => ({...inputs, [lbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [lbl]: 0}));
	}
};       
const handleOnChange = (event) => {
      event.persist();
	  const inputAmount = event.target.value;
	  if (/^\d*$/.test(inputAmount)) {
		setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));  
	  }		
}
/* End Value set */
/* Start Submit Marketing */
const handleShow = () => {
	const validationErrors = validatemarketing(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
	setErrors({});
    setErrors(validationErrors);
	if(noErrors){
			setLoading(true);
			let all_new_member=inputs.all_new_member;
			let discount_offer=inputs.discount_offer;
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));	
			formData.append('all_new_member', all_new_member);
			formData.append('discount_offer', discount_offer);
			API.post('setup_promotion', formData)
                .then(response => {
					setLoading(false); 
                if(response.data.status === 0){
                    //alert(response.data.message);
					gettoastwarning(response.data.message,'');
                    setLoading(false); 
                }else{
					//alert(response.data.message);
				    //window.location.href = "/mer-sending-coupons";
					gettoastsuccess(response.data.message,'/mer-sending-coupons');
                }                  
            }).catch(error => {
                 setLoading(false);
				  //alert("Something went wrong. Please try again later.");
				  gettoastwarning("Something went wrong. Please try again later.",'');
			});  
		}
}
/* End Submit Marketing */

    return (

        <>
      {/* ========== Merchant Marketing Page Start ========== */}
         <section className="gen-wrap">
		 <ToastContainer />
      {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt">
            <Col xs={2} md={2} className="align-self-center px-0">
            <Button onClick={() => navigate(-1)} className="back-btn">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </Button>
            </Col>
            <Col xs={8} md={8} className="align-self-center text-center">
            <h3>Welcome Coupon</h3>
            </Col>
            <Col xs={2} md={2} className="align-self-center text-end mcustmrsw d-none">
            <Button className="" href={void(0)} variant=""><img src="assets/images/merchant/svg/plus-icn.svg" className="img-fluid" alt="Logo"/></Button>{' '}</Col>
         </Row>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
      {/* ========== Markrting Content Start ========== */}
               <Col md={12} xs={12} className="tptxtmrktng">
               <p>To every member who join the membership</p>
               </Col>
               <Col md={12} xs={12} className="mmrktbx">
               
               <p>The Welcome Coupon serves as an effective marketing instrument to incentivize customers to join your membership. You have the flexibility to decide how you'd like to extend this offer when they initially visit your store. Disabling it is simple if you wish to halt the distribution.</p>
               <Form.Group className="mb-3">
                  <Form.Label>Discount Offered (%)<span></span></Form.Label>
                  <Form.Control type="text" placeholder="5" value={inputs.discount_offer} name="discount_offer" onChange={handleOnChange} />
				  {errors.discount_offer && (<p className="text-danger">{errors.discount_offer}</p>)}
			   </Form.Group>
			   <Form.Group className="mb-3 ckbox">
                  <Form.Check aria-label="option 3" name="all_new_member" value="1" checked={inputs.all_new_member === 1} onChange={handleCheckboxChange}/>
                  <Form.Label>All new members join your membership</Form.Label>
               </Form.Group>
			   <Col md={12} xs={12}>
					<Row class="btbox">
					   <Col md={12} xs={12} className="">
					   <NavLink to="#" className="step1nxt btn-fill" onClick={handleShow}> Submit </NavLink>
					   </Col> 
					</Row>
					</Col> 
               </Col>	   
      {/* ========== Markrting Content End ========== */}
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
       <Footer/>
      {/* ========== Footer Start ========== */}
      {/* ========== Merchant Marketing Page Start ========== */}
        </>
    )
}
export default MerMarketing;