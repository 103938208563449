import React, { useEffect,useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import './UserCalenderprod.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess } from '../../../Utils/Common';

const UserCalenderprod = () => {
const { id } = useParams();
const [loading,setLoading] = useState(true);
const [events,setEvents] = useState([]);
const [inputs,setInputs] = useState({store_id:'',emp_id:'',type:''});
var currentDate = new Date();
var endDate=currentDate.setMonth(currentDate.getMonth() + 1);
var currentDate1 = new Date();
const dayOfWeek = currentDate1.getDay();
  

 const validRange = {
    start: new Date(), // Set the start date to the current date
	end: endDate
  };

//const events = [{ title: "Appointment Name here", start: '2023-09-20T08:00:00', end: '2023-09-20T010:00:00',className: 'event-green'  },{ title: "Appointment Name here", date: new Date(),className: 'event-green'  },{ title: "Appointment Name here", date: '2023-07-13T10:00:00',className: 'event-yellow'  },{ title: "Appointment Name here", date: '2023-07-13T12:00:00',className: 'event-yellow1'  },{ title: "Appointment Name here", date: '2023-07-12T14:00:00',className: 'event-yellow2'  },{ title: "", date: '2023-07-10T14:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-10T08:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-12T08:00:00',className: 'event-light-gray'  },{ title: "", date: '2023-07-12T17:00:00',className: 'event-light-gray'  }];
//const events = [{ title: "Appointment Name here", start: '2023-09-17T10:00', end: '2023-09-20T010:00:00',className: 'event-green'  }];
useEffect(() => {
      document.title = `User Calendar | Blue Faucet`;
      document.getElementById('bodywrap').className='clndrpgmain myclndrpgmain';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
     
	 /* Start get time slot */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('id', id);
		API.post('get_employee_or_store_working_hour', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setEvents(response.data.data.slot);
				
				setInputs({
					store_id:response.data.data.store_id,
					emp_id:response.data.data.emp_id,
					type:response.data.data.type,
				});
			}                  
		}).catch(error => {
			 
		});  
	 /* End get time slot */
    
},[id])  
const renderDayHeader = ({ date }) => {
    const formattedDate = date.toLocaleString('default', {
      weekday: 'short',
      day: 'numeric',
    });
    let array = formattedDate.split(" ");
    
    return <span>{array[1]} <p>{array[0]}</p></span>;
  };
  
const handleEventClick = (info) => {
    // 'info' contains information about the clicked event, including the date
	console.log(info.event);
	if(info.event.title==='Not available')
	{
		//alert('Already booked slots');
		gettoastsuccess('Already booked slots','');
	}else{
		const startDate = info.event.start;
		const newDate = new Date(startDate); // Create a new Date object to avoid mutating the original date.
		newDate.setHours(newDate.getHours() + 1); // Add 1 hour to the date.
		const start_date = moment(startDate).format('YYYY-MM-DD H:mm:ss');
		const end_date = moment(newDate).format('YYYY-MM-DD H:mm:ss');
	   
		 localStorage.setItem("book_start_date", start_date);
		 localStorage.setItem("book_end_date", end_date);
		 localStorage.setItem("book_store_id", inputs.store_id);
		 localStorage.setItem("book_emp_id", inputs.emp_id);
		 localStorage.setItem("book_type", inputs.type);
		 
		 console.log(localStorage.getItem("book_start_date"));
		 console.log(localStorage.getItem("book_end_date"));
		 console.log(localStorage.getItem("book_store_id"));
		 console.log(localStorage.getItem("book_emp_id"));
		 console.log(localStorage.getItem("book_type"));
		 window.location.href = '/order-summary';
	}
  };

    return (
        <>
            {/* ========== User Calender Page Start ========== */}
            <section className="gen-wrap">
			<ToastContainer />
               <Navbar headerText={'Calender'}/>
               <Container className="px-0">
                  <Row className="mx-0 mianhmdwncnt">
                     <Col md={12} xs={12} className="mnhmcnt my-3 px-0">
                     <Col md={12} xs={12} className="clndrmaindv px-0">
                     {/* ========== Calendar Code Start ========== */}
                     <FullCalendar
                     editable
                     selectable
                     events={events}
                     contentHeight='auto'
                     slotLabelFormat= {
                     {  hour: 'numeric'
                     }}
                     headerToolbar={{
                     start: "prev, next",
                     center: 'title',
                     right: "dayGridMonth,timeGridWeek,timeGridDay"
                     }}
                     plugins={[timeGridPlugin]}
                     initialView="timeGridWeek"
                     allDaySlot={false}
                     views={{
                     week: {
                     titleFormat: { month: 'long',year:'numeric' },
                     },
                     }}
                     dayHeaderContent={renderDayHeader}
                     eventClick={handleEventClick}
					 validRange={validRange}
					firstDay={dayOfWeek}
                     />
                     {/* ========== Calendar Code Start ========== */}
                     </Col>
                     </Col>
                  </Row>
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )} 
               </Container>
            </section>
            <Footer/>
            {/* ========== User Calender Page End ========== */}
        </>
    )
}

export default UserCalenderprod;