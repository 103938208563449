import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { NavLink,useParams } from "react-router-dom";
import './UserEplyscn.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const UserEplyscn = () => {
	const [employee, setEmployee] = useState([]);
	const [inputs, setInputs] = useState({name:'',image:'',email:''});
	const [loading,setLoading] = useState(true);
	const { id } = useParams()
	
useEffect(() => {
      document.title = `User Employee | Blue Faucet`;
      document.getElementById('bodywrap').className='usremplyscrnpgmain';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

    /* Start get service data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('store_id', id);
		API.post('get_employee_with_store_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				//var emp=response.data.data.employee_array;
				setEmployee(response.data.data.employee_array);
				setInputs({name:response.data.data.name,image:response.data.data.image,email:response.data.data.email});
				/*if(emp.length >0){
					
				}else{
					window.location.href = '/calendar/'+id;
				}*/
			}                  
		}).catch(error => {
			 
		});  
	 /* End get service data */

},[id])  


/* Start employee search keyword wise */
/*const handleInputChange = (event) => {

	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('store_id', id);
	formData.append('keyword', event.target.value);
	API.post('search_employee_with_store_id', formData)
		.then(response => {
		
		if(response.data.status === 0){
			
		}else{
			setEmployee(response.data.data.employee_array);
		}                  
	}).catch(error => {
		 
	});  
};*/
/* End employee search keyword wise */

/* Start view reating */
/*const ratingview = (count) => {
	var html='';
	for (let i = 1; i <= count; i++) {
			html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star"></i></label><input class="rating__input" name="rating"  defaultValue={i} type="radio"/>';
	
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}*/
/* End view reating */


    return (
        <>
         {/* ========== User Employee Screen Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Employees'}/>
            {/* ========== Navbar End ========== */}
               <Container>
			   {/*<Row className="uespgfixdv mx-0">
                     <Col md={10} xs={9} className="align-self-center pe-0">
                     <div className="constrain hide-inpt">
                        <input className="input-elevated" type="text" placeholder="Search here.." onChange={handleInputChange}/>
                     </div>
                     </Col>
                     <Col md={2} xs={2} className="align-self-center text-end usctpbtn">
                     <NavLink to="#"><Button variant=""><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/ucl-fltrimg.svg'} className="img-fluid" alt="Logo"/><span></span></Button>{' '} </NavLink>
                     </Col>     
			   </Row>*/}
               {/* ========== Employee Box Box Start ========== */}
			   
                  <Row className="mx-0 mianhmdwncnt main-emp-box">
				  
				 
                     <Col className="mnhmcnt">
					 <Col md={12} xs={12} className="pt-3">
						<p><b>Whom do you want to make an appointment?</b></p>
					 </Col>
					 <Col md={12} xs={12} className="py-2 pt-0">
                     <Col className="ustrlpg">
						 <Row className="mx-0 ustrl-box uesbox">
						   <Col md={2} xs={2} className="px-0 align-self-center prflimgues">
							   <NavLink to={`/calendar/${id}`}><img src={inputs.image} className="img-fluid" alt="Hm Img"/><span></span></NavLink>
							</Col>
							<Col md={8} xs={9} className="px-2 px-md-0 align-self-center">
								<NavLink to={`/calendar/${id}`}><Button variant="" type="submit" className="text-btn-emp">Store Owner </Button></NavLink>
						    </Col>
							<Col md={2} xs={1} className="text-end px-0 useeslinedv">
                        
							</Col>
						 </Row> 
					 </Col>  
					</Col>  
					 
					{employee && employee.map((val,index) => {
						return( 
                     <Col md={12} xs={12} className="py-2">
                     <Col className="ustrlpg">
                     <Row className="mx-0 ustrl-box uesbox">
                        <Col md={2} xs={2} className="px-0 align-self-center prflimgues">
							<NavLink to={`/calendar/${val.id}`}><img src={val.image} className="img-fluid" alt="Hm Img"/><span></span></NavLink>
						</Col>
                        <Col md={8} xs={9} className="px-2 px-md-0 align-self-center">
                        <NavLink to={`/calendar/${val.id}`}><h3>{val.name}</h3></NavLink>
                        </Col>
                        <Col md={2} xs={1} className="text-end px-0 useeslinedv">
                        
                        </Col>
                       
                        <Col md={6} xs={6} className="align-self-center mt-2 left-uespg">
							{/*<div id="str-trng" className="">
                           <div className="rating-group strrtg-rating">
                              {ratingview(val.rating)}
                           </div>
                        </div>
							<p>{val.total_rating} Ratings</p>*/}
                        </Col>
                        {/*<Col md={12} xs={12} className="ueslstbtn px-0">
                        <NavLink to={`/calendar/${val.id}`}><Button variant="" type="submit">Appointment <img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/btnsvgtmclk.svg'} className="img-fluid ps-2 appo-img-btn" alt="Hm Img"/></Button></NavLink>
                        </Col>*/}
                     </Row>
                     </Col>
                     </Col>
                     )
					  })}
					 
                     </Col>
					 
                  </Row>
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )} 
                  {/* ========== Employee Box Box End ========== */}
               </Container>
            </section>
            <Footer/>
         {/* ========== User Employee Screen Page End ========== */}
        </>
    )
}

export default UserEplyscn;