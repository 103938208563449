import React, { useEffect,useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerVideo.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const MerVideo = () => {
const navigate = useNavigate();
const [video, setVideo] = useState([]);
const [loading,setLoading] = useState(true);
const [inputs,setInputs] = useState({profile_setup:3});

useEffect(() => {
      document.title = `User Video | Blue Faucet`;
      document.getElementById('bodywrap').className='vdopgmain';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	/* Start get profile */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setInputs({
				  profile_setup: response.data.data.profile_setup,
				})
			}                  
		}).catch(error => {
			 
		});  
	 /* End get profile */
	/* Start get video */
	let formData1 = new FormData(); 
		formData1.append('type', 'merchant');
		API.post('video', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setVideo(response.data.data);
			}                  
		}).catch(error => {
			  
		});  
	/* End get video */
},[])  


    return (
        <>
        {/* ========== Video Page Start ========== */}
       
        <section className="gen-wrap " id="showaftr">
           <Container>
            {/* ========== Navbar Start ========== */}
              <Row className="gen-box">
                 <Col md={12} lg={12} xl={12} xxl={12} className="mer-tpcntgen tpcntgen">
                 <Row className="maincnttp">
                    <Col xs={2} md={2}>
                    <button onClick={() =>
                       navigate(-1)} className="back-btn d-none">
                       <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                       </svg>
                    </button>
                    </Col>
                    <Col xs={8} md={8}>
                    <h3>Training Videos</h3>
                    </Col>
                    <Col xs={2} md={2}>
					{(() => {
					  if (inputs.profile_setup===2){
						 return (<NavLink to="/mer-stripe-account-setup">
						   <p>Skip</p>
						</NavLink>)
					  }else {
						  return (<NavLink to="/mer-dashboard">
						   <p>Skip</p>
						</NavLink>)
					  }
					 })()}
                    </Col>
                 </Row>
                 </Col>
              </Row>
            {/* ========== Navbar End ========== */}
            {/* ========== Content Start ========== */}
              <Row className="maincntflbg">
                 <Col md={12} lg={12} xl={12} xxl={12} className="toptxtcnt">
                 </Col>
				 {video && video.map((val,index) => {
					 return(
					 <Col xs={12} md={12} className="px-0 imgbx vdobx">
					 <h3>{val.video_name}</h3>
					 {(() => {
					  if (val.type==='link'){
						return (<iframe title={`id{val.id}`} width="100%" height="280" src={`https://www.youtube.com/embed/${val.video_path.split('v=')[1]}`} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>)
					 }else {
						 return (<video controls >
							<source src={val.video_path} type="video/mp4"/>
							<source src="assets/images/hm-imgone.png" type="video/ogg"/>
						 </video>)
					 }
					 })()}
					 </Col>
					 )
                })}
              </Row>
              {/* ========== Content End ========== */}
			  {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
           </Container>
        </section>
        {/* ========== Video Page End ========== */}
        </>
    )
}

export default MerVideo;