import React, { useEffect, useState,useRef  } from 'react';
import { Row, Col, Container, Button, Form, Modal} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerExistingCust.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
import loader from '../../../assests/loader.gif';
import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS
import intlTelInput from 'intl-tel-input';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';
import {validateexistingcustomer} from '../../../Utils/validate';
import API from '../../../Utils/Api';

const MerExistingCust = () => {
const inputRef = useRef(null);
const navigate = useNavigate();

const [loading,setLoading] = useState(true);

const [inputs,setInputs] = useState({email:'',mobile:'',country_code:'1',country_short_code:'us','filter':'all'});

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [errors,setErrors] = useState({});

const [inputstype,setInputstype] = useState({type:'customer',total_customer:0});
const [members, setMembers] = useState([]);


useEffect(() => {
      document.title = ` Merchant Customers | Blue Faucet`;
      document.getElementById('bodywrap').className='cstmrpgmain grnbg mcus';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
	
	
	/* Start get customer */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'customer');
		formData.append('filter', 'all');
		API.post('get_members', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setMembers(response.data.data.customer_invitation);
				 var total_customer_lbl='total_customer';
				setInputstype(inputstype => ({...inputstype, [total_customer_lbl]: response.data.data.total_customer}));
			}                  
		}).catch(error => {
			 
		});  
	 /* End get customer */	 
},[])  

useEffect(() => {
    if (show) {
      const input = inputRef.current;
    const iti = intlTelInput(input, {
	  initialCountry:"us",
      separateDialCode:true,
    });

    // Listen for changes
    input.addEventListener('countrychange', () => {
      const selectedCountryData = iti.getSelectedCountryData();
	  var country_code_lbl='country_code';
	  setInputs(inputs => ({...inputs, [country_code_lbl]: selectedCountryData.dialCode}));
	  var country_short_code_lbl='country_short_code';
	  setInputs(inputs => ({...inputs, [country_short_code_lbl]: selectedCountryData.iso2}));
    });

    // Clean up when the component unmounts
    return () => {
      iti.destroy();
    };
    }
  }, [show]);
  
const handleInputChange = (event) => {
  event.persist();
  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
}
const formatAsPhoneNumber = (event) => {
	event.persist();
	var value=event.target.value;
	value = value.replace(/ /g, '');
	value = value.replace(/\D/g, '');
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	if(value.length > 3 && value.length < 7)
	{
	    value = value.slice(0, 3) + ' ' + value.slice(3, 6);
	}else if(value.length > 6)
	{
		
		value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
	}
	setInputs(inputs => ({...inputs, [event.target.name]: value})); 
}

/* Start Submit Customer */
const handleSubmit = (event) => {
	event.preventDefault();
		const validationErrors = validateexistingcustomer(inputs);
        const noErrors = Object.keys(validationErrors).length === 0;
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let email=inputs.email;
			let mobile=inputs.mobile;
			//let country_code=inputs.country_code;
			//let country_short_code=inputs.country_short_code;
			
			let formData = new FormData();
            formData.append('token', localStorage.getItem("token"));			
			formData.append('email', email);
			formData.append('mobile', mobile);
			API.post('add_member', formData)
                .then(response => {
                if(response.data.status === 0){
                    //alert(response.data.message);
					gettoastwarning(response.data.message,'');
                    setLoading(false); 
                }else{
					setShow(false);
					setLoading(false);
					//alert(response.data.message);
				    //window.location.href = "/mer-existing-customer";
					gettoastsuccess(response.data.message,'/mer-existing-customer');
                }                  
            }).catch(error => {
                 setLoading(false);
				  //alert("Something went wrong. Please try again later.");
				  gettoastwarning("Something went wrong. Please try again later.",'');
			});
			
		}
}
/* End Submit Customer */

const handleInputChangeType = (event) => {
      event.persist();
      setInputstype(inputstype => ({...inputstype, [event.target.name]: event.target.value}));  
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', event.target.value);
		formData.append('filter', inputs.filter);
		API.post('get_members', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setMembers(response.data.data.customer_invitation);
				 var total_customer_lbl='total_customer';
				setInputstype(inputstype => ({...inputstype, [total_customer_lbl]: response.data.data.total_customer}));
			}                  
		}).catch(error => {
			 
		}); 
}

const handleSelectChange = (id, value) => {	
    setLoading(true);
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('id', id);
		formData.append('status', value);
		API.post('change_member_invitation_status_by_merchant', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				let updateData = members.map((item, i) => {
					return id === item.id ? { ...item, other_status: value } : item;
				});
				setMembers(updateData);
			}                  
		}).catch(error => {
			 
		});  
}
const handleInputChangeFilter = (event) => {
      event.persist();
	  var fil_lbl='filter';
      setInputs(inputstype => ({...inputs, [fil_lbl]: event.target.value})); 
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', inputstype.type);
		formData.append('filter', event.target.value);
		API.post('get_members', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setMembers(response.data.data.customer_invitation);
				 var total_customer_lbl='total_customer';
				setInputstype(inputstype => ({...inputstype, [total_customer_lbl]: response.data.data.total_customer}));
			}                  
		}).catch(error => {
			 
		});  
}

    return (
        <>
      {/* ========== Merchant Customer Page Start ========== */}
         <section className="gen-wrap">
		 <ToastContainer />
         {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt">
            <Col xs={1} md={1} className="align-self-center px-0">
            <button onClick={() =>
               navigate(-1)} className="back-btn ">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </button>
            </Col>
            <Col xs={11} md={11} className="align-self-center text-center">
            <h3>Bluefaucet existing customer membership</h3>
            </Col>
         </Row>
         {/* ========== Navbar End ========== */}
         <Container>
            {/* ========== Customer Top Box Start ========== */}
			
            <Row className="tpfixgrn">
			<Col md={12} xs={12}>
				<p>Invite new customer to Bluefaucet</p>
			</Col>
               <Col sm={3} xs={5} className="align-self-center ps-0 ps-md-2">
               <Form.Select aria-label="Default select example" onChange={handleInputChangeFilter}>
                  <option value="all">All</option>
                  <option value="normal">Normal</option>
                  <option value="vip">VIP</option>
                  <option value="block">Block</option>
               </Form.Select>
               </Col>
               <Col sm={9} xs={7} className="align-self-center text-end btntpfx btnpfxbexiting px-0">
               <NavLink to="#" onClick={handleShow}><Button variant="gren" type="submit">Invite BF existing customer membership</Button></NavLink>
               </Col>
            </Row>
            {/* ========== Customer Top Box End ========== */}
            {/* ========== Customer Content Start ========== */}
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="cusslctbtn btnspcer">
               <div className="cusradio">
                  <input className="radio-label" type="radio" name="type" id="one" value="customer" onChange={handleInputChangeType} checked={inputstype.type === 'customer'} />
                  <label className="button-label" htmlFor="one">Customers: <span>{inputstype.total_customer}</span></label>
                  <input className="radio-label" type="radio" name="type" id="two" value="members" onChange={handleInputChangeType} checked={inputstype.type === 'members'} />
                  <label className="button-label" htmlFor="two">Members</label>
                  <input className="radio-label" type="radio" name="type" id="three" value="pending" onChange={handleInputChangeType} checked={inputstype.type === 'pending'} />
                  <label className="button-label" htmlFor="three">Pending</label>
               </div>
               </Col>
			   {members.length >0 ? (
			   <div>
			   {members && members.map((val,index) => {
				return(
               <Col md={12} xs={12} className="mcusbx cus-box">
               <Row className="mx-0">
                  <Col md={2} xs={2} className="align-self-center px-1">
				  {val.image ? (
                          <> 
							<img src={val.image} className="img-fluid" alt="Logo"/>
						  </>
							) : (
							  <>
							<img src= {'assets/images/merchant/mcust-img1.png'} className="img-fluid" alt="Mcust Img"/>
						</>
                          )}
                  </Col>
                  <Col md={6} xs={6} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                  <h3>{val.name}</h3>
                  <p>{val.email}</p>
                  </Col>
                  <Col md={4} xs={4} className="align-self-center text-md-end">
                  <div className="demo">
                     <select onChange={(e) => handleSelectChange(val.id, e.target.value)} value={val.status}>
                        <option value="0">Normal</option>
                        <option value="1">Vip</option>
                        <option value="2">Block</option>
                     </select>
                  </div>
                  </Col>
               </Row>
               </Col>
			   )
				})}
               </div>
			   ) : (
						<Col className="mnhmcnt">
							<p className="text-center mt-4">No data found</p>
						</Col>
					  )} 
               <Col>
			   
			   <div className="madcustmrpop">
                  <Modal show={show} onHide={handleClose} className="popupwrngcnt" centered>
                     <Modal.Header closeButton>
                        <h3 className="mdlttltxt">Send Membership invitation</h3>
                     </Modal.Header>
                     <Modal.Body>
					 <Form method="post" onSubmit={handleSubmit}  enctype="multipart/form-data">
                        <Col md={12} xs={12} className="ppinpt">
                       
                           <Form.Group className="mb-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" placeholder="Enter customer email" name="email" onChange={handleInputChange} value={inputs.email} />
                              {errors.email && (<p className="text-danger">{errors.email}</p>)}
						   </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Phone Number <span>*</span></Form.Label>
                              <Form.Control type="tel" placeholder="000 000 0000" id="mobile" name="mobile" ref={inputRef} onChange={formatAsPhoneNumber}  value={inputs.mobile} />
                              {errors.mobile && (<p className="text-danger">{errors.mobile}</p>)}
						   </Form.Group>
                        </Col>
                        <Col md={12} xs={12} className="btnpopmcstmr my-2">
                        <Button type="submit">Save and Send Invitation</Button>
                        </Col>
						</Form>
                     </Modal.Body>
                  </Modal>
               </div>
			   
               </Col>
               </Col>
            </Row>
            {/* ========== Customer Content End ========== */}
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
        <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Customer Page End ========== */}
        </>
    )
}
export default MerExistingCust;