import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Table,Form } from 'react-bootstrap';
import './MerCouponRevenue.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';

import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend);
export const options = {responsive: true,plugins: {legend: {position: 'bottom',display: true,labels: {
        boxWidth: 50,
		boxHeight:1,
      }},title: {display: false,text: '',},},};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];

//export const data = {labels,datasets: [{fill: true,label: 'Number of usage',data: [12, 19, 3, 5,15,20,25],borderColor: 'blue',backgroundColor: 'transparent',},{fill: true,label: 'Generate Amount',data: [22, 29, 6, 10,30,40,35],borderColor: 'yellow',backgroundColor: 'transparent',}],};

const MerCouponRevenue = () => {
const [loading,setLoading] = useState(true);	
const [years, setYears] = useState([]);
const [data, setData] = useState({labels,datasets: [{fill: true,label: 'Number of usage',data: [12, 19, 3, 5,15,20,25],borderColor: 'blue',backgroundColor: 'transparent',},{fill: true,label: 'Generate Amount',data: [22, 29, 6, 10,30,40,35],borderColor: 'red',backgroundColor: 'transparent',}],});

const [order, setOrder] = useState([]);

useEffect(() => {
     document.title = `Merchant Help | Blue Faucet`;
      document.getElementById('bodywrap').className='mhelppgmain grnbg grenicn';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	setLoading(false);
	const currentYear = new Date().getFullYear();
	  const yearsArray = Array.from({ length: 10 }, (_, index) => currentYear - index);

      setYears(yearsArray);
	   
	  /* Start get coupon revenue */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('year', currentYear);
		API.post('mer_coupon_revenue', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setOrder(response.data.data.table);
				setData({labels,datasets: [{fill: true,label: 'Number of usage',data: response.data.data.number_of_useage,borderColor: 'blue',backgroundColor: 'transparent',},{fill: true,label: 'Generate Amount',data: response.data.data.order_amount,borderColor: 'red',backgroundColor: 'transparent',}],});
			}                  
		}).catch(error => {
			 
		});  
	 /* End get coupon revenue */
	  
},[])  

const handleInputChangeFilter = (event) => {
      event.persist(); 
		let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('year', event.target.value);
		API.post('mer_coupon_revenue', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setOrder(response.data.data.table);
				setData({labels,datasets: [{fill: true,label: 'Number of usage',data: response.data.data.number_of_useage,borderColor: 'blue',backgroundColor: 'transparent',},{fill: true,label: 'Generate Amount',data: response.data.data.order_amount,borderColor: 'red',backgroundColor: 'transparent',}],});
			}                  
		}).catch(error => {
			 
		});  
}

    return (
      <>
      {/* ========== Merchant Order Statistics Page Start ========== */}
         <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Coupons vs. Revenue'}/>
        {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-3">
               <Row className="mx-0">
        {/* ========== Order Statistics Content Start ========== */}
                  <Col md={12} xs={12} className="px-0 usrhlp">
						<h4 className="text-center">Records</h4>
						<Row>
						<Col md={12} xs={12}>
						 <Form.Select className="filter-year-drop" aria-label="Default select example" name="year" onChange={handleInputChangeFilter}>
						  {years.map((year) => (
								<option value={year}>{year}</option>
							))}
					   </Form.Select>
					   </Col>
					   </Row>
						<Table responsive>
						<thead>
						<tr>
							<th>Month</th>
							<th>Issued Coupons</th>
							<th>Received Customers</th>
							<th>Number of usage </th>
							<th>Usage Rate</th>
							<th>Generate Amount</th>
						</tr>
						  </thead>
						  <tbody>
						  {order && order.map((val,index) => {
							return(
							<tr>
								<td>{val.month}</td>
								<td>{val.issued_coupon !== '0' ? (<div>{val.issued_coupon}</div>) : ( null)}</td>
								<td>{val.received_coupon !== '0' ? (<div>{val.received_coupon}</div>) : ( null)}</td>
								<td>{val.number_of_useage !== '0' ? (<div>{val.number_of_useage}</div>) : ( null)}</td>
								<td>{val.usage_rate !== '0.00' ? (<div>{val.usage_rate}%</div>) : ( null)}</td>
								<td>{val.order_amount !== '0.00' ? (<div>$ {val.order_amount}</div>) : ( null)}</td>
							</tr>
							)
							})}
						  </tbody>
						</Table>
						
						<h4 className="text-center">Trend</h4>
						
						<Line options={options} data={data} />
						
                  </Col>
        {/* ========== Order Statistics Content End ========== */}
               </Row>
               </Col>
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
        </section>
        {/* ========== Merchant Order Statistics Page End ========== */}
        </>
    )
}
export default MerCouponRevenue;