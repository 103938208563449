import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button, Table, Stack, Form } from 'react-bootstrap';
import { NavLink,useParams } from "react-router-dom";
import './UserInvic.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const UserInvic = () => {
const { id } = useParams();
const [loading,setLoading] = useState(true);
const [inputs,setInputs] = useState({order_id:'',order_number:'',tips:'',saving_amount:0,sales_tax_rate_per:0,sales_tax_rate:0,amount:'',subtotal:'',total:'',total_due:'',date:'',due_date:'',store_name:'',store_image:'',store_address:'',store_area:'',store_city:'',store_state:'',store_mobile:'',store_id:'',store_zip:'',user_address_title:'',user_address_street_name:'',user_address_state:'',user_address_city:'',user_address_zip_code:'',user_mobile:'',user_name:'',employee_name:''});
const [orderrev, setOrderrev] = useState([]);
const [coupon,setCoupon] = useState([]);
//const [inputsrat,setInputsrat] = useState({rating:3});

useEffect(() => {
      document.title = `User Invoice | Blue Faucet`;
      document.getElementById('bodywrap').className='usrinvcpgmain';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
    /* Start get order invoice */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('id', id);
		API.post('get_order_by_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				
				var sales_tax_rate_per=response.data.data.sales_tax_rate_per;
			    var sales_tax_rate=response.data.data.sales_tax_rate;
				
				var saving_amount=0;
				if(response.data.data.saving_amount!=='')
				{
					saving_amount=parseFloat(response.data.data.saving_amount);
				}
				var subtotal=parseFloat(response.data.data.subtotal);
				var total=subtotal-saving_amount;
				var tips=parseFloat(response.data.data.tips);
				var total_due=total+tips+sales_tax_rate;
				tips=tips.toFixed(2);
				sales_tax_rate=sales_tax_rate.toFixed(2);
				saving_amount=saving_amount.toFixed(2);
				subtotal=subtotal.toFixed(2);
				total=total.toFixed(2);
				total_due=total_due.toFixed(2);
				setInputs({
					order_id:response.data.data.order_id,
					order_number:response.data.data.order_number,
					tips:tips,
					saving_amount:saving_amount,
					sales_tax_rate_per:sales_tax_rate_per,
				    sales_tax_rate:sales_tax_rate,
					amount:response.data.data.amount,
					subtotal:subtotal,
					total:total,
					total_due:total_due,
					date:response.data.data.date,
					due_date:response.data.data.due_date,
					store_name:response.data.data.store_name,
					store_image:response.data.data.store_image,
					store_address:response.data.data.store_address,
					store_area:response.data.data.store_area,
					store_city:response.data.data.store_city,
					store_state:response.data.data.store_state,
					store_mobile:response.data.data.store_mobile,
					store_id:response.data.data.store_id,
					store_zip:response.data.data.store_zip,
					user_address_title:response.data.data.user_address_title,
					user_address_street_name:response.data.data.user_address_street_name,
					user_address_state:response.data.data.user_address_state,
					user_address_city:response.data.data.user_address_city,
					user_address_zip_code:response.data.data.user_address_zip_code,
					user_mobile:response.data.data.user_mobile,
					user_name:response.data.data.user_name,
					employee_name:response.data.data.employee_name,
				});
				localStorage.setItem("book_tips", response.data.data.tips);
				setOrderrev(response.data.data.order_array);
				setCoupon(response.data.data.coupon);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order invoice */
},[id])  

/* Start Value set */
const handleInputChange = (event) => {
      event.persist();
	   if(event.target.value >0 || event.target.value==='')
	  {
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value})); 
	  
	   var tip=event.target.value;
	  tip = (typeof tip !== 'undefined' && !isNaN(tip) && tip !== '') ? parseFloat(tip) : 0;
	  tip=tip.toFixed(2);
	  localStorage.setItem("book_tips", tip);
	  
	  var sales_tax_rate=parseFloat(inputs.sales_tax_rate);
	  var saving_amount=parseFloat(inputs.saving_amount);
	  var subtotal=parseFloat(inputs.subtotal);
		var total=subtotal-saving_amount;
		var tips=parseFloat(tip);
		var total_due=total+tips+sales_tax_rate;
	  
	  total_due=total_due.toFixed(2);
	  var amount_lbl='total_due';
	  setInputs(inputs => ({...inputs, [amount_lbl]: total_due})); 
	  }
}
/* End Value set */
/* Start Rating Value set */
/*const handleInputChangeRating = (event) => {
      event.persist();
      setInputsrat(inputsrat => ({...inputsrat, [event.target.name]: event.target.value}));   
}*/
/* End Rating Value set */
/* Start Rating Submit */
const handleRating = () => {
	/*let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('order_id', inputs.order_id);
		formData.append('store_id', inputs.store_id);
		formData.append('rating', inputsrat.rating);
		API.post('store_rating', formData)
			.then(response => {
			setLoading(false);
			if(response.data.status === 0){
				window.location.href = "/service-order-confirmation/"+inputs.order_id;
			}else{
				window.location.href = "/service-order-confirmation/"+inputs.order_id;
			}                  
		}).catch(error => {
			 window.location.href = "/service-order-confirmation/"+inputs.order_id;
		});  */
		window.location.href = "/service-order-confirmation/"+inputs.order_id;
   
};
/* End Rating Submit */
const addressview = (user_address_street_name,user_address_state,user_address_city,user_address_zip_code) => {
	var html='';
	if(user_address_street_name!=='')
	{
		html+=user_address_street_name+', ';
	}
	if(user_address_state!=='')
	{
		html+=user_address_state+', ';
	}
	if(user_address_city!=='')
	{
		html+=user_address_city+', ';
	}
	if(user_address_zip_code!=='')
	{
		html+=user_address_zip_code;
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}


    return (
        <>
        {/* ========== User Invoice Page Start ========== */}
         <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={'Invoice'}/>
         {/* ========== Navbar End ========== */}
           <Container>
            {/* ========== User Invoice Box Start ========== */}
              <Row className="mx-0 mianhmdwncnt">
                 <Col className="mnhmcnt">
                 <Col md={12} xs={12} className="py-2">
                 <Row className="mx-0 maincnt-invc">
                    <Col sm={8} xs={12} className="invone">
                    <h3>{inputs.store_name}</h3>
                    <p>{inputs.store_address} , {inputs.store_zip}</p>
                    <p>Phone: {inputs.store_mobile} </p>
					
					{/*<h2 className="mt-4 cus-add-lab">Customer Address</h2>
					<h3>{inputs.user_name}</h3>
					<h3>{inputs.user_address_title}</h3>
                    <p>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</p>
                    <p>Phone: {inputs.user_mobile}</p>*/}
                    <div className="left-cnt-rcptr">
						<h4>Customer Name: <span>{inputs.user_name}</span></h4>
						<h4>Contact info: <span>{inputs.user_mobile}</span></h4>
						{inputs.user_address_street_name!=='' ?
					<h4>Customer address: <span>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</span></h4>
						: null }
						
					
					</div>
					
                    </Col>
                    <Col sm={4} xs={12} className="text-end invtwo">
                    <img src={inputs.store_image} className="img-fluid" alt="Hm Img"/>
					
					</Col>
					<Col md={12} xs={12} className="invthr">
					  <Row>
						<Col md={10} xs={10}>
							<div className="dwncmt">
							<h5>Information:</h5>
							   {/*<h4>Serviced by: <span>xxxxxxxxxxxx</span></h4>*/}
							   <h4>Schedule date & time: <span>{inputs.due_date}</span></h4>
							   <h4>Receipts: <span>{inputs.order_number}</span></h4>
							   <h4>Date: <span>{inputs.date}</span></h4>
							   {inputs.employee_name!=='' ?
							<h4>Employee Name: <span>{inputs.employee_name}</span></h4>
								: null }
							</div>
						</Col>
						<Col md={2} xs={2} className="align-self-center text-end left-cnt-rcpt">
							
						</Col>
					  </Row>
					</Col>
                    <Col md={12} xs={12} className="tblinvcpg invfou">
                    <Table responsive>
                       <thead>
                          <tr>
                             <th>#</th>
                             <th>Service/Items</th>
                            <th>Price</th>
                             <th>Qty</th>
                             <th>Amount</th>
                          </tr>
                       </thead>
                       <tbody>
					   {orderrev && orderrev.map((val,index) => {
						return(
                          <tr>
                             <td>{index+1}</td>
                             <td>{val.product_name}</td>
                             <td>${val.product_price}</td>
                             <td>1</td>
                             <td>${val.total}</td>
                          </tr>
                         )
					  })}   
                       </tbody>
                    </Table>
                    <div className="text-end tbldwndv">
                       <Stack direction="horizontal" gap={0}>
                          <div className="pb-1 ms-auto">
                             <p>SubTotal</p>
                          </div>
                          <div className="pb-1"><span>${inputs.subtotal}</span></div>
                       </Stack>
                       {/*<Stack direction="horizontal" gap={5}>
                          <div className="pb-1 ms-auto">
                             <p>coupon deduction</p>
                          </div>
                          <div className="pb-1">-<span>$0</span></div>
                       </Stack>*/}
                       {/*<Stack direction="horizontal" gap={5}>
                          <div className="pb-1 pe-2 ms-auto">
                             <p>Tax</p>
                          </div>
                          <div className="pb-1"><span>$9</span></div>
                       </Stack>*/}
					   <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon or discount</p>
                        </div>
                        <div className="pb-1">-<span>${inputs.saving_amount}</span></div>
                     </Stack>
					 {/*<Stack direction="horizontal" gap={4}>
                        <div className="pb-1 ms-auto">
                           <p>Total</p>
                        </div>
                        <div className="pb-1"><span>${inputs.total}</span></div>
                     </Stack>*/}
					 <Stack direction="horizontal" gap={0}>
                          <div className="pb-1 ms-auto">
                             <p>Tax ({inputs.sales_tax_rate_per}%)</p>
                          </div>
                          <div className="pb-1"><span>${inputs.sales_tax_rate}</span></div>
                       </Stack>
                       <Stack direction="horizontal" gap={0}>
                          <div className="pb-1 ms-auto">
                             <p>Gratuity</p>
                          </div>
                          <div className="pb-1 ps-1 input-invic">
                           <Form.Group className="mb-2 ftright">
                              <Form.Control type="Number" name="tips" min="1" className="p-1 add-text-tips" placeholder="" value={inputs.tips} onChange={handleInputChange}/>
                           </Form.Group>
                          </div>
                       </Stack>
                       <Stack direction="horizontal" gap={5} className="lstprcdv">
                          <div className=" ms-auto">
                             <h6>Total Due</h6>
                          </div>
                          <div className="">
                             <h6>${inputs.total_due} </h6>
                          </div>
                       </Stack>
                    </div>
					{coupon.length >0 &&(
						<div className="mb-3 mt-3">
						<label className="form-label mb-4">Coupon</label>
					  <Row>
					  
						<Col md={12} xs={12}>
							<ul className="sldr-add">
								{coupon.length > 0 && coupon.map((item,index) => {
							return(<li className="sldr-in-add">
								<div className="row mincrd">
								<Col md={12} xs={12} className="align-self-center mb-2">
									<h4>{item.coupon_type}</h4>
								   </Col>
									
									<Col md={12}>
										{(() => {
										if (item.coupon_type==='Buy & Get') {
											return (<p>Buy {item.buy_quantity} get {item.get_quantity} free,for {item.product_item_name}</p>)
										}else if(item.coupon_type==='Discount Offer'){
											return (  <p>{item.discount_percentage} % Discount When Purchase Over ${item.minimum_purchase_amount}</p>)
										}else if(item.coupon_type==='Loyalty Setup'){
											return (<p>Buy {item.buy_quantity_needed} {item.product_applicable_item_if_any} get {item.product_get_item_name} free</p>)
										}else if(item.coupon_type==='Invitation'){
											return (<p>{item.product_item_name}</p>)
										}else if(item.coupon_type==='Welcome coupon'){
											return (<p>{item.discount_percentage}% for all new members joining your membership has been set up</p>)
										}								
										})()}
									   {(() => {
										if (item.coupon_type!=='Welcome coupon') {
											return (<p>{item.description}</p>)
										}									
										})()}
									</Col>
									</div>
								</li>
								)})}
							</ul>
						</Col>
						
						</Row>
						</div>
					  )}
                    </Col>
                    <Col className="btmreting text-center invfiv">
                    {/*<div id="str-trng" className="mb-1">
                       <div className="rating-group strrtg-rating">
                          <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input className="rating__input" name="rating" id="rating-1" value="1" type="radio" checked={inputsrat.rating === 1} onChange={handleInputChangeRating}/>
                          <label aria-label="2 stars" className="rating__label" htmlFor="rating-2" ><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" checked={inputsrat.rating === 2} onChange={handleInputChangeRating}/>
                          <label aria-label="3 stars" className="rating__label" htmlFor="rating-3" ><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" checked={inputsrat.rating === 3} onChange={handleInputChangeRating} />
                          <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" checked={inputsrat.rating === 4} onChange={handleInputChangeRating}/>
                          <label aria-label="5 stars" className="rating__label" htmlFor="rating-5"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" checked={inputsrat.rating === 5} onChange={handleInputChangeRating}/>
                       </div>
                    </div>*/}
                    <NavLink to="#" onClick={handleRating}><Button variant="" type="submit">Pay Now</Button></NavLink>
                    </Col>
                 </Row>
                 </Col>
                 </Col>
              </Row>
			  {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )} 
            {/* ========== User Invoice Box End ========== */}
           </Container>
        </section>
        {/* ========== User Invoice Page End ========== */}
        </>
    )
}

export default UserInvic;