import React, { useEffect, useState  } from 'react';
import { Row, Col, Container, Button, Form, Modal } from 'react-bootstrap';
import './SalPerson.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, NavLink } from 'react-router-dom';
import API from '../../../Utils/Api';
import {validatew9form} from '../../../Utils/validate';
import SignatureCanvas from 'react-signature-canvas'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess } from '../../../Utils/Common';

const SalPerson = () => {
const navigate = useNavigate();

const [inputs,setInputs] = useState({name:'',gender:'',country_code:'91',mobile:'',dob:'',user_name:'',email:''});
const [image, setImage] = useState({preview: '', raw: ''});
const [disaddress, setDisaddress] = useState([]);

const [inputsw,setInputsw] = useState({sales_name:'',business_name:'',type:'c_corporation',limited_instructions:'',type_other_instructions:'',payee_code:'',fatca_reporting:'',address:'',zip_code:'',account_number:'',address2:'',security_number:'',identification_number:'',date:''});
const [signature_of_us_person, setSignature_of_us_person] = useState('');
const [pdfurl,setPdfurl] = useState("");

const [imagechk, setImagechk] = useState(false);

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [error,setError] = useState();
const [errors,setErrors] = useState({});

const [limitedshow, setLimitedshow] = useState(false);
const [othershow, setOthershow] = useState(false);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);


var sigPad = {}

useEffect(() => {
      document.title = `Sales Personal Information Setup | Blue Faucet`;
      document.getElementById('bodywrap').className=' ylwthem';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get profile */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData)
			.then(response => {
				
			if(response.data.status === 0){
				
			}else{
			  setInputs({
				  name: response.data.data.name,
				  gender: response.data.data.gender,
				  mobile: response.data.data.mobile,
				  dob: response.data.data.dob,
				  user_name: response.data.data.user_name,
				  email: response.data.data.email,
				  country_code:91
			  });
			  if(response && response.data.data.image !== ""){
					setImage({
						preview : response.data.data.image,
						raw : ''
					});
				}
				setDisaddress(response.data.data.user_address);
				var type='c_corporation';
				if(response.data.data.salesSetup.type!=='')
				{
					type=response.data.data.salesSetup.type;
				}
				var business_name='';
				if(response.data.data.salesSetup.business_name!=='' && response.data.data.salesSetup.business_name!=='null')
				{
					business_name=response.data.data.salesSetup.business_name;
				}
				var payee_code='';
				if(response.data.data.salesSetup.payee_code!=='' && response.data.data.salesSetup.payee_code!=='null')
				{
					payee_code=response.data.data.salesSetup.payee_code;
				}
				var fatca_reporting='';
				if(response.data.data.salesSetup.fatca_reporting!=='' && response.data.data.salesSetup.fatca_reporting!=='null')
				{
					fatca_reporting=response.data.data.salesSetup.fatca_reporting;
				}
				setInputsw({
				  sales_name: response.data.data.salesSetup.sales_name,
				  business_name: business_name,
				  type: type,
				  limited_instructions: response.data.data.salesSetup.limited_instructions,
				  type_other_instructions: response.data.data.salesSetup.type_other_instructions,
				  payee_code: payee_code,
				  fatca_reporting: fatca_reporting,
				  address: response.data.data.salesSetup.address,
				  zip_code: response.data.data.salesSetup.zip_code,
				  account_number: response.data.data.salesSetup.account_number,
				  address2: response.data.data.salesSetup.address2,
				  security_number: response.data.data.salesSetup.security_number,
				  identification_number: response.data.data.salesSetup.identification_number,
				  date: response.data.data.salesSetup.date,
			  });
			  setPdfurl(response.data.data.salesSetup.pdf);
			  if(response && response.data.data.salesSetup.signature_of_us_person !== ""){
					setSignature_of_us_person(response.data.data.salesSetup.signature_of_us_person);
				}
				
			}                  
		}).catch(error => {
			 
		});  
	 /* End get profile */

},[])  


/* Start Value set */
  const handleInputChange = (event) => {
      event.persist();
      setInputsw(inputsw => ({...inputsw, [event.target.name]: event.target.value})); 
		if(event.target.name==='type')
		{
			if(event.target.value==='limited')
			{
				setLimitedshow(true);
			}else{
				setLimitedshow(false);
			}
			if(event.target.value==='other')
			{
				setOthershow(true);
			}else{
				setOthershow(false);
			}
		}
    }
/* End Value set */
/* Start Signature of us person set */
/*const handleChange = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
	  alert('Please select valid image.');
	}else{
		setSignature_of_us_person({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}*/
/* Start Signature of us person set */
/* Start Submit W-9 Form */
	const handleSubmit = (event) => {
		event.preventDefault();
	
		const validationErrors = validatew9form(inputsw,signature_of_us_person,pdfurl);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setErrors({});
        setErrors(validationErrors);
		console.log(validationErrors);
		if(noErrors){
			let sales_name=inputsw.sales_name;
			let business_name=inputsw.business_name;
			let type=inputsw.type;
			let limited_instructions=inputsw.limited_instructions;
			let type_other_instructions=inputsw.type_other_instructions;
			let address=inputsw.address;
			let payee_code=inputsw.payee_code;
			let fatca_reporting=inputsw.fatca_reporting;
			let zip_code=inputsw.zip_code;
			let security_number=inputsw.security_number;
			let address2=inputsw.address2;
			let account_number=inputsw.account_number;
			let identification_number=inputsw.identification_number;
			let date=inputsw.date;
			
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('sales_name', sales_name);
			formData.append('business_name', business_name);
			formData.append('type', type);
			formData.append('limited_instructions', limited_instructions);
			formData.append('type_other_instructions', type_other_instructions);
			formData.append('address', address);
			formData.append('payee_code',payee_code);
			formData.append('fatca_reporting', fatca_reporting);
			formData.append('zip_code', zip_code);
			formData.append('security_number', security_number);
			formData.append('address2', address2);
			formData.append('account_number', account_number);
			formData.append('identification_number', identification_number);
			formData.append('date', date);
			if(imagechk)
			{
			  formData.append('signature_of_us_person', signature_of_us_person);
			}else{
				formData.append('signature_of_us_person', '');	
			}
			API.post('sales_W_9_Form', formData)
                .then(response => {
                if(response.data.status === 0){
                    setError(response.data.message);
                   
                }else{
					setPdfurl(response.data.data.url);
					setShow(false);
                }                  
            }).catch(error => {
                
				  setError("Something went wrong. Please try again later.");
			});  
			
		}
	}
const handleSalesSkip = () => {
		 let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			API.post('update_sales_status', formData)
                .then(response => {
                if(response.data.status === 0){
                    setError(response.data.message);
                }else{
					window.location.href = "/sales-video";
                }                  
            }).catch(error => {
                
				  setError("Something went wrong. Please try again later.");
			});  
  } 
/* End Submit W-9 Form */
const handleDownloadPDF = () => {
  if(pdfurl!=='')
  {
	  window.open(pdfurl, '_blank');
  }
}
const handleSalesNext = () => {
  if(pdfurl!=='')
  {
	 let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('update_sales_status', formData)
			.then(response => {
			if(response.data.status === 0){
				setError(response.data.message);
			}else{
				window.location.href = "/sales-video";
			}                  
		}).catch(error => {
			
			  setError("Something went wrong. Please try again later.");
		});  
  }else{
	  //alert('Please fill up W-9 Form or Skip to continue.');
	  gettoastsuccess('Please fill up W-9 Form or Skip to continue.','');
  }
} 

const clear = () => {
	sigPad.clear();
	//setSignature_of_us_person('');
	setImagechk(false);
}

const save = () => {
	setSignature_of_us_person(sigPad.getTrimmedCanvas().toDataURL('image/png'));
	setImagechk(true);
	setShow1(false);
//console.log(sigPad.getTrimmedCanvas().toDataURL('image/png'));
}

    return (
        <>
         {/* ========== Sales Personal Information Setup Page Start ========== */}
          <section className="gen-main">
		  <ToastContainer />
         {/* ========== Navbar Start ========== */}
             <Row className="maincntylwtm mx-0">
                <Col xs={1} md={1} className="align-self-center px-0">
                <button onClick={() =>
                   navigate(-1)} className="back-btn d-none">
                   <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                   </svg>
                </button>
                </Col>
                <Col xs={10} md={10} className="align-self-center text-center">
                <h3>Sales Information Setup</h3>
                </Col>
                <Col xs={1} md={1} className="align-self-center">
                <p></p>
                </Col>
             </Row>
         {/* ========== Navbar End ========== */}
             <Container>
                <Row md={12} className="genscrnylwtm">
                   <Col>
         {/* ========== Sales Personal Information Setup Content Start ========== */}
                   <Row md={12} className="maincntsrnylwtm">
                      <Col className="px-0">
					  {error && (
						<div className="alert alert-danger">
						  {error}
						</div>)}
                      <Col className="prflupld">
                      <div className="avatar-upload">
                         <div className="avatar-edit">
                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"/>
                            <label htmlFor="imageUpload"></label>
                         </div>
                         <div className="avatar-preview">
                            <div >
							{image.preview ? (
                          <> 
							<img src={image.preview} id="imagePreview" className="img-fluid" alt="Logo"/>
						  </>
							) : (
							  <>
							<img src="assets/images/usr-img.png" id="imagePreview" className="img-fluid" alt="Logo"/>
						</>
                          )}
						</div>
                         </div>
                      </div>
                      </Col>
                      <Form>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Your Name<span></span></Form.Label>
                            <Form.Control type="text" placeholder="john" value={inputs.name} disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Gender<span></span></Form.Label>
                            <Form.Select aria-label="Default select example" value={inputs.gender}>
                               <option value="">Select Gender</option>
							   <option value="Male">Male</option>
							   <option value="Female">Female</option>
							   <option value="Other">Other</option>
                            </Form.Select>
                         </Form.Group>
                         
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone Number<span></span></Form.Label>
                            <Form.Control type="tel" placeholder="9876543210" value={inputs.mobile} disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Date of Birth<span></span></Form.Label>
                            <Form.Control type="date" placeholder="dd/mm/yy" value={inputs.dob} disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3">
                          <Form.Label>Address<span></span></Form.Label>
							{disaddress.length > 0 && disaddress.map((item,index) => {
                          return(
						  <Col md={12} xs={12} className="mcusbx">
						   <Row className="mx-0"> 
							  <Col md={12} xs={12} className="align-self-center mcusbx-txtcnt ps-md-0 address-main-box">
							  <p>{item.title}</p>
							  <p>{item.street_name}</p>
							  <p>{item.state_name}</p>
							  <p>{item.city_name}</p>
							  <p>{item.zip_code}</p>
							  </Col>
							  
						   </Row>
						   </Col>
						  )
                          })}  
                        </Form.Group>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control type="text" placeholder="john" value={inputs.user_name} disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="admin@gmail.com" value={inputs.email}  disabled/>
                         </Form.Group>
                         <Form.Group className="mb-3 ckbox" controlId="exampleForm.ControlInput1">
                            <Form.Check aria-label="option 1" defaultChecked={true}/>
                            <Form.Label>I agree With <NavLink to="/sales-signup">Bluefaucet Sales Agreement</NavLink></Form.Label>
                         </Form.Group>
						 <Row className="sprsn-btnfil my-3">
                            <Col className="btn-lft">
                            <NavLink to="#" onClick={handleShow}><Button variant=""><img src= {'assets/images/sales/svg/plusfil-icn.svg'} className="img-fluid pe-2" alt="Btn Img"/>Fill-up W-9 Form</Button>{' '}</NavLink>
                            </Col>
                            <Col className="btn-rgt">
                            <NavLink to="#" onClick={handleDownloadPDF}><Button variant="">Download W-9 Form</Button></NavLink>
                            </Col>
							


                         </Row>
                         <Row>
						 {/* <Col className="btn-clr">
                            <NavLink to="#"><Button variant="">Clear</Button>{' '}</NavLink>
						 </Col>*/ }
							<Col className="btn-dv">
                            <Button onClick={handleSalesSkip}>Skip</Button>
                            </Col>
                            <Col className="btn-dv">
                            <Button onClick={handleSalesNext}>Next</Button>
                            </Col>
                         </Row>
                      </Form>
                      </Col>
                   </Row>
         {/* ========== Sales Personal Information Setup Content End ========== */}
                   </Col>
                </Row>
             </Container>
             <Col>
             <div className="sprsnlsetuppop">
         {/* ========== Sales Personal Information Setup W-9 Form Popup Start ========== */}
                <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                   <Modal.Header closeButton>
                      <h3 className="sprslstp-tptxt">W-9 Form</h3>
                   </Modal.Header>
                   <Modal.Body>
				   <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                      <Col md={12} xs={12} className="sprsnlstppopcnt">
					  {error && (
						<div className="alert alert-danger">
						  {error}
						</div>)}
                      <p className="fw-bold">(Rev. October 2018)</p>
                      <p>Department of the Treasury Internal Revenue Service</p>
                      <h3>Request for Taxpayer Identification Number and Certification</h3>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">1.Name (as shown on your income tax return). Name is required on this line; do not leave this line blank.<span></span></Form.Label>
                         <Form.Control type="text" name="sales_name" placeholder="Enter your name" onChange={handleInputChange} value={inputsw.sales_name} />
						 {errors.sales_name && (<p className="text-danger">{errors.sales_name}</p>)}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">2.Business name/disregarded entity name, if different from above<span></span></Form.Label>
                         <Form.Control type="text" placeholder="Business Name" name="business_name" onChange={handleInputChange} value={inputsw.business_name}  />
						 {errors.business_name && (<p className="text-danger">{errors.business_name}</p>)}
                      </Form.Group>
                      <Form.Group className="mb-3 prsnckbx" controlId="exampleForm.ControlInput1">
                         <Form.Label>3.Check appro priate box for federal tax classification of the person whose name is entered on line 1. Check only one of the following seven boxes.<span></span></Form.Label>
                         <Form.Check type="radio" label="Individual/sole proprietor or single-member LLC" name="type" id="formHorizontalRadiosmsctp1" value="individual" onChange={handleInputChange} checked={inputsw.type === 'individual'}/>
                         <Form.Check type="radio" label="All" name="type" id="formHorizontalRadiosmsctp1" value="all" onChange={handleInputChange} checked={inputsw.type === 'all'}/>
                         <Form.Check type="radio" label="C Corporation"  name="type" id="formHorizontalRadiosmsctp1" value="c_corporation"  onChange={handleInputChange} checked={inputsw.type === 'c_corporation'}/>
                         <Form.Check type="radio" label="S Corporation" name="type" id="formHorizontalRadiosmsctp1" value="s_corporation" onChange={handleInputChange} checked={inputsw.type === 's_corporation'}/>
                         <Form.Check type="radio" label="Partnership" name="type" id="formHorizontalRadiosmsctp1" value="partnership" onChange={handleInputChange} checked={inputsw.type === 'partnership'}/>
                         <Form.Check type="radio" label="Trust/estate" name="type" id="formHorizontalRadiosmsctp1" value="trust" onChange={handleInputChange} checked={inputsw.type === 'trust'}/>
                         <Form.Check type="radio" label="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)" name="type" id="formHorizontalRadiosmsctp1" value="limited" onChange={handleInputChange} checked={inputsw.type === 'limited'}/>
                      </Form.Group>
					  {limitedshow && (
					  <>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label><span></span></Form.Label>
                         <Form.Control type="text" placeholder="" name="limited_instructions" onChange={handleInputChange} value={inputsw.limited_instructions} />
						 {errors.limited_instructions && (<p className="text-danger">{errors.limited_instructions}</p>)}
                      </Form.Group>
					 </>
					 )}
                      <div className="msgnote">
                         <p>Note: Check the appropriate box in the line above for the tax classification of the single-member owner. Do not check LLC if the LLC is classified as a single-member LLC that is disregarded from the owner unless the owner of the LLC is another LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member LLC that is disregarded from the owner should check the appropriate box for the tax classification of its owner.</p>
                      </div>
                      <Form.Group className="mb-3 prsnckbx" controlId="exampleForm.ControlInput1">
                         <Form.Check type="radio" label="Other (see instructions)" name="type" id="formHorizontalRadiosmsctp1" value="other" onChange={handleInputChange} checked={inputsw.type === 'other'}/>
                      </Form.Group>
                      <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
						{othershow && (
						<>
                         <Form.Control type="text" placeholder="Other Instructions" name="type_other_instructions" onChange={handleInputChange} value={inputsw.type_other_instructions}/>
                        </>
						)} 
						 <Form.Label className="fw-normal mt-2">4.Exemptions (codes apply only to certain entities, not individuals; see instructions on page 3):<span></span></Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">Exempt payee code (if any)<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" name="payee_code" onChange={handleInputChange} value={inputsw.payee_code}/>
						 {errors.payee_code && (<p className="text-danger">{errors.payee_code}</p>)}
                      </Form.Group>
					  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">Exemption from FATCA reporting code<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" name="fatca_reporting" onChange={handleInputChange} value={inputsw.fatca_reporting}/>
						 {errors.fatca_reporting && (<p className="text-danger">{errors.fatca_reporting}</p>)}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">5.Address (number, street, and apt. or suite no.) See instructions.<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" name="address" onChange={handleInputChange} value={inputsw.address}/>
						 {errors.address && (<p className="text-danger">{errors.address}</p>)}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">6.City, state, and ZIP code<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" name="zip_code" onChange={handleInputChange} value={inputsw.zip_code}/>
						 {errors.zip_code && (<p className="text-danger">{errors.zip_code}</p>)}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">7.List account number(s) here (optional)<span></span></Form.Label>
                         <Form.Control type="text" placeholder="" name="account_number" onChange={handleInputChange} value={inputsw.account_number}/>
						 {errors.account_number && (<p className="text-danger">{errors.account_number}</p>)}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-normal">Requester’s name and address (optional)<span></span></Form.Label>
                         <Form.Control as="textarea" rows={3} name="address2" onChange={handleInputChange} value={inputsw.address2}/>
						 {errors.address2 && (<p className="text-danger">{errors.address2}</p>)}
                      </Form.Group>
                      <div className="prt-1">
                         <h4>Part I</h4>
                         <h6>Taxpayer Identification Number (TIN)</h6>
                         <p>Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other entities, it is your employer identification number (EIN). If you do not have a number, see How to get a TIN, later.</p>
                         <div className="msgnote my-3">
                            <p>Note: If the account is in more than one name, see the instructions for line 1. Also see What Name and Number To Give the Requester for guidelines on whose number to enter.</p>
                         </div>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="fw-normal">Social security number<span></span></Form.Label>
                            <Form.Control type="text" placeholder="" name="security_number" onChange={handleInputChange} value={inputsw.security_number}/>
							{errors.security_number && (<p className="text-danger">{errors.security_number}</p>)}
                         </Form.Group>
                         <div className="">OR</div>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="fw-normal">Employer identification number<span></span></Form.Label>
                            <Form.Control type="text" placeholder="" name="identification_number" onChange={handleInputChange} value={inputsw.identification_number}/>
							{errors.identification_number && (<p className="text-danger">{errors.identification_number}</p>)}
                         </Form.Group>
                      </div>
                      <div className="prt-2">
                         <h4>Part II</h4>
                         <h6>Certification</h6>
                         <p>Under penalties of perjury, I certify that:</p>
                         <ul>
                            <li>The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and</li>
                            <li>I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and</li>
                            <li>I am a U.S. citizen or other U.S. person (defined below); and</li>
                            <li>The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.</li>
                         </ul>
                         <h5>Certification instructions</h5>
                         <p>You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.</p>
                         <h5>Sign Here</h5>
                         <Row>
                            <Col>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                               <Form.Label className="fw-normal">Signature of U.S. person<span></span></Form.Label>
                               {/*<Form.Control type="file" placeholder="" name="signature_of_us_person" onChange={handleChange} accept="image/png, image/jpg, image/jpeg" />*/}
							   <Button type="button" onClick={handleShow1}>Add Signature</Button>
							   {signature_of_us_person
								? <img  alt='signature'
								  src={signature_of_us_person} className="sig-image" />
								: null}
							   {errors.signature_of_us_person && (<p className="text-danger">{errors.signature_of_us_person}</p>)}
                            </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                               <Form.Label className="fw-normal">Date<span></span></Form.Label>
                               <Form.Control type="date" placeholder="" name="date" onChange={handleInputChange} value={inputsw.date}/>
							   {errors.date && (<p className="text-danger">{errors.date}</p>)}
                            </Form.Group>
                            </Col>
                         </Row>
						 

                      </div>
                      </Col>
                      <Row className="my-3 ">
                         <Col className="popprflstplstbtn">
                         <Button type="submit">Submit</Button>
                         </Col>
                      </Row>
					  </Form>
                   </Modal.Body>
                   <Modal.Footer className="d-none"></Modal.Footer>
                </Modal>
         {/* ========== Sales Personal Information Setup W-9 Form Popup End ========== */}
		 {/* ========== Sales Personal Information Setup W-9 Form Popup Start ========== */}
                <Modal show={show1} onHide={handleClose1} className="popupwrngcnt">
                   <Modal.Header closeButton>
                      <h3 className="sprslstp-tptxt">Signature</h3>
                   </Modal.Header>
                   <Modal.Body>
					<SignatureCanvas penColor='green'
    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} ref={(ref) => { sigPad = ref }} />
                   </Modal.Body>
                   <Modal.Footer><button onClick={clear} className="btn btn-primary">clear</button> <button onClick={save} className="btn btn-primary">Save</button></Modal.Footer>
                </Modal>
         {/* ========== Sales Personal Information Setup W-9 Form Popup End ========== */}
             </div>
             </Col>
          </section>
         {/* ========== Sales Personal Information Setup Page End ========== */}
        </>
    )
}
export default SalPerson;