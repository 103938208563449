import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Accordion } from 'react-bootstrap';
import './SalHelp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';

const SalHelp = () => {
const [help, setHelp] = useState([]);
useEffect(() => {
      document.title = `Sales Help | Blue Faucet`;
      document.getElementById('bodywrap').className='mhelppgmain ylwthem';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
	
	/* Start get help */
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('type', 'sales');
		API.post('get_help', formData)
			.then(response => {
				
			if(response.data.status === 0){
				
			}else{
				setHelp(response.data.data.help);
			}                  
		}).catch(error => {
			 
		});  
	/* End get help */
},[])  
    return (
        <>
        {/* ========== Sales Help Page Start ========== */}
            <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
             <Navbar headerText={'Help Center'}/>
        {/* ========== Navbar End ========== */}
             <Container>
                <Row className="mx-0 mianhmdwncnt">
                  <Col className="mnhmcnt">
        {/* ========== Sales Help Content Start ========== */}
                   <Col md={12} xs={12} className="py-3">
                     <Row className="mx-0">
                       <Col md={12} xs={12} className="px-0 usrhlp">
                         <Accordion defaultActiveKey={0}>
						  {help && help.map((val,index) => {
							return(
							   <Accordion.Item eventKey={index}>
								  <Accordion.Header>{val.title} </Accordion.Header>
								  <Accordion.Body>
									 {val.description}
								  </Accordion.Body>
							   </Accordion.Item>
						   )
						  })}
						</Accordion>
                       </Col>
                     </Row>
                   </Col>
        {/* ========== Sales Help Content End ========== */} 
                  </Col>
                </Row>
             </Container>
          </section>
        {/* ========== Sales Help Page End ========== */}
        </>
    )
}

export default SalHelp;